import React from 'react'
import { Icon } from '@blueprintjs/core'
import { observer } from 'mobx-react'

const AudioThumbnail = observer(
  class AudioThumbnail extends React.Component {
    render() {
      return (
        <div className="thumbnail audio-thumbnail">
          <Icon icon="volume-up" />
        </div>
      )
    }
  }
)

export default AudioThumbnail
