import React from 'react'
import { InputGroup } from '@blueprintjs/core'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'

const TextThumbnail = observer(
  class TextThumbnail extends React.Component {
    getContentProxy() {
      let contentProxy
      switch (this.props.action.command) {
        case 'sample':
          let sequence =
            this.props.action.targetCharacter.parentScore.currentScene.getSequence(
              this.props.action.content
            )
          if (sequence) {
            if (this.props.action.mimic) {
              contentProxy = 'Mimic ' + sequence.title.trim()
            } else {
              contentProxy = 'Sample ' + sequence.title.trim()
            }
          }
          break
        default:
          contentProxy = this.props.action.content.trim()
          break
      }
      /*if (contentProxy === '') {
      contentProxy = '[empty]';
    } else if (contentProxy.trim() === '') {
      contentProxy = '“' + contentProxy + '”';
    }*/
      return contentProxy
    }

    handleActionPropertyChange(evt, property, value) {
      let propValue = value ? value : evt.currentTarget.value
      this.props.action[property] = propValue
      this.props.onStepwiseEvent('action', this.props.action)
    }

    handleKeyUp(evt) {
      if (evt.key === 'Enter' && evt.shiftKey) {
        this.props.onAddStep()
      }
    }

    render() {
      let className = 'thumbnail text-thumbnail'
      if (this.props.current) className += ' current'
      let contentProxy = this.getContentProxy()
      if (contentProxy === '' && !this.props.current) {
        return null
      } else {
        return (
          <div className={className}>
            {this.props.current && this.props.editable ? (
              <InputGroup
                id="speak-input"
                value={this.props.action.content}
                ref={(component) => {
                  let overlays =
                    document.getElementsByClassName('bp3-overlay-open')
                  let node = ReactDOM.findDOMNode(component)
                  if (node && this.props.current && overlays.length === 0)
                    node.firstChild.focus()
                }}
                placeholder="Enter text..."
                onChange={(evt) =>
                  this.handleActionPropertyChange(evt, 'content')
                }
                onKeyUp={this.handleKeyUp.bind(this)}
              />
            ) : (
              this.getContentProxy()
            )}
          </div>
        )
      }
    }
  }
)

export default TextThumbnail
