import React from 'react'
import './Activate.css'
import {
  Colors,
  Button,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import firebase from './firebase.js'
import text from './i18n/english'

class Activate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activationCode: null,
      activationResponse: { t: 'Nothing yet' },
      activationMessage: 'Attempting activation...',
      hasBeenSubmitted: false,
    }
    this.inputRef = React.createRef()
    this.searchParams = new URLSearchParams(this.props.location.search)
  }

  onRefChange = (node) => {
    if (node) {
      node.focus()
    }
  }

  handleInput = (evt) => {
    this.setState({ activationCode: evt.target.value })
  }

  handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    console.log('submit')
    var xhr = new XMLHttpRequest()
    let data = {
      activationCode: this.state.activationCode,
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          this.signIn(JSON.parse(xhr.responseText).t)
          this.setState({
            activationResponse: JSON.parse(xhr.responseText),
            activationMessage: text.activate.activationSuccess,
          })
          if (window.vuplex) {
            this.tellUnitySignedIn()
          } else {
            window.addEventListener('vuplexready', this.tellUnitySignedIn)
          }
        } else {
          this.setState({
            activationMessage: text.activate.activationFailure,
          })
          console.log('error authorizing')
          this.tellUnityNotSignedIn()
        }
      }
    }
    xhr.open('POST', 'https://origin.step.works/appauthresp', true)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(data))
    this.setState({ hasBeenSubmitted: true })
  }

  signIn = (customToken) => {
    firebase
      .auth()
      .signInWithCustomToken(customToken)
      .then((userCredential) => {
        window.open(
          'https://' + process.env.REACT_APP_HOST_DOMAIN + '/stories',
          '_self'
        )
      })
      .catch((error) => {
        console.log(error.code, error.message)
        this.tellUnityNotSignedIn()
      })
  }

  tellUnitySignedIn = () => {
    if (window.vuplex) window.vuplex.postMessage('user signed in')
  }

  tellUnityNotSignedIn = () => {
    if (window.vuplex) window.vuplex.postMessage('user not signed in')
  }

  getInstructions = () => {
    switch (this.searchParams.get('device')) {
      case 'vr':
        return (
          <p id="instructions" className="bp3-text-large">
            {text.activate.vrInstructions}
          </p>
        )
      default:
        return (
          <p id="instructions" className="bp3-text-large">
            {text.activate.desktopInstructions}
          </p>
        )
    }
  }

  render() {
    return (
      <div
        className="absolute text-center w-full h-full left-0 top-0 p-5 flex items-center justify-center"
        style={{ background: Colors.DARK_GRAY4 }}
      >
        <div>
          <div className="max-w-md mb-6">
            <p>
              <img
                className="logo m-auto w-12 h-12 mb-4"
                src="/images/stepwise_insignia_red_lg@2x.png"
                alt={text.about.logoAlt}
              />
            </p>
            {this.getInstructions()}
          </div>
          <FormGroup labelFor="code-input">
            <InputGroup
              id="code-input"
              inputRef={this.onRefChange}
              large={true}
              placeholder={text.activate.placeholder}
              onChange={this.handleInput}
              onKeyUp={this.handleKeyUp}
            />
          </FormGroup>
          <Button
            type="submit"
            className="mt-2"
            large
            intent={Intent.PRIMARY}
            disabled={this.state.hasBeenSubmitted}
            onClick={this.handleSubmit}
          >
            {text.activate.submit}
          </Button>
          <br />
          <br />
          {this.state.hasBeenSubmitted ? (
            <p class="bp3-text-muted">{this.state.activationMessage}</p>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Activate
