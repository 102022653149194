import React from 'react'
import { Rect, PanelSubRect } from './stepworks-studio-utils.js'
import Panel from './Panel.js'
import { Action } from './stepwise/stepwise-v2.js'

class Channel extends React.Component {
  constructor(props) {
    super(props)
    this.baseElement = React.createRef()
    this.handleSolo = this.handleSolo.bind(this)
    this.animate = this.animate.bind(this)
  }

  componentDidMount() {
    this.updateLayout()
    requestAnimationFrame(this.animate)
  }

  shouldComponentUpdate() {
    return false
  }

  doUpdate() {
    this.forceUpdate()
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) {
        panelRef.doUpdate()
      }
    })
  }

  resetLayout() {
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) {
        panelRef.setLayout(
          this.props.channel.grid.columns + 1,
          0,
          0,
          this.props.channel.grid.columns,
          this.props.channel.grid.rows
        )
      }
    })
  }

  resetContent() {
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) panelRef.resetContent()
    })
  }

  distributePanels(method) {
    let characters = this.getCharacters(true)

    // calculate grid
    let rows, columns
    if (characters.length < 4) {
      rows = characters.length
      columns = 1
    } else {
      rows = Math.round(Math.sqrt(characters.length))
      columns = Math.ceil(characters.length / parseFloat(rows))
    }

    // remove actions
    let n = this.props.editStep.actions.length
    for (let i = n - 1; i >= 0; i--) {
      let action = this.props.editStep.actions[i]
      if (action.command === 'enter' || action.command === 'move') {
        this.props.editStep.deleteAction(action)
      }
    }

    let characterIndex = 0
    let currentHue = Math.round(Math.random() * 360)
    let hueStep = 360.0 / characters.length
    let columnUnit = 12.0 / columns
    let rowUnit = 12.0 / rows
    for (let x = 0; x < columns; x++) {
      for (let y = 0; y < rows; y++) {
        let character = characters[characterIndex]
        if (character) {
          let action = new Action(
            {
              character: character.id,
              command: 'move',
              content:
                x * columnUnit +
                ' ' +
                y * rowUnit +
                ' ' +
                columnUnit +
                ' ' +
                rowUnit,
            },
            this.props.stepwise.score
          )
          this.props.editStep.actions.push(action)
          characterIndex++
          currentHue += hueStep
          let state
          if (method === 'background-color') {
            let feature = character.getFeatureForType('frame')
            let currentState =
              this.props.sequence.getCurrentStateForFeatureInStep(
                feature,
                this.props.editStep,
                true,
                true
              )
            state = this.props.editStep.getStateForFeatureType(feature, true)
            if (!state) {
              state = this.props.editStep.createStateForFeature(
                feature,
                currentState
              )
            }
            state.backgroundColor = 'hsla(' + currentHue + ', 100%, 35%, 1)'

            feature = character.getFeatureForType('text')
            currentState = this.props.sequence.getCurrentStateForFeatureInStep(
              feature,
              this.props.editStep,
              true,
              true
            )
            state = this.props.editStep.getStateForFeatureType(feature, true)
            if (!state) {
              state = this.props.editStep.createStateForFeature(
                feature,
                currentState
              )
            }
            state.align = 'center'
            state.textAlign = 'center'
            state.color = 'white'
          } else if (method === 'text-color') {
            let feature = character.getFeatureForType('frame')
            let currentState =
              this.props.sequence.getCurrentStateForFeatureInStep(
                feature,
                this.props.editStep,
                true,
                true
              )
            state = this.props.editStep.getStateForFeatureType(feature, true)
            if (!state) {
              state = this.props.editStep.createStateForFeature(
                feature,
                currentState
              )
            }
            state.backgroundColor = 'black'

            feature = character.getFeatureForType('text')
            currentState = this.props.sequence.getCurrentStateForFeatureInStep(
              feature,
              this.props.editStep,
              true,
              true
            )
            state = this.props.editStep.getStateForFeatureType(feature, true)
            if (!state) {
              state = this.props.editStep.createStateForFeature(
                feature,
                currentState
              )
            }
            state.align = 'center'
            state.textAlign = 'center'
            state.color = 'hsla(' + currentHue + ', 100%, 80%, 1)'
          }
        }
      }
    }
    this.props.stepwise.score.setupReferences()

    this.props.onStepChange(this.props.editStep)
  }

  handleStoryUpdate() {
    this.forceUpdate()
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) {
        panelRef.handleStoryUpdate()
      }
    })
  }

  handleStepwiseEvent(type, obj) {
    //console.log(type,obj);
    let panelRef
    switch (type) {
      /*case 'nextStep':
      for (let property in this.panelRefs) {
        this.panelRefs[property].handleStepwiseEvent(type, obj);
      };
      break;*/

      case 'state':
        if (
          this.props.charactersToIgnore.indexOf(
            obj.targetFeature.parentCharacter.id
          ) === -1
        ) {
          Object.values(this.panelRefs).forEach((panelRef) => {
            if (panelRef) {
              if (
                panelRef.props.character === obj.targetFeature.parentCharacter
              ) {
                panelRef.handleStepwiseEvent(type, obj)
              }
            }
          })
        }
        break

      case 'action':
        if (
          this.props.charactersToIgnore.indexOf(obj.targetCharacter.id) === -1
        ) {
          panelRef = this.panelRefs[obj.targetCharacter.id]
          switch (obj.command) {
            case 'enter':
              if (obj.targetCharacter.visible) {
                let aspectRatio = this.getMediaAspectRatio(panelRef)
                if (obj.amount.indexOf('custom') === -1) {
                  let includeVertical =
                    obj.direction.indexOf('left') !== -1 ||
                    obj.direction.indexOf('right') !== -1
                  let includeHorizontal =
                    obj.direction.indexOf('top') !== -1 ||
                    obj.direction.indexOf('bottom') !== -1
                  let seams = this.getSeams(
                    includeVertical,
                    includeHorizontal,
                    aspectRatio
                  )
                  this.buildEntranceTransitionFromSeams(
                    obj.targetCharacter.id,
                    seams,
                    aspectRatio,
                    obj.direction,
                    obj.size,
                    obj.amount,
                    obj.physics
                  )
                } else {
                  this.buildExplicitEntranceTransition(
                    obj.targetCharacter.id,
                    obj.direction,
                    obj.content,
                    obj.physics
                  )
                }
                panelRef.handleStepwiseEvent(type, obj)
              }
              break

            case 'play-audio':
              panelRef.handleStepwiseEvent(type, obj)
              break

            case 'speak':
              if (obj.targetCharacter.visible && panelRef) {
                if (
                  panelRef.enterAction &&
                  this.panelIsOffScreenForStep(panelRef, this.lastStep)
                ) {
                  this.props.onStepwiseEvent('action', panelRef.enterAction)
                }
                panelRef.handleStepwiseEvent(type, obj)
              }
              break

            default:
              if (obj.targetCharacter.visible) {
                if (panelRef) {
                  panelRef.handleStepwiseEvent(type, obj)
                }
              }
              break
          }
        }
        break

      case 'step':
      case 'sequence':
        //console.log('----');
        Object.values(this.panelRefs).forEach((panelRef) => {
          if (panelRef) {
            panelRef.handleStepwiseEvent(type, obj)
          }
        })
        if (type === 'step') {
          this.lastStep = obj
        }
        break

      /*case 'characterWasDeleted':
      console.log('deleting '+obj);
      delete this.panelRefs[obj];
      console.log(this.panelRefs[obj]);
      this.forceUpdate();
      console.log(this.panelRefs[obj]);
      console.log(Object.keys(this.panelRefs));
      break;*/

      default:
        break
    }
  }

  panelIsOffScreenForStep(panelRef, step) {
    let n = step.actions.length
    for (var i = 0; i < n; i++) {
      let action = step.actions[i]
      if (action.targetCharacter === panelRef.props.character) {
        if (action.command === 'enter' || action.command === 'move') {
          return false
        }
      }
    }
    return !this.panelIsOnScreen(panelRef)
  }

  getStates() {
    let states = []
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) {
        states = states.concat(panelRef.getStates())
      }
    })
    return states
  }

  getActions() {
    let actions = []
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) {
        actions = actions.concat(panelRef.getActions())
      }
    })
    return actions
  }

  handleSolo(characterId) {
    let soloPanel = this.panelRefs[characterId]
    if (soloPanel) {
      Object.values(this.panelRefs).forEach((panelRef) => {
        if (panelRef !== soloPanel) {
          if (panelRef) panelRef.mute()
        }
      })
    }
  }

  togglePlayPause() {
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) panelRef.togglePlayPause()
    })
  }

  pause() {
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) panelRef.pause()
    })
  }

  play() {
    Object.values(this.panelRefs).forEach((panelRef) => {
      if (panelRef) panelRef.play()
    })
  }

  getFullSeams(direction) {
    let includeHorizontal =
      direction.indexOf('left') !== -1 || direction.indexOf('right') !== -1
    let includeVertical =
      direction.indexOf('top') !== -1 || direction.indexOf('bottom') !== -1
    var xcoords = []
    var ycoords = []
    var seams = []
    var screenRect = new Rect(
      this.props.channel.layout.left * this.unit.width,
      this.props.channel.layout.top * this.unit.height,
      this.props.channel.layout.width * this.unit.width,
      this.props.channel.layout.height * this.unit.height
    )
    xcoords.push(screenRect.xMin)
    xcoords.push(screenRect.xMax)
    ycoords.push(screenRect.yMin)
    ycoords.push(screenRect.yMax)
    this.subrects = this.createArray(xcoords.length - 1, ycoords.length - 1)
    if (includeHorizontal) {
      seams.push(
        new Rect(screenRect.xMin, screenRect.yMin, 1, screenRect.height)
      )
    }
    if (includeVertical) {
      seams.push(
        new Rect(screenRect.xMin, screenRect.yMin, screenRect.width, 1)
      )
    }
    return seams
  }

  getMediaAspectRatio(panel) {
    let sequence = this.props.stepwise.score.currentScene.defaultSequence
    let step = sequence.steps[Math.max(0, sequence.stepIndex)]
    let state = sequence.getCurrentStateForCharacterInStep(
      panel.props.character,
      'video',
      step
    )
    if (state) {
      if (!state.media) {
        state = null
      }
    }
    if (!state) {
      state = sequence.getCurrentStateForCharacterInStep(
        panel.props.character,
        'image',
        step
      )
    }
    let media = this.props.stepwise.score.getMedia(state.media)
    let aspectRatio = 1
    if (media) {
      aspectRatio = media.width / parseFloat(media.height)
    }
    return aspectRatio
  }

  getSeams(includeVertical, includeHorizontal, aspectRatio) {
    var sourceRects = []
    var xcoords = []
    var ycoords = []
    var seams = []
    var panel,
      rect,
      psRect,
      psRectSource,
      noOverlappingPanels,
      noMismatches,
      v,
      i,
      j,
      n,
      o
    var screenRect = new Rect(
      this.props.channel.layout.left * this.unit.width,
      this.props.channel.layout.top * this.unit.height,
      this.props.channel.layout.width * this.unit.width,
      this.props.channel.layout.height * this.unit.height
    )
    let characters = this.getCharacters(true)
    let panelRefs = Object.values(this.panelRefs)
    let lockedPanels = []
    n = panelRefs.length
    for (i = 0; i < n; i++) {
      panel = panelRefs[i]
      if (panel) {
        if (
          characters.length === 0 ||
          characters.indexOf(panel.props.character) !== -1
        ) {
          if (this.panelIsOnScreen(panel)) {
            sourceRects.push(
              new PanelSubRect(
                panel,
                new Rect(
                  panel.gridLayout.left * this.unit.width,
                  panel.gridLayout.top * this.unit.height,
                  panel.gridLayout.width * this.unit.width,
                  panel.gridLayout.height * this.unit.height
                )
              )
            )
            /*let sequence = this.props.stepwise.score.currentScene.defaultSequence;
            let step = sequence.steps[sequence.stepIndex];
            let enterAction = sequence.getPriorActionForCharacterFromStep('enter', panel.props.character, step);
            /*let feature = panel.props.character.getFeatureForType('frame');
            let sequence = this.props.stepwise.score.currentScene.defaultSequence;
            let step = sequence.steps[sequence.stepIndex];
            let frameState = sequence.getCurrentStateForFeatureInStep(feature, step, true, true);*/
          }
        }
      }
    }
    xcoords.push(Math.round(screenRect.xMin))
    xcoords.push(Math.round(screenRect.xMax))
    ycoords.push(Math.round(screenRect.yMin))
    ycoords.push(Math.round(screenRect.yMax))

    // build a grid from all the unique x and y coords in the rects
    for (let psRect of sourceRects) {
      v = Math.round(
        Math.min(screenRect.xMax, Math.max(screenRect.xMin, psRect.rect.xMin))
      )
      if (xcoords.indexOf(v) === -1) {
        xcoords.push(v)
      }
      v = Math.round(
        Math.min(screenRect.xMax, Math.max(screenRect.xMin, psRect.rect.xMax))
      )
      if (xcoords.indexOf(v) === -1) {
        xcoords.push(v)
      }
      v = Math.round(
        Math.min(screenRect.yMax, Math.max(screenRect.yMin, psRect.rect.yMin))
      )
      if (ycoords.indexOf(v) === -1) {
        ycoords.push(v)
      }
      v = Math.round(
        Math.min(screenRect.yMax, Math.max(screenRect.yMin, psRect.rect.yMax))
      )
      if (ycoords.indexOf(v) === -1) {
        ycoords.push(v)
      }
    }
    xcoords.sort(function (a, b) {
      return a - b
    })
    ycoords.sort(function (a, b) {
      return a - b
    })
    this.subrects = this.createArray(xcoords.length - 1, ycoords.length - 1)

    n = Math.max(0, xcoords.length - 1)
    for (i = 0; i < n; i++) {
      o = Math.max(0, ycoords.length - 1)
      for (j = 0; j < o; j++) {
        // create a rect for each cell in the grid
        rect = new Rect(
          xcoords[i],
          ycoords[j],
          xcoords[i + 1] - xcoords[i],
          ycoords[j + 1] - ycoords[j]
        )
        psRect = new PanelSubRect(null, rect)
        // check the rect against all of the other source rects
        if (sourceRects.length > 0) {
          var p = sourceRects.length
          for (var k = 0; k < p; k++) {
            psRectSource = sourceRects[k]
            if (psRectSource.rect.contains(psRect.rect.center())) {
              psRect.panel = psRectSource.panel
            }
          }
        }
        this.subrects[i][j] = psRect
      }
    }

    if (lockedPanels.length === 0) {
      if (includeHorizontal && aspectRatio >= 1) {
        seams.push(new Rect(screenRect.x, screenRect.y, screenRect.width, 1))
      }
      if (includeVertical && aspectRatio <= 1) {
        seams.push(new Rect(screenRect.x, screenRect.y, 1, screenRect.height))
      }
    }

    // look for rows
    if (includeVertical && ycoords.length > 1) {
      n = Math.max(0, ycoords.length - 1)
      for (var y = 0; y < n; y++) {
        noOverlappingPanels = true
        if (this.subrects[0].length > 1) {
          o = Math.max(0, xcoords.length - 1)
          for (var x = 0; x < o; x++) {
            if (y < n - 1) {
              if (this.subrects[x][y].panel === this.subrects[x][y + 1].panel) {
                noOverlappingPanels = false
                break
              }
            }
            if (y > 0) {
              if (this.subrects[x][y].panel === this.subrects[x][y - 1].panel) {
                noOverlappingPanels = false
                break
              }
            }
            // locked panels block the row
            if (lockedPanels.indexOf(this.subrects[x][y].panel) !== -1) {
              noOverlappingPanels = false
              break
            }
          }
        }
        // discard full seams if the aspect ratio doesn't match
        noMismatches = true
        if (
          Math.round((ycoords[y + 1] - ycoords[y]) / this.unit.height) ===
          this.props.channel.grid.rows
        ) {
          if (aspectRatio > 1) {
            noMismatches = false
          }
        }
        if (noOverlappingPanels && noMismatches) {
          seams.push(new Rect(0, ycoords[y], 1, ycoords[y + 1] - ycoords[y]))
        }
      }
    }

    // look for columns
    if (includeHorizontal && xcoords.length > 1) {
      n = Math.max(0, xcoords.length - 1)
      for (x = 0; x < n; x++) {
        noOverlappingPanels = true
        if (this.subrects.length > 1) {
          o = Math.max(0, ycoords.length - 1)
          for (y = 0; y < o; y++) {
            if (x < n - 1) {
              if (this.subrects[x][y].panel === this.subrects[x + 1][y].panel) {
                noOverlappingPanels = false
                break
              }
            }
            if (x > 0) {
              if (this.subrects[x][y].panel === this.subrects[x - 1][y].panel) {
                noOverlappingPanels = false
                break
              }
            }
            // locked panels block the column
            if (lockedPanels.indexOf(this.subrects[x][y].panel) !== -1) {
              noOverlappingPanels = false
              break
            }
          }
        }
        // discard full seams if the aspect ratio doesn't match
        noMismatches = true
        if (
          Math.round((xcoords[x + 1] - xcoords[x]) / this.unit.width) ===
          this.props.channel.grid.columns
        ) {
          if (aspectRatio < 1) {
            noMismatches = false
          }
        }
        if (noOverlappingPanels && noMismatches) {
          seams.push(new Rect(xcoords[x], 0, xcoords[x + 1] - xcoords[x], 1))
        }
      }
    }

    if (seams.length === 0) {
      if (aspectRatio >= 1) {
        seams.push(new Rect(screenRect.x, screenRect.y, 1, screenRect.height))
      }
      if (aspectRatio <= 1) {
        seams.push(new Rect(screenRect.x, screenRect.y, screenRect.width, 1))
      }
    }

    return seams
  }

  createArray(length) {
    var args
    var arr = new Array(length || 0)
    var i = length
    if (arguments.length > 1) {
      args = Array.prototype.slice.call(arguments, 1)
      while (i--) {
        arr[length - 1 - i] = this.createArray.apply(this, args)
      }
    }
    return arr
  }

  getSeamsForDirection(seams, direction) {
    let matchingSeams = []
    seams.forEach((seam) => {
      if (seam.width === 1) {
        if (
          direction.indexOf('left') !== -1 ||
          direction.indexOf('right') !== -1
        ) {
          matchingSeams.push(seam)
        }
      } else {
        if (
          direction.indexOf('top') !== -1 ||
          direction.indexOf('bottom') !== -1
        ) {
          matchingSeams.push(seam)
        }
      }
    })
    return matchingSeams
  }

  convertStringArrayToNumericSize(sizeArray) {
    let numericSizeArray = []
    sizeArray.forEach((size) => {
      numericSizeArray.push(this.convertStringToNumericSize(size))
    })
    return numericSizeArray
  }

  convertStringToNumericSize(size) {
    switch (size) {
      case 'quarter':
        return 3
      case 'third':
        return 4
      case 'half':
        return 6
      case 'two-thirds':
        return 8
      case 'three-quarters':
        return 9
      case 'full':
        return 12
      default:
        break
    }
  }

  buildEntranceTransitionFromSeams(
    characterId,
    seams,
    aspectRatio,
    direction,
    size,
    amount,
    physics
  ) {
    let entranceLayout
    let panel = this.panelRefs[characterId]
    let filteredSeams = this.filterSeamsByDirection(seams, direction)
    let numericSizeArray = this.convertStringArrayToNumericSize(size)
    let sizeFilteredSeams = []
    filteredSeams.forEach((seam) => {
      if (seam.width === 1) {
        let unitHeight = Math.round(seam.height / this.unit.height)
        if (numericSizeArray.indexOf(unitHeight) !== -1) {
          sizeFilteredSeams.push(seam)
        }
      } else if (seam.height === 1) {
        let unitWidth = Math.round(seam.width / this.unit.width)
        if (numericSizeArray.indexOf(unitWidth) !== -1) {
          sizeFilteredSeams.push(seam)
        }
      }
    })

    if (sizeFilteredSeams.length === 0) {
      let screenRect = new Rect(
        this.props.channel.layout.left * this.unit.width,
        this.props.channel.layout.top * this.unit.height,
        this.props.channel.layout.width * this.unit.width,
        this.props.channel.layout.height * this.unit.height
      )
      sizeFilteredSeams.push(
        new Rect(screenRect.x, screenRect.y, screenRect.width, 1)
      )
      sizeFilteredSeams.push(
        new Rect(screenRect.x, screenRect.y, 1, screenRect.height)
      )
    }

    if (sizeFilteredSeams.length > 0) {
      let seam =
        sizeFilteredSeams[Math.floor(Math.random() * sizeFilteredSeams.length)]
      let numericAmount
      if (amount.indexOf('match-content') !== -1) {
        if (seam.width === 1) {
          numericAmount = Math.round(
            (seam.height * aspectRatio) / this.unit.width
          )
        } else if (seam.height === 1) {
          numericAmount = Math.round(
            seam.width / aspectRatio / this.unit.height
          )
        }
      } else {
        numericAmount = this.convertStringToNumericSize(
          amount[Math.floor(Math.random() * amount.length)]
        )
      }
      entranceLayout = this.getEntranceLayoutForSeam(
        panel,
        seam,
        aspectRatio,
        direction,
        numericAmount,
        physics
      )
    }
    if (entranceLayout) {
      this.doEntranceForPanel(characterId, entranceLayout, panel, physics)
    }
  }

  buildExplicitEntranceTransition(
    characterId,
    direction,
    layoutString,
    physics
  ) {
    let panel = this.panelRefs[characterId]
    if (!layoutString) {
      layoutString =
        '0 0 ' +
        this.props.channel.grid.columns +
        ' ' +
        this.props.channel.grid.rows
    }
    let entranceLayout = this.getEntranceLayoutForLayout(
      panel,
      direction,
      layoutString
    )
    if (entranceLayout) {
      this.doEntranceForPanel(characterId, entranceLayout, panel, physics)
    }
  }

  doEntranceForPanel(characterId, entranceLayout, panel, physics) {
    this.lastEntranceDirection = entranceLayout.entranceDirection
    let transition = panel.transition
    panel.setTransition(0)
    //console.log('entrance layout',entranceLayout);
    panel.setLayout(
      entranceLayout.x,
      entranceLayout.y,
      entranceLayout.w,
      entranceLayout.h
    )
    panel.updateLayout()
    panel.reflow()
    //let state = panel.getCurrentFrameState();
    //this.props.replaceHistoryState(state);
    panel.setTransition(transition)
    switch (physics) {
      case 'press':
        break

      case 'none':
        this.movePanel(
          characterId,
          entranceLayout.push.x,
          entranceLayout.push.y
        )
        break

      default: // push
        this.pushOtherElements(
          characterId,
          entranceLayout.push.x,
          entranceLayout.push.y
        )
        break
    }
  }

  filterSeamsByDirection(seams, direction) {
    let left = direction.indexOf('left') !== -1
    let right = direction.indexOf('right') !== -1
    let top = direction.indexOf('top') !== -1
    let bottom = direction.indexOf('bottom') !== -1
    let filteredSeams = []
    seams.forEach((seam) => {
      if (seam.width === 1) {
        if (left || right) {
          filteredSeams.push(seam)
        }
      } else {
        if (top || bottom) {
          filteredSeams.push(seam)
        }
      }
    })
    if (filteredSeams.length === 0) {
      filteredSeams = seams
    }
    return filteredSeams
  }

  getEntranceLayoutForLayout(panel, direction, layoutString) {
    // don't allow the new entrance to reverse the direction of the previous one
    let left =
      direction.indexOf('left') !== -1 &&
      (this.lastEntranceDirection !== 'fromRight' || direction.length === 1)
    let right =
      direction.indexOf('right') !== -1 &&
      (this.lastEntranceDirection !== 'fromLeft' || direction.length === 1)
    let top =
      direction.indexOf('top') !== -1 &&
      (this.lastEntranceDirection !== 'fromBottom' || direction.length === 1)
    let bottom =
      direction.indexOf('bottom') !== -1 &&
      (this.lastEntranceDirection !== 'fromTop' || direction.length === 1)
    let horizontal = left || right
    let vertical = top || bottom
    let r
    if (horizontal && vertical) {
      r = Math.random()
    } else if (horizontal) {
      r = 0.25
    } else if (vertical) {
      r = 0.75
    }
    let layout = this.props.channel.layout
    let x = window.getLayoutComponent(layoutString, 'left')
    let y = window.getLayoutComponent(layoutString, 'top')
    let w = window.getLayoutComponent(layoutString, 'width')
    let h = window.getLayoutComponent(layoutString, 'height')
    let v, fromRight, fromBottom, push, entranceDirection
    if (r < 0.5) {
      fromRight = right || !left
      if (right === left) {
        fromRight = Math.random() < 0.5
      }
      entranceDirection = fromRight ? 'fromRight' : 'fromLeft'
      if (fromRight) {
        v = x - (layout.left + layout.width)
        x = layout.left + layout.width
      } else {
        x = layout.left - w
        v = window.getLayoutComponent(layoutString, 'left') - x
      }
      push = { x: v, y: 0 }
    } else {
      fromBottom = bottom || !top
      if (top === bottom) {
        fromBottom = Math.random() < 0.5
      }
      entranceDirection = fromBottom ? 'fromBottom' : 'fromTop'
      if (fromBottom) {
        v = y - (layout.top + layout.height)
        y = layout.top + layout.height
      } else {
        y = layout.top - h
        v = window.getLayoutComponent(layoutString, 'top') - y
      }
      push = { x: 0, y: v }
    }
    return {
      x: x,
      y: y,
      w: w,
      h: h,
      push: push,
      aspectRatio: (this.unit.width * w) / (this.unit.height * parseFloat(h)),
      entranceDirection: entranceDirection,
    }
  }

  getEntranceLayoutForSeam(
    panel,
    seam,
    aspectRatio,
    direction,
    amount,
    physics
  ) {
    // don't allow the new entrance to reverse the direction of the previous one
    let left =
      direction.indexOf('left') !== -1 &&
      (this.lastEntranceDirection !== 'fromRight' || direction.length < 3)
    let right =
      direction.indexOf('right') !== -1 &&
      (this.lastEntranceDirection !== 'fromLeft' || direction.length < 3)
    let top =
      direction.indexOf('top') !== -1 &&
      (this.lastEntranceDirection !== 'fromBottom' || direction.length < 3)
    let bottom =
      direction.indexOf('bottom') !== -1 &&
      (this.lastEntranceDirection !== 'fromTop' || direction.length < 3)
    /*let left = direction.indexOf('left') !== -1;
    let right = direction.indexOf('right') !== -1;
    let top = direction.indexOf('top') !== -1;
    let bottom = direction.indexOf('bottom') !== -1;*/
    let layout = this.props.channel.layout
    let x, y, h, w, v, fromRight, fromBottom, push, entranceDirection
    let emptyExtents = null
    if (seam.width === 1) {
      h = Math.round(seam.height / this.unit.height)
      y = Math.round(seam.y / this.unit.height)
      w = amount
      fromRight = right || !left
      if (right === left) {
        fromRight = Math.random() < 0.5
      }
      entranceDirection = fromRight ? 'fromRight' : 'fromLeft'
      emptyExtents = this.getEmptySubrectExtents(
        panel,
        layout.left,
        y,
        layout.width - layout.left,
        y + h - 1
      )
      switch (physics) {
        case 'press':
          break

        case 'none':
          if (fromRight) {
            x = layout.left + layout.width
            v = -w
          } else {
            x = -w
            v = w
          }
          push = { x: v, y: 0 }
          break

        default: // push
          if (fromRight) {
            if (emptyExtents !== null) {
              w = Math.max(w, layout.left + layout.width - emptyExtents.xMin)
            }
            x = layout.left + layout.width
            v = -w
          } else {
            if (emptyExtents) {
              w = Math.max(w, emptyExtents.xMax)
            }
            x = -w
            v = w
          }
          push = { x: v, y: 0 }
          break
      }
    } else {
      w = Math.round(seam.width / this.unit.width)
      x = Math.round(seam.x / this.unit.width)
      h = amount
      fromBottom = bottom || !top
      if (top === bottom) {
        fromBottom = Math.random() < 0.5
      }
      entranceDirection = fromBottom ? 'fromBottom' : 'fromTop'
      emptyExtents = this.getEmptySubrectExtents(
        panel,
        x,
        layout.top,
        x + w - 1,
        layout.height - layout.top
      )
      switch (physics) {
        case 'press':
          break

        case 'none':
          if (fromBottom) {
            y = layout.height + layout.top
            v = -h
          } else {
            y = -h + layout.top
            v = h
          }
          push = { x: 0, y: v }
          break

        default: // push
          if (fromBottom) {
            if (emptyExtents) {
              h = Math.max(h, layout.height - layout.top - emptyExtents.yMin)
            }
            y = layout.height + layout.top
            v = -h
          } else {
            if (emptyExtents) {
              h = Math.max(h, emptyExtents.yMax)
            }
            y = -h + layout.top
            v = h
          }
          push = { x: 0, y: v }
          break
      }
    }
    let entranceLayout = {
      x: x,
      y: y,
      w: w,
      h: h,
      push: push,
      aspectRatio: (this.unit.width * w) / (this.unit.height * parseFloat(h)),
      entranceDirection: entranceDirection,
    }
    return entranceLayout
  }

  getEmptySubrectExtents(panel, xMin, yMin, xMax, yMax) {
    var emptySubrects = this.getEmptySubrects(panel, xMin, yMin, xMax, yMax)
    var xMinEmpty = 9999
    var yMinEmpty = 9999
    var xMaxEmpty = -9999
    var yMaxEmpty = -9999
    for (let subrect of emptySubrects) {
      xMinEmpty = Math.min(subrect.rect.x, xMinEmpty)
      yMinEmpty = Math.min(subrect.rect.y, yMinEmpty)
      xMaxEmpty = Math.max(subrect.rect.x + subrect.rect.width, xMaxEmpty)
      yMaxEmpty = Math.max(subrect.rect.y + subrect.rect.height, yMaxEmpty)
    }
    if (xMinEmpty === 9999) {
      return null
    } else {
      return {
        xMin: Math.round(xMinEmpty / this.unit.width),
        yMin: Math.round(yMinEmpty / this.unit.height),
        xMax: Math.round(xMaxEmpty / this.unit.width),
        yMax: Math.round(yMaxEmpty / this.unit.height),
      }
    }
  }

  getEmptySubrects(panel, xMin, yMin, xMax, yMax) {
    xMin = Math.min(this.subrects.length - 1, Math.max(0, xMin))
    xMax = Math.min(this.subrects.length - 1, Math.max(0, xMax))
    yMin = Math.min(this.subrects[0].length - 1, Math.max(0, yMin))
    yMax = Math.min(this.subrects[0].length - 1, Math.max(0, yMax))
    var emptySubrects = []
    if (xMin < xMax) {
      for (var h = xMin; h <= xMax; h++) {
        if (yMin < yMax) {
          for (var j = yMin; j <= yMax; j++) {
            if (!this.subrects[h][j].panel) {
              emptySubrects.push(this.subrects[h][j])
            } else if (this.subrects[h][j].panel === panel) {
              emptySubrects.push(this.subrects[h][j])
            }
          }
        }
      }
    }
    return emptySubrects
  }

  movePanel(id, x, y) {
    var panel = this.panelRefs[id]
    panel.gridLayout.left = panel.gridLayout.left + parseFloat(x)
    panel.gridLayout.top = panel.gridLayout.top + parseFloat(y)
    this.cropAllElements(id)
    this.updateLayoutForAllPanels(id)
  }

  pushOtherElements(id, x, y) {
    //console.log('push other elements '+x+' '+y);
    var pushingPanel = this.panelRefs[id]
    var panel
    this.getCharacters(true).forEach((character) => {
      panel = this.panelRefs[character.id]
      if (panel !== pushingPanel && this.panelIsOnScreen(panel)) {
        if (
          parseFloat(x) !== 0 &&
          panel.gridLayout.top <
            pushingPanel.gridLayout.top + pushingPanel.gridLayout.height &&
          panel.gridLayout.top + panel.gridLayout.height >
            pushingPanel.gridLayout.top
        ) {
          panel.gridLayout.left = panel.gridLayout.left + parseFloat(x)
        }
        if (
          parseFloat(y) !== 0 &&
          panel.gridLayout.left <
            pushingPanel.gridLayout.left + pushingPanel.gridLayout.width &&
          panel.gridLayout.left + panel.gridLayout.width >
            pushingPanel.gridLayout.left
        ) {
          panel.gridLayout.top = panel.gridLayout.top + parseFloat(y)
        }
      }
    })
    pushingPanel.gridLayout.left = pushingPanel.gridLayout.left + parseFloat(x)
    pushingPanel.gridLayout.top = pushingPanel.gridLayout.top + parseFloat(y)
    this.cropAllElements(id)
    this.updateLayoutForAllPanels(id)
  }

  panelIsOnScreen(panel) {
    let layout = this.props.channel.layout
    var xMin = panel.gridLayout.left
    var xMax = xMin + panel.gridLayout.width
    var yMin = panel.gridLayout.top
    var yMax = yMin + panel.gridLayout.height
    return (
      yMin < layout.top + layout.height &&
      yMax > layout.top &&
      xMin < layout.left + layout.width &&
      xMax > layout.left
    )
  }

  cropAllElements(id) {
    var x, y, w, h, panel
    var characters = this.getCharacters(true)
    let layout = this.props.channel.layout
    var n = characters.length
    for (var i = 0; i < n; i++) {
      panel = this.panelRefs[characters[i].id]
      x = panel.gridLayout.left
      w = panel.gridLayout.width
      if (x < layout.left && x + w > layout.left) {
        panel.gridLayout.left = layout.left
        panel.gridLayout.width = x + w - layout.left
      }
      if (
        x < layout.left + layout.width &&
        x + w > layout.left + layout.width
      ) {
        panel.gridLayout.width = layout.left + layout.width - x
      }
      y = panel.gridLayout.top
      h = panel.gridLayout.height
      if (y < layout.top && y + h > layout.top) {
        panel.gridLayout.top = layout.top
        panel.gridLayout.height = y + h - layout.top
      }
      if (
        y < layout.top + layout.height &&
        y + h > layout.top + layout.height
      ) {
        panel.gridLayout.height = layout.top + layout.height - y
      }
    }
  }

  animate(time) {
    if (this.baseElement.current) {
      requestAnimationFrame(this.animate)
      let grid = this.props.channel.grid
      this.unit = {
        width: this.baseElement.current.offsetWidth / parseFloat(grid.columns),
        height: this.baseElement.current.offsetHeight / parseFloat(grid.rows),
      }
    }
  }

  updateLayoutForAllPanels(id) {
    this.getCharacters(true).forEach((character) => {
      this.panelRefs[character.id].updateLayout(this.unit)
    })
    this.forceUpdate()
  }

  updateLayout() {
    let css = this.baseElement.current.style
    let grid = this.props.channel.grid
    let layout = this.props.channel.layout
    css.left = 'calc(' + (layout.left / grid.columns) * 100 + '%)'
    css.top = 'calc(' + (layout.top / grid.rows) * 100 + '%)'
    if (this.props.stepwise.score.enforceStageSize) {
      css.width = this.props.stepwise.score.stageWidth * 0.5 - 10 + 'px'
      css.height = this.props.stepwise.score.stageHeight * 0.5 - 10 + 'px'
    } else {
      css.width = 'calc(' + (layout.width / grid.columns) * 100 + '%)'
      css.height = 'calc(' + (layout.height / grid.rows) * 100 + '%)'
    }
    this.unit = {
      width:
        this.baseElement.current.parentElement.offsetWidth /
        parseFloat(grid.columns),
      height:
        this.baseElement.current.parentElement.offsetHeight /
        parseFloat(grid.rows),
    }
    this.updateLayoutForAllPanels()
  }

  getCharacters(visibleOnly = false) {
    let characters = []
    let sourceCharacters
    if (visibleOnly) {
      sourceCharacters = this.props.channel.getVisibleCharacters()
    } else {
      sourceCharacters = Object.values(this.props.channel.characters)
    }
    sourceCharacters.forEach((character) => {
      if (this.props.charactersToIgnore.indexOf(character.id) === -1) {
        characters.push(character)
      }
    })
    return characters
  }

  getPanelMarkup() {
    var panels = null
    if (this.props.stepwise != null) {
      this.panelRefs = {}
      panels = this.getCharacters().map((character, index) => {
        return (
          <Panel
            key={character.id}
            character={character}
            stepwise={this.props.stepwise}
            unit={this.unit}
            grid={this.props.channel.grid}
            ref={(ref) => {
              this.panelRefs[character.id] = ref
            }}
            isEditing={this.props.isEditing}
            isPreviewing={this.props.isPreviewing}
            onSaveActionToHistory={this.saveActionToHistory}
            onSolo={this.handleSolo}
          />
        )
      })
    }
    return panels
  }

  render() {
    return (
      <div
        id={this.props.channel.id + '-channel'}
        className="channel"
        ref={this.baseElement}
      >
        {this.getPanelMarkup()}
      </div>
    )
  }
}

export default Channel
