import React from 'react'

class Patreon extends React.Component {
  constructor(props) {
    super(props)
    console.log(props.location)
  }

  render() {
    return <div></div>
  }
}

export default Patreon
