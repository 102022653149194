import React from 'react'
import { Dialog, Classes } from '@blueprintjs/core'
import firebase from './firebase.js'

class Stories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      stories: [],
      storiesById: {},
      currentStory: null,
      currentMessage: null,
    }
  }

  componentDidMount() {
    this.setupAuth()
  }

  getStoryFromSearchParams = () => {
    let searchParams = new URLSearchParams(this.props.location.search)
    let storyId = searchParams.get('story')
    if (storyId) {
      this.getStory(storyId)
    }
  }

  getStory(storyId) {
    const db = firebase.firestore()
    var docRef = db.collection('stories').doc(storyId)
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.loadStoryJSON(JSON.parse(doc.data().story))
        }
      })
      .catch((error) => {
        console.log('Error getting story.')
      })
  }

  render() {
    return (
      <div>
        <Dialog
          isOpen={true}
          icon="document"
          isCloseButtonShown={false}
          title="Open story"
        >
          <div className={Classes.DIALOG_BODY}>
            {this.getStoryList(this.loadStoryForRow)}
          </div>
        </Dialog>
      </div>
    )
  }
}

export default Stories
