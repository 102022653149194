class GoogleSheetsParser {
  constructor() {
    this.restrictedCharacterIds = [
      'metadata',
      'pulse',
      'comments',
      'instructions',
    ]
  }

  getStepwiseJSONFromSheet(sheetId, success, apiKey) {
    if (!apiKey) {
      console.log(
        'The Google Sheets API v4 requires an API key to retrieve data.'
      )
      return
    }
    var url
    // sheetId contains a Google Sheets id
    if (sheetId.indexOf('https://') === -1) {
      url =
        'https://sheets.googleapis.com/v4/spreadsheets/' +
        sheetId +
        '?includeGridData=true&key=' +
        apiKey
      // or a Google Sheets edit URL
    } else if (sheetId.indexOf('edit#') !== -1) {
      var temp = sheetId.split('/')
      url =
        'https://sheets.googleapis.com/v4/spreadsheets/' +
        temp[5] +
        '?includeGridData=true&key=' +
        apiKey
      // otherwise, assume this is a correctly formed Google Sheets JSON URL
    } else {
      url = sheetId
    }
    this.script = {
      title: 'Untitled',
      description: '',
      primaryCredits: '',
      secondaryCredits: '',
      version: '1',
      referenceScreenSize: { x: 1024, y: 768 },
      enforceAspectRatio: false,
      characters: [],
      scenes: [
        {
          id: 'scene',
          title: 'Scene',
          beatsPerMinute: 120,
          pulsesPerBeat: 4,
          sequences: [
            {
              id: 'sequence',
              title: 'Sequence',
              repeat: true,
              steps: [],
            },
          ],
        },
      ],
      media: [],
    }
    var request = new Request(url)
    fetch(request).then((results) => {
      results
        .json()
        .then((data) => {
          this.script.title = data.properties.title
          var rowData = data.sheets[0].data[0].rowData
          this.addMetadataFromRows(rowData[0], rowData[1])
          this.addCharactersFromRow(rowData[0])
          this.addActionsFromRows(rowData)
          this.trimTrailingEmptySteps(this.script)
          console.log(this.script)
          success(this.script)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  addMetadataFromRows(topRow, secondRow) {
    var temp, param
    topRow.values.forEach((value, index) => {
      if (value.userEnteredValue) {
        let str
        if (value.userEnteredValue.stringValue) {
          str = value.userEnteredValue.stringValue.toLowerCase()
        } else {
          str = value.userEnteredValue.numberValue.toString()
        }
        switch (str) {
          case 'metadata':
            temp = secondRow.values[index].userEnteredValue.stringValue.split(
              /,(?=(?:[^"]*"[^"]*")*[^"]*$)/g
            ) // split on commas outside of double quotes
            temp.forEach((item) => {
              temp = item.trim().split(':')
              param = temp.shift()
              value = temp.join(':')
              value = value.replace(/^"|"$/g, '')
              switch (param) {
                case 'title':
                case 'primaryCredits':
                case 'secondaryCredits':
                case 'description':
                case 'version':
                  this.script[param] = value.trim()
                  break

                case 'pulse':
                  this.addTempoData(value)
                  break

                default:
                  break
              }
            })
            break

          case 'pulse':
            this.addTempoData(
              secondRow.values[index].userEnteredValue.stringValue
            )
            break

          default:
            break
        }
      }
    })
  }

  addTempoData(data) {
    let temp = data.split('/')
    this.script.scenes[0].beatsPerMinute = parseFloat(temp[0])
    this.script.scenes[0].pulsesPerBeat = parseFloat(temp[1])
    if (temp.length > 2) {
      this.script.scenes[0].swing = parseFloat(temp[2])
    }
  }

  addCharactersFromRow(topRow) {
    var character
    var characterIds = []
    this.characterData = []
    topRow.values.forEach((value, index) => {
      if (value.userEnteredValue) {
        let headerString = value.userEnteredValue.stringValue
        if (!headerString) {
          headerString = value.userEnteredValue.numberValue.toString()
        }
        let characterData = this.getCharacterDataFromHeaderString(
          headerString,
          index
        )
        if (
          characterIds.indexOf(characterData.id) === -1 &&
          !this.characterIdIsRestricted(characterData.id)
        ) {
          character = {
            id: characterData.id,
            fullName: characterData.fullName,
          }
          if (!characterData.visible) {
            character.visible = false
          }
          this.script.characters.push(character)
          this.characterData.push(characterData)
          characterIds.push(characterData.id)
        }
      }
    })
  }

  getCharacterDataFromHeaderString(str, column) {
    let name = this.getCharacterNameFromHeaderString(str)
    let id = name.toLowerCase()
    let characterData = {
      id: id,
      fullName: name,
      column: column,
      visible: this.getCharacterVisibilityFromHeaderString(str),
    }
    return characterData
  }

  getCharacterNameFromHeaderString(str) {
    if (!this.getCharacterVisibilityFromHeaderString(str)) {
      str = str.substr(0, str.length - 7)
    }
    return str
  }

  getCharacterVisibilityFromHeaderString(str) {
    return !(
      str.indexOf('-hidden') === str.length - 7 && str.indexOf('-hidden') !== -1
    )
  }

  trimTrailingEmptySteps() {
    this.script.scenes.forEach((scene) => {
      scene.sequences.forEach((sequence) => {
        var indexOfFirstTrailingEmptyStep = -1
        sequence.steps.forEach((step, index) => {
          if (this.isStepEmpty(step)) {
            if (indexOfFirstTrailingEmptyStep === -1) {
              indexOfFirstTrailingEmptyStep = index
            }
          } else {
            indexOfFirstTrailingEmptyStep = -1
          }
        })
        if (indexOfFirstTrailingEmptyStep !== -1) {
          sequence.steps.splice(indexOfFirstTrailingEmptyStep)
        }
      })
    })
  }

  propertyIsColumnHeader(property) {
    return property.userEnteredValue.trim() !== ''
  }

  characterIdIsRestricted(id) {
    return this.restrictedCharacterIds.indexOf(id.toLowerCase()) !== -1
  }

  addActionsFromRows(rows) {
    this.actingCharacters = []
    this.entranceCount = 0
    rows.forEach((row, index) => {
      if (index > 0) {
        let step = {
          states: [],
          actions: this.getActionsFromRow(row),
        }

        // add default states to first step
        if (index === 1) {
          this.characterData.forEach((character) => {
            let captionAlign = window.getRandomCaptionAlign(false)
            step.states.push({
              type: 'frame',
              character: character.id,
              backgroundColor: window.getRandomBackgroundColor(),
            })
            step.states.push({
              type: 'text',
              character: character.id,
              font: 'Newsreader',
              align: captionAlign,
              textAlign: window.getTextAlignFromCaptionAlign(captionAlign),
            })
            step.states.push({
              type: 'image',
              character: character.id,
            })
            step.states.push({
              type: 'audio',
              character: character.id,
            })
            step.states.push({
              type: 'video',
              character: character.id,
            })
          })
        }

        if (step.actions.length > 0) {
          this.script.scenes[0].sequences[0].steps.push(step)
        }
      }
    })
  }

  getActionsFromRow(row) {
    var actions = []
    this.characterData.forEach((character) => {
      let id = character.id
      if (row.values[character.column]) {
        let userEnteredValue = row.values[character.column].userEnteredValue
        if (userEnteredValue) {
          if (userEnteredValue.stringValue === ' ') {
            actions.push({
              command: 'nothing',
              character: id,
              explicit: true, // currently unused
            })
          } else if (userEnteredValue.stringValue === '') {
            actions.push({
              command: 'nothing',
              character: id,
            })
          } else {
            let subActionStr = userEnteredValue.stringValue
            if (!subActionStr) {
              subActionStr = userEnteredValue.numberValue.toString()
            }
            var subActions = subActionStr.split('\n')
            subActions.forEach((subAction, index) => {
              let action = this.getActionFromCell(subAction)
              if (action != null) {
                if (index === 0) {
                  if (
                    this.actingCharacters.indexOf(id) === -1 &&
                    character.visible
                  ) {
                    if (this.entranceCount === 0) {
                      actions = actions.concat(
                        this.getEntryActionsForCharacter(id, true)
                      )
                    } else {
                      actions = actions.concat(
                        this.getEntryActionsForCharacter(id, false)
                      )
                    }
                    this.actingCharacters.push(id)
                    this.entranceCount++
                  }
                }
                action.character = id
                actions.push(action)
              }
            })
          }
        }
      }
    })
    return actions
  }

  getEntryActionsForCharacter(id, isFull) {
    let actions = []
    actions.push({
      character: id,
      command: 'enter',
      direction: ['right', 'bottom'],
      physics: 'push',
      size: isFull
        ? ['full']
        : ['full', 'third', 'quarter', 'half', 'two-thirds', 'three-quarters'],
      amount: isFull ? ['full'] : ['half'],
      delay: 0,
    })
    /*actions.push({
      "character": id,
      "command": "play-phrase",
      "content": window.getRandomSimpleChord(),
      "duration": 0.5,
      "velocity": 36,
      "octave": 3,
      "sequenceLength": "2,4",
      "octaveShift": "-1,1"
    });*/
    return actions
  }

  getActionFromCell(cell) {
    if (cell !== '') {
      var action = {
        append: false,
      }
      var temp, source
      if (cell[0] === '$') {
        temp = cell.split(':')
        action.command = temp.shift().substr(1)
        action.content = source = temp.join(':')
      } else {
        action.command = 'speak'
        action.content = source = cell
      }

      var contentMatch = /[^+@]*/g
      var contentResults = contentMatch.exec(action.content)
      if (contentResults) {
        action.content = contentResults[0]
      }
      if (action.content[0] === '&') {
        action.append = true
        action.content = action.content.substr(1)
      }

      var delayMatch = /\+([\d])*(.[\d]*)(?![^+@])/g
      var delayResults = delayMatch.exec(source)
      if (delayResults) {
        action.delay = parseFloat(delayResults)
      }

      var toneMatch = /@[^+@]+/g
      var toneResults = toneMatch.exec(source)
      if (toneResults) {
        action.volume = toneResults[0].substr(1)
      }

      return action
    }
    return null
  }

  isStepEmpty(step) {
    var isEmpty = true
    step.actions.forEach((action) => {
      if (action.command !== 'nothing' || action.explicit) {
        isEmpty = false
      }
    })
    return isEmpty
  }
}

export { GoogleSheetsParser }
