import React from 'react'
import ScoreColumn from './ScoreColumn.js'
import StepColumn from './StepColumn.js'
import ActionColumn from './ActionColumn.js'
import TWEEN from '@tweenjs/tween.js'
import { observer } from 'mobx-react'
import { Step, Action } from '../stepworks/stepwise/stepwise-v2.js'

const ColumnEditor = observer(
  class ColumnEditor extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        editorWidth: '320px',
        columnsEnabled: 0,
        currentColumn: 0,
      }
      this.handleResizeMouseMove = this.handleResizeMouseMove.bind(this)
      this.handleResizeMouseUp = this.handleResizeMouseUp.bind(this)
      this.handleStepSelect = this.handleStepSelect.bind(this)
      this.animate = this.animate.bind(this)
      this.goBack = this.goBack.bind(this)
      this.columnEditorRef = React.createRef()
      this.initialSetupDone = false
      this.clipboardItem = null
      this.scrollListenerAdded = false
    }

    componentDidMount() {
      //let body = document.getElementsByTagName('body')[0];
      //body.addEventListener('cut', this.handleCut);
      //body.addEventListener('copy', this.handleCopy);
      //body.addEventListener('paste', this.handlePaste);
    }

    handleScroll = () => {
      this.columnEditorRef.current.scrollLeft = 320 * this.state.currentColumn
    }

    handleCut = (evt) => {
      console.log('cut')
    }

    handleCopy = (evt) => {
      console.log('copy')
      if (this.state.currentColumn === 0) {
        this.clipboardItem = this.props.editStep
      } else {
        this.clipboardItem = this.props.editAction
      }
    }

    handlePaste = async (evt) => {
      console.log('paste')
      if (this.state.currentColumn === 0) {
        if (this.clipboardItem instanceof Step) {
          let data = this.clipboardItem.toJSON()
          let step = new Step(
            data,
            this.props.stepwise.score,
            this.props.sequence
          )
          this.props.sequence.steps.push(step)
        }
      } else {
        if (this.clipboardItem instanceof Action) {
          let data = this.clipboardItem.toJSON()
          let action = new Action(data, this.props.stepwise.score)
          action.updateFeature()
          this.props.editStep.actions.push(action)
        }
      }
      evt.preventDefault()
    }

    handleResizeMouseDown(evt) {
      document.addEventListener('mousemove', this.handleResizeMouseMove)
      document.addEventListener('mouseup', this.handleResizeMouseUp)
    }

    handleResizeMouseMove(evt) {
      this.setState({ editorWidth: Math.max(120, evt.clientX) + 'px' })
    }

    handleResizeMouseUp(evt) {
      document.removeEventListener('mousemove', this.handleResizeMouseMove)
      document.removeEventListener('mouseup', this.handleResizeMouseUp)
    }

    animate(time) {
      if (this.isAnimating) requestAnimationFrame(this.animate)
      TWEEN.update(time)
    }

    handleStepSelect(step) {
      if (this.state.columnsEnabled > 0 && this.columnEditorRef.current) {
        this.scrollToColumn(1)
      }
    }

    handleStepChange(step) {
      this.handleStepSelect(step)
      this.props.onStepChange(step)
      if (step.actions.indexOf(this.props.editAction) === -1) {
        if (step.actions.length > 0) {
          this.setState({ actionIndex: 0 })
          this.props.onActionChange(step.actions[0])
        } else {
          this.setState({ actionIndex: -1 })
          this.props.onActionChange(null)
        }
      }
    }

    scrollToColumn(columnNum) {
      if (this.columnEditorRef.current) {
        if (columnNum === 0) {
          document.activeElement.blur()
        } else if (columnNum === 1) {
          this.setState({ columnsEnabled: 1 })
        }
        //var val = {t:this.state.currentColumn * 320};
        var val = { t: this.columnEditorRef.current.scrollLeft }
        this.setState({ currentColumn: columnNum })
        if (this.tween) this.tween.stop()
        this.tween = new TWEEN.Tween(val)
          .to({ t: 320 * columnNum }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate((obj) => {
            this.columnEditorRef.current.scrollLeft = obj.t
          })
          .onComplete(() => {
            this.isAnimating = false
          })
          .start()
        this.isAnimating = true
        requestAnimationFrame(this.animate)
      }
    }

    goBack() {
      this.scrollToColumn(this.state.currentColumn - 1)
    }

    handleColumnsEnabledChange(evt, numColumns) {
      /*let columnsEnabled = !this.state.columnsEnabled;
    let editorWidth = this.state.editorWidth;
    if (columnsEnabled) {
      editorWidth = '320px';
    } else {
      editorWidth = '320px';
    }
    this.setState({
      editorWidth: editorWidth,
      columnsEnabled: columnsEnabled
    });*/
      if (this.state.columnsEnabled >= numColumns) {
        numColumns--
      }
      this.scrollToColumn(numColumns)
      this.setState({ columnsEnabled: numColumns })
    }

    getActionForStep(step) {
      if (step) {
        let n = step.actions.length
        for (var i = 0; i < n; i++) {
          let action = step.actions[i]
          if (action.command !== 'enter') {
            return action
          }
        }
        if (n > 0) {
          return step.actions[0]
        }
      }
      return null
    }

    handleActionChange(action) {
      if (this.columnEditorRef.current) {
        this.scrollToColumn(this.state.columnsEnabled)
      }
      this.props.onActionChange(action)
    }

    doInitialSelection() {
      if (this.props.editStep) {
        if (!this.props.editAction) {
          let action = this.getActionForStep(this.props.editStep)
          this.props.onActionChange(action)
        }
      }
      this.initialSetupDone = true
    }

    addStep = () => {
      let stepData = {
        states: [],
        actions: [this.getNewActionData()],
      }
      let step = this.props.editStep
        ? this.props.editStep
        : this.props.sequence.steps[this.props.sequence.stepIndex]
      let newStep = new Step(
        stepData,
        this.props.stepwise.score,
        this.props.sequence
      )
      this.props.sequence.addStepAfterStep(step, newStep)
      this.props.stepwise.score.setupReferences()
      this.props.onStepChange(newStep)
    }

    getNewActionData() {
      let data
      if (this.props.sequence.stepIndex === -1) {
        this.props.sequence.stepIndex = this.props.sequence.steps.length - 1
      }
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      let character
      let foundAction = false
      if (step) {
        if (step.actions.length > 0) {
          foundAction = true
        }
      }
      if (foundAction) {
        character = step.actions[0].targetCharacter
      } else {
        character = Object.values(this.props.stepwise.score.characters)[0]
      }
      /*if (!this.sequenceHasEnterActionForCharacter(character)) {
      data = {
        "character": character.id,
        "command": "enter",
        "delay": this.action.delay,
        "direction": ["right","left","top","bottom"],
        "size": ['full'],
        "amount": ['full'],
        "content": '0 0 ' + character.channel.grid.columns + ' ' + character.channel.grid.rows
      };
    } else {*/
      data = {
        character: character.id,
        command: 'speak',
        content: '',
      }
      //}
      return data
    }

    render() {
      let className = 'column-editor'
      if (!this.props.visible) {
        className += ' hidden'
      }
      if (!this.scrollListenerAdded && this.columnEditorRef.current) {
        this.columnEditorRef.current.addEventListener(
          'scroll',
          this.handleScroll
        )
        this.scrollListenerAdded = true
      }
      let style = { flexBasis: this.state.editorWidth }
      if (!this.props.sequence) {
        return <div className={className} style={style}></div>
      } else {
        if (!this.initialSetupDone) {
          setTimeout(this.doInitialSelection.bind(this), 1)
        }
        return (
          <div className={className} ref={this.columnEditorRef} style={style}>
            <div className="editor-container no-scroll">
              <ScoreColumn
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                onAddStep={this.addStep}
                onStepChange={this.handleStepChange.bind(this)}
                onStepSelect={this.handleStepSelect}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onColumnsEnabledChange={this.handleColumnsEnabledChange.bind(
                  this
                )}
                onCharactersEnabledToggle={this.props.onCharactersEnabledToggle}
                onSequenceChange={this.props.onSequenceChange}
                onPanelLayoutNeeded={this.props.onPanelLayoutNeeded}
                columnsEnabled={this.state.columnsEnabled}
                charactersEnabled={this.props.charactersEnabled}
                musicPlayer={this.props.musicPlayer}
              />
              <StepColumn
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                user={this.props.user}
                uploadedMedia={this.props.uploadedMedia}
                uploadedMediaSize={this.props.uploadedMediaSize}
                maxUploadedMedia={this.props.maxUploadedMedia}
                onMediaUploaded={this.props.onMediaUploaded}
                onAddStep={this.addStep}
                onStepChange={this.handleStepChange.bind(this)}
                onStepSelect={this.handleStepSelect}
                onActionChange={this.handleActionChange.bind(this)}
                onGoBack={this.goBack}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onColumnsEnabledChange={this.handleColumnsEnabledChange.bind(
                  this
                )}
                onPanelLayoutNeeded={this.props.onPanelLayoutNeeded}
                currentColumn={this.state.currentColumn}
                columnsEnabled={this.state.columnsEnabled}
                charactersEnabled={this.props.charactersEnabled}
                musicPlayer={this.props.musicPlayer}
              />
              <ActionColumn
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                user={this.props.user}
                uploadedMedia={this.props.uploadedMedia}
                uploadedMediaSize={this.props.uploadedMediaSize}
                maxUploadedMedia={this.props.maxUploadedMedia}
                onMediaUploaded={this.props.onMediaUploaded}
                onStepChange={this.handleStepChange.bind(this)}
                onStepSelect={this.handleStepSelect}
                onGoBack={this.goBack}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onColumnsEnabledChange={this.handleColumnsEnabledChange.bind(
                  this
                )}
                onPanelLayoutNeeded={this.props.onPanelLayoutNeeded}
                currentColumn={this.state.currentColumn}
                columnsEnabled={this.state.columnsEnabled}
                charactersEnabled={this.props.charactersEnabled}
                musicPlayer={this.props.musicPlayer}
              />
            </div>
            {/*<div className="col-resizer" onMouseDown={this.handleResizeMouseDown.bind(this)}>
        </div>*/}
          </div>
        )
      }
    }
  }
)

export default ColumnEditor
