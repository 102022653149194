import React from 'react'
import CharacterAvatar from './CharacterAvatar.js'
import ComboThumbnail from './ComboThumbnail.js'
import { observer } from 'mobx-react'
import { Button } from '@blueprintjs/core'

const ScoreMusicEditor = observer(
  class ScoreMusicEditor extends React.Component {
    constructor(props) {
      super(props)
      this.handleStepChange = this.handleStepChange.bind(this)
      this.handleRowClick = this.handleRowClick.bind(this)
    }

    handleStepChange(step) {
      this.props.onStepChange(step)
      this.forceUpdate()
    }

    handleRowClick(evt) {
      let stepIndex = parseInt(evt.currentTarget.getAttribute('data-index'))
      let step = this.props.sequence.steps[stepIndex]
      this.props.onStepChange(step)
      this.forceUpdate()
    }

    sortActionsByCharacter(step) {
      let actionsByCharacter = {}
      step.actions.forEach((action) => {
        if (!actionsByCharacter[action.targetCharacter.id]) {
          actionsByCharacter[action.targetCharacter.id] = []
        }
        actionsByCharacter[action.targetCharacter.id].push(action)
      })
      return actionsByCharacter
    }

    render() {
      let characters = Object.values(this.props.stepwise.score.characters)
      let header = characters.map((character) => {
        return (
          <th key={character.id}>
            <CharacterAvatar
              character={character}
              characterDialog={this.props.characterDialog}
              clickable={true}
              action={null}
            />
          </th>
        )
      })
      let rows = this.props.sequence.steps.map((step, stepIndex) => {
        let actionsByCharacter = this.sortActionsByCharacter(step)
        let rowContent = characters.map((character) => {
          return (
            <td key={character.id + '-' + stepIndex}>
              {actionsByCharacter[character.id] ? (
                <ComboThumbnail
                  index={stepIndex}
                  stepwise={this.props.stepwise}
                  sequence={this.props.sequence}
                  onStepChange={this.handleStepChange}
                  actions={actionsByCharacter[character.id]}
                  compact="true"
                />
              ) : null}
            </td>
          )
        })
        let className
        if (this.props.sequence.stepIndex === stepIndex) {
          className = 'current'
        }
        return (
          <tr
            className={className}
            key={stepIndex}
            data-index={stepIndex}
            onClick={this.handleRowClick}
          >
            <th className="step-index-col">{stepIndex + 1}</th>
            {rowContent}
          </tr>
        )
      })
      let addButtonCells = characters.map((character) => {
        return (
          <td key={character.id + '-add'}>
            <Button
              className="combo-thumbnail compact"
              minimal="true"
              icon="plus"
              onClick={this.props.onAddStep}
            />
          </td>
        )
      })
      return (
        <table className="score-music-editor">
          {this.props.charactersEnabled ? (
            <thead>
              <tr>
                <th className="step-index"></th>
                {header}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {rows}
            <tr>
              <th className="step-index-col"></th>
              {addButtonCells}
            </tr>
          </tbody>
        </table>
      )
    }
  }
)

export default ScoreMusicEditor
