import React from 'react'
import { observer } from 'mobx-react'
import {
  FormGroup,
  HTMLSelect,
  InputGroup,
  NonIdealState,
  NumericInput,
} from '@blueprintjs/core'

const MediaControls = observer(
  class MediaControls extends React.Component {
    constructor(props) {
      super(props)
      this.typeOptions = [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
        { label: 'Audio', value: 'audio' },
      ]
    }

    componentDidUpdate() {
      if (this.props.media) {
        if (!this.props.media.name) this.props.media.name = ''
        if (!this.props.media.source) this.props.media.source = ''
        if (!this.props.media.attribution) this.props.media.attribution = ''
        if (!this.props.media.attributionURL)
          this.props.media.attributionURL = ''
        if (!this.props.media.thumbnail) this.props.media.thumbnail = ''
        if (!this.props.media.width) this.props.media.width = 1920
        if (!this.props.media.height) this.props.media.height = 1080
      }
    }

    handlePropertyChange(evt, property, value) {
      let propValue = value || evt.currentTarget.value
      this.props.media[property] = propValue
    }

    render() {
      let controls
      if (this.props.media) {
        let width = this.props.media.width || 1920
        let height = this.props.media.height || 1080
        controls = (
          <div className="media-controls">
            <FormGroup label="Name" labelFor="name-input">
              <InputGroup
                id="name-input"
                value={this.props.media.name}
                onChange={(evt) => this.handlePropertyChange(evt, 'name')}
              />
            </FormGroup>
            <FormGroup label="Type" labelFor="type-input">
              <HTMLSelect
                id="type-input"
                options={this.typeOptions}
                value={this.props.media.type}
                onChange={(evt) => this.handlePropertyChange(evt, 'type')}
              />
            </FormGroup>
            <FormGroup label="Source" labelFor="source-input">
              <InputGroup
                id="source-input"
                value={this.props.media.source}
                onChange={(evt) => this.handlePropertyChange(evt, 'source')}
              />
            </FormGroup>
            <FormGroup label="Attribution" labelFor="attribution-input">
              <InputGroup
                id="attribution-input"
                value={
                  this.props.media.attribution
                    ? this.props.media.attribution
                    : ''
                }
                onChange={(evt) =>
                  this.handlePropertyChange(evt, 'attribution')
                }
              />
            </FormGroup>
            <FormGroup label="Attribution URL" labelFor="attribution-url-input">
              <InputGroup
                id="attribution-url-input"
                value={
                  this.props.media.attributionURL
                    ? this.props.media.attributionURL
                    : ''
                }
                onChange={(evt) =>
                  this.handlePropertyChange(evt, 'attributionURL')
                }
              />
            </FormGroup>
            <FormGroup label="Thumbnail" labelFor="thumbnail-input">
              <InputGroup
                id="thumbnail-input"
                value={
                  this.props.media.thumbnail ? this.props.media.thumbnail : ''
                }
                onChange={(evt) => this.handlePropertyChange(evt, 'thumbnail')}
              />
            </FormGroup>
            <div className="form-col">
              <FormGroup label="Width" labelFor="width-input" inline={true}>
                <NumericInput
                  id="width-input"
                  style={{ width: '80px' }}
                  value={width}
                  onValueChange={(value) =>
                    this.handlePropertyChange(null, 'width', value)
                  }
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Height" labelFor="height-input" inline={true}>
                <NumericInput
                  id="height-input"
                  style={{ width: '80px' }}
                  value={height}
                  onValueChange={(value) =>
                    this.handlePropertyChange(null, 'height', value)
                  }
                />
              </FormGroup>
            </div>
          </div>
        )
      } else {
        controls = (
          <div className="media-controls">
            <br />
            <br />
            <NonIdealState icon="media" title="No media selected" />
          </div>
        )
      }
      return controls
    }
  }
)

export default MediaControls
