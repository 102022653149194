import React from 'react'

class Slabtype extends React.Component {
  constructor(props) {
    super(props)
    this.textRef = React.createRef()
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    return false
  }

  handleStoryUpdate() {
    this.forceUpdate()
  }

  calculateSlabtype() {
    let markup

    if (this.props.content.trim() !== '') {
      // establish metrics
      let measuringDiv = document.createElement('div')
      measuringDiv.classList.add('measuring', 'ar')
      measuringDiv.style.fontName = this.props.fontName
      document.body.appendChild(measuringDiv)
      measuringDiv.textContent = this.props.content
      let width = measuringDiv.clientWidth
      let height = measuringDiv.clientHeight
      this.averageCharacterAspectRatio =
        width / parseFloat(this.props.content.length) / parseFloat(height)
      this.idealLineLength = Math.round(5.34 / this.averageCharacterAspectRatio)
      document.body.removeChild(measuringDiv)
      this.idealLineAspectRatio =
        this.averageCharacterAspectRatio * this.idealLineLength

      if (this.textRef.current) {
        let characterCount = this.props.content.length
        let boxWidth = this.textRef.current.parentElement.offsetWidth
        let boxHeight = this.textRef.current.parentElement.offsetHeight
        let idealLineHeight = boxWidth / this.idealLineAspectRatio
        let hypotheticalLineCount = Math.floor(boxHeight / idealLineHeight)
        let idealCharacterCountPerLine = Math.min(
          60,
          Math.max(
            Math.round(characterCount / parseFloat(hypotheticalLineCount)),
            1
          )
        )

        // segment the text into lines
        let words = this.props.content.split(' ')
        let preText, postText, finalText, preDiff, postDiff
        let wordIndex = 0
        let lineText = []

        while (wordIndex < words.length) {
          preText = ''
          postText = ''

          // build two strings (preText and postText) word by word, with one
          // string always one word behind the other, until
          // the length of one string is less than the ideal number of characters
          // per line, while the length of the other is greater than that ideal
          while (postText.length < idealCharacterCountPerLine) {
            preText = postText
            postText += words[wordIndex] + ' '
            wordIndex++
            if (wordIndex >= words.length) {
              break
            }
          }

          // calculate the character difference between the two strings and the
          // ideal number of characters per line
          preDiff = idealCharacterCountPerLine - preText.length
          postDiff = postText.length - idealCharacterCountPerLine

          // if the smaller string is closer to the length of the ideal than
          // the longer string, and doesn’t contain just a single space, then
          // use that one for the line
          if (preDiff < postDiff && preText.length > 4) {
            finalText = preText
            wordIndex--

            // otherwise, use the longer string for the line
          } else {
            finalText = postText
          }

          lineText.push(finalText.substr(0, finalText.length - 1))
        }
        let measuringDiv = document.createElement('div')
        measuringDiv.classList.add('measuring')
        measuringDiv.style.fontName = this.props.fontName
        document.body.appendChild(measuringDiv)
        let totalHeight = 0
        let linesOfText = lineText.map((line, index) => {
          measuringDiv.textContent = line
          let width = measuringDiv.clientWidth
          let height = measuringDiv.clientHeight
          let size = (boxWidth / width) * 10
          totalHeight += height * (size / 10) * 0.95
          let style = {
            fontSize: size + 'vw',
          }
          return (
            <div key={index} style={style}>
              {line}
            </div>
          )
        })
        let scale = Math.min(0.85, boxHeight / totalHeight)
        markup = (
          <div style={{ transform: 'scale(' + scale + ')' }}>{linesOfText}</div>
        )
        document.body.removeChild(measuringDiv)
      } else {
        setTimeout(() => {
          this.forceUpdate()
        }, 1) // keep trying to calc until it's possible
      }
    }

    return markup
  }

  render() {
    let content = this.calculateSlabtype()
    return (
      <div ref={this.textRef} className="text-layer slabtype">
        {content}
      </div>
    )
  }
}

export default Slabtype
