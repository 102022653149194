import React from 'react'
import { observer } from 'mobx-react'
import {
  FormGroup,
  InputGroup,
  Dialog,
  Classes,
  Button,
  Intent,
  NumericInput,
  Switch,
  Alignment,
} from '@blueprintjs/core'

const StorySettingsDialog = observer(
  class StorySettingsDialog extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
    }

    handlePropertyChange(evt, property, value) {
      this.props.score[property] = value ? value : evt.currentTarget.value
    }

    handleNumericPropertyChange(property, valueAsNumber) {
      this.props.score[property] = valueAsNumber
    }

    handleSwitchChange(evt, property) {
      this.props.score[property] = evt.currentTarget.checked
    }

    render() {
      return (
        <Dialog
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          icon="settings"
          title="Story Settings"
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Title" labelFor="title-input">
              <InputGroup
                id="title-input"
                placeholder="Enter story title"
                value={this.props.score.title}
                onChange={(evt) =>
                  this.handlePropertyChange(evt, 'title', evt.target.value)
                }
              />
            </FormGroup>
            <FormGroup label="Primary credit" labelFor="primary-credit-input">
              <InputGroup
                id="primary-credit-input"
                placeholder="Enter primary credit"
                value={this.props.score.primaryCredit}
                onChange={(evt) =>
                  this.handlePropertyChange(
                    evt,
                    'primaryCredit',
                    evt.target.value
                  )
                }
              />
            </FormGroup>
            <FormGroup
              label="Secondary credit"
              labelFor="secondary-credit-input"
            >
              <InputGroup
                id="secondary-credit-input"
                placeholder="Enter secondary credit"
                value={this.props.score.secondaryCredit}
                onChange={(evt) =>
                  this.handlePropertyChange(
                    evt,
                    'secondaryCredit',
                    evt.target.value
                  )
                }
              />
            </FormGroup>
            <FormGroup
              label="Duration description"
              labelFor="duration-desc-input"
            >
              <InputGroup
                id="duration-desc-input"
                placeholder="Enter duration description"
                value={this.props.score.durationDescription}
                onChange={(evt) =>
                  this.handlePropertyChange(
                    evt,
                    'durationDescription',
                    evt.target.value
                  )
                }
              />
            </FormGroup>
            <FormGroup label="Analytics id" labelFor="analytics-id-input">
              <InputGroup
                id="analytics-id-input"
                placeholder="Enter Google Analytics id"
                value={
                  this.props.score.analyticsId
                    ? this.props.score.analyticsId
                    : ''
                }
                onChange={(evt) =>
                  this.handlePropertyChange(
                    evt,
                    'analyticsId',
                    evt.target.value
                  )
                }
              />
            </FormGroup>
            <div className="form-col">
              <FormGroup label="Stage width" labelFor="stage-width-input">
                <NumericInput
                  id="stage-width-input"
                  style={{ width: 60 }}
                  value={this.props.score.stageWidth}
                  onValueChange={(valueAsNumber) =>
                    this.handleNumericPropertyChange(
                      'stageWidth',
                      valueAsNumber
                    )
                  }
                  min="0"
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Stage height" labelFor="stage-height-input">
                <NumericInput
                  id="stage-height-input"
                  style={{ width: 60 }}
                  value={this.props.score.stageHeight}
                  onValueChange={(valueAsNumber) =>
                    this.handleNumericPropertyChange(
                      'stageHeight',
                      valueAsNumber
                    )
                  }
                  min="0"
                />
              </FormGroup>
            </div>
            <FormGroup labelFor="enforce-stage-size-switch" inline={true}>
              <Switch
                id="enforce-stage-size-switch"
                checked={this.props.score.enforceStageSize}
                label="Enforce stage size"
                alignIndicator={Alignment.RIGHT}
                onChange={(evt) =>
                  this.handleSwitchChange(evt, 'enforceStageSize')
                }
              />
            </FormGroup>
            <FormGroup labelFor="prevent-input-switch" inline={true}>
              <Switch
                id="prevent-input-switch"
                checked={this.props.score.preventInputDuringDelays}
                label="Prevent input during delays"
                alignIndicator={Alignment.RIGHT}
                onChange={(evt) =>
                  this.handleSwitchChange(evt, 'preventInputDuringDelays')
                }
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.props.onClose} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }
  }
)

export default StorySettingsDialog
