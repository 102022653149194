import React from 'react'

class MediaPreview extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.getMetadata()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.getMetadata()
    } else {
      if (prevProps.source !== this.props.source) {
        this.getMetadata()
      }
    }
  }

  getMetadata() {
    switch (this.props.type) {
      case 'image':
      case 'video':
        let image = new Image()
        image.src = this.props.source
        let me = this
        image.onload = function () {
          if (me.props.onMetadata) {
            me.props.onMetadata({ width: this.width, height: this.height })
          }
        }
        break

      case 'audio':
        break

      default:
        break
    }
  }

  handleClick() {
    this.props.onClick(this.props.index)
  }

  render() {
    switch (this.props.type) {
      case 'image':
      case 'video':
        let styles = { backgroundImage: 'url(' + this.props.source + ')' }
        return (
          <div
            className={this.props.className + ' media-preview image-preview'}
            style={styles}
            onClick={this.handleClick}
          />
        )

      /*case 'video':
      return <div className={this.props.className + " media-preview video-preview"} onClick={this.handleClick}>
        <video src={this.props.source}></video>
      </div>
      break;*/

      case 'audio':
        return (
          <div
            className={this.props.className + ' media-preview audio-preview'}
            onClick={this.handleClick}
          >
            <audio src={this.props.source}></audio>
          </div>
        )

      default:
        break
    }
  }
}

export default MediaPreview
