import React from 'react'
import { observer } from 'mobx-react'

const CharacterAvatar = observer(
  class ColumnEditor extends React.Component {
    getCharacterInitials() {
      if (this.props.character) {
        if (this.props.character.fullName.trim() === '') {
          return 'A'
        } else {
          let temp = this.props.character.fullName.split(' ')
          let initials = temp[0][0].toUpperCase()
          if (temp.length > 1) {
            if (temp[temp.length - 1].length > 0) {
              initials += temp[temp.length - 1][0].toUpperCase()
            }
          }
          return initials
        }
      } else {
        return ''
      }
    }

    handleClick = (evt) => {
      this.props.characterDialog.current.open(
        this.props.character,
        this.props.action,
        this.props.changesAffectStep,
        this.props.addEntranceActions
      )
    }

    render() {
      return (
        <div
          className="character-avatar"
          style={{ backgroundColor: this.props.character.color }}
          onClick={this.props.clickable ? this.handleClick : null}
        >
          {this.getCharacterInitials()}
        </div>
      )
    }
  }
)

export default CharacterAvatar
