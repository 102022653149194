import React from 'react'
import MediaSelector from './MediaSelector.js'
import {
  Button,
  FormGroup,
  HTMLSelect,
  Tabs,
  Tab,
  Slider,
  NonIdealState,
  InputGroup,
  Switch,
  RangeSlider,
  Popover,
  ButtonGroup,
  Intent,
  ControlGroup,
  NumericInput,
  TextArea,
} from '@blueprintjs/core'
import { Step, Action } from '../stepworks/stepwise/stepwise-v2.js'
import { observer } from 'mobx-react'
import ReactDOM from 'react-dom'
import compromise from 'compromise'
import syllables from 'compromise-syllables'
import sentences from 'compromise-sentences'
import { SketchPicker } from 'react-color'

const ActionColumn = observer(
  class ActionColumn extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        split: 'word',
        splitUnit: 'action',
        selectedTabId: 'single-text-tab',
      }
      this.physicsTypes = [
        { label: 'Push', value: 'push' },
        { label: 'None', value: 'none' },
      ]
      this.splitOptions = [
        { label: 'sentences', value: 'sentence' },
        { label: 'lines', value: 'line' },
        { label: 'words', value: 'word' },
        { label: 'syllables', value: 'syllable' },
        { label: 'characters', value: 'character' },
      ]
      this.splitUnitOptions = [
        { label: 'multiple actions in this step', value: 'action' },
        { label: 'multiple steps', value: 'step' },
      ]
      this.durationOptions = [
        { label: String.fromCharCode(0xe1d2), value: '4' },
        { label: String.fromCharCode(0xe1d3), value: '2' },
      ]
      this.handleDurationSelect = this.handleDurationSelect.bind(this)
      this.menuId = 'frame'
      this.lastSiblingIndex = -1
      this.delayPerAction = 2
      this.content = {}
      this.offsetY = 0
      this.handleKeyChange = this.handleKeyChange.bind(this)
      this.midiInputActive = false
      this.setupMidiInput()
      this.chordOptions = [
        { label: 'E minor', value: 'Em' },
        { label: 'A minor', value: 'Am' },
        { label: 'D minor', value: 'Dm' },
        { label: 'G major', value: 'G' },
        { label: 'C major', value: 'C' },
        { label: 'F major', value: 'F' },
        { label: 'Bb major', value: 'Bb' },
        { label: 'B diminshed', value: 'Bdim' },
      ]
      this.octaveOptions = [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
      ]
    }

    setupMidiInput() {
      var SimpleMidiInput = require('simple-midi-input')
      this.smi = new SimpleMidiInput()
      var onSuccessCallback = (midi) => {
        this.smi.attach(midi)
        this.midiInputActive = true
        console.log('MIDI input active')
        this.smi.on('noteOn', this.handleNoteOn.bind(this))
      }
      var onErrorCallback = (err) => {
        console.log('ERROR: ' + err.code)
      }
      if (navigator.requestMIDIAccess) {
        navigator.requestMIDIAccess().then(onSuccessCallback, onErrorCallback)
      }
    }

    handleNoteOn(data) {
      //console.log(data.event, data.key, data.velocity);
      if (this.props.editAction) {
        if (this.props.editAction.command === 'play-note') {
          this.props.editAction.content = window.midiNoteNumToNoteName(data.key)
          this.props.editAction.velocity = data.velocity
          this.props.onStepwiseEvent('action', this.props.editAction)
        } else {
          this.sendDefaultNoteAction(
            data,
            this.props.editAction.targetCharacter
          )
        }
      } else {
        this.sendDefaultNoteAction(
          data,
          Object.values(this.props.stepwise.score.characters)[0]
        )
      }
    }

    sendDefaultNoteAction(data, character) {
      let actionData = {
        character: character.id,
        command: 'play-note',
        content: window.midiNoteNumToNoteName(data.key),
        duration: 0.5,
        velocity: data.velocity,
      }
      let action = new Action(actionData, this.props.stepwise.score)
      this.props.onStepwiseEvent('action', action)
    }

    addStep() {
      let stepData = {
        states: [],
        actions: [this.getNewActionData()],
      }
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      let newStep = new Step(
        stepData,
        this.props.stepwise.score,
        this.props.sequence
      )
      this.props.sequence.addStepAfterStep(step, newStep)
      this.props.stepwise.score.setupReferences()
      this.props.onSelect(
        this.props.sequence.stepIndex + 1,
        null,
        this.props.row,
        newStep.actions[0]
      )
    }

    getNewActionData() {
      let data
      let character = this.props.editAction.targetCharacter
      if (!this.sequenceHasEnterActionForCharacter(character)) {
        data = {
          character: character.id,
          command: 'enter',
          delay: this.props.editAction.delay,
          direction: ['right', 'left', 'top', 'bottom'],
          size: ['full'],
          amount: ['full'],
          content:
            '0 0 ' +
            character.channel.grid.columns +
            ' ' +
            character.channel.grid.rows,
        }
      } else {
        data = {
          character: character.id,
          command: 'speak',
          delay: this.props.editAction.delay,
          content: '',
        }
      }
      return data
    }

    sequenceHasEnterActionForCharacter(character) {
      let result = false
      for (let i = 0; i < this.props.sequence.steps.length; i++) {
        let step = this.props.sequence.steps[i]
        for (let j = 0; j < step.actions.length; j++) {
          let action = step.actions[j]
          if (
            action.command === 'enter' &&
            action.targetCharacter === character
          ) {
            result = true
            break
          }
        }
        if (result) break
      }
      return result
    }

    handleNumericActionPropertyChange(property, value) {
      this.props.editAction[property] = value
      if (property === 'delay') {
        this.props.onSortNeeded()
      }
      //this.props.onStepwiseEvent('action', this.props.editAction);
    }

    handleStatePropertyChange(evt, state, property, value) {
      let propValue = value || evt.currentTarget.value
      state[property] = propValue
      this.props.onStepwiseEvent('state', state)
    }

    handleSwitchChange(evt, property) {
      this.props.editAction[property] = evt.currentTarget.checked
    }

    handleActionChange(evt) {
      this.props.editAction.command = evt.currentTarget.value
    }

    handleLayoutPropertyChange(value, property) {
      let temp
      if (!this.props.editAction.content) {
        let character = this.props.editAction.targetCharacter
        this.props.editAction.content =
          '0 0 ' +
          character.channel.grid.columns +
          ' ' +
          character.channel.grid.rows
      }
      let layout = this.props.editAction.content
      temp = layout.split(' ')
      switch (property) {
        case 'left':
          temp[0] = value
          break
        case 'top':
          temp[1] = value
          break
        case 'width':
          temp[2] = value
          break
        case 'height':
          temp[3] = value
          break
        default:
          break
      }
      layout = temp.join(' ')
      this.props.editAction.content = layout
      if (this.props.editAction.command === 'move') {
        this.props.onStepwiseEvent('action', this.props.editAction)
      }
    }

    handleDirectionToggle(evt, direction) {
      let index = this.props.editAction.direction.indexOf(direction)
      if (index === -1) {
        this.props.editAction.direction.push(direction)
      } else if (this.props.editAction.direction.length > 1) {
        this.props.editAction.direction.splice(index, 1)
      }
    }

    handleSizeToggle(evt, size) {
      if (!this.props.editAction.size) this.props.editAction.size = ['full']
      let index = this.props.editAction.size.indexOf(size)
      if (index === -1) {
        this.props.editAction.size.push(size)
      } else if (this.props.editAction.size.length > 1) {
        this.props.editAction.size.splice(index, 1)
      }
    }

    handleAmountToggle(evt, amount) {
      if (!this.props.editAction.amount) this.props.editAction.amount = ['full']
      let index = this.props.editAction.amount.indexOf(amount)
      if (index === -1) {
        // choosing 'custom' or 'content' turns off all others
        if (amount === 'custom' || amount === 'match-content') {
          this.props.editAction.amount = []
        }
        // any other selection turns off 'size' and 'content'
        index = this.props.editAction.amount.indexOf('custom')
        if (index !== -1) {
          this.props.editAction.amount.splice(index, 1)
        }
        index = this.props.editAction.amount.indexOf('match-content')
        if (index !== -1) {
          this.props.editAction.amount.splice(index, 1)
        }
        this.props.editAction.amount.push(amount)
      } else if (this.props.editAction.amount.length > 1) {
        this.props.editAction.amount.splice(index, 1)
      }
    }

    handleSplitChange(evt) {
      this.setState({ split: evt.currentTarget.value })
    }

    handleSplitUnitChange(evt) {
      this.setState({ splitUnit: evt.currentTarget.value })
    }

    getVisualMedia() {
      var mediaItems = [{ label: 'Choose...', value: '' }]
      Object.values(this.props.stepwise.score.media).forEach(
        (mediaItem, index) => {
          if (mediaItem.type === 'image' || mediaItem.type === 'video') {
            mediaItems.push({ label: mediaItem.name, value: mediaItem.id })
          }
        }
      )
      return mediaItems
    }

    getAudioMedia() {
      var mediaItems = [{ label: 'Choose...', value: '' }]
      Object.values(this.props.stepwise.score.media).forEach(
        (mediaItem, index) => {
          if (mediaItem.type === 'audio') {
            mediaItems.push({ label: mediaItem.name, value: mediaItem.id })
          }
        }
      )
      return mediaItems
    }

    handleActionPropertyChange(evt, property, value) {
      let propValue = value ? value : evt.currentTarget.value
      switch (property) {
        case 'command':
          this.props.editAction[property] = propValue
          this.setDefaultsForCommand(evt.currentTarget.value)
          break

        case 'content':
          if (
            this.props.editAction.command === 'show-image' ||
            this.props.editAction.command === 'show-video'
          ) {
            let media = this.props.stepwise.score.getMedia(propValue)
            if (media.type === 'image') {
              this.props.editAction.command = 'show-image'
            } else if (media.type === 'video') {
              this.props.editAction.command = 'show-video'
            }
          }
          this.props.editAction[property] = propValue
          this.props.onStepwiseEvent('action', this.props.editAction)
          break

        case 'enter':
        case 'delay':
          this.props.editAction[property] = propValue
          break

        case 'octave':
          this.props.editAction[property] = parseInt(propValue)
          this.props.onStepwiseEvent('action', this.props.editAction)
          break

        default:
          this.props.editAction[property] = propValue
          this.props.onStepwiseEvent('action', this.props.editAction)
          break
      }
    }

    handleRangePropertyChange(value, property) {
      this.props.editAction[property] = value[0] + ',' + value[1]
    }

    handleRangeRelease = () => {
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleVisualContentChange(evt, property) {
      let media = this.props.stepwise.score.getMedia(evt.currentTarget.value)
      if (media.type === 'image') {
        this.props.editAction.command = 'show-image'
      } else {
        this.props.editAction.command = 'show-video'
      }
      this.setDefaultsForCommand(this.props.editAction.command)
      this.props.editAction.content = evt.currentTarget.value
      this.forceUpdate()
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleKeyChange(evt) {
      this.updateContent()
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      console.log(this.content)
      if (!this.content.key) {
        let priorState = this.props.sequence.getCurrentStateForFeatureInStep(
          this.content.feature,
          step,
          true,
          true
        )
        this.content.state = step.createStateForFeature(
          this.content.feature,
          priorState
        )
        console.log('create state')
      } else {
        step.deleteStateForFeature(this.content.feature)
        this.content.state =
          this.props.sequence.getCurrentStateForFeatureInStep(
            this.content.feature,
            step,
            true,
            true
          )
        console.log('delete state')
      }
      this.props.onStepwiseEvent('state', this.content.state)
      this.forceUpdate()
    }

    handleDetailsToggle() {
      this.setState({ showDetails: !this.state.showDetails })
    }

    addTextSteps() {
      let score = this.props.stepwise.score
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      let action = this.props.editAction
      let delay = action.delay ? action.delay : 0
      let character = action.targetCharacter
      let sourceText = []
      let nonAppendStepIndexes = []
      let json, n, lines, totalLength
      var _compromise
      switch (this.state.split) {
        case 'syllable':
          _compromise = compromise.extend(syllables)
          json = _compromise(action.content).syllables({
            terms: { normal: true },
          })
          // concatenate arrays of syllables
          json.forEach((sentence) => {
            sourceText = sourceText.concat(sentence.syllables)
          })
          let sourceCharacterIndex = 0
          let punctText = []
          // the syllable routine removes punctuation, so now we loop through each syllable
          // and add it back in by comparing with the source text
          sourceText.forEach((syllable, index) => {
            let punctSyllable = ''
            let n = syllable.length
            let sourceChar, syllableChar
            // loop through each character of the syllable
            for (let i = 0; i < n; i++) {
              syllableChar = syllable[i]
              sourceChar = action.content[sourceCharacterIndex]
              // if it's the first character in the syllable, prepend any missing punctuation
              if (i === 0) {
                while (syllableChar !== sourceChar) {
                  punctSyllable += sourceChar
                  //console.log('added start punct: ' + sourceChar);
                  sourceCharacterIndex++
                  sourceChar = action.content[sourceCharacterIndex]
                }
              }
              // add the character if it matches the source
              if (syllableChar === sourceChar) {
                //console.log('added ' + syllableChar);
                punctSyllable += syllableChar
                sourceCharacterIndex++
              }
              // if it doesn't match, or if this is the last character in the syllable, then
              if (syllableChar !== sourceChar || i === n - 1) {
                // if it's the last character in the syllable, get the first
                // character of the next syllable
                if (i === n - 1) {
                  let nextSyllable
                  if (index < sourceText.length - 1) {
                    nextSyllable = sourceText[index + 1]
                    syllableChar = nextSyllable[0]
                  } else {
                    syllableChar = ''
                  }
                }
                sourceChar = action.content[sourceCharacterIndex]
                // add any missing punctuation until we run out of source text or characters start matching again
                while (
                  syllableChar !== sourceChar &&
                  sourceChar !== ' ' &&
                  sourceCharacterIndex < action.content.length
                ) {
                  punctSyllable += sourceChar
                  //console.log('added end punct: ' + sourceChar);
                  sourceCharacterIndex++
                  if (sourceCharacterIndex < action.content.length) {
                    sourceChar = action.content[sourceCharacterIndex]
                  }
                }
              }
            }
            //console.log(punctSyllable);
            punctText.push(punctSyllable)
          })
          sourceText = punctText
          break

        case 'line':
          sourceText = action.content.split(/\r?\n|\r|\n/g)
          sourceText.forEach((line, index) => {
            if (index > 0) {
              sourceText[index] = '\n' + line
            }
          })
          break

        case 'character':
          lines = action.content.split(/\r?\n|\r|\n/g)
          totalLength = 0
          lines.forEach((line) => {
            totalLength += line.length + 1
            nonAppendStepIndexes.push(totalLength)
          })
          n = action.content.length
          for (let i = 0; i < n; i++) {
            sourceText.push(action.content[i])
          }
          break

        case 'sentence':
          _compromise = compromise.extend(sentences)
          json = _compromise(action.content).sentences().json()
          json.forEach((sentence) => {
            sourceText.push(sentence.text)
          })
          break

        case 'word':
          sourceText = action.content.split(' ')
          n = sourceText.length
          for (let i = n - 1; i >= 0; i--) {
            if (sourceText[i].trim() === '') {
              sourceText.splice(i, 1)
            }
          }
          sourceText.forEach((word, index) => {
            if (index > 0) {
              sourceText[index] = ' ' + word
            }
          })
          break

        default:
          sourceText.push(action.content)
          break
      }
      if (this.state.splitUnit === 'action') {
        // create new actions for each subsequent token
        sourceText.forEach((token, index) => {
          if (index === 0) {
            action.content = token
          } else {
            let newAction = new Action(
              {
                character: character.id,
                command: 'speak',
                content: token,
                append: nonAppendStepIndexes.indexOf(index) == -1,
                delay: delay + index * this.delayPerAction,
              },
              score
            )
            step.actions.push(newAction)
          }
        })
      } else if (this.state.splitUnit === 'step') {
        // if an existing step exists, add an action for the token to it,
        // otherwise create a new step
        sourceText.forEach((token, index) => {
          if (index === 0) {
            action.content = token
          } else {
            let proxyIndex = this.props.sequence.stepIndex + index
            let newStep, newAction
            if (proxyIndex < this.props.sequence.steps.length) {
              newStep = this.props.sequence.steps[proxyIndex]
              newAction = new Action(
                {
                  character: character.id,
                  command: 'speak',
                  content: token,
                  append: nonAppendStepIndexes.indexOf(index) == -1,
                },
                score
              )
              newStep.actions.push(newAction)
            } else {
              let newStep = new Step(
                {
                  states: [],
                  actions: [
                    {
                      character: character.id,
                      command: 'speak',
                      content: token,
                      append: nonAppendStepIndexes.indexOf(index) == -1,
                    },
                  ],
                },
                score,
                this.props.sequence
              )
              this.props.sequence.addStep(newStep)
            }
          }
        })
      }
      score.setupReferences()
      this.props.onGoBack()
    }

    getTextSingleSettings() {
      return (
        <div>
          <FormGroup>
            <TextArea
              style={{ height: '200px' }}
              value={this.props.editAction.content}
              fill="true"
              ref={(component) => {
                let overlays =
                  document.getElementsByClassName('bp3-overlay-open')
                let node = ReactDOM.findDOMNode(component)
                if (
                  node &&
                  this.props.currentColumn === 2 &&
                  overlays.length === 0
                )
                  node.focus()
              }}
              onChange={(evt) =>
                this.handleActionPropertyChange(evt, 'content')
              }
            />
          </FormGroup>
          <FormGroup label="" labelFor="append-switch">
            <Switch
              id="append-switch"
              checked={
                this.props.editAction.append
                  ? this.props.editAction.append
                  : false
              }
              label="Add to existing text"
              onChange={(evt) => this.handleSwitchChange(evt, 'append')}
            />
          </FormGroup>
        </div>
      )
    }

    getTextMultiSettings() {
      return (
        <div>
          <FormGroup>
            <TextArea
              style={{ height: '200px' }}
              value={this.props.editAction.content}
              ref={(component) => {
                let overlays =
                  document.getElementsByClassName('bp3-overlay-open')
                let node = ReactDOM.findDOMNode(component)
                if (
                  node &&
                  this.props.currentColumn === 2 &&
                  overlays.length === 0
                )
                  node.focus()
              }}
              onChange={(evt) =>
                this.handleActionPropertyChange(evt, 'content')
              }
              fill="true"
            />
          </FormGroup>
          <p>
            Use these options to split the text above across multiple actions or
            steps, creating a "typing" effect.
          </p>
          <FormGroup label="Split on" labelFor="split-select" inline={true}>
            <HTMLSelect
              id="split-select"
              options={this.splitOptions}
              value={this.state.split}
              onChange={this.handleSplitChange.bind(this)}
            />
          </FormGroup>
          <FormGroup label="Across" labelFor="split-unit-select" inline={true}>
            <HTMLSelect
              id="split-unit-select"
              options={this.splitUnitOptions}
              value={this.state.splitUnit}
              onChange={this.handleSplitUnitChange.bind(this)}
            />
          </FormGroup>
          {this.state.splitUnit === 'action' ? (
            <FormGroup
              label="Delay per action"
              labelFor="delay-per-action"
              inline={true}
            >
              <NumericInput
                id="delay-per-action"
                style={{ width: 40 }}
                value={this.delayPerAction}
                min={1}
                onValueChange={(value) => (this.delayPerAction = value)}
              />
            </FormGroup>
          ) : null}
          <Button onClick={this.addTextSteps.bind(this)}>Split text</Button>
        </div>
      )
    }

    handleTabChange(newTabId, prevTabId) {
      this.setState({ selectedTabId: newTabId })
    }

    updateContent() {
      if (this.props.editStep && this.props.editAction) {
        let featureType = this.props.editAction.targetFeature
          ? this.props.editAction.targetFeature.type
          : 'text'
        this.content.feature =
          this.props.editAction.targetCharacter.getFeatureForType(featureType)
        this.content.state =
          this.props.sequence.getCurrentStateForFeatureInStep(
            this.content.feature,
            this.props.editStep,
            true,
            true
          )
        this.content.key = this.props.editStep.containsStateForFeature(
          this.content.feature,
          true
        )
      }
    }

    handleDurationSelect(duration) {
      this.props.editAction.duration = duration
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleVelocitySelect(velocity) {
      this.props.editAction.velocity = velocity
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleSliderChange = (property, value) => {
      this.props.editAction[property] = value
    }

    handleColorChange(property, color) {
      this.props.editAction[property] =
        'rgba(' +
        color.rgb.r +
        ',' +
        color.rgb.g +
        ',' +
        color.rgb.b +
        ',' +
        color.rgb.a +
        ')'
    }

    getDurationMenu() {
      return (
        <ButtonGroup className="music-widget note">
          {window.noteDurations.map((duration, index) => {
            return (
              <Button
                key={index}
                intent={
                  this.props.editAction.duration === duration
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
                onClick={() => this.handleDurationSelect(duration)}
              >
                {window.getNotationForDuration(duration)}
              </Button>
            )
          })}
        </ButtonGroup>
      )
    }

    getDynamicsMenu() {
      return (
        <ButtonGroup className="music-widget dynamics">
          {window.noteVelocities.map((velocity, index) => {
            let lo = 0
            if (index > 0) {
              lo = window.noteVelocities[index - 1]
            }
            return (
              <Button
                key={index}
                intent={
                  this.props.editAction.velocity > lo &&
                  this.props.editAction.velocity <= velocity
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
                onClick={() => this.handleVelocitySelect(velocity)}
              >
                {window.getNotationForVelocity(velocity)}
              </Button>
            )
          })}
        </ButtonGroup>
      )
    }

    getSequences() {
      var sequences = [{ label: 'Select sequence', value: null }]
      Object.values(this.props.stepwise.score.currentScene.sequences).forEach(
        (sequence, index) => {
          if (sequence !== this.props.sequence) {
            sequences.push({ label: sequence.title, value: sequence.id })
          }
        }
      )
      return sequences
    }

    getActionEditor() {
      let settings
      if (this.props.editAction) {
        let media

        this.updateContent()

        switch (this.props.editAction.command) {
          case 'enter':
            settings = (
              <div>
                <div>
                  <div>
                    <FormGroup
                      label="Direction"
                      helperText="Chosen randomly from all selected"
                      labelFor="enter-direction-select"
                    >
                      <ButtonGroup id="enter-direction-select" fill={true}>
                        <Button
                          intent={
                            this.props.editAction.direction.indexOf('top') !==
                            -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleDirectionToggle(evt, 'top')
                          }
                          icon="arrow-down"
                        >
                          Top
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.direction.indexOf('right') !==
                            -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleDirectionToggle(evt, 'right')
                          }
                          icon="arrow-left"
                        >
                          Right
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.direction.indexOf(
                              'bottom'
                            ) !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleDirectionToggle(evt, 'bottom')
                          }
                          icon="arrow-up"
                        >
                          Bottom
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.direction.indexOf('left') !==
                            -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleDirectionToggle(evt, 'left')
                          }
                          icon="arrow-right"
                        >
                          Left
                        </Button>
                      </ButtonGroup>
                    </FormGroup>
                    {this.props.editAction.amount.indexOf('custom') === -1 ? (
                      <FormGroup
                        label="Lane Size"
                        helperText="Size of the lane the character slides in on (if layout allows)"
                        labelFor="size-select"
                      >
                        <ButtonGroup id="size-select" fill={true}>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf('third') !== -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'third')
                            }
                          >
                            ¼
                          </Button>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf('quarter') !==
                              -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'quarter')
                            }
                          >
                            ⅓
                          </Button>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf('half') !== -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'half')
                            }
                          >
                            Half
                          </Button>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf(
                                'two-thirds'
                              ) !== -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'two-thirds')
                            }
                          >
                            ⅔
                          </Button>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf(
                                'three-quarters'
                              ) !== -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'three-quarters')
                            }
                          >
                            ¾
                          </Button>
                          <Button
                            intent={
                              this.props.editAction.size.indexOf('full') !== -1
                                ? Intent.PRIMARY
                                : null
                            }
                            onClick={(evt) =>
                              this.handleSizeToggle(evt, 'full')
                            }
                          >
                            Full
                          </Button>
                        </ButtonGroup>
                      </FormGroup>
                    ) : null}
                    <FormGroup
                      label="Fill Amount"
                      helperText="Amount of the lane the character fills"
                      labelFor="amount-select"
                    >
                      <ButtonGroup id="amount-select" fill={true}>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf('quarter') !==
                            -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'quarter')
                          }
                        >
                          ¼
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf('third') !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'third')
                          }
                        >
                          ⅓
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf('half') !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'half')
                          }
                        >
                          Half
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf(
                              'two-thirds'
                            ) !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'two-thirds')
                          }
                        >
                          ⅔
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf(
                              'three-quarters'
                            ) !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'three-quarters')
                          }
                        >
                          ¾
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf('full') !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'full')
                          }
                        >
                          Full
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf(
                              'match-content'
                            ) !== -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'match-content')
                          }
                        >
                          Aspect
                        </Button>
                        <Button
                          intent={
                            this.props.editAction.amount.indexOf('custom') !==
                            -1
                              ? Intent.PRIMARY
                              : null
                          }
                          onClick={(evt) =>
                            this.handleAmountToggle(evt, 'custom')
                          }
                        >
                          Custom
                        </Button>
                      </ButtonGroup>
                    </FormGroup>

                    {this.props.editAction.amount.indexOf('custom') !== -1 ? (
                      <FormGroup
                        label="Destination"
                        labelFor="destination-input"
                        helperText="(left, top, width, height, 12x12 grid)"
                      >
                        <ControlGroup id="destination-input">
                          <NumericInput
                            id="destination-left-enter-input"
                            style={{ width: 40 }}
                            value={window.getLayoutComponent(
                              this.props.editAction.content,
                              'left'
                            )}
                            onValueChange={(value) =>
                              this.handleLayoutPropertyChange(value, 'left')
                            }
                          />
                          <NumericInput
                            id="destination-top-enter-input"
                            style={{ width: 40 }}
                            value={window.getLayoutComponent(
                              this.props.editAction.content,
                              'top'
                            )}
                            onValueChange={(value) =>
                              this.handleLayoutPropertyChange(value, 'top')
                            }
                          />
                          <NumericInput
                            id="destination-width-input"
                            style={{ width: 40 }}
                            value={window.getLayoutComponent(
                              this.props.editAction.content,
                              'width'
                            )}
                            onValueChange={(value) =>
                              this.handleLayoutPropertyChange(value, 'width')
                            }
                          />
                          <NumericInput
                            id="destination-height-input"
                            style={{ width: 40 }}
                            value={window.getLayoutComponent(
                              this.props.editAction.content,
                              'height'
                            )}
                            onValueChange={(value) =>
                              this.handleLayoutPropertyChange(value, 'height')
                            }
                          />
                        </ControlGroup>
                      </FormGroup>
                    ) : null}
                    <FormGroup
                      label="Physics"
                      helperText="The character's effect on other characters"
                      labelFor="physics-select"
                    >
                      <HTMLSelect
                        className="physics-select"
                        options={this.physicsTypes}
                        value={this.props.editAction.physics}
                        onChange={(evt) =>
                          this.handleActionPropertyChange(evt, 'physics')
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            )
            break

          case 'move':
            settings = (
              <div>
                <div>
                  <div>
                    <FormGroup
                      label="Destination"
                      labelFor="destination-input"
                      helperText="(left, top, width, height)"
                    >
                      <ControlGroup id="destination-input">
                        <NumericInput
                          id="destination-left-input"
                          style={{ width: 40 }}
                          value={window.getLayoutComponent(
                            this.props.editAction.content,
                            'left'
                          )}
                          onValueChange={(value) =>
                            this.handleLayoutPropertyChange(value, 'left')
                          }
                        />
                        <NumericInput
                          id="destination-top-input"
                          style={{ width: 40 }}
                          value={window.getLayoutComponent(
                            this.props.editAction.content,
                            'top'
                          )}
                          onValueChange={(value) =>
                            this.handleLayoutPropertyChange(value, 'top')
                          }
                        />
                        <NumericInput
                          id="destination-width-input"
                          style={{ width: 40 }}
                          value={window.getLayoutComponent(
                            this.props.editAction.content,
                            'width'
                          )}
                          onValueChange={(value) =>
                            this.handleLayoutPropertyChange(value, 'width')
                          }
                        />
                        <NumericInput
                          id="destination-height-input"
                          style={{ width: 40 }}
                          value={window.getLayoutComponent(
                            this.props.editAction.content,
                            'height'
                          )}
                          onValueChange={(value) =>
                            this.handleLayoutPropertyChange(value, 'height')
                          }
                        />
                      </ControlGroup>
                    </FormGroup>
                  </div>
                </div>
              </div>
            )
            break

          case 'play-audio':
            media = this.props.stepwise.score.getMedia(
              this.props.editAction.content
            )
            settings = (
              <div>
                <div>
                  <div>
                    <MediaSelector
                      stepwise={this.props.stepwise}
                      sequence={this.props.sequence}
                      user={this.props.user}
                      editStep={this.props.editStep}
                      editAction={this.props.editAction}
                      uploadedMedia={this.props.uploadedMedia}
                      uploadedMediaSize={this.props.uploadedMediaSize}
                      onMediaUploaded={this.props.onMediaUploaded}
                      onStepChange={this.props.onStepChange}
                      onStoryUpdate={this.props.onStoryUpdate}
                      onStepwiseEvent={this.props.onStepwiseEvent}
                    />
                    <FormGroup
                      label="Volume &nbsp;&nbsp;"
                      inline={true}
                      style={{ marginRight: '20px' }}
                    >
                      <Slider
                        min={0}
                        max={2}
                        labelStepSize={1}
                        labelPrecision={1}
                        stepSize={0.01}
                        vertical={false}
                        value={this.props.editAction.volume}
                        onChange={(value) =>
                          this.handleSliderChange('volume', value)
                        }
                      />
                    </FormGroup>
                    <Switch
                      id="loop-switch"
                      checked={
                        this.props.editAction.loop
                          ? this.props.editAction.loop
                          : false
                      }
                      label="Loop"
                      onChange={(evt) => this.handleSwitchChange(evt, 'loop')}
                    />
                    <Switch
                      id="pause-other-switch"
                      checked={
                        this.props.editAction.solo
                          ? this.props.editAction.solo
                          : false
                      }
                      label="Pause other audio"
                      onChange={(evt) => this.handleSwitchChange(evt, 'solo')}
                    />
                  </div>
                </div>
              </div>
            )
            break

          case 'play-note':
            settings = (
              <div>
                <div className="form-col min">
                  <InputGroup
                    id="note-input"
                    value={this.props.editAction.content}
                    ref={(component) => {
                      let overlays =
                        document.getElementsByClassName('bp3-overlay-open')
                      let node = ReactDOM.findDOMNode(component)
                      if (node && overlays.length === 0) node.firstChild.focus()
                    }}
                    style={{ width: 60 }}
                    placeholder="Enter MIDI note name..."
                    onChange={(evt) =>
                      this.handleActionPropertyChange(evt, 'content')
                    }
                  />
                </div>
                <div className="form-col min">
                  <Popover content={this.getDurationMenu()}>
                    <Button className="music-widget note">
                      {window.getNotationForDuration(
                        this.props.editAction.duration
                      )}
                    </Button>
                  </Popover>
                </div>
                <div className="form-col min">
                  <Popover content={this.getDynamicsMenu()}>
                    <Button className="music-widget dynamics">
                      {window.getNotationForVelocity(
                        this.props.editAction.velocity
                      )}
                    </Button>
                  </Popover>
                </div>
              </div>
            )
            break

          case 'set-audio-volume':
          case 'set-video-volume':
            media = this.props.stepwise.score.getMedia(
              this.props.editAction.content
            )
            settings = (
              <div>
                <div>
                  <div>
                    <FormGroup
                      label="Volume &nbsp;&nbsp;"
                      inline={true}
                      style={{ marginRight: '20px' }}
                    >
                      <Slider
                        min={0}
                        max={1.5}
                        labelStepSize={1}
                        labelPrecision={1}
                        stepSize={0.01}
                        vertical={false}
                        value={this.props.editAction.volume}
                        onChange={(value) =>
                          this.handleSliderChange('volume', value)
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      label="Fade (secs)"
                      labelFor="fade-duration-input"
                      inline={true}
                    >
                      <InputGroup
                        id="fade-duration-input"
                        style={{ width: '50px' }}
                        placeholder="secs"
                        value={this.props.editAction.duration}
                        onChange={(evt) =>
                          this.handleActionPropertyChange(evt, 'duration')
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            )
            break

          case 'set-sequence':
            settings = (
              <div>
                <FormGroup>
                  <HTMLSelect
                    options={this.getSequences()}
                    value={this.props.editAction.content}
                    onChange={(evt) =>
                      this.handleActionPropertyChange(evt, 'content')
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Switch
                    id="autoplay-switch"
                    checked={
                      this.props.editAction.autoplay
                        ? this.props.editAction.autoplay
                        : false
                    }
                    label="Autoplay first step"
                    onChange={(evt) => this.handleSwitchChange(evt, 'autoplay')}
                  />
                </FormGroup>
              </div>
            )
            break

          case 'play-beat':
            settings = (
              <div>
                <div className="form-col min">
                  <InputGroup
                    id="note-input"
                    value={this.props.editAction.content}
                    ref={(component) => {
                      let overlays =
                        document.getElementsByClassName('bp3-overlay-open')
                      let node = ReactDOM.findDOMNode(component)
                      if (node && overlays.length === 0) node.firstChild.focus()
                    }}
                    style={{ width: 220 }}
                    placeholder="Enter beat..."
                    onChange={(evt) =>
                      this.handleActionPropertyChange(evt, 'content')
                    }
                  />
                </div>
                <div className="form-col min">
                  <Popover content={this.getDynamicsMenu()}>
                    <Button className="music-widget dynamics">
                      {window.getNotationForVelocity(
                        this.props.editAction.velocity
                      )}
                    </Button>
                  </Popover>
                </div>
              </div>
            )
            break

          case 'play-phrase':
            let octaveShift = this.props.editAction.octaveShift
              ? this.props.editAction.octaveShift
              : '-1,1'
            let octaveShiftRange = octaveShift.split(',')
            let minOctaveShift = parseInt(octaveShiftRange[0])
            let maxOctaveShift = parseInt(octaveShiftRange[1])
            let sequenceLength = this.props.editAction.sequenceLength
              ? this.props.editAction.sequenceLength
              : '2,4'
            let sequenceLengthRange = sequenceLength.split(',')
            let minSequenceLength = parseInt(sequenceLengthRange[0])
            let maxSequenceLength = parseInt(sequenceLengthRange[1])
            settings = (
              <div>
                <p>
                  The Play Phrase action plays a sequence of notes randomly
                  chosen from the selected chord. The starting or ending note
                  will be randomly shifted up or down by octaves.
                </p>
                <div>
                  <div className="form-col min">
                    <HTMLSelect
                      id="chord-select"
                      options={this.chordOptions}
                      value={this.props.editAction.content}
                      onChange={(evt) =>
                        this.handleActionPropertyChange(evt, 'content')
                      }
                    />
                  </div>
                  <div className="form-col min">
                    <Popover content={this.getDurationMenu()}>
                      <Button className="music-widget note">
                        {window.getNotationForDuration(
                          this.props.editAction.duration
                        )}
                      </Button>
                    </Popover>
                  </div>
                  <div className="form-col min">
                    <Popover content={this.getDynamicsMenu()}>
                      <Button className="music-widget dynamics">
                        {window.getNotationForVelocity(
                          this.props.editAction.velocity
                        )}
                      </Button>
                    </Popover>
                  </div>
                </div>
                <br />
                <FormGroup
                  label="Sequence length"
                  labelFor="sequence-length-slider"
                >
                  <RangeSlider
                    id="sequence-length-slider"
                    min={2}
                    max={10}
                    value={[minSequenceLength, maxSequenceLength]}
                    onChange={(value) =>
                      this.handleRangePropertyChange(value, 'sequenceLength')
                    }
                    onRelease={this.handleRangeRelease}
                  />
                </FormGroup>
                <FormGroup
                  label="Base octave"
                  labelFor="octave-select"
                  inline={true}
                >
                  <HTMLSelect
                    id="octave-select"
                    options={this.octaveOptions}
                    value={this.props.editAction.octave}
                    onChange={(evt) =>
                      this.handleActionPropertyChange(evt, 'octave')
                    }
                  />
                </FormGroup>
                <FormGroup
                  label="Start/end octave shift"
                  labelFor="octave-shift-slider"
                >
                  <RangeSlider
                    id="octave-shift-slider"
                    min={-3}
                    max={3}
                    value={[minOctaveShift, maxOctaveShift]}
                    onChange={(value) =>
                      this.handleRangePropertyChange(value, 'octaveShift')
                    }
                    onRelease={this.handleRangeRelease}
                  />
                </FormGroup>
              </div>
            )
            break

          case 'sample':
            settings = (
              <div>
                <div>
                  <div>
                    <Switch
                      id="mimic-switch"
                      checked={
                        this.props.editAction.mimic
                          ? this.props.editAction.mimic
                          : false
                      }
                      label="Mimic sampled character"
                      onChange={(evt) => this.handleSwitchChange(evt, 'mimic')}
                    />
                  </div>
                </div>
              </div>
            )
            break

          case 'set-light':
            settings = (
              <div>
                <div>
                  <div>
                    <FormGroup
                      label="Color"
                      labelFor="light-color"
                      inline={true}
                    >
                      <Popover id="light-color" usePortal={false}>
                        <div className="swatch">
                          <div
                            className="color"
                            style={{
                              backgroundColor: this.props.editAction.color,
                            }}
                          />
                        </div>
                        <SketchPicker
                          color={window.getRGBAColor(
                            this.props.editAction.color
                          )}
                          onChangeComplete={(color) =>
                            this.handleColorChange(
                              this.props.editAction,
                              'color',
                              color
                            )
                          }
                        />
                      </Popover>
                    </FormGroup>
                    <FormGroup
                      label="Intensity&nbsp;&nbsp;"
                      labelFor="light-intensity"
                      inline={true}
                    >
                      <Slider
                        id="light-intensity"
                        min={0}
                        max={1}
                        labelStepSize={1}
                        labelPrecision={1}
                        stepSize={0.01}
                        vertical={false}
                        value={this.props.editAction.intensity}
                        onChange={(value) =>
                          this.handleSliderChange('intensity', value)
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            )
            break

          case 'show-image':
            media = this.props.stepwise.score.getMedia(
              this.props.editAction.content
            )
            settings = (
              <div>
                <div>
                  <div>
                    <MediaSelector
                      stepwise={this.props.stepwise}
                      sequence={this.props.sequence}
                      user={this.props.user}
                      editStep={this.props.editStep}
                      editAction={this.props.editAction}
                      uploadedMedia={this.props.uploadedMedia}
                      uploadedMediaSize={this.props.uploadedMediaSize}
                      onMediaUploaded={this.props.onMediaUploaded}
                      onStepChange={this.props.onStepChange}
                      onStoryUpdate={this.props.onStoryUpdate}
                      onStepwiseEvent={this.props.onStepwiseEvent}
                    />
                    {media ? (
                      <img
                        className="media-preview"
                        src={media.source}
                        alt={media.name}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            )
            break

          case 'show-video':
            media = this.props.stepwise.score.getMedia(
              this.props.editAction.content
            )
            settings = (
              <div>
                <div>
                  <div>
                    <MediaSelector
                      stepwise={this.props.stepwise}
                      sequence={this.props.sequence}
                      user={this.props.user}
                      editStep={this.props.editStep}
                      editAction={this.props.editAction}
                      uploadedMedia={this.props.uploadedMedia}
                      uploadedMediaSize={this.props.uploadedMediaSize}
                      onMediaUploaded={this.props.onMediaUploaded}
                      onStepChange={this.props.onStepChange}
                      onStoryUpdate={this.props.onStoryUpdate}
                      onStepwiseEvent={this.props.onStepwiseEvent}
                    />
                    {media ? (
                      <video
                        className="media-preview"
                        src={media.source}
                        controls
                      />
                    ) : null}
                    <FormGroup
                      label="Volume &nbsp;&nbsp;"
                      inline={true}
                      style={{ marginRight: '20px' }}
                    >
                      <Slider
                        min={0}
                        max={1}
                        labelStepSize={1}
                        labelPrecision={1}
                        stepSize={0.01}
                        vertical={false}
                        value={this.props.editAction.volume}
                        onChange={(value) =>
                          this.handleSliderChange('volume', value)
                        }
                      />
                    </FormGroup>
                    <Switch
                      id="loop-switch"
                      checked={
                        this.props.editAction.loop
                          ? this.props.editAction.loop
                          : false
                      }
                      label="Loop"
                      onChange={(evt) => this.handleSwitchChange(evt, 'loop')}
                    />
                    <FormGroup
                      label="Start time"
                      labelFor="start-time"
                      labelInfo="(secs)"
                      inline={true}
                    >
                      <NumericInput
                        id="start-time"
                        style={{ width: 40 }}
                        value={
                          this.props.editAction.startTime !== undefined
                            ? this.props.editAction.startTime
                            : 0
                        }
                        min={0}
                        onValueChange={(value) =>
                          this.handleNumericActionPropertyChange(
                            'startTime',
                            value
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      label="Playback rate"
                      labelInfo="(1 = normal)"
                      style={{ marginRight: '20px' }}
                    >
                      <Slider
                        min={0}
                        max={2}
                        labelStepSize={1}
                        labelPrecision={1}
                        stepSize={0.01}
                        vertical={false}
                        value={
                          this.props.editAction.playbackRate !== undefined
                            ? this.props.editAction.playbackRate
                            : 1
                        }
                        onChange={(value) =>
                          this.handleSliderChange('playbackRate', value)
                        }
                      />
                    </FormGroup>
                    <Switch
                      id="pause-other-switch"
                      checked={
                        this.props.editAction.solo
                          ? this.props.editAction.solo
                          : false
                      }
                      label="Pause other audio"
                      onChange={(evt) => this.handleSwitchChange(evt, 'solo')}
                    />
                  </div>
                </div>
              </div>
            )
            break

          case 'speak':
            settings = (
              <div>
                <div>
                  <Tabs
                    onChange={this.handleTabChange.bind(this)}
                    selectedTabId={this.state.selectedTabId}
                  >
                    <Tab
                      id="single-text-tab"
                      title="Single"
                      panel={this.getTextSingleSettings()}
                    />
                    <Tab
                      id="multi-text-tab"
                      title="Multiple"
                      panel={this.getTextMultiSettings()}
                    />
                  </Tabs>
                </div>
              </div>
            )
            break

          default:
            settings = (
              <NonIdealState icon={'settings'} title="No additional options" />
            )
            break
        }
      }
      return <div className="info-popover">{settings}</div>
    }

    render() {
      if (!this.props.editStep || !this.props.editAction) {
        return (
          <div className="score-column">
            <div className="sequence-editor">
              <Button
                icon="chevron-left"
                minimal="true"
                onClick={this.props.onGoBack}
              ></Button>
              &nbsp;No action selected
            </div>
          </div>
        )
      } else {
        return (
          <div className="score-column">
            <div className="sequence-editor">
              <Button
                icon="chevron-left"
                minimal="true"
                onClick={this.props.onGoBack}
              ></Button>
              &nbsp;Action:{' '}
              {window.getCapitalizedCommand(this.props.editAction.command)}
            </div>
            {/*<div className="score-column-header">
        </div>*/}
            <div className="score-column-body no-scroll">
              {this.getActionEditor()}
            </div>
          </div>
        )
      }
    }
  }
)

export default ActionColumn
