import React from 'react'
import {
  FormGroup,
  InputGroup,
  Switch,
  Slider,
  Popover,
  Button,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'

const AudioStateEditor = observer(
  class AudioStateEditor extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
    }

    handlePropertyChange(evt, property, value) {
      let propValue = value || evt.currentTarget.value
      this.props.state[property] = propValue
      this.props.onStepwiseEvent('state', this.props.state)
    }

    handleTabChange(newTabId, prevTabId) {
      this.setState({ selectedTabId: newTabId })
    }

    handleSwitchChange(evt, property) {
      this.props.state[property] = evt.currentTarget.checked
    }

    handleSliderChange(property, value) {
      this.props.state[property] = value
    }

    getSettings() {
      return (
        <React.Fragment>
          <Popover>
            <Button minimal="true" icon="settings" />
            <div className="state-settings">
              <FormGroup
                label="Volume &nbsp;&nbsp;"
                inline={true}
                style={{ marginRight: '20px' }}
              >
                <Slider
                  min={0}
                  max={1}
                  labelStepSize={1}
                  labelPrecision={1}
                  stepSize={0.01}
                  vertical={false}
                  value={this.props.state.volume}
                  onChange={(value) => this.handleSliderChange('volume', value)}
                />
              </FormGroup>
              <FormGroup
                label="Fade"
                labelFor="fade-duration-input"
                inline={true}
              >
                <InputGroup
                  id="fade-duration-input"
                  style={{ width: '50px' }}
                  placeholder="secs"
                  value={this.props.state.transitionDuration}
                  onChange={(evt) =>
                    this.handlePropertyChange(evt, 'transitionDuration')
                  }
                />
              </FormGroup>
            </div>
          </Popover>{' '}
          &nbsp;&nbsp;
          <FormGroup labelFor="loop-switch" inline={true}>
            <Switch
              id="loop-switch"
              checked={this.props.state.loop}
              label="Loop"
              onChange={(evt) => this.handleSwitchChange(evt, 'loop')}
            />
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <FormGroup labelFor="pause-audio-switch" inline={true}>
            <Switch
              id="pause-audio-switch"
              checked={this.props.state.pauseOtherAudio}
              label="Pause other audio"
              onChange={(evt) =>
                this.handleSwitchChange(evt, 'pauseOtherAudio')
              }
            />
          </FormGroup>{' '}
          &nbsp;&nbsp;
        </React.Fragment>
      )
    }

    render() {
      return <div className="state-editor-controls">{this.getSettings()}</div>
    }
  }
)

export default AudioStateEditor
