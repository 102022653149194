import React from 'react'
import * as Tone from 'tone'
import TWEEN from '@tweenjs/tween.js'
//import { isMobile } from  'react-device-detect';

class AudioFeature extends React.Component {
  constructor(props) {
    super(props)
    this.playing = false
    this.isMuted = false
    this.players = {}
    this.tweens = {}
    this.isFading = false
    this.animateFade = this.animateFade.bind(this)
    this.maxVolumeForCurrentMedia = 1
    this.tweenGroup = new TWEEN.Group()
    requestAnimationFrame(this.animateFade)
    this.currentState = this.props.feature.defaultState
  }

  componentDidMount() {
    this.applyState(this.currentState)
  }

  shouldComponentUpdate() {
    return false
  }

  handleStoryUpdate() {
    this.forceUpdate()
  }

  applyState(state) {
    this.currentState = state
    for (let property in state) {
      switch (property) {
        case 'volume':
          /*if (this.tween) {
          this.tween.stop();
        }
        this.tweenGroup.removeAll();
        if (Object.values(this.players).length > 0) {
          if (state.transitionDuration > 0) {
            var data = {volume: Tone.dbToGain(Object.values(this.players)[0].volume.value)}
            this.tween = new TWEEN.Tween(data, this.tweenGroup)
              .to({volume:state.volume}, state.transitionDuration * 1000)
              .onUpdate(() => {
                Object.values(this.players).forEach(player => {
                  player.volume.value = Tone.gainToDb(data.volume)
                });
              })
              .start();
            //this.isFading = true;
          } else {
            Object.values(this.players).forEach(player => {
              player.volume.value = Tone.gainToDb(state.volume)
            });
          }
        }*/
          break

        default:
          break
      }
    }
    /*if (this.stateAudio) {
      this.stateAudio.current.volume = state.volume;
    }*/
    this.setTransition(state.transitionDuration)
  }

  animateFade(time) {
    requestAnimationFrame(this.animateFade)
    //if (this.isFading) requestAnimationFrame(this.animateFade);
    //if (Object.values(this.tweenGroup._tweens).length > 0) requestAnimationFrame(this.animateFade);
    //console.log(this.isFading, time);
    this.tweenGroup.update(time)
  }

  preloadAudioForSequence(sequence) {
    sequence.steps.forEach((step) => {
      let media
      /*step.states.forEach(state => {
        if (state.type === 'audio') {
          media = this.state.stepwise.score.getMedia(state.media);
          if (media) {
            this.addAudioPlayer(media);
          }
        }
      });*/
      step.actions.forEach((action) => {
        if (
          action.targetCharacter === this.props.feature.parentCharacter &&
          action.command === 'play-audio'
        ) {
          media = this.props.stepwise.score.getMedia(action.content)
          if (media) {
            this.addAudioPlayer(media)
          }
        }
      })
    })
  }

  addAudioPlayer(media, playImmediately = false, volume = 1, loop = false) {
    if (!this.players[media.id]) {
      //console.log('adding audio player: ' + media.source);
      this.players[media.id] = new Tone.Player(media.source).toDestination()
      this.players[media.id].volume.value = Tone.gainToDb(volume)
      this.players[media.id].loop = loop
      if (playImmediately) {
        this.players[media.id].autostart = true
      }
    }
  }

  playAudio(media, volume = 1, loop = false) {
    if (this.players[media.id]) {
      if (this.players[media.id].loaded) {
        this.players[media.id].volume.value = Tone.gainToDb(volume)
        this.players[media.id].loop = loop
        this.players[media.id].start()
      }
    } else {
      this.addAudioPlayer(media, true, volume, loop)
    }
  }

  doAction(action) {
    //console.log(action);
    switch (action.command) {
      case 'enter':
        if (this.currentVideo) {
          this.playing = this.props.isPreviewing
          /*if (this.currentVideo.source !== 'webcam' && this.currentVideo.source.indexOf('giphy') === -1) {
          this.props.onSolo(this.props.feature.parentCharacter.id);
        }*/
          this.isMuted = false
          setTimeout(() => {
            this.forceUpdate()
          }, 250)
        }
        break

      case 'play-audio':
        let media = this.props.stepwise.score.getMedia(action.content)
        if (media && this.props.isPreviewing) {
          //console.log('play-audio',this.props.feature.parentCharacter.fullName,action.volume);
          this.playAudio(media, action.volume, action.loop)
        }
        if (action.solo) {
          this.props.onSolo(this.props.feature.parentCharacter.id)
        }
        break

      case 'set-audio-volume':
        if (this.tween) {
          this.tween.stop()
        }
        this.tweenGroup.removeAll()
        if (Object.values(this.players).length > 0) {
          if (action.duration > 0) {
            var data = {
              volume: Tone.dbToGain(
                Object.values(this.players)[0].volume.value
              ),
            }
            this.tween = new TWEEN.Tween(data, this.tweenGroup)
              .to({ volume: action.volume }, action.duration * 1000)
              .onUpdate(() => {
                Object.values(this.players).forEach((player) => {
                  //console.log(this.props.feature.parentCharacter.fullName,player.state,data.volume);
                  player.volume.value = Tone.gainToDb(data.volume)
                })
              })
              .start()
            //this.isFading = true;
          } else {
            Object.values(this.players).forEach((player) => {
              player.volume.value = Tone.gainToDb(action.volume)
            })
          }
        }
        break

      case 'stop-audio':
        this.pause()
        break

      default:
        break
    }
  }

  getActions() {
    return []
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs
    //this.video.current.style.transition = `scale ${secs}s, transform ${secs}s, background-size ${secs}s, opacity ${secs}s, filter ${secs}s`;
  }

  /*setAudio(media) {
    if (this.currentStateAudio !== media) {
      this.currentStateAudio = media;
      if (media) {
        this.stateAudio.current.src = media.source;
        this.stateAudio.current.currentTime = 0;
        this.maxVolumeForCurrentMedia = this.currentState.volume;
        if (this.props.isPreviewing && this.currentState.isPlaying) {
          this.stateAudio.current.play();
        } else {
          this.stateAudio.current.pause();
        }
      }
    } else if (this.currentStateAudio) {
      // if on mobile, interpret any attempt to lower volume as a command to stop playing
      // since we can't control volume programmatically
      if (isMobile && this.currentState.volume < this.maxVolumeForCurrentMedia) {
        this.stateAudio.current.pause();
      } else if (this.props.isPreviewing && this.currentState.isPlaying) {
        this.stateAudio.current.play();
      } else {
        this.stateAudio.current.pause();
      }
    }
    this.forceUpdate();
  }*/

  mute() {
    //console.log('mute '+this.props.feature.id);
    //this.playing = false;
    Object.values(this.players).forEach((player) => {
      player.volume.value = Tone.gainToDb(0)
    })
    this.isMuted = true
    this.forceUpdate()
  }

  togglePlayPause() {
    if (!this.isMuted) {
      Object.values(this.players).forEach((player) => {
        if (player.state === 'started') {
          player.stop()
        } else {
          player.start()
        }
      })
    }
  }

  pause() {
    this.playing = false
    Object.values(this.players).forEach((player) => {
      player.stop()
    })
    //this.stateAudio.current.pause();
    this.forceUpdate()
  }

  play() {}

  updateLayout() {}

  resetContent() {}

  render() {
    let className = 'panel-audio disable-interaction'
    return <div className={className}></div>
  }
}

export default AudioFeature
