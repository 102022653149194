import React from 'react'
import { observer } from 'mobx-react'

const VisualMediaThumbnail = observer(
  class VisualMediaThumbnail extends React.Component {
    render() {
      if (this.props.stepwise) {
        let media = this.props.stepwise.score.getMedia(
          this.props.action.content
        )
        let thumbnail
        if (media) {
          thumbnail = media.thumbnail
          if (!thumbnail) {
            if (media.type === 'video') {
              if (media.source === 'webcam') {
                thumbnail = 'images/webcam.png'
              } else {
                thumbnail = 'images/video.png'
              }
            } else {
              thumbnail = media.source
            }
          }
        }
        if (thumbnail && thumbnail !== 'placeholder') {
          return (
            <div className="thumbnail visual-media-thumbnail">
              <img src={thumbnail} alt={media.name} />
            </div>
          )
        } else {
          return (
            <div className="thumbnail visual-media-thumbnail placeholder"></div>
          )
        }
      }
    }
  }
)

export default VisualMediaThumbnail
