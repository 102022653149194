import React from 'react'
import './Activate.css'
import { Colors, Button, Intent, Spinner } from '@blueprintjs/core'
import firebase from './firebase.js'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import text from './i18n/english'

class Stories extends React.Component {
  constructor(props) {
    super(props)

    this.benefitLevel = {
      basic: 0,
      hobbyist: 5,
      professional: 10,
    }

    this.state = {
      user: null,
      activationCode: null,
      showSignIn: false,
      isLoading: true,
      patreonAccountLinked: false,
      benefits: this.benefitLevel.basic,
    }
  }

  componentDidMount() {
    this.setupAuth()
  }

  setupAuth = () => {
    this.ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())

    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          // User is signed in.
          //if (!this.state.user) this.getBenefitsForUser(user)
          if (!this.state.user) this.sendAppAuthorizationRequest(user)
          this.setState({
            user: user,
            showSignIn: false,
          })
        } else {
          // User is signed out.
          this.setState({
            user: null,
            showSignIn: true,
          })
          this.showSignInUI()
        }
      },
      function (error) {
        console.log(error)
      }
    )
  }

  sendAppAuthorizationRequest = (user) => {
    this.setState({
      activationCode: null,
    })
    user.getIdToken().then((accessToken) => {
      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            var response = JSON.parse(xhr.responseText)
            if (response) {
              this.setState({
                isLoading: false,
                activationCode: response.activationCode,
              })
            }
          } else {
            self.setState({
              isLoading: false,
            })
            console.log('error authorizing')
          }
        }
      }
      xhr.open('POST', 'https://origin.step.works/appauthreq', true)
      xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
      xhr.send()
    })
  }

  getBenefitsForSupportLevel = (cents) => {
    if (cents >= 1000) {
      return this.benefitLevel.professional
    } else if (cents >= 500) {
      return this.benefitLevel.hobbyist
    } else {
      return this.benefitLevel.basic
    }
  }

  getBenefitsForUser = (user) => {
    const self = this
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const tier = JSON.parse(xhr.responseText)
          const benefits = self.getBenefitsForSupportLevel(
            tier.currently_entitled_amount_cents
          )
          if (benefits >= 5) {
            self.sendAppAuthorizationRequest()
          }
          self.setState({
            patreonAccountLinked: tier.account_linked,
            benefits: benefits,
            isLoading: false,
          })
        } else {
          self.setState({
            isLoading: false,
          })
          console.log('error getting tier')
        }
      }
    }
    xhr.open('GET', 'https://origin.step.works/get_tier?user=' + user.uid, true)
    xhr.send()
  }

  showSignInUI = () => {
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return false
        },
        uiShown: function () {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none'
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      signInSuccessUrl: 'edit',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // Terms of service url.
      tosUrl: 'https://step.works/index.php/terms-conditions',
      // Privacy policy url.
      privacyPolicyUrl: 'https://step.works/index.php/privacy-policy',
    }
    this.ui.start('#firebaseui-auth-container', uiConfig)
  }

  getLogo = () => {
    return (
      <p className="about-content">
        <img
          className="logo m-auto"
          src="/images/stepwise_insignia_red_lg@2x.png"
          alt={text.about.logoAlt}
        />
      </p>
    )
  }

  getSignInUI = () => {
    return (
      <div>
        <div className="about-content">
          {this.getLogo()}
          <h1>{text.ui.stepworksStudio}</h1>
        </div>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">{text.getCode.loading}</div>
      </div>
    )
  }

  getLoadingUI = () => {
    return (
      <div>
        <p className="text-center text-lg">{text.getCode.authenticating}</p>
        <Spinner />
      </div>
    )
  }

  /* this is a duplicate of a method in App.js */
  requestPatreonLink = () => {
    console.log('request patreon link')
    var params = 'response_type=code'
    params += '&client_id=' + process.env.REACT_APP_PATREON_CLIENT_ID
    params +=
      '&redirect_uri=' +
      encodeURIComponent('https://origin.step.works/patreon_redirect')
    params += '&scope=identity identity.memberships'
    if (this.state.user) params += '&state=' + this.state.user.uid
    var url = 'https://www.patreon.com/oauth2/authorize?' + params
    window.open(url)
  }

  /* this is a duplicate of a method in App.js */
  openBenefitsPage = () => {
    window.open('https://www.patreon.com/opertoon', '_blank')
  }

  getLinkPatreonUI = () => {
    return (
      <div className="text-center text-lg">
        <p className="mb-4">{text.getCode.notLinked}</p>
        <Button onClick={this.requestPatreonLink} intent={Intent.PRIMARY} large>
          {text.benefits.patreonLinkButton}
        </Button>
      </div>
    )
  }

  getBasicBenefitsUI = () => {
    return (
      <div className="text-center text-lg">
        <p className="mb-4">{text.getCode.basicBenefits}</p>
        <Button onClick={this.openBenefitsPage} intent={Intent.PRIMARY} large>
          {text.benefits.upgradeAccessButton}
        </Button>
      </div>
    )
  }

  getActivationCodeUI = () => {
    return (
      <div className="text-center text-lg">
        <p className="mb-4">{text.getCode.enterCode}</p>
        <p className="text-5xl">
          {this.state.activationCode ? this.state.activationCode : ''}
        </p>
      </div>
    )
  }

  render() {
    return (
      <div
        className="absolute text-center w-full h-full left-0 top-0 p-5 flex items-center justify-center"
        style={{ background: Colors.DARK_GRAY4 }}
      >
        {this.state.showSignIn ? (
          this.getSignInUI()
        ) : (
          <div>
            {this.getLogo()}
            {this.state.isLoading ? this.getLoadingUI() : null}
            {/*!this.state.isLoading && !this.state.patreonAccountLinked
              ? this.getLinkPatreonUI()
        : null*/}
            {/*!this.state.isLoading &&
            this.state.patreonAccountLinked &&
            this.state.benefits < this.benefitLevel.hobbyist
              ? this.getBasicBenefitsUI()
      : null*/}
            {!this.state.isLoading /*&&
            this.state.patreonAccountLinked &&
            this.state.benefits >= this.benefitLevel.hobbyist*/
              ? this.getActivationCodeUI()
              : null}
          </div>
        )}
      </div>
    )
  }
}

export default Stories
