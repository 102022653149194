import React from 'react'
import MIDISounds from 'midi-sounds-react'

class MusicPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleStepwiseEvent = this.handleStepwiseEvent.bind(this)
    this.drumCategories = [
      'Tom',
      'Hi-hat',
      'Cymbal',
      'Bongo',
      'Conga',
      'Timbale',
      'Agogo',
      'Whistle',
      'Guiro',
      'Wood Block',
      'Cuica',
      'Triangle',
    ]
    this.instanceProcessed = false
    this.instruments = null
    this.defaultInstrument = 1158
    this.beats = {}
    this.beatCounters = {}
    this.beatVelocities = {}
    this.kickoffNotePlayed = false
  }

  componentDidMount() {
    this.instanceProcessed = false
    this.setState(this.state)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stepwise !== this.props.stepwise) {
      this.instanceProcessed = false
    }
    if (this.props.stepwise !== null) {
      if (!this.instanceProcessed) {
        this.props.stepwise.eventManager.addListener(this.handleStepwiseEvent)
        this.instanceProcessed = true
        this.parseInstruments()
        this.startMetronome()
      }
      if (prevProps.isPreviewing && !this.kickoffNotePlayed) {
        this.playNotes(-1, ['C0'], 0, 0.001) // get the clock started
        this.kickoffNotePlayed = true
      }
    }
  }

  getCategoryForDrum(drumTitle) {
    let n = this.drumCategories.length
    for (let i = 0; i < n; i++) {
      if (drumTitle.indexOf(this.drumCategories[i]) !== -1) {
        return this.drumCategories[i]
      }
    }
    return null
  }

  startMetronome() {
    if (this.metronome) clearInterval(this.metronome)
    this.metronome = setInterval(
      this.handleMetronome,
      this.props.stepwise.score.currentScene.pulse.millisecondDuration
    )
  }

  handleMetronome = () => {
    if (this.midiSounds) {
      let now = this.midiSounds.contextTime()
      //let now = Math.ceil(this.midiSounds.contextTime() / this.props.stepwise.score.currentScene.pulse.secondDuration) * this.props.stepwise.score.currentScene.pulse.secondDuration;
      Object.keys(this.beatCounters).forEach((key) => {
        let beat = this.beats[key]
        let counter = this.beatCounters[key]
        let notes
        if (beat[counter]) {
          notes = beat[counter][0][0]
        }
        if (notes) {
          let velocity = Math.max(
            0,
            Math.min(
              128,
              this.beatVelocities[key][counter] + (Math.random() * 20 - 10)
            )
          )
          this.midiSounds.setDrumVolume(notes[0], velocity / 127.0)
          this.midiSounds.setInstrumentVolume(notes[1], velocity / 127.0)
          this.midiSounds.playBeatAt(
            now + Math.random() * 0.0125,
            beat[counter],
            this.props.stepwise.score.currentScene.pulse.beatsPerMinute
          )
        }
        this.beatCounters[key]++
        if (this.beatCounters[key] >= beat.length) {
          if (this.props.isPreviewing) {
            this.beatCounters[key] = 0
          } else {
            delete this.beats[key]
            delete this.beatCounters[key]
          }
        }
      })
    }
  }

  parseInstruments() {
    this.instruments = [
      { label: 'Instruments', value: [] },
      { label: 'Drums', value: [] },
    ]
    this.instruments.forEach((instrumentType, index) => {
      let categoryNames = []
      let categories = []
      let subcategoryNames = []
      let subcategories = []
      let instrumentData
      if (instrumentType.label === 'Instruments') {
        instrumentData = this.midiSounds.player.loader.instrumentKeys()
      } else {
        instrumentData = this.midiSounds.player.loader.drumKeys()
      }
      instrumentData.forEach((datum, index) => {
        let info
        if (instrumentType.label === 'Instruments') {
          info = this.midiSounds.player.loader.instrumentInfo(index)
          let temp = info.title.split(': ')
          let categoryIndex = categoryNames.indexOf(temp[1])
          if (categoryIndex === -1) {
            categoryNames.push(temp[1])
            categories.push({ label: temp[1], value: [] })
            categoryIndex = categoryNames.length - 1
            subcategoryNames = []
            subcategories = []
          }
          let subcategoryIndex = subcategoryNames.indexOf(temp[0])
          if (subcategoryIndex === -1) {
            subcategoryNames.push(temp[0])
            subcategories.push({ label: temp[0], value: [] })
            subcategoryIndex = subcategoryNames.length - 1
            categories[categoryIndex].value.push(
              subcategories[subcategoryIndex]
            )
          }
          subcategories[subcategoryIndex].value.push({
            label:
              temp[0] +
              ' - ' +
              (subcategories[subcategoryIndex].value.length + 1),
            value: 'inst-' + index,
          })
        } else {
          info = this.midiSounds.player.loader.drumInfo(index)
          let category = this.getCategoryForDrum(info.title)
          if (category) {
            let categoryIndex = categoryNames.indexOf(category)
            if (categoryIndex === -1) {
              categoryNames.push(category)
              categories.push({ label: category, value: [] })
              categoryIndex = categoryNames.length - 1
              subcategoryNames = []
              subcategories = []
            }
            let subcategoryIndex = subcategoryNames.indexOf(info.title)
            if (subcategoryIndex === -1) {
              subcategoryNames.push(info.title)
              subcategories.push({ label: info.title, value: [] })
              subcategoryIndex = subcategoryNames.length - 1
              categories[categoryIndex].value.push(
                subcategories[subcategoryIndex]
              )
            }
            subcategories[subcategoryIndex].value.push({
              label:
                info.title +
                ' - ' +
                (subcategories[subcategoryIndex].value.length + 1),
              value: 'drum-' + index,
            })
          } else {
            let categoryIndex = categoryNames.indexOf(info.title)
            if (categoryIndex === -1) {
              categoryNames.push(info.title)
              categories.push({ label: info.title, value: [] })
              categoryIndex = categoryNames.length - 1
            }
            categories[categoryIndex].value.push({
              label:
                info.title +
                ' - ' +
                (categories[categoryIndex].value.length + 1),
              value: 'drum-' + index,
            })
          }
        }
      })
      this.instruments[index].value = categories
    })
  }

  getPlaybackDurationFromActionDuration(actionDuration) {
    if (!actionDuration) {
      actionDuration = 1
    }
    let pulse = this.props.stepwise.score.currentScene.pulse
    let n = (4 * 60) / pulse.beatsPerMinute
    let duration16th = n / 16
    let multiplier = 4 / pulse.pulsesPerBeat
    return actionDuration * 4 * multiplier * duration16th
  }

  getInstrumentData(instrument) {
    if (parseInt(instrument) !== -1) {
      let temp = instrument.split('-')
      if (temp[0] === 'inst') {
        return { type: 'instrument', id: temp[1] }
      } else if (temp[0] === 'drum') {
        return { type: 'drum', id: temp[1] }
      }
    } else {
      return { type: 'instrument', id: this.defaultInstrument }
    }
  }

  playNotes(instrument, notes, velocity, duration, when = 0) {
    velocity = velocity !== undefined ? velocity / 127.0 : 0.7
    duration = this.getPlaybackDurationFromActionDuration(duration)
    let now = this.midiSounds.contextTime()
    //let now = Math.ceil(this.midiSounds.contextTime() / this.props.stepwise.score.currentScene.pulse.secondDuration) * this.props.stepwise.score.currentScene.pulse.secondDuration;
    if (when !== 0) {
      when = this.getPlaybackDurationFromActionDuration(when)
    }
    let noteNums = []
    notes.forEach((note) => {
      noteNums.push(window.noteNameToMidiNoteNum(note))
    })
    let instrumentData = this.getInstrumentData(instrument)
    if (instrumentData.type === 'instrument') {
      this.midiSounds.setInstrumentVolume(instrumentData.id, velocity)
      this.midiSounds.playChordAt(
        now + when,
        instrumentData.id,
        noteNums,
        duration
      )
    } else {
      this.midiSounds.setDrumVolume(instrumentData.id, velocity)
      this.midiSounds.playDrumsAt(now + when, [instrumentData.id])
    }
  }

  getNotesFromChord(chord) {
    let notes = []
    switch (chord) {
      case 'Em':
        notes = ['E', 'G', 'B']
        break
      case 'Am':
        notes = ['A', 'C', 'E']
        break
      case 'Dm':
        notes = ['D', 'F', 'A']
        break
      case 'G':
        notes = ['G', 'B', 'D']
        break
      case 'C':
        notes = ['C', 'E', 'G']
        break
      case 'F':
        notes = ['F', 'A', 'C']
        break
      case 'Bb':
        notes = ['Bb', 'D', 'F']
        break
      case 'Bdim':
        notes = ['B', 'D', 'F', 'G#']
        break
      default:
        break
    }
    return notes
  }

  getPhraseFromChord(
    chord,
    octave,
    sequenceLength = '2,4',
    octaveShift = '-1,1'
  ) {
    let source = this.getNotesFromChord(chord)
    let octaveShiftRange = octaveShift.split(',').sort()
    let minOctaveShift = parseInt(octaveShiftRange[0])
    let maxOctaveShift = parseInt(octaveShiftRange[1])
    let sequenceLengthRange = sequenceLength.split(',').sort()
    let minSequenceLength = parseInt(sequenceLengthRange[0])
    let maxSequenceLength = parseInt(sequenceLengthRange[1])
    let notes = []
    let noteCount =
      minSequenceLength +
      Math.round(Math.random() * (maxSequenceLength - minSequenceLength))
    let octaveShiftValue =
      minOctaveShift +
      Math.round(Math.random() * (maxOctaveShift - minOctaveShift))
    let altAtStart = true
    if (Math.random() < 0.5) altAtStart = false
    for (var i = 0; i < noteCount; i++) {
      if (i === 0 && altAtStart) {
        notes.push(
          source[Math.floor(Math.random() * source.length)] +
            (octave + octaveShiftValue)
        )
      } else if (i === noteCount - 1 && !altAtStart) {
        notes.push(
          source[Math.floor(Math.random() * source.length)] +
            (octave + octaveShiftValue)
        )
      } else {
        notes.push(source[Math.floor(Math.random() * source.length)] + octave)
      }
    }
    return notes
  }

  playPhrase(character, script, velocity, duration) {
    //console.log(script);
    let beats = []
    let velocities = []
    let instrumentData = this.getInstrumentData(character.instrumentId)
    let n = script.length
    for (var i = 0; i < n; i++) {
      let note = script[i]
      let beat = [[], []]
      if (note) {
        if (instrumentData.type === 'instrument') {
          beat[1].push([
            instrumentData.id,
            [window.noteNameToMidiNoteNum(note)],
            duration,
          ])
          velocities.push(velocity)
        }
      } else {
        velocities.push(0)
      }
      beats.push(beat)
      //console.log(beat);
    }
    if (this.beats[character.id] !== beats) {
      this.beats[character.id] = beats
      this.beatVelocities[character.id] = velocities
      if (!this.beatCounters[character.id]) this.beatCounters[character.id] = 0
    }
  }

  playBeat(character, script, velocity, duration) {
    let beats = []
    let velocities = []
    let instrumentData = this.getInstrumentData(character.instrumentId)
    let n = script.length
    for (var i = 0; i < n; i++) {
      let char = script[i]
      let beat = [[], []]
      if (char !== '.') {
        if (instrumentData.type === 'drum') {
          beat[0].push([instrumentData.id])
          velocities.push(velocity)
        }
      } else {
        velocities.push(0)
      }
      beats.push(beat)
    }
    if (this.beats[character.id] !== beats) {
      this.beats[character.id] = beats
      this.beatVelocities[character.id] = velocities
      if (!this.beatCounters[character.id]) this.beatCounters[character.id] = 0
    }
  }

  cacheInstruments() {
    if (this.midiSounds) {
      Object.values(this.props.stepwise.score.characters).forEach(
        (character) => {
          let instrumentData = this.getInstrumentData(character.instrumentId)
          if (instrumentData.type === 'instrument') {
            this.midiSounds.cacheInstrument(instrumentData.id)
          } else {
            this.midiSounds.cacheDrum(instrumentData.id)
          }
        }
      )
    }
  }

  handleStepwiseEvent(type, obj) {
    if (this.props.isPreviewing || this.props.canPlayWhileEditing) {
      switch (type) {
        case 'action':
          switch (obj.command) {
            case 'play-note':
              this.playNotes(
                obj.targetCharacter.instrumentId,
                [obj.content],
                obj.velocity,
                obj.duration
              )
              break

            case 'play-phrase':
              let notes = this.getPhraseFromChord(
                obj.content,
                obj.octave,
                obj.sequenceLength,
                obj.octaveShift
              )
              //this.playPhrase(obj.targetCharacter, notes, obj.velocity, obj.duration);
              notes.forEach((note, index) => {
                this.playNotes(
                  obj.targetCharacter.instrumentId,
                  [note],
                  obj.velocity + (Math.random() * 60 - 30),
                  obj.duration * 0.75,
                  Math.max(
                    0,
                    obj.duration * index + (Math.random() * 0.05 - 0.025)
                  )
                )
              })
              break

            case 'play-beat':
              this.playBeat(
                obj.targetCharacter,
                obj.content,
                obj.velocity,
                obj.duration
              )
              break

            default:
              break
          }
          break
        default:
          break
      }
    }
    if (type === 'scoreLoaded') {
      this.cacheInstruments()
    }
  }

  render() {
    return (
      <MIDISounds
        ref={(ref) => (this.midiSounds = ref)}
        appElementName="root"
        instruments={[this.defaultInstrument]}
      />
    )
  }
}

export default MusicPlayer
