import React from 'react'
import CharacterAvatar from '../ColumnEditor/CharacterAvatar.js'
import FrameStateEditor from './FrameStateEditor.js'
import TextStateEditor from './TextStateEditor.js'
import VisualStateEditor from './VisualStateEditor.js'
import AudioStateEditor from './AudioStateEditor.js'
import {
  Icon,
  Button,
  ButtonGroup,
  Alignment,
  Popover,
  Position,
  Switch,
  Menu,
  Intent,
} from '@blueprintjs/core'
import { Stepwise } from '../stepworks/stepwise/stepwise-v2.js'
import { observer } from 'mobx-react'

const StateEditor = observer(
  class StateEditor extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        menuId: 'text',
      }
      this.menuData = [
        { id: 'frame', name: 'Frame', icon: 'grid-view' },
        { id: 'text', name: 'Text', icon: 'font' },
        { id: 'visual', name: 'Visual', icon: 'media' } /*,
      {"id": "audio", "name": "Audio", "icon":"volume-up"}*/,
      ]
      this.content = {}
      this.characterIcons = {
        person: 'person',
        'non-person': 'cube',
        instrument: 'music',
      }
      this.lastCommand = null
    }

    componentDidUpdate(prevProps) {
      let okToUpdate = false
      if (prevProps.editAction !== this.props.editAction) {
        okToUpdate = true
      } else {
        if (prevProps.editAction) {
          if (prevProps.editAction.command !== this.lastCommand) {
            okToUpdate = true
          }
        }
      }
      if (okToUpdate) {
        this.updateContent()
        this.setState({
          menuId: this.getMenuIdFromAction(this.props.editAction),
        })
      }
      if (this.props.editAction) {
        this.lastCommand = this.props.editAction.command
      } else {
        this.lastCommand = null
      }
    }

    getDataForMenuId(menuId) {
      let n = this.menuData.length
      for (var i = 0; i < n; i++) {
        if (this.menuData[i].id === menuId) {
          return this.menuData[i]
        }
      }
      return this.menuData[0]
    }

    getMenuIdFromAction(action) {
      if (action) {
        switch (action.command) {
          case 'enter':
          case 'move':
            return 'frame'

          case 'show-image':
          case 'show-video':
          case 'set-video-volume':
            return 'visual'

          case 'speak':
            return 'text'

          /*case 'play-audio':
        case 'set-audio-volume':
        return 'audio';*/

          default:
            return this.state.menuId
        }
      } else {
        return this.state.menuId
      }
    }

    handleOptionClick(evt) {
      this.setState({
        menuId: evt.currentTarget.getAttribute('id').split('-')[0],
      })
    }

    handleCharacterSelect = (evt) => {
      let id = evt.currentTarget.getAttribute('id').split('@')[0]
      let character = this.props.stepwise.score.getCharacter(id)
      if (character) {
        this.props.onCharacterChange(character)
      }
    }

    handleKeyChange(evt) {
      this.updateContent()
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      if (!this.content.key) {
        let priorState = this.props.sequence.getCurrentStateForFeatureInStep(
          this.content.feature,
          step,
          true,
          true
        )
        this.content.state = step.createStateForFeature(
          this.content.feature,
          priorState
        )
      } else {
        step.deleteStateForFeature(this.content.feature)
        this.content.state =
          this.props.sequence.getCurrentStateForFeatureInStep(
            this.content.feature,
            step,
            true,
            true
          )
      }
      this.props.onStepwiseEvent('state', this.content.state)
      this.forceUpdate()
    }

    getFeatureTypeForCommand(command) {
      switch (command) {
        case 'enter':
          return Stepwise.FeatureTypes.FRAME
        case 'show-image':
        case 'clear-image':
          return Stepwise.FeatureTypes.IMAGE
        case 'show-video':
        case 'set-video-volume':
        case 'stop-video':
        case 'clear-video':
          return Stepwise.FeatureTypes.VIDEO
        case 'play-audio':
        case 'set-audio-volume':
        case 'stop-audio':
          return Stepwise.FeatureTypes.AUDIO
        default:
          return null
      }
    }

    getFeatureTypeForMenuId(menuId) {
      switch (menuId) {
        case 'visual':
          if (this.props.editAction) {
            let featureType = this.getFeatureTypeForCommand(
              this.props.editAction.command
            )
            if (
              featureType === Stepwise.FeatureTypes.IMAGE ||
              featureType === Stepwise.FeatureTypes.VIDEO
            ) {
              return featureType
            } else {
              return Stepwise.FeatureTypes.IMAGE
            }
          } else {
            return Stepwise.FeatureTypes.IMAGE
          }

        default:
          return menuId
      }
    }

    updateContent() {
      if (this.props.editStep) {
        let featureType = this.getFeatureTypeForMenuId(this.state.menuId)
        if (this.props.editCharacter) {
          this.content.feature =
            this.props.editCharacter.getFeatureForType(featureType)
          this.content.state =
            this.props.sequence.getCurrentStateForFeatureInStep(
              this.content.feature,
              this.props.editStep,
              true,
              true
            )
          this.content.key = this.props.editStep.containsStateForFeature(
            this.content.feature,
            true
          )
        } else {
          this.content = {}
        }
      }
    }

    getEditor() {
      if (this.content.state) {
        switch (this.content.state.type) {
          case Stepwise.FeatureTypes.FRAME:
            return (
              <FrameStateEditor
                state={this.content.state}
                stepIndex={this.props.sequence.stepIndex}
                onStepwiseEvent={this.props.onStepwiseEvent}
                isKeyState={this.content.key}
                onKeyChange={this.handleKeyChange}
              />
            )

          case Stepwise.FeatureTypes.TEXT:
            return (
              <TextStateEditor
                state={this.content.state}
                stepIndex={this.props.sequence.stepIndex}
                onStepwiseEvent={this.props.onStepwiseEvent}
                isKeyState={this.content.key}
                onKeyChange={this.handleKeyChange}
              />
            )

          case Stepwise.FeatureTypes.IMAGE:
          case Stepwise.FeatureTypes.VIDEO:
            return (
              <VisualStateEditor
                state={this.content.state}
                stepIndex={this.props.sequence.stepIndex}
                onStepwiseEvent={this.props.onStepwiseEvent}
                isKeyState={this.content.key}
                onKeyChange={this.handleKeyChange}
              />
            )

          case Stepwise.FeatureTypes.AUDIO:
            return (
              <AudioStateEditor
                state={this.content.state}
                stepIndex={this.props.sequence.stepIndex}
                onStepwiseEvent={this.props.onStepwiseEvent}
                isKeyState={this.content.key}
                onKeyChange={this.handleKeyChange}
              />
            )

          default:
            return null
        }
      } else {
        return null
      }
    }

    render() {
      if (this.props.stepwise && this.props.editCharacter) {
        let menuOptions = this.menuData.map((data, index) => {
          return (
            <Menu.Item
              id={data.id + '-btn'}
              key={index}
              icon={data.icon}
              text={data.name}
              intent={
                this.state.menuId === data.id ? Intent.PRIMARY : Intent.NONE
              }
              onClick={this.handleOptionClick.bind(this)}
            />
          )
        })

        let characterOptions = Object.values(
          this.props.stepwise.score.characters
        ).map((character) => {
          return (
            <Menu.Item
              id={character.id + '@btn'}
              key={character.id}
              icon={this.characterIcons[character.type]}
              text={character.fullName}
              onClick={this.handleCharacterSelect}
            />
          )
        })

        this.updateContent()
        let menuData = this.getDataForMenuId(this.state.menuId)
        let isFirstStep = this.props.sequence.stepIndex <= 0

        let priorStateIndex = -1
        let nextStateIndex = -1
        if (this.content.state) {
          priorStateIndex = this.props.sequence.getPriorStateIndexForState(
            this.content.state
          )
          nextStateIndex = this.props.sequence.getNextStateIndexForState(
            this.content.state
          )
        }

        return (
          <div className="state-editor">
            {this.props.charactersEnabled ? (
              <Popover>
                <CharacterAvatar
                  character={this.props.editCharacter}
                  characterDialog={this.props.characterDialog}
                  addEntranceActions={false}
                  clickable={false}
                  action={this.props.editAction}
                />
                <Menu>{characterOptions}</Menu>
              </Popover>
            ) : null}
            <Popover
              modifiers={{
                keepTogether: { enabled: true },
                preventOverflow: { enabled: false },
                hide: { enabled: false },
                flip: { enabled: false },
              }}
              position={Position.BOTTOM}
            >
              <Button
                id={menuData.id + '-btn'}
                className="options-btn"
                icon={menuData.icon}
                minimal="true"
              />
              <Menu>{menuOptions}</Menu>
            </Popover>
            &nbsp;
            {this.getEditor()}
            <div style={{ flexGrow: 1 }} />
            <ButtonGroup>
              <Button
                icon="chevron-left"
                disabled={priorStateIndex === -1}
                onClick={() =>
                  this.props.onStepChange(
                    this.props.sequence.steps[priorStateIndex],
                    this.props.editCharacter
                  )
                }
              />
              <Button
                icon="chevron-right"
                disabled={nextStateIndex === -1}
                onClick={() =>
                  this.props.onStepChange(
                    this.props.sequence.steps[nextStateIndex],
                    this.props.editCharacter
                  )
                }
              />
            </ButtonGroup>
            &nbsp;
            <Switch
              checked={this.content.key || isFirstStep}
              alignIndicator={Alignment.LEFT}
              onChange={this.handleKeyChange.bind(this)}
              inline="true"
              disabled={isFirstStep}
            >
              <Icon icon="key" />
            </Switch>
          </div>
        )
      } else {
        return <div className="state-editor"></div>
      }
    }
  }
)

export default StateEditor
