import React from 'react'
import { Button, Dialog, Classes, Intent, ProgressBar } from '@blueprintjs/core'
import text from '../i18n/english'

interface IProps {
  isOpen: boolean
  onClose: (ev?: React.SyntheticEvent<HTMLElement, Event> | undefined) => void
  onRequestPatreonLink: (
    ev?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void
  onOpenBenefitsPage: (
    ev?: React.SyntheticEvent<HTMLElement, Event> | undefined
  ) => void
  patreonAccountLinked: boolean
  benefits: number
  uploadedMediaSize: number
  maxUploadedMedia: number
  uploadedStoryCount: number
  maxStories: number
}

class BenefitsDialog extends React.Component<IProps> {
  constructor(props: Readonly<IProps>) {
    super(props)
  }

  getBenefitsPageLink = () => {
    return <a onClick={this.props.onOpenBenefitsPage}>{text.ui.learnMore}</a>
  }

  getHeaderSection = (benefits: number) => {
    if (!this.props.patreonAccountLinked) {
      return (
        <div>
          <h1>{text.benefits.nonSupporterHead}</h1>
          <p className="max-w-prose mb-4">
            {text.benefits.nonSupporterDescription} {this.getBenefitsPageLink()}
          </p>
          <Button
            onClick={this.props.onRequestPatreonLink}
            intent={Intent.PRIMARY}
          >
            {text.benefits.patreonLinkButton}
          </Button>
        </div>
      )
    } else {
      if (benefits === 0) {
        // no contribution, just account linked
        return (
          <div>
            <h1>{text.benefits.nonSupporterHead}</h1>
            <p className="max-w-prose mb-4">
              {text.benefits.noContributionDescription}{' '}
              {this.getBenefitsPageLink()}
            </p>
            <Button
              onClick={this.props.onOpenBenefitsPage}
              intent={Intent.PRIMARY}
            >
              {text.benefits.upgradeAccessButton}
            </Button>
          </div>
        )
      } else {
        // contribution made
        return (
          <div>
            <h1>{text.benefits.supporterHead}</h1>
            <p className="max-w-prose mb-4">
              {text.benefits.supporterDescription}
            </p>
            <Button
              onClick={this.props.onOpenBenefitsPage}
              intent={Intent.PRIMARY}
            >
              {text.benefits.accessBenefitsButton}
            </Button>
          </div>
        )
      }
    }
  }

  getTierPanel = (benefitId: string, currentBenefits: number) => {
    let currentBenefitId: string = ''
    if (currentBenefits === 10) {
      currentBenefitId = 'professional'
    } else if (currentBenefits === 5) {
      currentBenefitId = 'hobbyist'
    } else if (currentBenefits === 0) {
      currentBenefitId = 'basic'
    }
    let borderClasses =
      'pt-2 pb-4 px-4 rounded cursor-pointer hover:bg-slate-800 w-1/3'
    let currentLevelClasses = 'text-center bp3-text-small uppercase'
    let priceSuffix = ''
    if (text.benefits.tiers[benefitId].price) {
      priceSuffix = ' - ' + text.benefits.tiers[benefitId].price
    }
    if (benefitId === currentBenefitId) {
      borderClasses += ' border border-lime-400'
      currentLevelClasses += ' text-lime-400'
    } else {
      currentLevelClasses += ' invisible'
    }
    const benefitsList = text.benefits.tiers[benefitId].benefits.map(
      (benefitStr: string, index: number) => <li key={index}>{benefitStr}</li>
    )
    return (
      <div className={borderClasses} onClick={this.props.onOpenBenefitsPage}>
        <p className={currentLevelClasses}>{text.benefits.currentLevel}</p>
        <h3 className="bp3-heading text-center">
          {text.benefits.tiers[benefitId].title}
          {priceSuffix}
        </h3>
        <p className="bp3-text-small">
          {text.benefits.tiers[benefitId].description}
        </p>
        <ul className="bp3-text-small list-disc list-inside">{benefitsList}</ul>
      </div>
    )
  }

  getTiers = (benefits: number) => {
    return (
      <div className="flex gap-1 mt-8">
        {this.getTierPanel('basic', benefits)}
        {this.getTierPanel('hobbyist', benefits)}
        {this.getTierPanel('professional', benefits)}
      </div>
    )
  }

  getGaugeIntent = (value: number) => {
    if (value > 0.834) {
      return Intent.DANGER
    } else if (value > 0.667) {
      return Intent.WARNING
    } else {
      return Intent.SUCCESS
    }
  }

  getQuantityGauge = (
    current: number,
    max: number,
    decimalPlaces: number,
    suffix: string
  ) => {
    let maxProxy
    if (max >= 9999) {
      maxProxy = '∞'
    } else {
      maxProxy = max.toString()
    }
    return (
      <div className="mb-2">
        <p className="mb-1">
          <span className="text-lg">
            {current?.toFixed(decimalPlaces)} of {maxProxy}
          </span>
          &nbsp;{suffix}
        </p>
        {max < 9999 ? (
          <ProgressBar
            animate={false}
            intent={this.getGaugeIntent(current / max)}
            stripes={false}
            value={current / (max * 1.0)}
          />
        ) : null}
      </div>
    )
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        className="benefits-dialog bp3-dark"
        icon="clean"
        title={
          this.props.benefits < 1
            ? text.benefits.nonSupporterTitle
            : text.benefits.supporterTitle
        }
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="flex gap-8 justify-between items-center">
            {this.getHeaderSection(this.props.benefits)}
            <div className="w-80">
              {this.getQuantityGauge(
                this.props.uploadedStoryCount,
                this.props.maxStories,
                0,
                text.benefits.storiesSaved
              )}
              {this.getQuantityGauge(
                this.props.uploadedMediaSize,
                this.props.maxUploadedMedia,
                1,
                text.benefits.mediaUploaded
              )}
            </div>
          </div>
          {this.getTiers(this.props.benefits)}
        </div>
      </Dialog>
    )
  }
}

export default BenefitsDialog
