import React from 'react'
import { Colors, Spinner } from '@blueprintjs/core'
import firebase from './firebase.js'
import text from './i18n/english'

class Stories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      stories: [],
      storiesById: {},
      currentStory: null,
      currentMessage: null,
      storiesNeedUpdate: true,
    }
  }

  componentDidMount() {
    this.setupAuth()
  }

  setupAuth = () => {
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          // User is signed in.
          this.getStoriesForUser(user)
          this.setState({
            user: user,
          })
        } else {
          // User is signed out.
          this.setState({
            user: null,
            stories: [],
            storiesById: {},
          })
          console.log('signed out')
          this.setState({ currentMessage: 'user not signed in' })
          if (window.vuplex) {
            this.sendMessageToUnity('user not signed in')
          } else {
            window.addEventListener('vuplexready', this.sendMessageToUnity)
          }
        }
      },
      function (error) {
        console.log(error)
      }
    )
  }

  getStoriesForUser = (user) => {
    const db = firebase.firestore()
    db.collection('stories')
      .where('author', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        let stories = []
        let storiesById = {}
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          stories.push({ key: doc.id, value: data })
          storiesById[doc.id] = data
        })
        stories.sort(function (a, b) {
          var nameA = a.value.title.toUpperCase()
          var nameB = b.value.title.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        this.setState({
          stories: stories,
          storiesById: storiesById,
          storiesNeedUpdate: false,
        })
        if (window.vuplex) {
          this.sendMessageToUnity('stories received')
        } else {
          window.addEventListener('vuplexready', this.sendMessageToUnity)
        }
      })
      .catch((error) => {
        console.log('Error getting stories.')
      })
  }

  loadStoryForRow = (evt) => {
    var id = evt.currentTarget.getAttribute('data-story-id')
    var storyJSON = JSON.parse(this.state.storiesById[id].story)
    this.setState({ currentStory: storyJSON })
    if (window.vuplex) {
      this.sendStoryToUnity(storyJSON)
    } else {
      window.addEventListener('vuplexready', this.sendStoryToUnity)
    }
  }

  getStoryList = (handleRowClick) => {
    let stories = this.state.stories.map((data) => {
      var story = data.value
      var storyData = JSON.parse(story.story)
      return (
        <div
          key={data.key}
          className="story-row"
          data-story-id={data.key}
          onClick={handleRowClick}
        >
          <strong>{storyData.title}</strong>
          {storyData.primaryCredit.trim() !== ''
            ? ' ' + storyData.primaryCredit
            : ''}
        </div>
      )
    })
    return <div className="w-full h-full overflow-y-auto">{stories}</div>
  }

  sendStoryToUnity = (storyJSON) => {
    if (storyJSON) {
      window.vuplex.postMessage(storyJSON)
    } else {
      window.vuplex.postMessage(this.state.currentStory)
    }
  }

  sendMessageToUnity = (message) => {
    if (message) {
      window.vuplex.postMessage(message)
    } else {
      window.vuplex.postMessage(this.state.currentMessage)
    }
  }

  getLogo = () => {
    return (
      <p className="about-content">
        <img
          className="logo m-auto"
          src="/images/stepwise_insignia_red_lg@2x.png"
          alt={text.about.logoAlt}
        />
      </p>
    )
  }

  getLoadingUI = () => {
    return (
      <div>
        <p className="text-center text-lg">{text.stories.loading}</p>
        <Spinner />
      </div>
    )
  }

  render() {
    return (
      <div
        className="absolute w-full h-full left-0 top-0 flex items-center justify-center"
        style={{ background: Colors.DARK_GRAY4 }}
      >
        {this.state.storiesNeedUpdate ? (
          <div>
            {this.getLogo()}
            {this.getLoadingUI()}
          </div>
        ) : (
          this.getStoryList(this.loadStoryForRow)
        )}
      </div>
    )
  }
}

export default Stories
