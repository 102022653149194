import React from 'react'
import MediaCaption from './MediaCaption.js'

class ImageFeature extends React.Component {
  constructor(props) {
    super(props)
    this.image = React.createRef()
    //this.captionRef = React.createRef();
    //this.titleRef = React.createRef();
    //this.creditRef = React.createRef();
    this.transition = 0.5
    this.stepsSinceImageSet = 0
    this.currentImage = null
    this.images = {}
    this.currentState = this.props.feature.defaultState
  }

  componentDidMount() {
    this.applyState(this.currentState)
  }

  shouldComponentUpdate() {
    return false
  }

  handleStoryUpdate() {
    this.forceUpdate()
  }

  applyState(state) {
    this.currentState = state
    for (let property in state) {
      switch (property) {
        case 'media':
          //this.setImage(this.props.stepwise.score.getMedia(state[property]));
          break

        case 'fit':
          this.image.current.style['background-size'] = state[property]
          break

        default:
          this.image.current.style[property] = state[property]
          break
      }
    }
    this.setTransition(state.transitionDuration)
  }

  doAction(action) {
    switch (action.command) {
      case 'show-image':
        this.setImage(this.props.stepwise.score.getMedia(action.content))
        break
      case 'clear-visuals':
      case 'clear-image':
        this.setImage(null)
        break
      default:
        break
    }
  }

  getActions() {
    return []
  }

  mute() {}

  togglePlayPause() {}

  pause() {}

  play() {}

  updateLayout() {}

  resetContent() {
    this.setImage(null)
  }

  setImage(media) {
    if (this.currentImage !== media) {
      if (media) {
        this.image.current.classList.remove('disable-interaction')
        this.stepsSinceImageSet = 0
        this.image.current.style.backgroundImage = 'url(' + media.source + ')'
        /*if (media.attribution) {
          this.titleRef.current.textContent = media.name;
          if (!media.attributionURL) {
            this.creditRef.current.textContent = media.attribution;
          } else {
            this.creditRef.current.innerHTML = '<a href="' + media.attributionURL + '" target="_blank">' + media.attribution + '</a>';
          }
          this.captionRef.current.classList.remove('hidden');
          this.captionRef.current.classList.remove('fade-out');
        } else {
          this.captionRef.current.classList.add('hidden');
        }*/
        this.currentImage = media
      } else {
        this.stepsSinceImageSet = 0
        this.image.current.style.backgroundImage = 'none'
        //this.captionRef.current.classList.add('hidden');
        this.currentImage = null
      }
    } else {
      this.image.current.classList.add('disable-interaction')
      this.stepsSinceImageSet++
      //this.captionRef.current.classList.add('fade-out');
    }
    this.forceUpdate()
  }

  arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  async verifyPermission(fileHandle) {
    if ((await fileHandle.queryPermission()) === 'granted') {
      return true
    }
    if ((await fileHandle.requestPermission()) === 'granted') {
      return true
    }
    return false
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs
    this.image.current.style.transition = `scale ${secs}s, transform ${secs}s, background-image ${secs}s, background-size ${secs}s, background-position ${secs}s, opacity ${secs}s, filter ${secs}s`
  }

  /*handleStep(step) {
    if (step) {
      var css = this.image.current.style;
      switch (step.command) {
        case 'narrate':
        case 'speak':
        case 'think':
        case 'sing':
        var extension = window.getFilenameExtension(step.content);
        switch (extension) {
          case 'gif':
          case 'png':
          case 'jpg':
          case 'jpeg':
          css.backgroundImage = 'url('+step.content+')';
          break;
          default:
          break;
        }
        break;
        case 'clearImage':
        css.backgroundImage = 'none';
        break;
        default:
        break;
      }
    }
  }*/

  render() {
    let showCaption = this.currentState ? this.currentState.showCaption : false
    return (
      <div className="panel-image disable-interaction" ref={this.image}>
        {showCaption ? (
          <MediaCaption
            media={this.currentImage}
            analyticsId={this.props.stepwise.score.analyticsId}
            isEditing={this.props.isEditing}
          />
        ) : null}
      </div>
    )
  }
}

export default ImageFeature
