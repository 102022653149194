import React from 'react'
import { Dialog, Classes, Tabs, Tab, Button, Intent } from '@blueprintjs/core'
import UnsplashSearch from '../UnsplashSearch.js'
import UnsplashSearchTab from '../UnsplashSearchTab.js'
import GiphySearch from '../GiphySearch.js'
import GiphySearchTab from '../GiphySearchTab.js'
import MediaDistributor from './MediaDistributor.js'
import MediaPreview from '../MediaPreview.js'

class SearchMediaDialog extends React.Component {
  constructor(props) {
    super(props)
    this.mediaDistributorRef = React.createRef()
    this.doSearch = this.doSearch.bind(this)
    this.handleSearchResults = this.handleSearchResults.bind(this)
    this.handleMediaSelected = this.handleMediaSelected.bind(this)
    this.addMediaToSequence = this.addMediaToSequence.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.handleSearchResults = this.handleSearchResults.bind(this)
    this.state = {
      view: 'search',
      selectedTabId: null,
      searchResults: null,
      selectedMediaIndices: [],
    }
    this.searchRefs = {
      unsplash: React.createRef(),
      giphy: React.createRef(),
    }
    this.searchInterfaceRefs = {
      'unsplash-tab': React.createRef(),
      'giphy-tab': React.createRef(),
    }
  }

  handleTabChange(newTabId) {
    this.setState({ selectedTabId: newTabId })
  }

  doSearch(domain, parameters) {
    this.waitingForSearchResults = true
    if (!domain) {
      domain = this.state.selectedTabId.split('-')[0]
    }
    this.setSourceCreditForDomain(domain)
    this.lastDomain = domain
    this.lastSearchParameters = parameters
    if (domain === 'webcam') {
      this.addWebcamStep()
      this.props.onStoryUpdate()
    } else if (domain === 'text') {
      this.addTextSteps(parameters)
      this.props.onStoryUpdate()
    } else {
      this.searchRefs[domain].current.doSearch(parameters, this.searchPage)
    }
  }

  setSourceCreditForDomain(domain) {
    switch (domain) {
      case 'giphy':
        this.sourceCredit = (
          <p>
            Powered by{' '}
            <a
              href="https://giphy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Giphy
            </a>
          </p>
        )
        break
      case 'unsplash':
        this.sourceCredit = (
          <p>
            Powered by{' '}
            <a
              href="https://unsplash.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unsplash
            </a>
          </p>
        )
        break
      default:
        this.sourceCredit = null
        break
    }
  }

  handleSearchResults(data) {
    if (data.length === 0) {
      this.maxSearchPage = this.searchPage
    }
    this.waitingForSearchResults = false
    let searchResults = this.state.searchResults
    if (this.searchPage === 1) {
      searchResults = data
    } else {
      searchResults = searchResults.concat(data)
    }
    let selectedMediaIndices
    if (this.searchPage === 1) {
      selectedMediaIndices = []
    } else {
      selectedMediaIndices = this.state.selectedMediaIndices
    }
    this.setState({
      view: 'selectMedia',
      searchResults: searchResults,
      selectedMediaIndices: selectedMediaIndices,
    })
  }

  handleSearchTabSubmit(evt) {
    evt.preventDefault()
    this.resetSearch()
    let tabId = this.state.selectedTabId
      ? this.state.selectedTabId
      : this.props.initialTab
    let searchInterface = this.searchInterfaceRefs[tabId].current
    searchInterface.getSearchParameters((domain, parameters) => {
      this.doSearch(domain, parameters)
    })
  }

  getSearchResults() {
    if (this.state.searchResults) {
      return this.state.searchResults.map((result, index) => {
        let className = 'media-card'
        if (this.state.selectedMediaIndices.indexOf(index) !== -1) {
          className += ' selected'
        }
        return (
          <div key={index} className={className}>
            <MediaPreview
              index={index}
              className="select-media-preview"
              type={result.type}
              source={result.thumbnail}
              onClick={this.handleMediaSelected}
            />
          </div>
        )
      })
    } else {
      return null
    }
  }

  handleMediaSelected(mediaIndex) {
    let indices = this.state.selectedMediaIndices
    let index = indices.indexOf(mediaIndex)
    if (index === -1) {
      indices.push(mediaIndex)
    } else {
      indices.splice(index, 1)
    }
    this.setState({ selectedMediaIndices: indices })
  }

  handleMediaResultsScroll(evt) {
    let element = evt.currentTarget
    if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
      if (!this.waitingForSearchResults) {
        this.searchPage++
        if (this.searchPage < this.maxSearchPage) {
          this.doSearch(
            this.lastDomain,
            this.lastSearchParameters,
            this.searchPage
          )
        }
      }
    }
  }

  addSelectedMedia() {
    if (this.state.selectedMediaIndices.length === 1) {
      this.addMediaToSequence()
    } else {
      this.setState({ view: 'distributeMedia' })
    }
  }

  addMediaToSequence() {
    let selectedMediaData = this.state.selectedMediaIndices.map((index) => {
      return this.state.searchResults[index]
    })
    let media = this.mediaDistributorRef.current.createMedia(selectedMediaData)
    this.mediaDistributorRef.current.distributeMedia(media)
    this.closeDialog('all')
  }

  closeDialog(dialog) {
    switch (dialog) {
      case 'all':
        this.props.onClose()
        this.setState({ view: 'search' })
        break

      case 'addMedia':
      case 'search':
        this.setState({ view: 'main' })
        break

      case 'selectMedia':
        this.setState({ view: 'search' })
        break

      case 'distributeMedia':
        this.setState({ view: 'selectMedia' })
        break

      default:
        break
    }
  }

  resetSearch() {
    this.searchPage = 1
    this.maxSearchPage = 20
    this.setState({ searchResults: null })
  }

  render() {
    return (
      <div>
        <UnsplashSearch
          ref={this.searchRefs['unsplash']}
          onSearchResults={this.handleSearchResults}
        />
        <GiphySearch
          ref={this.searchRefs['giphy']}
          onSearchResults={this.handleSearchResults}
        />
        <MediaDistributor
          ref={this.mediaDistributorRef}
          stepwise={this.props.stepwise}
          sequence={this.props.sequence}
          editStep={this.props.editStep}
          editAction={this.props.editAction}
          editCharacter={this.props.editCharacter}
          onStepwiseEvent={this.props.onStepwiseEvent}
          onStepChange={this.props.onStepChange}
          onStoryUpdate={this.props.onStoryUpdate}
          isOpen={this.state.view === 'distributeMedia'}
          onClose={() => this.closeDialog('distributeMedia')}
          onComplete={this.addMediaToSequence}
        />
        <Dialog
          isOpen={this.props.isOpen && this.state.view === 'search'}
          title="Media Search"
          onClose={this.props.onClose}
        >
          <form onSubmit={this.handleSearchTabSubmit.bind(this)}>
            <div className={Classes.DIALOG_BODY + ' search-body'}>
              <Tabs
                renderActiveTabPanelOnly={true}
                selectedTabId={
                  this.state.selectedTabId
                    ? this.state.selectedTabId
                    : this.props.initialTab
                }
                onChange={this.handleTabChange.bind(this)}
              >
                <Tab
                  id="unsplash-tab"
                  title="Unsplash"
                  panel={
                    <UnsplashSearchTab
                      ref={this.searchInterfaceRefs['unsplash-tab']}
                      onSearchResults={this.handleSearchResults}
                    />
                  }
                />
                <Tab
                  id="giphy-tab"
                  title="GIPHY"
                  panel={
                    <GiphySearchTab
                      ref={this.searchInterfaceRefs['giphy-tab']}
                      onSearchResults={this.handleSearchResults}
                    />
                  }
                />
              </Tabs>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={this.props.onClose}>Cancel</Button>
                <Button intent={Intent.PRIMARY} type="submit">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          className="select-media-dialog"
          isOpen={this.props.isOpen && this.state.view === 'selectMedia'}
          title="Select Media"
          onClose={() => this.closeDialog('selectMedia')}
        >
          <div className={Classes.DIALOG_BODY}>
            {this.sourceCredit}
            <div
              className="media-results-container"
              onScroll={this.handleMediaResultsScroll.bind(this)}
            >
              {this.getSearchResults()}
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.closeDialog('selectMedia')}>
                Back
              </Button>
              <Button
                onClick={this.addSelectedMedia.bind(this)}
                intent={Intent.PRIMARY}
              >
                Add media
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default SearchMediaDialog
