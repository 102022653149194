import React from 'react'
import CharacterAvatar from './CharacterAvatar.js'
import TextThumbnail from './TextThumbnail.js'
import VisualMediaThumbnail from './VisualMediaThumbnail.js'
import AudioThumbnail from './AudioThumbnail.js'
//import EnterThumbnail from './EnterThumbnail.js';
import { Button } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import Reorder, { reorder } from 'react-reorder'

const ScoreTextEditor = observer(
  class ScoreTextEditor extends React.Component {
    constructor(props) {
      super(props)
      this.rowMouseDownTime = 0
    }

    handleRowMouseDown = (evt) => {
      this.rowMouseDownTime = Date.now()
    }

    handleRowMouseUp = (evt) => {
      if (Date.now() - this.rowMouseDownTime < 250) {
        let stepIndex = parseInt(evt.currentTarget.getAttribute('data-index'))
        let step = this.props.sequence.steps[stepIndex]
        //let clickedBackground = (evt.target.nodeName === 'TR' || evt.target.nodeName === 'TH' || evt.target.nodeName === 'TD' || (evt.target.nodeName === 'DIV' && evt.target.classList.contains('thumbnail')));
        let clickedInput = evt.target.nodeName === 'INPUT'
        let clickedAvatar = evt.target.classList.contains('character-avatar')
        if (step !== this.props.editStep || (!clickedInput && !clickedAvatar)) {
          this.props.onStepChange(step)
          this.forceUpdate()
        }
      }
    }

    getStepData(step) {
      let data = {}
      step.actions.forEach((action) => {
        if (!data[action.command]) {
          data[action.command] = 1
        } else {
          data[action.command]++
        }
      })
      return data
    }

    onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
      if (previousIndex !== 0) {
        this.props.sequence.steps = reorder(
          this.props.sequence.steps,
          previousIndex,
          nextIndex
        )
      }
    }

    render() {
      let rows = this.props.sequence.steps.map((step, stepIndex) => {
        let character
        let isCurrent = this.props.editStep === step
        let isEditable = this.props.columnsEnabled === 0
        let stepData = this.getStepData(step)
        let rowContent = step.actions.map((action, actionIndex) => {
          let avatar
          let thumbnail
          switch (action.command) {
            /*case 'enter':
          thumbnail = <EnterThumbnail action={action}/>
          break;*/

            case 'play-audio':
            case 'set-audio-volume':
            case 'set-video-volume':
              thumbnail = (
                <AudioThumbnail
                  stepwise={this.props.stepwise}
                  action={action}
                />
              )
              break

            case 'show-image':
            case 'show-video':
              thumbnail = (
                <VisualMediaThumbnail
                  stepwise={this.props.stepwise}
                  action={action}
                />
              )
              break

            case 'sample':
            case 'speak':
              if (
                action.content.trim() !== '' ||
                window.getContentfulActionsFromStep(step).length === 0
              ) {
                thumbnail = (
                  <TextThumbnail
                    action={action}
                    current={isCurrent}
                    editable={isEditable && stepData[action] === 1}
                    onStepwiseEvent={this.props.onStepwiseEvent}
                    onAddStep={this.props.onAddStep}
                  />
                )
              }
              break

            default:
              break
          }
          if (
            this.props.charactersEnabled &&
            action.targetCharacter !== character &&
            thumbnail
          ) {
            avatar = (
              <CharacterAvatar
                character={action.targetCharacter}
                characterDialog={this.props.characterDialog}
                changesAffectStep={true}
                clickable={true}
                action={action}
              />
            )
            character = action.targetCharacter
          }
          if (thumbnail) {
            return (
              <React.Fragment key={stepIndex + ' ' + actionIndex}>
                {avatar}
                {thumbnail}
              </React.Fragment>
            )
          } else {
            return null
          }
        })
        let noThumbnails = true
        for (let i = 0; i < rowContent.length; i++) {
          if (rowContent[i]) {
            noThumbnails = false
            break
          }
        }
        if (noThumbnails)
          rowContent = (
            <span>
              {step.actions.length} action{step.actions.length !== 1 ? 's' : ''}
            </span>
          )
        let className
        if (isCurrent) className = 'current'
        return (
          <tr
            className={className}
            key={stepIndex}
            data-index={stepIndex}
            onMouseDown={this.handleRowMouseDown}
            onMouseUp={this.handleRowMouseUp}
          >
            <th className="step-index-col">{stepIndex + 1}</th>
            <td>{rowContent}</td>
          </tr>
        )
      })
      rows.push(
        <tr key="add-col">
          <th className="add-col">
            <Button minimal="true" icon="plus" onClick={this.props.onAddStep} />
          </th>
        </tr>
      )
      return (
        <table className="score-text-editor">
          <Reorder
            reorderId="step-list"
            component="tbody"
            lock="horizontal"
            autoScroll={false}
            holdTime={500}
            onReorder={this.onReorder}
          >
            {rows}
          </Reorder>
        </table>
      )
    }
  }
)

export default ScoreTextEditor
