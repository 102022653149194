import React from 'react'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { v4 as uuidv4 } from 'uuid'

const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY)

class GiphySearch extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchResults = this.handleSearchResults.bind(this)
  }

  doSearch(parameters, page) {
    gf.search(parameters.keyword, {
      sort: 'relevant',
      offset: (page - 1) * 30,
      limit: 30,
      type: 'gifs',
    }).then(this.handleSearchResults)
  }

  handleSearchResults(json) {
    let mediaData = []
    console.log(json)
    json.data.forEach((item, i) => {
      let mediaItem = {
        id: uuidv4(),
        name: item.title,
        type: 'video',
        source: item.images.original.mp4,
        attribution: item.username,
        thumbnail: item.images.fixed_width_small_still.url,
        width: item.images.original.width,
        height: item.images.original.height,
      }
      mediaData.push(mediaItem)
    })
    this.props.onSearchResults(mediaData)
  }

  render() {
    return <div />
  }
}

export default GiphySearch
