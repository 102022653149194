import React from 'react'
import { InputGroup, FormGroup } from '@blueprintjs/core'

class UnsplashSearchTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: '',
    }
  }

  onKeywordChange(evt) {
    this.setState({ keyword: evt.currentTarget.value })
  }

  getSearchParameters(returnHandler) {
    let parameters = {
      keyword: this.state.keyword,
    }
    returnHandler('unsplash', parameters)
  }

  render() {
    return (
      <div>
        <p className="bp3-text-large">
          The internet's source of freely usable images.
        </p>
        <br />
        <FormGroup>
          <InputGroup
            id="keyword-input"
            value={this.state.keyword}
            placeholder="Search for images..."
            onChange={this.onKeywordChange.bind(this)}
          />
        </FormGroup>
        <p className="bp3-text-small">
          Powered by{' '}
          <a
            href="https://unsplash.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsplash
          </a>
        </p>
      </div>
    )
  }
}

export default UnsplashSearchTab
