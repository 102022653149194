import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// Your web app's Firebase configuration
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'stepworks-studio.firebaseapp.com',
  projectId: 'stepworks-studio',
  storageBucket: 'stepworks-studio.appspot.com',
  messagingSenderId: '861555302374',
  appId: '1:861555302374:web:6d83f59785603c96f14027',
}
// Initialize Firebase
firebase.initializeApp(config)

export default firebase
