import React from 'react'
import { SketchPicker } from 'react-color'
import {
  Button,
  Dialog,
  Classes,
  Intent,
  FormGroup,
  Icon,
  HTMLSelect,
  ControlGroup,
  InputGroup,
  Alert,
  Popover,
  Position,
  Menu,
  MenuItem,
} from '@blueprintjs/core'
import {
  Character,
  Action,
  FrameState,
  TextState,
  ImageState,
  VideoState,
  AudioState,
} from '../stepworks/stepwise/stepwise-v2.js'
import { uniqueNamesGenerator, names } from 'unique-names-generator'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react'

const CharacterDialog = observer(
  class CharacterDialog extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isOpen: false,
        character: null,
        action: null,
        changesAffectStep: true,
        addEntranceActions: true,
        deleteAlertIsOpen: false,
      }
      this.characterTypes = [
        { label: 'Person', value: 'person' },
        { label: 'Thing', value: 'non-person' } /*,
      {label:'Instrument', value:'instrument'}*/,
      ]
      this.characterIcons = {
        person: 'person',
        'non-person': 'cube',
        instrument: 'music',
      }
    }

    open = (
      character,
      action,
      changesAffectStep = true,
      addEntranceActions = true
    ) => {
      this.setState({
        isOpen: true,
        character: character,
        action: action,
        changesAffectStep: changesAffectStep,
        addEntranceActions: addEntranceActions,
      })
    }

    handleClose = (evt) => {
      this.setState({ isOpen: false })
    }

    getCharacters() {
      var characters = []
      if (this.props.stepwise) {
        Object.values(this.props.stepwise.score.characters).forEach(
          (character, index) => {
            characters.push({ label: character.fullName, value: character.id })
          }
        )
        characters.push({
          label: 'Delete character',
          value: 'delete-character',
        })
        characters.push({ label: 'Add character', value: 'add-character' })
      }
      return characters
    }

    // TODO: refactor, this is redundant with what is in MediaDistributor
    addCharacter(addEntranceActions = true) {
      let color = window.getRandomBackgroundColor()
      let character = new Character(
        {
          id: uuidv4(),
          fullName: uniqueNamesGenerator({
            dictionaries: [names],
            style: 'capital',
            separator: ' ',
            length: 1,
          }),
          color: color,
          features: [],
        },
        this.props.stepwise.score
      )
      this.props.stepwise.score.addCharacter(character)

      let data
      if (addEntranceActions) {
        data = {
          character: character.id,
          command: 'enter',
          direction: ['right', 'bottom'],
          physics: 'push',
          size: [
            'full',
            'third',
            'quarter',
            'half',
            'two-thirds',
            'three-quarters',
          ],
          amount: ['half'],
          delay: 0,
          //"content": layout.gridLayout.left + ' ' + layout.gridLayout.top + ' ' + layout.gridLayout.width + ' ' + layout.gridLayout.height
        }
        let action = new Action(data, this.props.stepwise.score)
        this.props.editStep.addAction(action)
        data = {
          character: character.id,
          command: 'play-phrase',
          content: window.getRandomSimpleChord(),
          duration: 0.5,
          velocity: 36,
          octave: 3,
          sequenceLength: '2,4',
          octaveShift: '-1,1',
        }
        action = new Action(data, this.props.stepwise.score)
        this.props.editStep.addAction(action)
        this.setState({ character: character, action: action })
      }

      data = {
        type: 'frame',
        character: character.id,
        backgroundColor: color,
      }
      let state = new FrameState(data, this.props.stepwise.score, character)
      this.props.sequence.steps[0].states.push(state)

      let captionAlign = window.getRandomCaptionAlign()
      data = {
        type: 'text',
        character: character.id,
        font: 'Newsreader',
        align: captionAlign,
        textAlign: window.getTextAlignFromCaptionAlign(captionAlign),
      }
      state = new TextState(data, this.props.stepwise.score, character)
      this.props.sequence.steps[0].states.push(state)

      data = {
        type: 'image',
        character: character.id,
      }
      state = new ImageState(data, this.props.stepwise.score, character)
      this.props.sequence.steps[0].states.push(state)

      data = {
        type: 'video',
        character: character.id,
      }
      state = new VideoState(data, this.props.stepwise.score, character)
      this.props.sequence.steps[0].states.push(state)

      data = {
        type: 'audio',
        character: character.id,
      }
      state = new AudioState(data, this.props.stepwise.score, character)
      this.props.sequence.steps[0].states.push(state)

      this.props.stepwise.score.setupReferences()

      this.props.onStoryUpdate()
      return character
    }

    handleDeleteCharacterRequest = () => {
      if (Object.values(this.props.stepwise.score.characters).length > 1) {
        this.setState({ deleteAlertIsOpen: true })
      }
    }

    handleAlertClose = () => {
      this.setState({ deleteAlertIsOpen: false })
    }

    handleDeleteCharacter = () => {
      this.props.stepwise.score.deleteCharacter(this.state.character)
      this.props.onActionChange(null)
      this.props.onStoryUpdate()
      this.handleClose()
    }

    handleCharacterChange(evt) {
      let character, oldCharacter
      if (this.state.action) oldCharacter = this.state.action.targetCharacter
      if (evt.currentTarget.value === 'add-character') {
        character = this.addCharacter(this.state.addEntranceActions)
        if (this.state.action) oldCharacter = character
      } else if (evt.currentTarget.value === 'delete-character') {
        this.handleDeleteCharacterRequest()
      } else {
        character = this.props.stepwise.score.getCharacter(
          evt.currentTarget.value
        )
        if (character && !this.state.action) {
          oldCharacter = character
        }
      }
      if (character) {
        if (this.state.action) {
          let action = this.state.action
          action.targetCharacter = character
          this.state.action.updateFeature()
        }
        // change all other actions referencing the same prior character as well, if requested
        if (this.state.changesAffectStep) {
          this.props.editStep.actions.forEach((action) => {
            if (action.targetCharacter === oldCharacter) {
              action.targetCharacter = character
              action.updateFeature()
            }
          })
        }
        this.props.onStepChange(this.props.editStep)
        this.setState({ character: character })
      }
    }

    handleCharacterPropertyChange(evt, property) {
      let character = this.state.character
      if (property === 'type') {
        switch (evt.currentTarget.value) {
          case 'person':
            this.generateCharacterName()
            character.instrumentId = -1
            break
          case 'non-person':
            character.fullName = 'Thing'
            character.instrumentId = -1
            break
          case 'instrument':
            character.fullName = 'Unknown'
            break
          default:
            break
        }
      }
      character[property] = evt.currentTarget.value
    }

    handleSwitchChange(evt, property) {
      let character = this.state.character
      character[property] = evt.currentTarget.checked
    }

    generateCharacterName() {
      let character = this.state.character
      character.fullName = uniqueNamesGenerator({
        dictionaries: [names],
        style: 'capital',
        separator: ' ',
        length: 1,
      })
      if (character.fullName.indexOf('Jemima') !== -1) {
        character.fullName = '[REDACTED]'
      }
    }

    handleInstrumentSelected = (evt) => {
      let character = this.state.character
      character.instrumentName = evt.currentTarget.getAttribute('data-name')
      character.instrumentId = evt.currentTarget.getAttribute('data-index')
      this.props.musicPlayer.current.playNotes(
        character.instrumentId,
        ['C4'],
        0,
        1
      )
      this.forceUpdate()
    }

    buildInstrumentMenu() {
      if (this.props.musicPlayer.current.instruments) {
        this.instrumentMenu = (
          <Menu>
            {this.props.musicPlayer.current.instruments.map(
              (topCategoryData, index) => {
                return (
                  <MenuItem key={index} text={topCategoryData.label}>
                    {this.props.musicPlayer.current.instruments[
                      index
                    ].value.map((categoryData, index) => {
                      return (
                        <MenuItem key={index} text={categoryData.label}>
                          {categoryData.value.map((subcategoryData, index) => {
                            if (Array.isArray(subcategoryData.value)) {
                              return (
                                <MenuItem
                                  key={index}
                                  text={subcategoryData.label}
                                >
                                  {subcategoryData.value.map(
                                    (instrumentData, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          text={instrumentData.label}
                                          data-name={instrumentData.label}
                                          data-index={instrumentData.value}
                                          onClick={
                                            this.handleInstrumentSelected
                                          }
                                        />
                                      )
                                    }
                                  )}
                                </MenuItem>
                              )
                            } else {
                              return (
                                <MenuItem
                                  key={index}
                                  text={subcategoryData.label}
                                  data-name={subcategoryData.label}
                                  data-index={subcategoryData.value}
                                  onClick={this.handleInstrumentSelected}
                                />
                              )
                            }
                          })}
                        </MenuItem>
                      )
                    })}
                  </MenuItem>
                )
              }
            )}
          </Menu>
        )
      }
    }

    handleColorChange = (color) => {
      let character = this.state.character
      character.color =
        'rgba(' +
        color.rgb.r +
        ',' +
        color.rgb.g +
        ',' +
        color.rgb.b +
        ',' +
        color.rgb.a +
        ')'
    }

    render() {
      if (!this.instrumentMenu && this.props.musicPlayer.current) {
        this.buildInstrumentMenu()
      }
      return (
        <>
          <Dialog
            isOpen={this.state.isOpen}
            onClose={this.handleClose}
            title="Edit Character"
          >
            <div className={Classes.DIALOG_BODY}>
              {this.state.character ? (
                <div>
                  <FormGroup>
                    <HTMLSelect
                      className="character-select"
                      large="true"
                      options={this.getCharacters()}
                      value={this.state.character.id}
                      onChange={(evt) => this.handleCharacterChange(evt)}
                    />
                  </FormGroup>
                  {this.state.character.type === 'person' ? (
                    <FormGroup
                      label="Name"
                      labelFor="full-name-input"
                      inline="true"
                    >
                      <ControlGroup id="full-name-input" fill="true">
                        <InputGroup
                          value={this.state.character.fullName}
                          data-property="fullName"
                          onChange={(evt) =>
                            this.handleCharacterPropertyChange(evt, 'fullName')
                          }
                        />
                        <Button onClick={this.generateCharacterName.bind(this)}>
                          Randomize
                        </Button>
                      </ControlGroup>
                    </FormGroup>
                  ) : null}
                  {this.state.character.type === 'non-person' ? (
                    <FormGroup
                      label="Name"
                      labelFor="full-name-input"
                      inline="true"
                    >
                      <InputGroup
                        id="full-name-input"
                        value={this.state.character.fullName}
                        data-property="fullName"
                        onChange={(evt) =>
                          this.handleCharacterPropertyChange(evt, 'fullName')
                        }
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Icon
                      icon={this.characterIcons[this.state.character.type]}
                    />{' '}
                    &nbsp;
                    <HTMLSelect
                      id="type-select"
                      options={this.characterTypes}
                      value={this.state.character.type}
                      onChange={(evt) =>
                        this.handleCharacterPropertyChange(evt, 'type')
                      }
                    />
                  </FormGroup>
                  <div className="form-col">
                    <FormGroup>
                      <Popover position={Position.BOTTOM}>
                        <Button rightIcon="caret-down">
                          {this.state.character.instrumentName
                            ? this.state.character.instrumentName
                            : 'Select instrument'}
                        </Button>
                        {this.instrumentMenu}
                      </Popover>
                    </FormGroup>
                  </div>
                  {
                    <FormGroup inline={true}>
                      <Popover usePortal={false}>
                        <div className="swatch">
                          <div
                            className="color"
                            style={{
                              backgroundColor: this.state.character.color,
                            }}
                          />
                        </div>
                        <SketchPicker
                          color={window.getRGBAColor(
                            this.state.character.color
                          )}
                          onChangeComplete={this.handleColorChange}
                        />
                      </Popover>
                    </FormGroup>
                  }
                  {/*<FormGroup label="" labelFor="visible-switch" inline={true}>
              <Switch id="visible-switch" checked={this.state.character.visible} label="Visible" onChange={(evt) => this.handleSwitchChange(evt, 'visible')}/>
            </FormGroup>*/}
                </div>
              ) : null}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={this.handleClose} intent={Intent.PRIMARY}>
                  Done
                </Button>
              </div>
            </div>
          </Dialog>
          {this.state.character ? (
            <Alert
              isOpen={this.state.deleteAlertIsOpen}
              cancelButtonText="Cancel"
              confirmButtonText="Delete character"
              intent={Intent.DANGER}
              icon="trash"
              onClose={this.handleAlertClose}
              onConfirm={this.handleDeleteCharacter}
            >
              <p>
                {'Are you sure you want to delete the character “' +
                  this.state.character.fullName +
                  '”? This will delete all of their actions, and cannot be undone.'}
              </p>
            </Alert>
          ) : null}
        </>
      )
    }
  }
)

export default CharacterDialog
