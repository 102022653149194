import React from 'react'
import './Activate.css'
import { Colors } from '@blueprintjs/core'
import text from './i18n/english'

class Logo extends React.Component {
  render() {
    return (
      <div
        className="absolute text-center w-full h-full p-5 left-0 top-0 flex items-center justify-center"
        style={{ background: Colors.DARK_GRAY4 }}
      >
        <div>
          <p>
            <img
              className="logo m-auto w-12 h-12"
              src="/images/stepwise_insignia_red_lg@2x.png"
              alt={text.about.logoAlt}
            />
          </p>
          <h1>{text.ui.stepworksStudio}</h1>
        </div>
      </div>
    )
  }
}

export default Logo
