import React from 'react'
import { Dialog, Classes, Button, Intent, FormGroup } from '@blueprintjs/core'
import AddMediaControl from '../AddMediaControl.js'
import MediaDistributor from './MediaDistributor.js'
import { v4 as uuidv4 } from 'uuid'

class LinkMediaDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'input',
      addMediaKeys: [],
    }
    this.addMediaData = []
    this.handleAddMediaEnter = this.handleAddMediaEnter.bind(this)
    this.handleAddMediaChange = this.handleAddMediaChange.bind(this)
    this.handleAddMediaDelete = this.handleAddMediaDelete.bind(this)
    this.mediaDistributorRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.reset()
      }
    }
  }

  reset() {
    let addMediaKeys = []
    this.addMediaData = []
    let data = this.getDefaultMediaData()
    addMediaKeys.push(data.key)
    this.addMediaData.push(data)
    this.setState({ addMediaKeys: addMediaKeys })
  }

  getTypeForCommand(command) {
    switch (command) {
      case 'show-image':
        return 'image'
      case 'play-audio':
        return 'audio'
      case 'show-video':
        return 'video'
      default:
        break
    }
  }

  getDefaultMediaData() {
    return {
      key: String.fromCharCode(97 + this.state.addMediaKeys.length),
      type: this.getTypeForCommand(this.props.editAction.command),
      name: '',
      url: '',
    }
  }

  handleAddMediaEnter(index) {
    let addMediaKeys = this.state.addMediaKeys
    let mediaData = this.getDefaultMediaData()
    addMediaKeys.splice(index + 1, 0, mediaData.key)
    this.addMediaData.splice(index + 1, 0, mediaData)
    this.setState({ addMediaKeys: addMediaKeys })
  }

  handleAddMediaChange(index, mediaData) {
    this.addMediaData[index] = mediaData
  }

  handleAddMediaDelete(index) {
    if (this.state.addMediaKeys.length > 1) {
      let addMediaKeys = this.state.addMediaKeys
      addMediaKeys.splice(index, 1)
      this.addMediaData.splice(index, 1)
      this.setState({ addMediaKeys: addMediaKeys })
    }
  }

  getAddMediaControls() {
    const mediaControls = this.addMediaData.map((mediaData, index) => {
      return (
        <AddMediaControl
          key={mediaData.key}
          type={mediaData.type}
          uniqueId={mediaData.key}
          url={mediaData.url}
          index={index}
          onEnter={this.handleAddMediaEnter}
          onChange={this.handleAddMediaChange}
          onDelete={this.handleAddMediaDelete}
        />
      )
    })
    return mediaControls
  }

  openDistributionDialog = () => {
    if (this.addMediaData.length === 1) {
      this.addMedia()
    } else {
      this.setState({ view: 'distributeMedia' })
    }
  }

  closeDistributionDialog = () => {
    this.setState({ view: 'input' })
  }

  // adds a piece of media to the library, then adds it to the sequence
  addMedia = () => {
    let addedMediaData = []
    let existingMedia = []
    let score = this.props.stepwise.score
    this.addMediaData.forEach((mediaData) => {
      let mediaItem
      Object.values(score.media).forEach((mediaItemCandidate) => {
        if (mediaItemCandidate.source === mediaData.url) {
          mediaItem = mediaItemCandidate
        }
      })
      if (!mediaItem) {
        addedMediaData.push({
          id: uuidv4(),
          name: mediaData.name,
          type: mediaData.type,
          source: mediaData.url,
          width: mediaData.width,
          height: mediaData.height,
        })
      } else {
        existingMedia.push(mediaItem)
      }
    })
    let media = this.mediaDistributorRef.current.createMedia(addedMediaData)
    media = media.concat(existingMedia)
    this.mediaDistributorRef.current.distributeMedia(
      media,
      this.state.characterAssign,
      this.state.splitUnit
    )
    this.closeDistributionDialog()
    this.props.onClose()
  }

  render() {
    return (
      <div>
        <MediaDistributor
          ref={this.mediaDistributorRef}
          stepwise={this.props.stepwise}
          sequence={this.props.sequence}
          editStep={this.props.editStep}
          editAction={this.props.editAction}
          editCharacter={this.props.editCharacter}
          onStepwiseEvent={this.props.onStepwiseEvent}
          onStepChange={this.props.onStepChange}
          onStoryUpdate={this.props.onStoryUpdate}
          isOpen={this.state.view === 'distributeMedia'}
          onClose={this.closeDistributionDialog}
          onComplete={this.addMedia}
        />
        <Dialog
          className="add-media-dialog"
          isOpen={this.props.isOpen}
          title="Add Media"
          onClose={this.props.onClose}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Enter the URLs of one or more media items to add to your story."
              helperText="Press return to add rows"
            >
              {this.getAddMediaControls()}
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.props.onClose}>Cancel</Button>
              <Button
                onClick={this.openDistributionDialog}
                intent={Intent.PRIMARY}
              >
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default LinkMediaDialog
