import React from 'react'
import { observer } from 'mobx-react'
import {
  FormGroup,
  InputGroup,
  Dialog,
  Classes,
  Button,
  Intent,
  NumericInput,
  Switch,
  Alignment,
  HTMLSelect,
  TextArea,
} from '@blueprintjs/core'

const SequenceSettingsDialog = observer(
  class SequenceSettingsDialog extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
    }

    handlePropertyChange(evt, property, value) {
      this.props.sequence[property] = value ? value : evt.currentTarget.value
    }

    handleNumericPropertyChange(property, valueAsNumber) {
      this.props.sequence[property] = valueAsNumber
    }

    handleSwitchChange(evt, property) {
      this.props.sequence[property] = evt.currentTarget.checked
    }

    getSequences() {
      var sequences = [{ label: 'Select sequence', value: null }]
      Object.values(this.props.sequence.parentScene.sequences).forEach(
        (sequence, index) => {
          if (sequence !== this.props.sequence) {
            sequences.push({ label: sequence.title, value: sequence.id })
          }
        }
      )
      return sequences
    }

    /*handleGenerateSequence = () => {
      this.props.user.getIdToken().then((accessToken) => {
        var xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              //var response = JSON.parse(xhr.responseText)
              console.log(xhr.responseText)
            } else {
              console.log('error authorizing chat')
            }
          }
        }
        const params = `?prompt=${encodeURIComponent(
          this.props.sequence.prompt
        )}`
        console.log(params)
        xhr.open('POST', 'https://origin.step.works/chat' + params, true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
        xhr.send()
      })
    }*/

    render() {
      return (
        <Dialog
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          icon="settings"
          title="Sequence Settings"
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Title" labelFor="title-input">
              <InputGroup
                id="title-input"
                placeholder="Enter sequence title"
                value={this.props.sequence.title}
                onChange={(evt) =>
                  this.handlePropertyChange(evt, 'title', evt.target.value)
                }
              />
            </FormGroup>
            {/*this.props.benefits > 0 ? (
              <div>
                <FormGroup label="Prompt" labelFor="prompt-input">
                  <TextArea
                    style={{ height: '100px' }}
                    value={this.props.sequence.prompt}
                    maxLength={500}
                    fill="true"
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'prompt', evt.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup label="" labelFor="text-input">
                  <Button
                    id="generate-btn"
                    onClick={this.handleGenerateSequence}
                    intent={Intent.NONE}
                  >
                    Generate sequence
                  </Button>
                </FormGroup>
              </div>
                  ) : null*/}
            <div className="form-flex-row">
              <div className="form-col">
                <FormGroup labelFor="shuffle-switch" inline={true}>
                  <Switch
                    id="shuffle-switch"
                    checked={this.props.sequence.shuffle}
                    label="Shuffle"
                    alignIndicator={Alignment.RIGHT}
                    onChange={(evt) => this.handleSwitchChange(evt, 'shuffle')}
                  />
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup labelFor="loop-switch" inline={true}>
                  <Switch
                    id="loop-switch"
                    checked={this.props.sequence.repeat}
                    label="Repeat"
                    alignIndicator={Alignment.RIGHT}
                    onChange={(evt) => this.handleSwitchChange(evt, 'repeat')}
                  />
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup labelFor="ignore-state-switch" inline={true}>
                  <Switch
                    id="ignore-state-switch"
                    checked={this.props.sequence.ignoreStates}
                    label="Ignore states"
                    alignIndicator={Alignment.RIGHT}
                    onChange={(evt) =>
                      this.handleSwitchChange(evt, 'ignoreStates')
                    }
                  />
                </FormGroup>
              </div>
            </div>
            {this.props.sequence.repeat ? (
              <div className="form-col">
                <FormGroup
                  label="Repeat count"
                  helperText="-1 means ∞"
                  labelFor="repeat-count-input"
                >
                  <NumericInput
                    id="repeat-count-input"
                    style={{ width: 60 }}
                    value={this.props.sequence.count}
                    onValueChange={(valueAsNumber) =>
                      this.handleNumericPropertyChange('count', valueAsNumber)
                    }
                    placeholder="-1"
                    min="-1"
                  />
                </FormGroup>
              </div>
            ) : null}
            <div className="form-col">
              <FormGroup
                label="Autoplay step duration"
                labelFor="step-duration-input"
              >
                <NumericInput
                  id="step-duration-input"
                  style={{ width: 60 }}
                  value={this.props.sequence.stepDuration}
                  onValueChange={(valueAsNumber) =>
                    this.handleNumericPropertyChange(
                      'stepDuration',
                      valueAsNumber
                    )
                  }
                  placeholder="4"
                  min="1"
                />
              </FormGroup>
            </div>
            <FormGroup label="Next sequence" labelFor="next-sequence-input">
              <HTMLSelect
                id="next-sequence-input"
                options={this.getSequences()}
                value={this.props.sequence.nextSequence}
                onChange={(evt) =>
                  this.handlePropertyChange(
                    evt,
                    'nextSequence',
                    evt.target.value
                  )
                }
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.props.onClose} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }
  }
)

export default SequenceSettingsDialog
