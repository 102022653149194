import React from 'react'
import { observer } from 'mobx-react'
import ResizableRect from 'react-resizable-rotatable-draggable'
import { Action } from '../stepworks/stepwise/stepwise-v2.js'

const EditorHighlights = observer(
  class EditorHighlights extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        unitWidth: 100,
        unitHeight: 100,
        width: 100,
        height: 100,
        top: -200,
        left: -200,
      }
      this.baseElement = React.createRef()
      this.actionsByCharacter = {}
      this.lastStep = null
    }

    componentDidMount() {
      let body = document.getElementsByTagName('body')[0]
      body.addEventListener('panelLayoutChanged', this.handlePanelLayoutChanged)
      window.addEventListener('resize', this.handleWindowResize.bind(this))
    }

    componentWillUnmount() {
      let body = document.getElementsByTagName('body')[0]
      body.removeEventListener(
        'panelLayoutChanged',
        this.handlePanelLayoutChanged
      )
      window.removeEventListener('resize', this.handleWindowResize)
    }

    handleWindowResize() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.props.onLayoutUpdateNeeded()
      }, 250)
    }

    handlePanelLayoutChanged = (evt) => {
      this.updateLayout(evt.detail)
    }

    handleStepwiseEvent(type, obj) {
      switch (type) {
        case 'step':
          this.lastStep = obj
          /*Object.values(this.actionsByCharacter).forEach(data => {
        if (this.lastStep.actions.indexOf(data.action) !== -1) {
          this.actionsByCharacter[data.action.targetCharacter.id].step = this.lastStep;
        }
      });*/
          break

        case 'action':
          this.actionsByCharacter[obj.targetCharacter.id] = {
            action: obj,
            step: this.lastStep,
          }
          break

        default:
          break
      }
    }

    updateLayout(layout) {
      if (layout) {
        if (!layout.unit) {
          this.props.onLayoutUpdateNeeded()
        }
        if (this.props.editCharacter && layout.unit) {
          if (layout.character === this.props.editCharacter) {
            this.setState({
              panel: layout.panel,
              unitWidth: layout.unit.width,
              unitHeight: layout.unit.height,
              left:
                layout.gridLayout.left * layout.unit.width +
                layout.margins.left,
              top:
                layout.gridLayout.top * layout.unit.height + layout.margins.top,
              width:
                layout.gridLayout.width * layout.unit.width +
                (layout.margins.left + layout.margins.right),
              height:
                layout.gridLayout.height * layout.unit.height +
                (layout.margins.top + layout.margins.bottom),
            })
          }
        }
        this.forceUpdate()
      }
    }

    handleResize = (style, isShiftKey, type) => {
      let { top, left, width, height } = style
      top = Math.round(top / this.state.unitHeight)
      left = Math.round(left / this.state.unitWidth)
      width = Math.round(width / this.state.unitWidth)
      height = Math.round(height / this.state.unitHeight)
      this.state.panel.setLayout(left, top, width, height)
      this.state.panel.updateLayout()
    }

    handleDragStart = () => {
      this.left = this.state.left
      this.top = this.state.top
    }

    handleDrag = (deltaX, deltaY) => {
      this.left = this.left + deltaX
      this.top = this.top + deltaY
      let left = Math.round(this.left / this.state.unitWidth)
      let top = Math.round(this.top / this.state.unitHeight)
      this.state.panel.moveTo(left, top)
    }

    isPositionAction(action) {
      if (action) {
        return action.command === 'move' || action.command === 'enter'
      } else {
        return false
      }
    }

    getPositionActionForCharacter(step, character) {
      if (this.isPositionAction(this.props.editAction)) {
        return this.props.editAction
      }
      let n = step.actions.length
      for (var i = 0; i < n; i++) {
        let action = step.actions[i]
        if (action.targetCharacter === character) {
          if (this.isPositionAction(action)) {
            return action
          }
        }
      }
      return null
    }

    handleResizeEnd = () => {
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      if (step) {
        let action = this.getPositionActionForCharacter(
          step,
          this.props.editCharacter
        )
        let top = Math.round(this.state.top / this.state.unitHeight)
        let left = Math.round(this.state.left / this.state.unitWidth)
        let width = Math.round(this.state.width / this.state.unitWidth)
        let height = Math.round(this.state.height / this.state.unitHeight)
        if (action) {
          if (action.command === 'enter') {
            action.amount = ['custom']
          }
          action.content = left + ' ' + top + ' ' + width + ' ' + height
        } else {
          let data = {
            character: this.props.editCharacter.id,
            command: 'move',
            delay: 0,
            content: left + ' ' + top + ' ' + width + ' ' + height,
          }
          action = new Action(data, this.props.stepwise.score)
          step.addAction(action)
        }
      }
    }

    handleUnselectedPanelClick = (evt) => {
      let bounds = evt.target.parentElement.getBoundingClientRect()
      let clickX = evt.clientX - bounds.left
      let clickY = evt.clientY - bounds.top
      if (
        clickX < this.state.left ||
        clickX > this.state.left + this.state.width ||
        clickY < this.state.top ||
        clickY > this.state.top + this.state.height
      ) {
        let characterId = evt.target.getAttribute('data-character')
        var character = this.props.stepwise.score.getCharacter(characterId)
        if (character) {
          var actionData = this.actionsByCharacter[characterId]
          if (actionData) {
            this.props.onCharacterChange(character)
            setTimeout(() => {
              this.props.onLayoutUpdateNeeded()
            }, 10)
          }
        }
      }
    }

    render() {
      if (this.props.editCharacter) {
        // if we haven't gotten a layout yet, keep triggering updates until we do
        if (!this.state.panel) {
          if (!this.interval)
            this.interval = setInterval(
              () => this.props.onLayoutUpdateNeeded(),
              100
            )
        } else {
          clearInterval(this.interval)
        }
        let layouts = this.props.onAllLayoutsNeeded()
        let unselectedPanels = layouts.map((layout, index) => {
          if (layout.character !== this.props.editCharacter) {
            let style = {
              left:
                layout.gridLayout.left * layout.unit.width +
                layout.margins.left,
              top:
                layout.gridLayout.top * layout.unit.height + layout.margins.top,
              width:
                layout.gridLayout.width * layout.unit.width +
                (layout.margins.left + layout.margins.right),
              height:
                layout.gridLayout.height * layout.unit.height +
                (layout.margins.top + layout.margins.bottom),
            }
            return (
              <div
                key={index}
                className="unselected-panel"
                onClick={this.handleUnselectedPanelClick}
                data-character={layout.character.id}
                style={style}
              ></div>
            )
          } else {
            return null
          }
        })
        const { width, top, left, height } = this.state
        return (
          <div>
            <ResizableRect
              left={left}
              top={top}
              width={width}
              height={height}
              zoomable="n, w, s, e, nw, ne, se, sw"
              rotatable={false}
              onResize={this.handleResize}
              onResizeEnd={this.handleResizeEnd}
              onDragStart={this.handleDragStart}
              onDrag={this.handleDrag}
              onDragEnd={this.handleResizeEnd}
            />
            {this.props.charactersEnabled ? (
              <div className="panel-info" style={{ left: left, top: top }}>
                {this.props.editCharacter.fullName}
              </div>
            ) : null}
            {unselectedPanels}
          </div>
        )
      } else {
        return null
      }
    }
  }
)

export default EditorHighlights
