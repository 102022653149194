import React from 'react'
import ScoreTextEditor from './ScoreTextEditor.js'
import ScoreMediaEditor from './ScoreMediaEditor.js'
import ScoreMusicEditor from './ScoreMusicEditor.js'
import {
  Button,
  ButtonGroup,
  Intent,
  HTMLSelect,
  Alert,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'

const ScoreColumn = observer(
  class ScoreColumn extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        viewType: 'text',
        openAlert: 'none',
      }
      this.handleViewTypeClick = this.handleViewTypeClick.bind(this)
    }

    handleViewTypeClick(evt, viewType) {
      this.setState({ viewType: viewType })
    }

    getSequences() {
      var sequences = []
      if (this.props.stepwise) {
        Object.values(this.props.stepwise.score.currentScene.sequences).forEach(
          (sequence, index) => {
            let title = sequence.title
            /*if (index === 0) {
          title += '*';
        }*/
            sequences.push({ label: title, value: sequence.id })
          }
        )
        if (sequences.length > 1)
          sequences.push({ label: 'Delete sequence', value: 'delete-sequence' })
        sequences.push({ label: 'Add sequence', value: 'add-sequence' })
      }
      return sequences
    }

    handleSequenceSelect = (evt) => {
      switch (evt.currentTarget.value) {
        case 'add-sequence':
          this.addSequence()
          break

        case 'delete-sequence':
          this.handleDeleteSequenceRequest()
          break

        default:
          let sequence = this.props.stepwise.score.currentScene.getSequence(
            evt.currentTarget.value
          )
          this.props.onSequenceChange(sequence)
          break
      }
    }

    addSequence() {
      var sequence = this.props.stepwise.score.currentScene.createSequence()
      this.props.onSequenceChange(sequence)
    }

    handleDeleteSequenceRequest = () => {
      let index = Object.values(
        this.props.stepwise.score.currentScene.sequences
      ).indexOf(this.props.sequence)
      if (index > 0) {
        this.setState({ openAlert: 'deleteSequence' })
      }
    }

    handleAlertClose = () => {
      this.setState({ openAlert: 'none' })
    }

    deleteSelectedSequence = () => {
      this.props.stepwise.score.currentScene.deleteSequence(this.props.sequence)
      this.props.onSequenceChange(
        Object.values(this.props.stepwise.score.currentScene.sequences)[0]
      )
    }

    render() {
      return (
        <div className="score-column">
          <div className="sequence-editor">
            <HTMLSelect
              options={this.getSequences()}
              value={this.props.sequence.id}
              onChange={this.handleSequenceSelect}
            />
            <ButtonGroup className="pull-right">
              <Button
                icon={this.props.columnsEnabled > 0 ? 'unlock' : 'lock'}
                minimal="true"
                onClick={(evt) => this.props.onColumnsEnabledChange(evt, 1)}
              />
            </ButtonGroup>
          </div>
          <div className="score-column-header">
            <ButtonGroup>
              <Button
                icon="font"
                intent={
                  this.state.viewType === 'text' ? Intent.PRIMARY : Intent.NONE
                }
                onClick={(evt) => this.handleViewTypeClick(evt, 'text')}
              />
              <Button
                icon="media"
                intent={
                  this.state.viewType === 'media' ? Intent.PRIMARY : Intent.NONE
                }
                onClick={(evt) => this.handleViewTypeClick(evt, 'media')}
              />
              <Button
                icon="music"
                intent={
                  this.state.viewType === 'music' ? Intent.PRIMARY : Intent.NONE
                }
                onClick={(evt) => this.handleViewTypeClick(evt, 'music')}
              />
            </ButtonGroup>
            &nbsp;&nbsp;
            <ButtonGroup>
              <Button
                icon="person"
                intent={
                  this.props.charactersEnabled ? Intent.PRIMARY : Intent.NONE
                }
                onClick={this.props.onCharactersEnabledToggle}
              />
            </ButtonGroup>
          </div>
          <div className="score-column-body no-scroll">
            {this.state.viewType === 'text' ? (
              <ScoreTextEditor
                stage={this.props.stage}
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                columnsEnabled={this.props.columnsEnabled}
                onStepChange={this.props.onStepChange}
                onStepSelect={this.props.onStepSelect}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onPanelLayoutNeeded={this.props.onPanelLayoutNeeded}
                onAddStep={this.props.onAddStep}
                musicPlayer={this.props.musicPlayer}
                charactersEnabled={this.props.charactersEnabled}
              />
            ) : null}
            {this.state.viewType === 'media' ? (
              <ScoreMediaEditor
                stage={this.props.stage}
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                onStepChange={this.props.onStepChange}
                onStepSelect={this.props.onStepSelect}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onAddStep={this.props.onAddStep}
                musicPlayer={this.props.musicPlayer}
                charactersEnabled={this.props.charactersEnabled}
              />
            ) : null}
            {this.state.viewType === 'music' ? (
              <ScoreMusicEditor
                stage={this.props.stage}
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                editCharacter={this.props.editCharacter}
                characterDialog={this.props.characterDialog}
                onStepChange={this.props.onStepChange}
                onStepSelect={this.props.onStepSelect}
                onStepwiseEvent={this.props.onStepwiseEvent}
                onStoryUpdate={this.props.onStoryUpdate}
                onAddStep={this.props.onAddStep}
                musicPlayer={this.props.musicPlayer}
                charactersEnabled={this.props.charactersEnabled}
              />
            ) : null}
          </div>
          <Alert
            isOpen={this.state.openAlert === 'deleteSequence'}
            cancelButtonText="Cancel"
            confirmButtonText="Delete sequence"
            intent={Intent.DANGER}
            icon="trash"
            onClose={this.handleAlertClose}
            onConfirm={this.deleteSelectedSequence}
          >
            <p>
              Are you sure you want to delete the sequence “
              {this.props.sequence ? this.props.sequence.title : ''}”? This
              cannot be undone.
            </p>
          </Alert>
        </div>
      )
    }
  }
)

export default ScoreColumn
