import React from 'react'
import {
  InputGroup,
  FormGroup,
  HTMLSelect,
  NumericInput,
} from '@blueprintjs/core'

class TunesMapSearchTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      year: '',
      city: '',
      artist: '',
      genre: '',
      mediaType: '',
      cultureType: '',
    }
    this.genreOptions = [
      { value: '', label: 'Select a genre...' },
      { value: 'ambient', label: 'Ambient' },
      { value: 'electronic', label: 'Electronic' },
      { value: 'jazz', label: 'Jazz' },
      { value: 'world/international', label: 'World/International' },
      { value: 'indie pop & rock', label: 'Indie Pop & Rock' },
      { value: 'blues', label: 'Blues' },
      { value: "children's", label: "Children's" },
      { value: 'classical', label: 'Classical' },
      { value: 'stage &amp; screen', label: 'Stage & Screen' },
      { value: 'spoken word', label: 'Spoken Word' },
      { value: 'comedy', label: 'Comedy' },
      { value: 'country & western', label: 'Country & Western' },
      { value: 'easy listening', label: 'Easy Listening' },
      { value: 'r&b', label: 'R&B' },
      { value: 'dance', label: 'Dance' },
      { value: 'singer-songwriter & folk', label: 'Singer Songwriter & Folk' },
      { value: 'religious', label: 'Religious' },
      { value: 'pop', label: 'Pop' },
      { value: 'rock', label: 'Rock' },
      { value: 'hard rock & metal', label: 'Hard Rock & Metal' },
      { value: 'punk', label: 'Punk' },
      { value: 'hip-hop & rap', label: 'Hip Hop & Rap' },
      { value: 'reggae', label: 'Reggae' },
      { value: 'zeitgeist', label: 'Zeitgeist' },
      { value: 'n/a', label: 'n/a' },
    ]
    this.mediaTypeOptions = [
      { label: 'Select a media type...', value: '' },
      { label: 'Image', value: 'image' },
      { label: 'Video', value: 'video' },
    ]
    this.cultureTypeOptions = [
      { label: 'Select a culture type...', value: '' },
      { value: 'artist_influence', label: 'Artist influence' },
      { value: 'artist_photos', label: 'Artist photos' },
      { value: 'fashion', label: 'Fashion' },
      { value: 'festivals_and_concerts', label: 'Festivals & concerts' },
      { value: 'film', label: 'Film' },
      { value: 'fine_art', label: 'Fine art' },
      { value: 'landmarks', label: 'Landmarks' },
      { value: 'literature', label: 'Literature' },
      { value: 'memorabilia', label: 'Memorabilia' },
      { value: 'music_news', label: 'Music news' },
      { value: 'music_videos', label: 'Music videos' },
      { value: 'news', label: 'News' },
      { value: 'performing_arts', label: 'Performing arts' },
      { value: 'photography', label: 'Photography' },
      { value: 'radio', label: 'Radio' },
      { value: 'skyline', label: 'Skyline' },
      { value: 'tv', label: 'TV' },
      { value: 'significant_figures', label: 'Significant figures' },
    ]
  }

  onYearChange(valueAsNumber, valueAsString) {
    this.setState({ year: valueAsString })
  }

  onCityChange(evt) {
    this.setState({ city: evt.currentTarget.value })
  }

  onArtistChange(evt) {
    this.setState({ artist: evt.currentTarget.value })
  }

  onGenreChange(evt) {
    this.setState({ genre: evt.currentTarget.value })
  }

  onMediaTypeChange(evt) {
    this.setState({ mediaType: evt.currentTarget.value })
  }

  onCultureTypeChange(evt) {
    this.setState({ cultureType: evt.currentTarget.value })
  }

  getSearchParameters(returnHandler) {
    this.props.api.normalizeArtistName(this.state.artist, (artistName) => {
      if (this.state.artist.trim() !== '') {
        this.normalizedArtistName = artistName
      } else {
        this.normalizedArtistName = null
      }
      this.props.api.normalizeLocation(this.state.city, (location) => {
        this.normalizedLocation = location
        let parameters = {
          time: this.state.year,
          location: this.normalizedLocation,
          genre: this.state.genre,
          mediaType: this.state.mediaType,
          cultureType: this.state.cultureType,
        }
        if (this.normalizedArtistName)
          parameters.artist = this.normalizedArtistName
        returnHandler('tunesmap', parameters)
      })
    })
  }

  render() {
    return (
      <div>
        <p className="bp3-text-large">
          Search a rich collection of cultural media.
        </p>
        <br />
        <FormGroup label="" labelFor="artist-input" inline={true}>
          <InputGroup
            id="artist-input"
            value={this.state.artist}
            placeholder="Enter an artist..."
            onChange={this.onArtistChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="" labelFor="year-input" inline={true}>
          <NumericInput
            id="year-input"
            allowNumericCharactersOnly={true}
            value={this.state.year}
            min="1900"
            max="2100"
            minorStepSize={null}
            placeholder="Enter a year..."
            onValueChange={this.onYearChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="" labelFor="city-input" inline={true}>
          <InputGroup
            id="city-input"
            value={this.state.city}
            placeholder="Enter a city..."
            onChange={this.onCityChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="" labelFor="genre-input" inline={true}>
          <HTMLSelect
            id="genre-input"
            options={this.genreOptions}
            onChange={this.onGenreChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="" labelFor="culture-type-input" inline={true}>
          <HTMLSelect
            id="culture-type-input"
            options={this.cultureTypeOptions}
            onChange={this.onCultureTypeChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="" labelFor="media-type-input" inline={true}>
          <HTMLSelect
            id="media-type-input"
            options={this.mediaTypeOptions}
            onChange={this.onMediaTypeChange.bind(this)}
          />
        </FormGroup>
        <p className="bp3-text-small">
          Powered by{' '}
          <a
            href="https://tunesmap.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            TunesMap
          </a>
        </p>
      </div>
    )
  }
}

export default TunesMapSearchTab
