import React from 'react'
import { Stepwise, FrameState } from '../stepworks/stepwise/stepwise-v2.js'
import VideoFeature from './VideoFeature.js'
import TextFeature from './TextFeature.js'
import ImageFeature from './ImageFeature.js'
import AudioFeature from './AudioFeature.js'
import PanelMatte from './PanelMatte.js'

class Panel extends React.Component {
  constructor(props) {
    super(props)
    this.isEchoing = false
    this.transition = 0.5
    this.gridLayout = { left: 13, top: 0, width: 12, height: 12 }
    this.margins = { left: 0, top: 0, right: 0, bottom: 0 }
    this.baseElement = React.createRef()
    this.media = React.createRef()
    this.image = React.createRef()
    this.textFeature = React.createRef()
    this.matte = React.createRef()
    this.enterAction = null
    let feature = this.props.character.getFeatureForType('frame')
    this.currentState = feature.defaultState
  }

  componentDidMount() {
    this.applyState(this.currentState)
    this.updateLayout()
    this.setTransition(0.5)
  }

  shouldComponentUpdate() {
    return false
  }

  doUpdate() {
    this.updateLayout()
    this.forceUpdate()
  }

  handleStoryUpdate() {
    this.forceUpdate()
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) featureRef.handleStoryUpdate()
    })
  }

  reflow() {
    //console.log('reflow: '+this.props.character.id);
    let a = this.baseElement.current.offsetHeight
    return a
  }

  handleStepwiseEvent(type, obj) {
    switch (type) {
      case 'sequence':
        let feature = this.props.character.getFeatureForType('audio')
        if (this.featureRefs[feature.id]) {
          this.featureRefs[feature.id].preloadAudioForSequence(obj)
        }
        break

      case 'state':
        switch (obj.type) {
          case 'frame':
            this.applyState(obj)
            this.updateLayout()
            break
          default:
            if (this.featureRefs[obj.targetFeature.id]) {
              this.featureRefs[obj.targetFeature.id].applyState(obj)
            }
            break
        }
        break

      case 'action':
        switch (obj.command) {
          case 'move':
            let a = obj.content.split(' ')
            this.setLayout(
              parseFloat(a[0]),
              parseFloat(a[1]),
              parseFloat(a[2]),
              parseFloat(a[3])
            )
            this.updateLayout()
            break

          default:
            if (obj.command === 'enter') {
              this.enterAction = obj
            }
            if (obj.targetFeature) {
              this.featureRefs[obj.targetFeature.id].doAction(obj)
            } else {
              Object.values(this.featureRefs).forEach((featureRef) => {
                if (featureRef) featureRef.doAction(obj)
              })
            }
            break
        }
        break

      /*case 'nextStep':
      Object.values(this.featureRefs).forEach((featureRef, i) => {
        if (featureRef) {
          if (featureRef.handleStep) featureRef.handleStep();
        }
      });
      break;*/

      case 'step':
        this.lastStep = obj
        break

      default:
        break
    }
  }

  applyState(state) {
    this.currentState = state
    this.setTransition(state.transitionDuration)
    for (let property in state) {
      switch (property) {
        case 'layout':
          if (state.enforceLayout) {
            let a = state[property].split(' ')
            this.setLayout(
              parseFloat(a[0]),
              parseFloat(a[1]),
              parseFloat(a[2]),
              parseFloat(a[3])
            )
          }
          break

        case 'margin':
          this.setMargin(state[property])
          break

        case 'depth':
          this.baseElement.current.style.zIndex = parseInt(state[property])
          break

        case 'enforceLayout':
          // nothing; temporary fix so that layout can be enforced when restoring
          // from history but not at other times
          break

        default:
          if (
            property !== 'length' &&
            property !== 'parentRule' &&
            property !== 'type'
          ) {
            this.baseElement.current.style[property] = state[property]
          }
          break
      }
    }
    if (state.enforceLayout) {
      this.updateLayout()
    }
  }

  getStates() {
    let states = []
    states.push(this.getCurrentFrameState())
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) {
        states.push(
          this.props.stepwise.score.currentScene.defaultSequence.getCurrentStateForFeatureInStep(
            featureRef.props.feature,
            this.lastStep,
            true,
            true
          )
        )
      }
    })
    return states
  }

  getActions() {
    let actions = []
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) {
        actions = actions.concat(featureRef.getActions())
      }
    })
    return actions
  }

  getCurrentFrameState() {
    let data = {
      type: 'frame',
      character: this.props.character.id,
      layout:
        this.gridLayout.left +
        ' ' +
        this.gridLayout.top +
        ' ' +
        this.gridLayout.width +
        ' ' +
        this.gridLayout.height,
      margin:
        this.margins.left +
        ' ' +
        this.margins.top +
        ' ' +
        this.margins.right +
        ' ' +
        this.margins.bottom,
      backgroundColor: this.baseElement.current.style.backgroundColor,
      opacity: this.baseElement.current.style.opacity,
      border: this.baseElement.current.style.border,
      overflow: this.baseElement.current.style.baseElement,
      enforceLayout: true,
    }
    //console.log('current frame',this.props.character.id,data);
    let state = this.currentState
    if (!state) {
      let feature = this.props.character.getFeatureForType('frame')
      state =
        this.props.stepwise.score.currentScene.defaultSequence.getCurrentStateForFeatureInStep(
          feature,
          this.lastStep,
          true,
          true
        )
    }
    if (state.targetFeature) {
      data.feature = state.targetFeature.id
    }
    let frameState = new FrameState(data, this.props.stepwise.score)
    frameState.setupReferences()
    return frameState
  }

  mute() {
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) featureRef.mute()
    })
  }

  togglePlayPause() {
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) featureRef.togglePlayPause()
    })
  }

  pause() {
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) featureRef.pause()
    })
  }

  play() {
    Object.values(this.featureRefs).forEach((featureRef) => {
      if (featureRef) featureRef.play()
    })
  }

  resetContent() {
    this.props.character.features.forEach((feature) => {
      let featureRef = this.featureRefs[feature.id]
      if (featureRef) {
        featureRef.resetContent()
      }
    })
  }

  getPositionFromString(str) {
    var x,
      y,
      temp = str.split(' ')
    if (isNaN(parseFloat(temp[0]))) {
      switch (temp[0]) {
        case 'offleft':
          x = -this.gridLayout.width
          break
        case 'left':
          x = 0
          break
        case 'center':
          x = (this.props.grid.columns - this.gridLayout.width) * 0.5
          break
        case 'right':
          x = this.props.grid.columns - this.gridLayout.width
          break
        case 'offright':
          x = this.props.grid.columns
          break
        case 'offtop':
          y = this.gridLayout.height
          break
        case 'top':
          y = 0
          break
        case 'bottom':
          y = this.props.grid.rows - this.gridLayout.height
          break
        case 'offbottom':
          y = this.props.grid.rows
          break
        default:
          break
      }
      if (temp.length > 1) {
        switch (temp[1]) {
          case 'offtop':
            y = this.gridLayout.height
            break
          case 'top':
            y = 0
            break
          case 'center':
            y = (this.props.grid.rows - this.gridLayout.height) * 0.5
            break
          case 'bottom':
            y = this.props.grid.rows - this.gridLayout.height
            break
          case 'offbottom':
            y = this.props.grid.rows
            break
          default:
            break
        }
      } else if (temp.length === 1) {
        if (temp[0] === 'center') {
          y = (this.props.grid.rows - this.gridLayout.height) * 0.5
        }
      }
    } else {
      x = temp[0]
      y = temp[1]
    }
    return { left: x, top: y }
  }

  setMargin(marginString) {
    let temp = marginString.split(' ')
    if (temp.length === 1) {
      this.margins.top = parseFloat(temp[0])
      this.margins.bottom = parseFloat(temp[0])
      this.margins.left = parseFloat(temp[0])
      this.margins.right = parseFloat(temp[0])
    } else if (temp.length === 2) {
      this.margins.top = parseFloat(temp[0])
      this.margins.bottom = parseFloat(temp[0])
      this.margins.left = parseFloat(temp[1])
      this.margins.right = parseFloat(temp[1])
    } else if (temp.length === 3) {
      this.margins.top = parseFloat(temp[0])
      this.margins.bottom = parseFloat(temp[2])
      this.margins.left = parseFloat(temp[1])
      this.margins.right = parseFloat(temp[1])
    } else if (temp.length === 4) {
      this.margins.top = parseFloat(temp[0])
      this.margins.bottom = parseFloat(temp[2])
      this.margins.left = parseFloat(temp[3])
      this.margins.right = parseFloat(temp[1])
    }
  }

  setLayout(x, y, w, h) {
    this.gridLayout.left = x
    this.gridLayout.top = y
    this.gridLayout.width = w
    this.gridLayout.height = h
  }

  setTransition(secs) {
    //console.log('set transition: '+this.props.character.fullName+' '+secs);
    this.transition = secs
    this.baseElement.current.style.transition = `width ${secs}s, height ${secs}s, background-color ${secs}s, opacity ${secs}s, filter ${secs}s, top ${secs}s, left ${secs}s`
  }

  setPosition(left, top) {
    //console.log('set position: '+this.props.character.id+' '+left+' '+top);
    if (!isNaN(left) && !isNaN(top)) {
      var currentTransition = this.transition
      this.setTransition(0)
      this.gridLayout.left = left
      this.gridLayout.top = top
      this.reflow()
      setTimeout(() => this.updateLayout(), 1000)
      this.setTransition(currentTransition)
    }
  }

  moveTo(left, top) {
    if (!isNaN(left) && !isNaN(top)) {
      this.gridLayout.left = left
      this.gridLayout.top = top
      this.updateLayout()
    }
  }

  updateLayout(unit) {
    //console.log('updateLayout: '+this.props.character.fullName+' / '+this.gridLayout.left+' '+this.gridLayout.top+' '+this.gridLayout.width+' '+this.gridLayout.height);
    var css = this.baseElement.current.style
    var grid = this.props.grid
    let border = parseInt(this.baseElement.current.style.border)
    if (isNaN(border)) border = 0
    let borderOffset = 0
    css.left =
      'calc(' +
      (this.gridLayout.left / grid.columns) * 100 +
      '% + ' +
      this.margins.left +
      'px)'
    css.top =
      'calc(' +
      (this.gridLayout.top / grid.rows) * 100 +
      '% + ' +
      this.margins.top +
      'px)'
    css.width =
      'calc(' +
      (this.gridLayout.width / grid.columns) * 100 +
      '% - ' +
      (this.margins.left + this.margins.right + borderOffset) +
      'px)'
    css.height =
      'calc(' +
      (this.gridLayout.height / grid.rows) * 100 +
      '% - ' +
      (this.margins.top + this.margins.bottom + borderOffset) +
      'px)'
    this.props.character.features.forEach((feature) => {
      let featureRef = this.featureRefs[feature.id]
      if (featureRef) {
        featureRef.updateLayout(/*this.unit*/)
      }
    })
    const event = new CustomEvent('panelLayoutChanged', {
      detail: {
        panel: this,
        character: this.props.character,
        grid: this.props.grid,
        unit: unit,
        gridLayout: this.gridLayout,
        margins: this.margins,
        element: this.baseElement,
      },
    })
    let body = document.getElementsByTagName('body')[0]
    body.dispatchEvent(event)
  }

  render() {
    this.featureRefs = {}
    let className = 'panel'
    if (!this.props.character.visible) {
      className += ' hidden'
    }
    var features = this.props.character.features.map((feature, index) => {
      switch (feature.type) {
        case Stepwise.FeatureTypes.TEXT:
          return (
            <TextFeature
              key={index}
              ref={(ref) => {
                this.featureRefs[feature.id] = ref
              }}
              feature={feature}
              stepwise={this.props.stepwise}
              isEchoing={this.isEchoing}
              onSaveActionToHistory={this.saveActionToHistory}
            />
          )

        case Stepwise.FeatureTypes.IMAGE:
          return (
            <ImageFeature
              key={index}
              ref={(ref) => {
                this.featureRefs[feature.id] = ref
              }}
              feature={feature}
              stepwise={this.props.stepwise}
              isEditing={this.props.isEditing}
              onSaveActionToHistory={this.saveActionToHistory}
            />
          )

        case Stepwise.FeatureTypes.AUDIO:
          return (
            <AudioFeature
              key={index}
              ref={(ref) => {
                this.featureRefs[feature.id] = ref
              }}
              feature={feature}
              onSolo={this.props.onSolo}
              stepwise={this.props.stepwise}
              isEditing={this.props.isEditing}
              isPreviewing={this.props.isPreviewing}
              onSaveActionToHistory={this.saveActionToHistory}
            />
          )

        case Stepwise.FeatureTypes.VIDEO:
          return (
            <VideoFeature
              key={index}
              ref={(ref) => {
                this.featureRefs[feature.id] = ref
              }}
              feature={feature}
              stepwise={this.props.stepwise}
              onSolo={this.props.onSolo}
              gridLayout={this.gridLayout}
              isEditing={this.props.isEditing}
              isPreviewing={this.props.isPreviewing}
              onSaveActionToHistory={this.saveActionToHistory}
            />
          )

        default:
          return null
      }
    })
    return (
      <div
        id={this.props.character.id + '-panel'}
        ref={this.baseElement}
        className={className}
      >
        {features}
        <PanelMatte ref={this.matte} />
      </div>
    )
  }
}

export default Panel
