import React from 'react'
import Channel from './Channel.js'
import PlayBar from './PlayBar.js'
import EditorHighlights from './EditorHighlights.js'
import { observer } from 'mobx-react'
import { Step } from './stepwise/stepwise-v2.js'
import { Rect, PanelSubRect } from './stepworks-studio-utils.js'

const Stage = observer(
  class Stage extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        step: null,
        location: null,
        mediaInfo: null,
      }
      this.grid = null
      this.panelCornerBeingDragged = null
      this.handleStepwiseEvent = this.handleStepwiseEvent.bind(this)
      this.handleStep = this.handleStep.bind(this)
      this.handleClick = this.handleClick.bind(this)
      this.handleMediaRequest = this.handleMediaRequest.bind(this)
      this.handleStoryUpdate = this.handleStoryUpdate.bind(this)
      this.replaceHistoryState = this.replaceHistoryState.bind(this)
      this.instanceProcessed = false
      this.element = React.createRef()
      this.highlightsRef = React.createRef()
      this.playBar = React.createRef()
      this.history = []
      this.historyIndex = 0
      this.mousePos = { x: 0, y: 0 }
      this.lastStepWasForward = true
    }

    componentDidMount() {
      this.instanceProcessed = false
    }

    componentDidUpdate(prevProps) {
      if (prevProps.stepwise !== this.props.stepwise) {
        this.instanceProcessed = false
      }
      if (this.props.stepwise != null && !this.instanceProcessed) {
        this.props.stepwise.eventManager.addListener(this.handleStepwiseEvent)
        //this.props.stepwise.element.addEventListener('executeStep', this.handleStep);
        this.instanceProcessed = true
      }
    }

    componentWillUnmount() {}

    doUpdate() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) {
          channelRef.doUpdate()
        }
      })
    }

    handleStoryUpdate() {
      this.forceUpdate()
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.handleStoryUpdate()
      })
    }

    updateLayout() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.updateLayout()
      })
    }

    resetLayout() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.resetLayout()
      })
    }

    resetContent() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.resetContent()
      })
    }

    handleStepwiseEvent(type, obj) {
      let channel
      switch (type) {
        case 'state':
          for (let property in this.channelRefs) {
            if (this.channelRefs[property]) {
              if (
                this.channelRefs[property].props.channel.characters[
                  obj.targetFeature.parentCharacter.id
                ]
              ) {
                this.channelRefs[property].handleStepwiseEvent(type, obj)
              }
            }
          }
          break

        case 'action':
          channel = this.props.stepwise.score.getChannelForCharacter(
            obj.targetCharacter.id
          )
          if (this.channelRefs[channel.id]) {
            this.channelRefs[channel.id].handleStepwiseEvent(type, obj)
          }
          if (this.highlightsRef) {
            if (this.highlightsRef.current) {
              this.highlightsRef.current.handleStepwiseEvent(type, obj)
            }
          }
          break

        /*case 'nextStep':
      Object.values(this.channelRefs).forEach((channelRef) => {
        channelRef.handleStepwiseEvent(type, obj);
      })
      break;*/

        case 'step': // called before all states and actions have executed
          Object.values(this.channelRefs).forEach((channelRef) => {
            if (channelRef) {
              channelRef.handleStepwiseEvent(type, obj)
            }
          })
          if (this.highlightsRef) {
            if (this.highlightsRef.current) {
              this.highlightsRef.current.handleStepwiseEvent(type, obj)
            }
          }
          if (this.props.isPreviewing && !this.isNavigatingHistory()) {
            this.saveToHistory()
            this.historyIndex = this.history.length
          }
          this.lastStepWasForward = true
          //console.log('history contains '+this.history.length+' steps, index is '+this.historyIndex);
          break

        default:
          Object.values(this.channelRefs).forEach((channelRef) => {
            if (channelRef) {
              channelRef.handleStepwiseEvent(type, obj)
            }
          })
          break
      }
    }

    saveToHistory() {
      let states = []
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) {
          states = states.concat(channelRef.getStates())
        }
      })
      let actions = []
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) {
          actions = actions.concat(channelRef.getActions())
        }
      })
      if (this.historyIndex < this.history.length) {
        this.history.splice(this.historyIndex)
      }
      let stepData = {
        states: [],
        actions: [],
      }
      let step = new Step(
        stepData,
        this.props.stepwise.score,
        this.props.sequence
      )
      if (states.length > 0) {
        step.states = states
      }
      if (actions.length > 0) {
        step.actions = actions
      }
      step.setupReferences()
      this.history.push(step)
    }

    saveActionToHistory = (action) => {
      if (this.history.length > 0 && !this.isNavigatingHistory()) {
        let step = this.history[this.historyIndex]
        step.addAction(action)
      }
    }

    isNavigatingHistory() {
      return this.historyIndex < this.history.length
    }

    popFromHistory() {
      if (this.lastStepWasForward) {
        this.saveToHistory()
      }
      this.historyIndex = Math.max(-1, this.historyIndex - 1)
      console.log('<<<<', this.historyIndex)
      if (this.historyIndex > -1) {
        this.restoreHistoryAtIndex()
      }
      this.lastStepWasForward = false
    }

    nextInHistory() {
      //console.log(this.historyIndex,this.history.length);
      if (this.historyIndex < this.history.length - 1) {
        console.log('next in history')
        this.historyIndex++
        this.restoreHistoryAtIndex()
        return true
      } else {
        return false
      }
    }

    restoreHistoryAtIndex() {
      let historyEntry = this.history[this.historyIndex]
      historyEntry.states.forEach((state) => {
        this.handleStepwiseEvent('state', state)
      })
      historyEntry.actions.forEach((action) => {
        console.log(
          'restore',
          this.historyIndex,
          action.command,
          action.content
        )
        this.handleStepwiseEvent('action', action)
      })
    }

    replaceHistoryState(replacingState) {
      /*let historyEntry = this.history[this.historyIndex];
    if (historyEntry) {
      historyEntry.forEach((state, index) => {
        if (state.targetFeature === replacingState.targetFeature) {
          historyEntry[index] = replacingState;
        }
      });
    } else {
      this.history.unshift([replacingState]);
      this.historyIndex++;
    }*/
    }

    togglePlayPause() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.togglePlayPause()
      })
    }

    pause() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.pause()
      })
    }

    play() {
      Object.values(this.channelRefs).forEach((channelRef) => {
        if (channelRef) channelRef.play()
      })
    }

    /*applyState(state) {
    console.log(state);
    this.currentState = state;
    this.setTransition(state.transitionDuration);
    for (let property in state) {
      switch (property) {

        case 'margin':
        case 'padding':
        this.element.current.style[property] = state[property];
        break;

        default:
        break;
      }
    }
    this.updateLayout();
  }

  setTransition(secs) {
    //console.log('set transition: '+this.props.character.id+' '+secs);
    this.transition = secs;
    this.element.current.style.transition = `background-color ${secs}s, margin ${secs}s, padding ${secs}s, filter ${secs}s, top ${secs}s`;
  }*/

    handleStep(event) {
      var right, left, top, bottom, temp, seams, aspectRatio
      var step = event.detail
      switch (step.command) {
        case 'setGrid':
          temp = step.content.split(' ')
          this.setGrid(temp[0], temp[1])
          this.setState({ step: step })
          break

        case 'setLocation':
          var location = this.props.stepwise.score.getItemForId(
            'location',
            step.content
          )
          this.setState({ location: location })
          if (this.environmentPanel.current)
            this.environmentPanel.current.setLocation(location)
          break

        case 'enterFull':
          var amount = -2
          if (step.content === '') {
            right = left = top = bottom = true
          } else if (!isNaN(parseInt(step.content))) {
            right = left = top = bottom = true
            amount = parseInt(step.content)
          } else {
            right = left = top = bottom = false
            temp = step.content.split(' ')
            for (let direction of temp) {
              switch (direction) {
                case 'right':
                  right = true
                  break
                case 'left':
                  left = true
                  break
                case 'top':
                  top = true
                  break
                case 'bottom':
                  bottom = true
                  break
                default:
                  if (!isNaN(parseInt(direction))) {
                    amount = parseInt(direction)
                  }
                  break
              }
            }
          }
          seams = this.getFullSeams(right || left, top || bottom)
          if (seams.length === 0 && aspectRatio !== -1) {
            seams = this.getFullSeams(true, true)
          }
          this.buildEntranceTransitionFromSeams(
            step.target.id,
            seams,
            -1,
            right,
            left,
            top,
            bottom,
            amount
          )
          break

        case 'enterOnSeam':
          aspectRatio = -1
          if (step.content === '') {
            right = left = top = bottom = true
          } else if (!isNaN(parseFloat(step.content))) {
            right = left = top = bottom = true
            aspectRatio = parseFloat(step.content)
          } else {
            right = left = top = bottom = false
            temp = step.content.split(' ')
            for (let direction of temp) {
              switch (direction) {
                case 'right':
                  right = true
                  break
                case 'left':
                  left = true
                  break
                case 'top':
                  top = true
                  break
                case 'bottom':
                  bottom = true
                  break
                default:
                  if (!isNaN(parseFloat(direction))) {
                    aspectRatio = parseFloat(direction)
                  }
              }
            }
          }
          var cliqueCharacters = this.props.stepwise.score.getCliqueCharacters(
            step.target.id
          )
          seams = this.getSeams(right || left, top || bottom, cliqueCharacters)
          if (seams.length === 0 && aspectRatio !== -1) {
            seams = this.getSeams(true, true, cliqueCharacters)
          }
          this.buildEntranceTransitionFromSeams(
            step.target.id,
            seams,
            aspectRatio,
            right,
            left,
            top,
            bottom,
            -1
          )
          break

        default:
          break
      }
      if (step.target) {
        for (let panel of this.panelRefs) {
          if (panel.props.character.id === step.target.id) {
            panel.handleStep(step)
          }
        }
      }
    }

    handleClick() {
      this.props.onPanelSelect(null)
    }

    setGrid(columns, rows) {
      if (rows && columns) {
        this.grid = { rows: parseFloat(rows), columns: parseFloat(columns) }
        this.unit = {
          width: this.element.current.offsetWidth / parseFloat(columns),
          height: this.element.current.offsetHeight / parseFloat(rows),
        }
        this.subGrid = { left: 0, top: 1, width: 5, height: 3 }
      }
    }

    setEditLocation(location) {
      if (this.environmentPanel.current)
        this.environmentPanel.current.setEditLocation(location)
    }

    getVisibleCharacters() {
      var character,
        characters = []
      var i,
        n = this.props.stepwise.score.characters.length
      for (i = 0; i < n; i++) {
        character = this.props.stepwise.score.characters[i]
        if (character.visible) {
          characters.push(character)
        }
      }
      return characters
    }

    getGridFromVisibleCharacters() {
      var grid
      var visibleCharacters = this.getVisibleCharacters()
      if (visibleCharacters.length < 4) {
        grid = { rows: 1, columns: visibleCharacters.length }
      } else {
        var rowCount = Math.round(Math.sqrt(visibleCharacters.length))
        var colCount = Math.ceil(
          visibleCharacters.length / parseFloat(rowCount)
        )
        grid = { rows: rowCount, columns: colCount }
      }
      return grid
    }

    handleMediaRequest(mediaInfo) {
      setTimeout(() => this.setState({ mediaInfo: mediaInfo }), 250)
    }

    /*panelIsOnScreen(panel) {
    var xMin = panel.gridLayout.left;
		var xMax = xMin + panel.gridLayout.width;
		var yMin = panel.gridLayout.top;
		var yMax = yMin + panel.gridLayout.height;
		return (yMin < (this.subGrid.top + this.subGrid.height) && yMax > this.subGrid.top && xMin < (this.subGrid.left + this.subGrid.width) && xMax > this.subGrid.left);
  }*/

    createArray(length) {
      var args
      var arr = new Array(length || 0)
      var i = length
      if (arguments.length > 1) {
        args = Array.prototype.slice.call(arguments, 1)
        while (i--) {
          arr[length - 1 - i] = this.createArray.apply(this, args)
        }
      }
      return arr
    }

    getFullSeams(direction) {
      let includeHorizontal =
        direction.indexOf('left') !== -1 || direction.indexOf('right') !== -1
      let includeVertical =
        direction.indexOf('top') !== -1 || direction.indexOf('bottom') !== -1
      var xcoords = []
      var ycoords = []
      var seams = []
      var screenRect = new Rect(
        this.subGrid.left * this.unit.width,
        this.subGrid.top * this.unit.height,
        this.subGrid.width * this.unit.width,
        this.subGrid.height * this.unit.height
      )
      xcoords.push(screenRect.xMin)
      xcoords.push(screenRect.xMax)
      ycoords.push(screenRect.yMin)
      ycoords.push(screenRect.yMax)
      this.subrects = this.createArray(xcoords.length - 1, ycoords.length - 1)
      if (includeHorizontal) {
        seams.push(
          new Rect(screenRect.xMin, screenRect.yMin, screenRect.width, 1)
        )
      }
      if (includeVertical) {
        seams.push(
          new Rect(screenRect.xMin, screenRect.yMin, 1, screenRect.height)
        )
      }
      return seams
    }

    getSeams(includeVertical, includeHorizontal, cliqueCharacters) {
      if (!cliqueCharacters) {
        cliqueCharacters = []
      }
      var sourceRects = []
      var xcoords = []
      var ycoords = []
      var seams = []
      var panel, rect, psRect, psRectSource, noOverlappingPanels, v, i, n
      var screenRect = new Rect(
        this.subGrid.left * this.unit.width,
        this.subGrid.top * this.unit.height,
        this.subGrid.width * this.unit.width,
        this.subGrid.height * this.unit.height
      )
      n = this.panelRefs.length
      for (i = 0; i < n; i++) {
        panel = this.panelRefs[i]
        if (
          cliqueCharacters.length === 0 ||
          cliqueCharacters.indexOf(panel.props.character) !== -1
        ) {
          if (this.panelIsOnScreen(panel)) {
            sourceRects.push(
              new PanelSubRect(
                panel,
                new Rect(
                  panel.gridLayout.left * this.unit.width,
                  panel.gridLayout.top * this.unit.height,
                  panel.gridLayout.width * this.unit.width,
                  panel.gridLayout.height * this.unit.height
                )
              )
            )
          }
        }
      }
      xcoords.push(Math.round(screenRect.xMin))
      xcoords.push(Math.round(screenRect.xMax))
      ycoords.push(Math.round(screenRect.yMin))
      ycoords.push(Math.round(screenRect.yMax))

      // build a grid from all the unique x and y coords in the rects
      for (let psRect of sourceRects) {
        v = Math.round(
          Math.min(screenRect.xMax, Math.max(screenRect.xMin, psRect.rect.xMin))
        )
        if (xcoords.indexOf(v) === -1) {
          xcoords.push(v)
        }
        v = Math.round(
          Math.min(screenRect.xMax, Math.max(screenRect.xMin, psRect.rect.xMax))
        )
        if (xcoords.indexOf(v) === -1) {
          xcoords.push(v)
        }
        v = Math.round(
          Math.min(screenRect.yMax, Math.max(screenRect.yMin, psRect.rect.yMin))
        )
        if (ycoords.indexOf(v) === -1) {
          ycoords.push(v)
        }
        v = Math.round(
          Math.min(screenRect.yMax, Math.max(screenRect.yMin, psRect.rect.yMax))
        )
        if (ycoords.indexOf(v) === -1) {
          ycoords.push(v)
        }
      }
      xcoords.sort(function (a, b) {
        return a - b
      })
      ycoords.sort(function (a, b) {
        return a - b
      })
      this.subrects = this.createArray(xcoords.length - 1, ycoords.length - 1)

      n = Math.max(0, xcoords.length - 1)
      for (i = 0; i < n; i++) {
        var o = Math.max(0, ycoords.length - 1)
        for (var j = 0; j < o; j++) {
          // create a rect for each cell in the grid
          rect = new Rect(
            xcoords[i],
            ycoords[j],
            xcoords[i + 1] - xcoords[i],
            ycoords[j + 1] - ycoords[j]
          )
          psRect = new PanelSubRect(null, rect)
          // check the rect against all of the other source rects
          if (sourceRects.length > 0) {
            var p = sourceRects.length
            for (var k = 0; k < p; k++) {
              psRectSource = sourceRects[k]
              if (psRectSource.rect.contains(psRect.rect.center())) {
                psRect.panel = psRectSource.panel
              }
            }
          }
          this.subrects[i][j] = psRect
        }
      }

      if (includeHorizontal) {
        seams.push(new Rect(screenRect.x, screenRect.y, screenRect.width, 1))
      }
      if (includeVertical) {
        seams.push(new Rect(screenRect.x, screenRect.y, 1, screenRect.height))
      }

      // look for rows
      if (includeVertical && ycoords.length > 1) {
        n = Math.max(0, ycoords.length - 2)
        for (var y = 0; y < n; y++) {
          noOverlappingPanels = true
          if (this.subrects[0].length > 1) {
            o = Math.max(0, xcoords.length - 1)
            for (var x = 0; x < o; x++) {
              if (this.subrects[x][y].panel === this.subrects[x][y + 1].panel) {
                noOverlappingPanels = false
                break
              }
            }
          }
          if (noOverlappingPanels) {
            seams.push(new Rect(0, ycoords[y], 1, ycoords[y + 1] - ycoords[y]))
            // pick up the last straggler
            if (y === ycoords.length - 3) {
              seams.push(
                new Rect(0, ycoords[y + 1], 1, ycoords[y + 2] - ycoords[y + 1])
              )
            }
          }
        }
      }

      // look for columns
      if (includeHorizontal && xcoords.length > 1) {
        n = Math.max(0, xcoords.length - 2)
        for (x = 0; x < n; x++) {
          noOverlappingPanels = true
          if (this.subrects.length > 1) {
            o = Math.max(0, ycoords.length - 1)
            for (y = 0; y < o; y++) {
              if (this.subrects[x][y].panel === this.subrects[x + 1][y].panel) {
                noOverlappingPanels = false
                break
              }
            }
          }
          if (noOverlappingPanels) {
            seams.push(new Rect(xcoords[x], 0, xcoords[x + 1] - xcoords[x], 1))
            // pick up the last straggler
            if (x === xcoords.length - 3) {
              seams.push(
                new Rect(xcoords[x + 1], 0, xcoords[x + 2] - xcoords[x + 1], 1)
              )
            }
          }
        }
      }

      return seams
    }

    getChannelMarkup() {
      var channels = null
      if (this.props.stepwise) {
        this.channelRefs = {}
        channels = Object.values(this.props.stepwise.score.channels).map(
          (channel, index) => {
            return (
              <Channel
                key={channel.id}
                ref={(ref) => {
                  this.channelRefs[channel.id] = ref
                }}
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                channel={channel}
                onStepChange={this.props.onStepChange}
                onStepwiseEvent={this.props.onStepwiseEvent}
                charactersToIgnore={this.props.charactersToIgnore}
                replaceHistoryState={this.replaceHistoryState}
                onSaveActionToHistory={this.saveActionToHistory}
                isEditing={this.props.isEditing}
                editStep={this.props.editStep}
                isPreviewing={this.props.isPreviewing}
              />
            )
          }
        )
      }
      return channels
    }

    distributePanels(method) {
      Object.values(this.channelRefs).forEach((channelRef) => {
        channelRef.distributePanels(method)
      })
    }

    getPanelLayoutForCharacter(character) {
      let channel = character.channel
      let channelRef = this.channelRefs[channel.id]
      if (channelRef) {
        let panel = channelRef.panelRefs[character.id]
        let layout = {
          panel: panel,
          character: panel.props.character,
          grid: panel.props.grid,
          unit: panel.unit,
          gridLayout: panel.gridLayout,
          margins: panel.margins,
          element: panel.baseElement,
        }
        return layout
      }
      return null
    }

    getAllPanelLayouts() {
      let layouts = []
      Object.values(this.channelRefs).forEach((channelRef) => {
        Object.values(channelRef.panelRefs).forEach((panelRef) => {
          if (panelRef) {
            layouts.push({
              panel: panelRef,
              character: panelRef.props.character,
              grid: panelRef.props.grid,
              unit: panelRef.props.unit,
              gridLayout: panelRef.gridLayout,
              margins: panelRef.margins,
              element: panelRef.baseElement,
            })
          }
        })
      })
      return layouts
    }

    handleMouseMove = (evt) => {
      let dist = Math.max(
        Math.abs(evt.clientX - this.mousePos.x),
        Math.abs(evt.clientY - this.mousePos.y)
      )
      if (dist > 5 && this.props.isPreviewing && this.playBar.current) {
        this.playBar.current.fadeIn()
      }
      this.mousePos.x = evt.clientX
      this.mousePos.y = evt.clientY
    }

    reset = () => {
      this.props.stepwise.score.reset()
      this.resetLayout()
      this.resetContent()
      this.doUpdate()
      this.play()
    }

    render() {
      var className = 'stage'
      if (!this.props.visible) {
        className += ' hidden'
      }
      return (
        <div
          id="stage"
          className={className}
          ref={this.element}
          onMouseMove={this.handleMouseMove}
        >
          {this.getChannelMarkup()}
          {this.props.isEditing && !this.props.isPreviewing ? (
            <EditorHighlights
              ref={this.highlightsRef}
              stepwise={this.props.stepwise}
              sequence={this.props.sequence}
              editStep={this.props.editStep}
              editAction={this.props.editAction}
              editCharacter={this.props.editCharacter}
              charactersEnabled={this.props.charactersEnabled}
              onStepChange={this.props.onStepChange}
              onActionChange={this.props.onActionChange}
              onCharacterChange={this.props.onCharacterChange}
              onLayoutUpdateNeeded={this.updateLayout.bind(this)}
              onAllLayoutsNeeded={this.getAllPanelLayouts.bind(this)}
              channelRefs={this.channelRefs}
            />
          ) : null}
          {this.props.isPreviewing ? (
            <PlayBar
              ref={this.playBar}
              onReset={this.reset}
              stepwise={this.props.stepwise}
              sequence={this.props.sequence}
            />
          ) : null}
        </div>
      )
    }
  }
)

export default Stage
