import React from 'react'
import { Route, Router } from 'react-router-dom'
import App from './App.js'
import Activate from './Activate'
import GetCode from './GetCode'
import Stories from './Stories'
import Patreon from './Patreon'
import Logo from './Logo'
import Raw from './Raw'
import history from './history'

const Routes = () => (
  <Router history={history} component={App}>
    <Route exact path="/" render={(props) => <App mode="play" {...props} />} />
    <Route path="/edit" render={(props) => <App mode="edit" {...props} />} />
    <Route path="/raw" render={(props) => <Raw {...props} />} />
    <Route path="/activate" render={(props) => <Activate {...props} />} />
    <Route path="/getcode" render={(props) => <GetCode {...props} />} />
    <Route path="/stories" render={(props) => <Stories {...props} />} />
    <Route path="/patreon" render={(props) => <Patreon {...props} />} />
    <Route path="/logo" render={(props) => <Logo {...props} />} />
  </Router>
)

export default Routes
