import React from 'react'
import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Popover,
  Alignment,
  Switch,
  ButtonGroup,
  ControlGroup,
  NumericInput,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { SketchPicker } from 'react-color'

const TextStateEditor = observer(
  class TextStateEditor extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 60
      this.overflowOptions = [
        { label: 'Visible', value: 'visible' },
        { label: 'Hidden', value: 'hidden' },
        { label: 'Scroll', value: 'scroll' },
        { label: 'Auto', value: 'auto' },
      ]
      this.fontSizeOptions = [
        { label: 'pixels', value: 'px' },
        { label: 'points', value: 'pt' },
        { label: '% of window width', value: 'vw' },
        { label: '% of window height', value: 'vh' },
        { label: '% of window’s short side', value: 'vmin' },
        { label: '% of window’s long side', value: 'vmax' },
      ]
      this.layoutOptions = [
        { label: 'Standard', value: 'standard' },
        { label: 'Slabtype', value: 'slabtype' },
      ]
      this.transformOptions = [
        { label: 'Normal', value: 'none' },
        { label: 'Capitalize', value: 'capitalize' },
        { label: 'Uppercase', value: 'uppercase' },
        { label: 'Lowercase', value: 'lowercase' },
      ]
      this.whiteSpaceOptions = [
        { label: 'Normal', value: 'normal' },
        { label: 'Nowrap', value: 'nowrap' },
        { label: 'Pre', value: 'pre' },
        { label: 'Pre-Line', value: 'pre-line' },
        { label: 'Pre-Wrap', value: 'pre-wrap' },
      ]
      this.updateFontOptions()
    }

    handlePropertyChange(evt, property, value) {
      let propValue = value || evt.currentTarget.value
      let temp, alignment
      switch (property) {
        case 'alignHorz':
          temp = this.props.state.align.split(' ')
          temp[0] = value
          alignment = temp.join(' ')
          this.props.state.align = alignment
          break

        case 'alignVert':
          temp = this.props.state.align.split(' ')
          temp[1] = value
          alignment = temp.join(' ')
          this.props.state.align = alignment
          break

        case 'fontWeight':
          if (this.props.state.fontWeight === 'bold') {
            this.props.state.fontWeight = 'normal'
          } else {
            this.props.state.fontWeight = 'bold'
          }
          break

        case 'fontStyle':
          if (this.props.state.fontStyle === 'italic') {
            this.props.state.fontStyle = 'normal'
          } else {
            this.props.state.fontStyle = 'italic'
          }
          break

        case 'fontSizeUnits':
          let fontSize = parseFloat(this.props.state.fontSize).toString()
          this.props.state.fontSize = fontSize + propValue
          break

        default:
          this.props.state[property] = propValue
          break
      }
      this.props.onStepwiseEvent('state', this.props.state)
    }

    updateFontOptions() {
      this.fontOptions = window.fontNames.map((fontName, index) => {
        return { label: fontName, value: fontName }
      })
      this.fontOptions.unshift({ label: 'Choose font...', value: 'none' })
    }

    handleColorChange(property, color) {
      switch (property) {
        case 'textShadowColor':
          let props = this.getShadowProperties(this.props.state.textShadow)
          props.color =
            'rgba(' +
            color.rgb.r +
            ',' +
            color.rgb.g +
            ',' +
            color.rgb.b +
            ',' +
            color.rgb.a +
            ')'
          this.props.state.textShadow =
            props.offsetX +
            'px ' +
            props.offsetY +
            'px ' +
            props.blur +
            'px ' +
            props.color
          break

        default:
          this.props.state[property] =
            'rgba(' +
            color.rgb.r +
            ',' +
            color.rgb.g +
            ',' +
            color.rgb.b +
            ',' +
            color.rgb.a +
            ')'
          break
      }
      this.props.onStepwiseEvent('state', this.props.state)
    }

    getAlignment(alignStr, direction) {
      var temp = alignStr.split(' ')
      if (direction === 'horizontal') {
        return temp[0]
      } else if (direction === 'vertical') {
        if (temp.length === 2) {
          return temp[1]
        } else {
          return temp[0]
        }
      }
      return temp[0]
    }

    handleNumericChange(command, value, property) {
      let temp
      switch (command) {
        case 'fontSize':
          this.props.state.fontSize = value + this.getFontSizeUnits()
          break

        case 'lineHeight':
          this.props.state.lineHeight = value * 100 + '%'
          break

        case 'textShadow':
          let props = this.getShadowProperties(this.props.state.textShadow)
          switch (property) {
            case 'offsetX':
              props.offsetX = value
              break
            case 'offsetY':
              props.offsetY = value
              break
            case 'blur':
              props.blur = value
              break
            case 'color':
              props.color = value
              break
            default:
              break
          }
          this.props.state.textShadow =
            props.offsetX +
            'px ' +
            props.offsetY +
            'px ' +
            props.blur +
            'px ' +
            props.color
          break

        case 'margin':
          let margin = this.props.state.margin
          temp = margin.split(' ')
          if (temp.length === 1) {
            temp.push(temp[0])
            temp.push(temp[0])
            temp.push(temp[0])
          } else if (temp.length === 2) {
            temp.splice(1, null, temp[0])
            temp.push(temp[2])
          }
          switch (property) {
            case 'top':
              temp[0] = parseInt(value)
              break
            case 'right':
              temp[1] = parseInt(value)
              break
            case 'bottom':
              temp[2] = parseInt(value)
              break
            case 'left':
              temp[3] = parseInt(value)
              break
            default:
              break
          }
          margin =
            parseInt(temp[0]) +
            'px ' +
            parseInt(temp[1]) +
            'px ' +
            parseInt(temp[2]) +
            'px ' +
            parseInt(temp[3]) +
            'px'
          this.props.state.margin = margin
          break

        case 'padding':
          let padding = this.props.state.padding
          temp = padding.split(' ')
          if (temp.length === 1) {
            temp.push(temp[0])
            temp.push(temp[0])
            temp.push(temp[0])
          } else if (temp.length === 2) {
            temp.splice(1, null, temp[0])
            temp.push(temp[2])
          }
          switch (property) {
            case 'top':
              temp[0] = parseInt(value)
              break
            case 'right':
              temp[1] = parseInt(value)
              break
            case 'bottom':
              temp[2] = parseInt(value)
              break
            case 'left':
              temp[3] = parseInt(value)
              break
            default:
              break
          }
          padding =
            parseInt(temp[0]) +
            'px ' +
            parseInt(temp[1]) +
            'px ' +
            parseInt(temp[2]) +
            'px ' +
            parseInt(temp[3]) +
            'px'
          this.props.state.padding = padding
          break

        default:
          break
      }
      this.props.onStepwiseEvent('state', this.props.state)
    }

    getFontSizeUnits() {
      let fontSize = parseFloat(this.props.state.fontSize).toString()
      let units = this.props.state.fontSize.substr(fontSize.length)
      return units
    }

    getShadowProperty(shadowStr, property) {
      let props = this.getShadowProperties(shadowStr)
      return props[property]
    }

    getShadowProperties(shadowStr) {
      if (!shadowStr) shadowStr = '0 0 0 black'
      let temp = shadowStr.split(' ')
      let props = {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        color: 'black',
      }
      if (temp.length > 0) props.offsetX = parseInt(temp[0])
      if (temp.length > 1) props.offsetY = parseInt(temp[1])
      if (temp.length > 2) props.blur = parseInt(temp[2])
      if (temp.length > 3) props.color = temp[3]
      return props
    }

    handleSwitchChange(evt, property) {
      this.props.state[property] = evt.currentTarget.checked
    }

    getStyleSettings() {
      let textShadowBackgroundColor = this.getShadowProperty(
        this.props.state.textShadow,
        'color'
      )
      return (
        <React.Fragment>
          <FormGroup>
            <HTMLSelect
              id="font-select"
              options={this.fontOptions}
              value={this.props.state.font}
              onChange={(evt) => this.handlePropertyChange(evt, 'font')}
            />
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <FormGroup>
            <NumericInput
              style={{ width: '50px' }}
              value={
                isNaN(parseFloat(this.props.state.fontSize))
                  ? 0
                  : parseFloat(this.props.state.fontSize)
              }
              onValueChange={(value) =>
                this.handleNumericChange('fontSize', value)
              }
            />
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <FormGroup>
            <ButtonGroup>
              <Button
                active={this.props.state.fontWeight === 'bold'}
                icon="bold"
                onClick={(evt) =>
                  this.handlePropertyChange(evt, 'fontWeight', 'bold')
                }
              />
              <Button
                active={this.props.state.fontStyle === 'italic'}
                icon="italic"
                onClick={(evt) =>
                  this.handlePropertyChange(evt, 'fontStyle', 'italic')
                }
              />
            </ButtonGroup>
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <FormGroup inline={true}>
            <Popover>
              <div className="swatch">
                <div
                  className="color"
                  style={{ backgroundColor: this.props.state.color }}
                />
              </div>
              <SketchPicker
                color={window.getRGBAColor(this.props.state.color)}
                onChangeComplete={(color) =>
                  this.handleColorChange('color', color)
                }
              />
            </Popover>
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <Popover>
            <Button minimal="true">Style</Button>
            <div className="text-settings">
              <div className="form-col">
                <FormGroup
                  label="Size"
                  labelFor="font-size-input"
                  inline={true}
                >
                  <ControlGroup id="padding-input">
                    <NumericInput
                      id="font-size-input"
                      style={{ width: '50px' }}
                      value={
                        isNaN(parseFloat(this.props.state.fontSize))
                          ? 0
                          : parseFloat(this.props.state.fontSize)
                      }
                      onValueChange={(value) =>
                        this.handleNumericChange('fontSize', value)
                      }
                    />
                    <HTMLSelect
                      id="font-units-select"
                      options={this.fontSizeOptions}
                      value={this.getFontSizeUnits()}
                      onChange={(evt) =>
                        this.handlePropertyChange(evt, 'fontSizeUnits')
                      }
                    />
                  </ControlGroup>
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup inline={true}>
                  <ButtonGroup id="font-style-select">
                    <Button
                      active={this.props.state.fontWeight === 'bold'}
                      icon="bold"
                      onClick={(evt) =>
                        this.handlePropertyChange(evt, 'fontWeight', 'bold')
                      }
                    />
                    <Button
                      active={this.props.state.fontStyle === 'italic'}
                      icon="italic"
                      onClick={(evt) =>
                        this.handlePropertyChange(evt, 'fontStyle', 'italic')
                      }
                    />
                  </ButtonGroup>
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup inline={true}>
                  <ButtonGroup>
                    <Button
                      active={this.props.state.textAlign === 'left'}
                      icon="alignment-left"
                      onClick={(evt) =>
                        this.handlePropertyChange(evt, 'textAlign', 'left')
                      }
                    />
                    <Button
                      active={this.props.state.textAlign === 'center'}
                      icon="alignment-vertical-center"
                      onClick={(evt) =>
                        this.handlePropertyChange(evt, 'textAlign', 'center')
                      }
                    />
                    <Button
                      active={this.props.state.textAlign === 'right'}
                      icon="alignment-right"
                      onClick={(evt) =>
                        this.handlePropertyChange(evt, 'textAlign', 'right')
                      }
                    />
                  </ButtonGroup>
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup inline={true}>
                  <HTMLSelect
                    id="transform-select"
                    className="margin-adjust"
                    options={this.transformOptions}
                    value={this.props.state.textTransform}
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'textTransform')
                    }
                  />
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup label="Text" labelFor="font-color" inline={true}>
                  <Popover id="font-color">
                    <div className="swatch">
                      <div
                        className="color"
                        style={{ backgroundColor: this.props.state.color }}
                      />
                    </div>
                    <SketchPicker
                      color={window.getRGBAColor(this.props.state.color)}
                      onChangeComplete={(color) =>
                        this.handleColorChange('color', color)
                      }
                    />
                  </Popover>
                </FormGroup>
              </div>
              <div className="form-col">
                <FormGroup
                  label="Background"
                  labelFor="background-color"
                  inline={true}
                >
                  <Popover id="background-color">
                    <div className="swatch">
                      <div
                        className="color"
                        style={{
                          backgroundColor: this.props.state.backgroundColor,
                        }}
                      />
                    </div>
                    <SketchPicker
                      color={window.getRGBAColor(
                        this.props.state.backgroundColor
                      )}
                      onChangeComplete={(color) =>
                        this.handleColorChange('backgroundColor', color)
                      }
                    />
                  </Popover>
                </FormGroup>
              </div>
              <FormGroup
                label="Shadow"
                labelFor="shadow-input"
                helperText="Offset x, offset y, blur"
              >
                <ControlGroup>
                  <NumericInput
                    id="shadow-offset-x-input"
                    style={{ width: this.fieldWidth }}
                    value={this.getShadowProperty(
                      this.props.state.shadow,
                      'offset-x'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('textShadow', value, 'offsetX')
                    }
                  />
                  <NumericInput
                    id="shadow-offset-y-input"
                    style={{ width: this.fieldWidth }}
                    value={this.getShadowProperty(
                      this.props.state.shadow,
                      'offset-y'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('textShadow', value, 'offsetY')
                    }
                  />
                  <NumericInput
                    id="shadow-blur-input"
                    style={{ width: this.fieldWidth }}
                    value={this.getShadowProperty(
                      this.props.state.shadow,
                      'blur'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('textShadow', value, 'blur')
                    }
                  />
                  <Popover id="text-shadow-color">
                    <div className="swatch">
                      <div
                        className="color"
                        style={{ backgroundColor: textShadowBackgroundColor }}
                      />
                    </div>
                    <SketchPicker
                      color={textShadowBackgroundColor}
                      onChangeComplete={(color) =>
                        this.handleColorChange('textShadowColor', color)
                      }
                    />
                  </Popover>
                </ControlGroup>
              </FormGroup>
            </div>
          </Popover>
        </React.Fragment>
      )
    }

    getLayoutSettings() {
      return (
        <Popover>
          <Button minimal="true">Layout</Button>
          <div className="state-settings">
            {/*<div className="form-col">
          <FormGroup inline={true}>
            <HTMLSelect id="layout-select"
              className="margin-adjust"
              options={this.layoutOptions}
              value={this.props.state.layout}
              onChange={(evt) => this.handlePropertyChange(evt, 'layout')}/>
          </FormGroup>
        </div>*/}
            <div className="form-col">
              <FormGroup inline={true}>
                <ButtonGroup>
                  <Button
                    active={
                      this.getAlignment(
                        this.props.state.align,
                        'horizontal'
                      ) === 'left'
                    }
                    icon="alignment-left"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignHorz', 'left')
                    }
                  />
                  <Button
                    active={
                      this.getAlignment(
                        this.props.state.align,
                        'horizontal'
                      ) === 'center'
                    }
                    icon="alignment-vertical-center"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignHorz', 'center')
                    }
                  />
                  <Button
                    active={
                      this.getAlignment(
                        this.props.state.align,
                        'horizontal'
                      ) === 'right'
                    }
                    icon="alignment-right"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignHorz', 'right')
                    }
                  />
                </ButtonGroup>
                &nbsp;&nbsp;
                <ButtonGroup>
                  <Button
                    active={
                      this.getAlignment(this.props.state.align, 'vertical') ===
                      'top'
                    }
                    icon="alignment-top"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignVert', 'top')
                    }
                  />
                  <Button
                    active={
                      this.getAlignment(this.props.state.align, 'vertical') ===
                      'center'
                    }
                    icon="alignment-horizontal-center"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignVert', 'center')
                    }
                  />
                  <Button
                    active={
                      this.getAlignment(this.props.state.align, 'vertical') ===
                      'bottom'
                    }
                    icon="alignment-bottom"
                    onClick={(evt) =>
                      this.handlePropertyChange(evt, 'alignVert', 'bottom')
                    }
                  />
                </ButtonGroup>
              </FormGroup>
            </div>
            <FormGroup
              label="Line height"
              labelFor="line-height-input"
              inline={true}
            >
              <NumericInput
                id="line-height-input"
                style={{ width: this.fieldWidth }}
                value={
                  Math.round(parseInt(this.props.state.lineHeight) * 0.1) * 0.1
                }
                stepSize={0.1}
                minorStepSize={0.01}
                majorStepSize={1}
                onValueChange={(value) =>
                  this.handleNumericChange('lineHeight', value)
                }
              />
            </FormGroup>
            <FormGroup
              label="Margins"
              labelFor="margin-input"
              helperText="Left, top, right, bottom"
            >
              <ControlGroup id="margin-input">
                <NumericInput
                  id="margin-left-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.margin,
                    'left'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('margin', value, 'left')
                  }
                />
                <NumericInput
                  id="margin-top-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.margin,
                    'top'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('margin', value, 'top')
                  }
                />
                <NumericInput
                  id="margin-right-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.margin,
                    'right'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('margin', value, 'right')
                  }
                />
                <NumericInput
                  id="margin-bottom-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.margin,
                    'bottom'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('margin', value, 'bottom')
                  }
                />
              </ControlGroup>
            </FormGroup>
            <FormGroup
              label="Padding"
              labelFor="padding-input"
              helperText="Left, top, right, bottom"
            >
              <ControlGroup id="padding-input">
                <NumericInput
                  id="padding-left-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.padding,
                    'left'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('padding', value, 'left')
                  }
                />
                <NumericInput
                  id="padding-top-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.padding,
                    'top'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('padding', value, 'top')
                  }
                />
                <NumericInput
                  id="padding-right-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.padding,
                    'right'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('padding', value, 'right')
                  }
                />
                <NumericInput
                  id="padding-bottom-input"
                  style={{ width: this.fieldWidth }}
                  value={window.getMarginComponent(
                    this.props.state.padding,
                    'bottom'
                  )}
                  onValueChange={(value) =>
                    this.handleNumericChange('padding', value, 'bottom')
                  }
                />
              </ControlGroup>
            </FormGroup>
          </div>
        </Popover>
      )
    }

    getAdvancedSettings() {
      return (
        <Popover>
          <Button minimal="true">More...</Button>
          <div className="text-settings">
            <div className="form-col">
              <FormGroup label="Width" labelFor="width-input" inline={true}>
                <InputGroup
                  id="width-input"
                  style={{ width: '50px' }}
                  value={this.props.state.width}
                  placeholder="Width"
                  onChange={(evt) => this.handlePropertyChange(evt, 'width')}
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Height" labelFor="height-input" inline={true}>
                <InputGroup
                  id="height-input"
                  style={{ width: '50px' }}
                  value={this.props.state.height}
                  placeholder="Height"
                  onChange={(evt) => this.handlePropertyChange(evt, 'height')}
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup
                label="Overflow"
                labelFor="overflow-select"
                inline={true}
              >
                <HTMLSelect
                  id="overflow-select"
                  options={this.overflowOptions}
                  value={this.props.state.overflow}
                  onChange={(evt) => this.handlePropertyChange(evt, 'overflow')}
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup
                label="White space"
                labelFor="white-space-select"
                inline={true}
              >
                <HTMLSelect
                  id="white-space-select"
                  options={this.whiteSpaceOptions}
                  value={this.props.state.whiteSpace}
                  onChange={(evt) =>
                    this.handlePropertyChange(evt, 'whiteSpace')
                  }
                />
              </FormGroup>
            </div>
            <FormGroup
              label="Transform"
              labelFor="transform-input"
              inline={true}
            >
              <InputGroup
                id="transform-input"
                value={this.props.state.transform}
                placeholder="CSS transform"
                onChange={(evt) => this.handlePropertyChange(evt, 'transform')}
              />
            </FormGroup>
            <FormGroup
              label="Show quotation marks"
              labelFor="quotation-switch"
              inline={true}
            >
              <Switch
                id="quotation-switch"
                checked={this.props.state.quotationMarks}
                alignIndicator={Alignment.LEFT}
                onChange={(evt) =>
                  this.handleSwitchChange(evt, 'quotationMarks')
                }
                inline="true"
              />
            </FormGroup>
            <FormGroup
              label="Show character name"
              labelFor="name-switch"
              inline={true}
            >
              <Switch
                id="name-switch"
                checked={this.props.state.characterName}
                alignIndicator={Alignment.LEFT}
                onChange={(evt) =>
                  this.handleSwitchChange(evt, 'characterName')
                }
                inline="true"
              />
            </FormGroup>
          </div>
        </Popover>
      )
    }

    render() {
      return (
        <div className="state-editor-controls">
          {this.getStyleSettings()}
          {this.getLayoutSettings()}
          {this.getAdvancedSettings()}
        </div>
      )
    }
  }
)

export default TextStateEditor
