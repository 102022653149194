import React from 'react'
import ReactPlayer from 'react-player'
import ReactDOM from 'react-dom'
import TWEEN from '@tweenjs/tween.js'
import MediaCaption from './MediaCaption.js'
//import { isMobile } from  'react-device-detect';

class VideoFeature extends React.Component {
  constructor(props) {
    super(props)
    this.animate = this.animate.bind(this)
    this.animateFade = this.animateFade.bind(this)
    this.video = null
    //this.audio = null;
    this.objectFit = 'cover'
    this.element = React.createRef()
    this.video = React.createRef()
    this.player = React.createRef()
    this.currentVideo = null
    this.playing = false
    this.isMuted = false
    this.isAnimating = false
    this.volume = 1
    this.loop = false
    this.hasBlurred = true
    this.isFading = false
    this.maxVolumeForCurrentMedia = 1
    this.playbackRate = 1
    this.seekTime = 0
    this.currentState = this.props.feature.defaultState
  }

  componentDidMount() {
    this.applyState(this.currentState)
  }

  shouldComponentUpdate() {
    return false
  }

  handleStoryUpdate() {
    this.forceUpdate()
  }

  applyState(state) {
    this.currentState = state
    for (let property in state) {
      switch (property) {
        case 'filter':
          this.element.current.style[property] = state[property]
          break

        default:
          break
      }
    }
    this.setTransition(state.transitionDuration)
  }

  animateFade(time) {
    if (this.isFading) requestAnimationFrame(this.animateFade)
    TWEEN.update(time)
  }

  doAction(action) {
    switch (action.command) {
      case 'set-video-volume':
        if (this.volumeTween) {
          this.volumeTween.stop()
        }
        if (action.duration > 0) {
          var data = { volume: this.volume } // is this redundant?
          this.volumeTween = new TWEEN.Tween(data)
            .to({ volume: action.volume }, action.duration * 1000)
            .onUpdate(() => {
              this.volume = data.volume
              this.forceUpdate()
            })
            .start()
        } else {
          this.volume = action.volume
        }
        break

      case 'show-video':
        this.setVideo(
          this.props.stepwise.score.getMedia(action.content),
          action.volume,
          action.loop,
          action.playbackRate,
          action.startTime
        )
        if (action.solo) {
          this.props.onSolo(this.props.feature.parentCharacter.id)
        }
        break

      case 'stop-video':
        this.pause()
        break

      case 'clear-visuals':
      case 'clear-video':
        this.setVideo(null)
        break

      default:
        break
    }
  }

  getActions() {
    return []
  }

  setTransition(secs) {
    //console.log('set transition '+secs);
    this.transition = secs
  }

  setVideo(media, volume = 1, loop = false, playbackRate = 1, startTime = 0) {
    this.volume = volume
    this.loop = loop
    this.playbackRate = playbackRate
    this.seekTime = startTime
    if (this.currentVideo !== media) {
      let video = this.video.current
      let playerElement = ReactDOM.findDOMNode(this.player.current)
      if (media) {
        /*if (this.currentState) {
          this.maxVolumeForCurrentMedia = this.currentState.volume;
        }*/
        if (media.source === 'webcam') {
          if (navigator.mediaDevices) {
            if (navigator.mediaDevices.getUserMedia) {
              navigator.mediaDevices
                .getUserMedia({
                  video: {
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                  },
                })
                .then((stream) => {
                  console.log(stream)
                  video.srcObject = stream
                  video.play()
                })
                .catch(function (err0r) {
                  console.log('Something went wrong!')
                })
            }
            video.autoPlay = true
            video.currentTime = 0
            video.style.objectFit = this.objectFit
            video.play()
            this.video.current.classList.remove('hidden')
            playerElement.classList.add('hidden')
          } else {
            console.log(
              'Can’t access navigator.mediaDevices. Is this a secure URL?'
            )
          }
        } else {
          this.playerURL = media.source
          this.playing = this.props.isPreviewing
          this.isMuted = false
          if (
            this.playerURL.indexOf('twitch.tv') !== -1 ||
            this.playerURL.indexOf('streamable.com') !== -1
          ) {
            var listener = window.addEventListener('blur', () => {
              if (document.activeElement.src) {
                if (
                  document.activeElement.src.indexOf('twitch.tv') !== -1 ||
                  document.activeElement.src.indexOf('streamable.com') !== -1
                ) {
                  this.hasBlurred = true
                  setTimeout(() => {
                    window.focus()
                  }, 10)
                }
              }
              window.removeEventListener('blur', listener)
            })
            this.hasBlurred = false
          } else {
            this.hasBlurred = true
          }
          this.video.current.classList.add('hidden')
          playerElement.classList.remove('hidden')
        }
        /*this.isMuted = false;
        this.playing = true;
        this.forceUpdate();*/
        this.currentVideo = media
        setTimeout(() => this.updateLayout(), 1)
      } else {
        this.playerURL = null
        this.currentVideo = null
        this.video.current.srcObject = null
      }
      this.forceUpdate()
    }
    /*} else if (media) {
      // if on mobile, interpret any attempt to lower volume as a command to stop playing
      // since we can't control volume programmatically
      if (isMobile && this.currentState.volume < this.maxVolumeForCurrentMedia) {
        this.playing = false;
      }
    }*/
    /*if (this.player.current.player)*/ //this.forceUpdate();
  }

  mute() {
    //console.log('mute '+this.props.feature.id);
    //this.playing = false;
    this.volume = 0
    this.isMuted = true
    if (this.player.current.player) this.forceUpdate()
  }

  togglePlayPause() {
    console.log('toggle')
    if (!this.isMuted) {
      console.log('yes toggle')
      this.playing = !this.playing
      if (this.playing) this.playerIsMuted = false
      if (this.player.current.player) this.forceUpdate()
    }
  }

  pause() {
    this.playing = false
    if (this.player.current.player) this.forceUpdate()
  }

  play() {
    this.playing = true
    if (this.player.current.player) this.forceUpdate()
  }

  animate(time) {
    if (this.isAnimating) requestAnimationFrame(this.animate)
    TWEEN.update(time)
  }

  animateScroll(element) {
    var val = { t: 0 }
    if (this.tween) this.tween.stop()
    this.tween = new TWEEN.Tween(val)
      .to({ t: 1 }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        let outerWidth = parseFloat(this.element.current.offsetWidth)
        let outerHeight = parseFloat(this.element.current.offsetHeight)
        let outerAspectRatio = outerWidth / outerHeight
        let mediaAspectRatio = 1
        if (this.currentVideo)
          mediaAspectRatio = this.currentVideo.width / this.currentVideo.height
        let h, w
        if (this.currentState.fit === 'cover') {
          if (outerAspectRatio < mediaAspectRatio) {
            h = outerHeight
            w = h * mediaAspectRatio
            this.playerChild.style.width = w + 'px'
            this.playerChild.style.height = h + 'px'
            element.scrollLeft = (outerWidth - w) * -0.5
            element.scrollTop = 0
          } else {
            w = outerWidth
            h = w * (1 / mediaAspectRatio)
            this.playerChild.style.width = w + 'px'
            this.playerChild.style.height = h + 'px'
            this.playerChild.style.height = h + 'px'
            this.playerChild.style.marginTop = 0
            this.playerChild.style.marginLeft = 0
            element.scrollLeft = 0
            element.scrollTop = (outerHeight - h) * -0.5
          }
        } else if (this.currentState.fit === 'contain') {
          if (outerAspectRatio > mediaAspectRatio) {
            h = outerHeight
            w = h * mediaAspectRatio
            this.playerChild.style.width = w + 'px'
            this.playerChild.style.height = h + 'px'
            this.playerChild.style.marginLeft = (outerWidth - w) * 0.5 + 'px'
            this.playerChild.style.marginTop = 0
            element.scrollLeft = 0
            element.scrollTop = 0
          } else {
            w = outerWidth
            h = w * (1 / mediaAspectRatio)
            this.playerChild.style.width = w + 'px'
            this.playerChild.style.height = h + 'px'
            this.playerChild.style.marginTop = (outerHeight - h) * 0.5 + 'px'
            this.playerChild.style.marginLeft = 0
            element.scrollLeft = 0
            element.scrollTop = 0
          }
        }
      })
      .onComplete(() => {
        this.isAnimating = false
      })
      .start()
    this.isAnimating = true
    requestAnimationFrame(this.animate)
  }

  updateLayout(unit) {
    if (unit) this.unit = unit
    if (this.currentVideo) {
      if (this.currentVideo.source !== 'webcam') {
        let playerElement = ReactDOM.findDOMNode(this.player.current)
        if (playerElement) {
          this.playerChild = playerElement.firstChild
          if (this.playerChild) {
            this.animateScroll(playerElement)
          }
        }
      }
    }
  }

  resetContent() {
    this.setVideo(null)
  }

  handleStep(step) {
    var video
    if (step) {
      switch (step.command) {
        case 'pause':
          if (this.video) this.video.pause()
          //if (this.audio) this.audio.pause();
          break

        case 'play':
          if (this.video) this.video.play()
          //if (this.audio) this.audio.play();
          break

        case 'seekTo':
          var time = parseFloat(step.content)
          if (this.video && !isNaN(video.duration)) {
            if (step.content === 'random') {
              this.video.currentTime = Math.random() * this.video.duration
            } else if (!isNaN(time)) {
              this.video.currentTime = time
            }
          }
          /*if (audio) {
          if (step.content === 'random') {
            audio.currentTime = Math.random() * audio.duration;
          } else if (!isNaN(time)) {
            audio.currentTime = time;
          }
        }*/
          break

        default:
          break
      }
    }
  }

  handleVideoPlay = () => {
    //console.log('video play',this.seekTime);
    if (this.seekTime !== 0) {
      this.player.current.seekTo(this.seekTime)
      this.seekTime = 0
    }
  }

  handleVideoReady = () => {
    //console.log('video ready',this.seekTime);
    this.updateLayout()
  }

  render() {
    let className = 'panel-video'
    if (this.playerURL) {
      if (this.playerURL.indexOf('twitch.tv') === -1) {
        className += ' disable-interaction'
      } else if (this.hasBlurred) {
        className += ' disable-interaction'
      }
    } else {
      className += ' disable-interaction'
    }
    let showCaption = this.currentState ? this.currentState.showCaption : false
    return (
      <div className={className} ref={this.element}>
        <video className="webcam hidden" ref={this.video} />
        <ReactPlayer
          className="hidden"
          ref={this.player}
          url={this.playerURL}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                rel: 0,
                modestbranding: 1,
                iv_load_policy: 3,
                disablekb: 1,
              },
            },
          }}
          playing={this.playing}
          playbackRate={this.playbackRate}
          playsinline={true}
          volume={this.isMuted ? 0 : this.volume}
          style={{ overflow: 'scroll' }}
          width="100%"
          height="100%"
          onPlay={this.handleVideoPlay}
          onReady={this.handleVideoReady}
          loop={this.loop}
        />
        {showCaption ? (
          <MediaCaption
            media={this.currentVideo}
            analyticsId={this.props.stepwise.score.analyticsId}
            isEditing={this.props.isEditing}
          />
        ) : null}
      </div>
    )
  }
}

export default VideoFeature
