import React from 'react'
import { Icon } from '@blueprintjs/core'
import { observer } from 'mobx-react'

const ComboThumbnail = observer(
  class ComboThumbnail extends React.Component {
    constructor(props) {
      super(props)
      this.handleClick = this.handleClick.bind(this)
    }

    handleClick(evt) {
      let stepIndex = parseInt(evt.currentTarget.getAttribute('data-index'))
      let step = this.props.sequence.steps[stepIndex]
      this.props.onStepChange(step)
      this.forceUpdate()
    }

    getVisualMediaFromActions() {
      let n = this.props.actions.length
      for (let i = 0; i < n; i++) {
        switch (this.props.actions[i].command) {
          case 'show-image':
          case 'show-video':
            return this.props.stepwise.score.getMedia(
              this.props.actions[i].content
            )
          case 'set-video-volume':
            return true
          default:
            break
        }
      }
      return null
    }

    getAudioMediaFromActions() {
      let n = this.props.actions.length
      for (let i = 0; i < n; i++) {
        switch (this.props.actions[i].command) {
          case 'play-audio':
            return this.props.stepwise.score.getMedia(
              this.props.actions[i].content
            )
          case 'set-audio-volume':
            return true
          default:
            break
        }
      }
      return null
    }

    getTextFromActions() {
      let text = ''
      let n = this.props.actions.length
      for (let i = 0; i < n; i++) {
        if (this.props.actions[i].command === 'speak') {
          text += this.props.actions[i].content
        }
      }
      return text
    }

    getMusicalActions() {
      let actions = []
      let n = this.props.actions.length
      for (let i = 0; i < n; i++) {
        switch (this.props.actions[i].command) {
          case 'play-note':
          case 'play-phrase':
          case 'play-beat':
            actions.push(this.props.actions[i])
            break
          default:
            break
        }
      }
      return actions
    }

    getSummaryForMusicalActions(actions) {
      if (actions.length > 1) {
        return <Icon icon="music" />
      } else if (actions.length === 1) {
        switch (actions[0].command) {
          case 'play-note':
          case 'play-phrase':
            return actions[0].content

          case 'play-beat':
            return <Icon icon="music" />

          default:
            break
        }
      }
      return null
    }

    render() {
      let media = this.getVisualMediaFromActions()
      let audio = this.getAudioMediaFromActions()
      let music = this.getMusicalActions()
      let text = this.getTextFromActions()
      if (text.trim() !== '' && this.props.compact) {
        text = <Icon icon="font" />
      } else if (audio || media === true) {
        text = <Icon icon="volume-up" />
      } else if (music.length > 0) {
        text = this.getSummaryForMusicalActions(music)
      }
      let className = 'thumbnail combo-thumbnail'
      if (this.props.compact) className += ' compact'
      if (this.props.sequence.stepIndex === this.props.index)
        className += ' current'

      let thumbnail
      if (media) {
        if (media.thumbnail) {
          thumbnail = media.thumbnail
        } else if (media.type === 'image') {
          thumbnail = media.source
        } else if (media.type === 'video') {
          if (media.source === 'webcam') {
            thumbnail = 'images/webcam.png'
          } else {
            thumbnail = 'images/video.png'
          }
        }
      }

      if (thumbnail) {
        return (
          <div
            className={className}
            onClick={this.handleClick}
            data-index={this.props.index}
          >
            <div style={{ backgroundImage: 'url(' + thumbnail + ')' }}>
              {text}
            </div>
          </div>
        )
      } else {
        className += ' empty'
        return (
          <div
            className={className}
            onClick={this.handleClick}
            data-index={this.props.index}
          >
            <div>{text}</div>
          </div>
        )
      }
    }
  }
)

export default ComboThumbnail
