import React from 'react'
import CharacterAvatar from './CharacterAvatar.js'
import MediaSelector from './MediaSelector.js'
import { SketchPicker } from 'react-color'
import {
  Button,
  Menu,
  MenuItem,
  Slider,
  Popover,
  Position,
  HTMLSelect,
  Icon,
  InputGroup,
  FormGroup,
  ButtonGroup,
  Intent,
  ControlGroup,
  NumericInput,
} from '@blueprintjs/core'
import ReactDOM from 'react-dom'
import { Action } from '../stepworks/stepwise/stepwise-v2.js'
import { observer } from 'mobx-react'
import { reorder } from 'react-reorder'

const StepColumn = observer(
  class StepColumn extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        uploadDialogVisible: false,
        searchMediaDialogVisible: false,
        searchMediaDialogInitialTab: null,
        linkMediaDialogVisible: false,
      }
      this.handleCommandChange = this.handleCommandChange.bind(this)
      this.handleRowClick = this.handleRowClick.bind(this)
      this.menuStructure = [
        {
          label: 'Frame',
          value: 'frame',
          icon: 'grid-view',
          children: [
            { label: 'Enter', value: 'enter' },
            { label: 'Move', value: 'move' },
          ],
        },
        {
          label: 'Text',
          value: 'text',
          icon: 'font',
          children: [{ label: 'Speak', value: 'speak' }],
        },
        {
          label: 'Visual',
          value: 'visual',
          icon: 'media',
          children: [
            { label: 'Show Image', value: 'show-image' },
            { label: 'Show Video', value: 'show-video' },
            { label: 'Set Video Volume', value: 'set-video-volume' },
            { label: 'Stop Video', value: 'stop-video' },
            { label: 'Clear Visuals', value: 'clear-visuals' },
            { label: 'Clear Image', value: 'clear-image' },
            { label: 'Clear Video', value: 'clear-video' },
          ],
        },
        {
          label: 'Audio',
          value: 'audio',
          icon: 'volume-up',
          children: [
            { label: 'Play Audio', value: 'play-audio' },
            { label: 'Set Audio Volume', value: 'set-audio-volume' },
            { label: 'Stop Audio', value: 'stop-audio' },
          ],
        },
        {
          label: 'Music',
          value: 'music',
          icon: 'music',
          children: [
            { label: 'Play Note', value: 'play-note' },
            { label: 'Play Phrase', value: 'play-phrase' },
            { label: 'Play Beat', value: 'play-beat' },
          ],
        },
        {
          label: 'Scene',
          value: 'scene',
          icon: 'shapes',
          children: [{ label: 'Set Light', value: 'set-light' }],
        },
        {
          label: 'Miscellaneous',
          value: 'miscellaneous',
          icon: 'code',
          children: [
            { label: 'Next Step', value: 'next-step' },
            { label: 'Disable Navigation', value: 'disable-navigation' },
            { label: 'Enable Navigation', value: 'enable-navigation' },
            { label: 'Sample', value: 'sample' },
            { label: 'Set Sequence', value: 'set-sequence' },
            { label: 'Play Sequence', value: 'play-sequence' },
            { label: 'Stop Sequence', value: 'stop-sequence' },
            { label: 'Trigger', value: 'trigger' },
          ],
        },
      ]
      this.chordOptions = [
        { label: 'E minor', value: 'Em' },
        { label: 'A minor', value: 'Am' },
        { label: 'D minor', value: 'Dm' },
        { label: 'G major', value: 'G' },
        { label: 'C major', value: 'C' },
        { label: 'F major', value: 'F' },
        { label: 'Bb major', value: 'Bb' },
        { label: 'B diminshed', value: 'Bdim' },
      ]
      this.commandMenu = this.buildCommandMenu()
      this.sortedActions = []
    }

    getLabelForCommand(command) {
      let label
      this.menuStructure.forEach((menuItem) => {
        let labelCandidate = this.getLabelForCommandFromMenuItem(
          command,
          menuItem
        )
        if (labelCandidate) {
          label = labelCandidate
        }
      })
      return label
    }

    getLabelForCommandFromMenuItem(command, menuItem) {
      let label
      if (menuItem.value === command) {
        return menuItem.label
      } else if (menuItem.children) {
        menuItem.children.forEach((item) => {
          let labelCandidate = this.getLabelForCommandFromMenuItem(
            command,
            item
          )
          if (labelCandidate) {
            label = labelCandidate
          }
        })
      }
      return label
    }

    buildCommandMenu() {
      let menuItems = this.menuStructure.map((menuItem, index) => {
        return this.buildMenuItem(menuItem, index)
      })
      return (
        <Menu>
          {menuItems}
          <Menu.Divider />
          <MenuItem
            text="Delete action"
            onClick={this.deleteAction.bind(this)}
          />
        </Menu>
      )
    }

    buildMenuItem(menuItem, prefix) {
      if (menuItem.children) {
        let children = menuItem.children.map((item, index) => {
          return this.buildMenuItem(item, prefix + '-' + index)
        })
        return (
          <MenuItem key={prefix} text={menuItem.label} icon={menuItem.icon}>
            {children}
          </MenuItem>
        )
      } else {
        return (
          <MenuItem
            key={prefix}
            text={menuItem.label}
            data-command={menuItem.value}
            onClick={this.handleCommandChange}
          />
        )
      }
    }

    handleCommandChange(evt) {
      let command = evt.currentTarget.getAttribute('data-command')
      switch (command) {
        case 'delete-action':
          this.deleteAction()
          break

        default:
          this.props.editAction.command = command
          this.setDefaultsForCommand(this.props.editAction.command)
          this.props.editAction.updateFeature()
          this.props.onActionChange(this.props.editAction)
          if (
            command === 'clear-visuals' ||
            command === 'clear-image' ||
            command === 'clear-video'
          ) {
            this.props.onStepwiseEvent('action', this.props.editAction)
          }
          break
      }
    }

    setDefaultsForCommand(command) {
      this.props.editAction.reset()
      switch (command) {
        case 'enter':
          this.props.editAction.direction = ['top', 'right', 'bottom', 'left']
          this.props.editAction.amount = ['full']
          this.props.editAction.size = ['full']
          this.props.editAction.content =
            '0 0 ' +
            this.props.editAction.targetCharacter.channel.grid.columns +
            ' ' +
            this.props.editAction.targetCharacter.channel.grid.rows
          break
        case 'move':
          this.props.editAction.content =
            '0 0 ' +
            this.props.editAction.targetCharacter.channel.grid.columns +
            ' ' +
            this.props.editAction.targetCharacter.channel.grid.rows
          break
        case 'play-audio':
          this.props.editAction.content = ''
          this.props.editAction.volume = 1
          this.props.editAction.loop = false
          this.props.editAction.solo = false
          break
        case 'set-audio-volume':
        case 'set-video-volume':
          this.props.editAction.content = ''
          this.props.editAction.volume = 1
          this.props.editAction.duration = 0.5
          break
        case 'play-note':
          this.props.editAction.content = 'C4'
          this.props.editAction.duration = 1
          this.props.editAction.velocity = 88
          break
        case 'play-phrase':
          this.props.editAction.content = 'Dm'
          this.props.editAction.octave = 3
          this.props.editAction.sequenceLength = '2,4'
          this.props.editAction.octaveShift = '-1,1'
          break
        case 'play-beat':
          this.props.editAction.content = 'x...'
          this.props.editAction.duration = 0.5
          this.props.editAction.velocity = 88
          break
        case 'show-image':
          this.props.editAction.content = ''
          break
        case 'show-video':
          this.props.editAction.content = ''
          this.props.editAction.volume = 1
          this.props.editAction.loop = true
          this.props.editAction.solo = false
          break
        default:
          this.props.editAction.content = ''
          break
      }
    }

    getPreviewContentForAction(action) {
      let avatar
      if (this.props.charactersEnabled) {
        avatar = (
          <CharacterAvatar
            character={action.targetCharacter}
            characterDialog={this.props.characterDialog}
            changesAffectStep={false}
            addEntranceActions={false}
            clickable={true}
            action={action}
          />
        )
      }

      let media, preview, sequence, contentProxy
      switch (action.command) {
        case 'speak':
          contentProxy = action.content
          if (contentProxy === '') {
            contentProxy = '[empty]'
          } else if (contentProxy.trim() === '') {
            contentProxy = '“' + contentProxy + '”'
          }
          preview = <React.Fragment>{contentProxy}</React.Fragment>
          break

        case 'trigger':
          contentProxy = action.content
          if (contentProxy === '') {
            contentProxy = '[empty]'
          } else if (contentProxy.trim() === '') {
            contentProxy = '“' + contentProxy + '”'
          }
          preview = <React.Fragment>Trigger: {contentProxy}</React.Fragment>
          break

        case 'enter':
          preview = (
            <React.Fragment>
              Enter: &nbsp;
              {action.direction.indexOf('top') !== -1 ? (
                <Icon icon="arrow-down" iconSize={Icon.SIZE_STANDARD} />
              ) : null}
              {action.direction.indexOf('right') !== -1 ? (
                <Icon icon="arrow-left" iconSize={Icon.SIZE_STANDARD} />
              ) : null}
              {action.direction.indexOf('bottom') !== -1 ? (
                <Icon icon="arrow-up" iconSize={Icon.SIZE_STANDARD} />
              ) : null}
              {action.direction.indexOf('left') !== -1 ? (
                <Icon icon="arrow-right" iconSize={Icon.SIZE_STANDARD} />
              ) : null}
            </React.Fragment>
          )
          break

        case 'move':
          let temp = action.content.split(' ')
          preview = (
            <React.Fragment>
              Move to {temp[0]},{temp[1]}
            </React.Fragment>
          )
          break

        case 'sample':
          sequence = this.props.stepwise.score.currentScene.getSequence(
            action.content
          )
          if (sequence) {
            preview = <React.Fragment>Sample “{sequence.title}”</React.Fragment>
          } else {
            preview = (
              <React.Fragment>Sample [no sequence selected]</React.Fragment>
            )
          }
          break

        case 'set-sequence':
        case 'play-sequence':
        case 'stop-sequence':
          sequence = this.props.stepwise.score.currentScene.getSequence(
            action.content
          )
          if (sequence) {
            preview = (
              <React.Fragment>
                {window.getCapitalizedCommand(action.command)}: “
                {sequence.title}”
              </React.Fragment>
            )
          } else {
            preview = (
              <React.Fragment>
                {window.getCapitalizedCommand(action.command)}: [no sequence
                selected]
              </React.Fragment>
            )
          }
          break

        case 'show-image':
          media = this.props.stepwise.score.getMedia(action.content)
          preview = media ? (
            <span>
              <img
                src={media.thumbnail ? media.thumbnail : media.source}
                alt={media.name}
              />
              &nbsp;&nbsp;{media.name}
            </span>
          ) : null
          break

        case 'show-video':
          media = this.props.stepwise.score.getMedia(action.content)
          if (media) {
            if (media.thumbnail) {
              preview = (
                <span>
                  <img src={media.thumbnail} alt={media.name} />
                  &nbsp;&nbsp;{media.name}
                </span>
              )
            } else if (media.source === 'webcam') {
              preview = (
                <span>
                  <img src="images/webcam.png" alt={media.name} />
                  &nbsp;&nbsp;{media.name}
                </span>
              )
            } else {
              preview = (
                <span>
                  <img src="images/video.png" alt={media.name} />
                  &nbsp;&nbsp;{media.name}
                </span>
              )
            }
          }
          break

        case 'play-audio':
          media = this.props.stepwise.score.getMedia(action.content)
          preview = media ? (
            <span>
              <Icon icon="volume-up" />
              &nbsp;&nbsp;{media.name}
            </span>
          ) : null
          break

        case 'set-audio-volume':
        case 'set-video-volume':
          preview = (
            <span>
              <Icon icon="volume-up" />
              &nbsp;&nbsp;Set volume: {action.volume}
            </span>
          )
          break

        case 'play-note':
          preview = (
            <React.Fragment>
              Play note: &nbsp;{action.content}
              &nbsp;&nbsp;
              <span className="music-widget note">
                {window.getNotationForDuration(action.duration)}
              </span>
              &nbsp;&nbsp;
              <span className="music-widget dynamics">
                {window.getNotationForVelocity(action.velocity)}
              </span>
            </React.Fragment>
          )
          break

        case 'play-phrase':
          preview = (
            <React.Fragment>
              Play phrase: &nbsp;{action.content}
              &nbsp;&nbsp;
              <span className="music-widget note">
                {window.getNotationForDuration(action.duration)}
              </span>
            </React.Fragment>
          )
          break

        case 'play-beat':
          preview = (
            <React.Fragment>
              Play beat: &nbsp;{action.content}
              &nbsp;&nbsp;
              <span className="music-widget dynamics">
                {window.getNotationForVelocity(action.velocity)}
              </span>
            </React.Fragment>
          )
          break

        default:
          preview = <>{window.getCapitalizedCommand(action.command)}</>
          break
      }
      return (
        <div className="action-preview">
          {avatar}
          {preview}
        </div>
      )
    }

    handleLayoutPropertyChange(value, property) {
      let temp
      if (!this.props.editAction.content) {
        let character = this.props.editAction.targetCharacter
        this.props.editAction.content =
          '0 0 ' +
          character.channel.grid.columns +
          ' ' +
          character.channel.grid.rows
      }
      let layout = this.props.editAction.content
      temp = layout.split(' ')
      switch (property) {
        case 'left':
          temp[0] = value
          break
        case 'top':
          temp[1] = value
          break
        case 'width':
          temp[2] = value
          break
        case 'height':
          temp[3] = value
          break
        default:
          break
      }
      layout = temp.join(' ')
      this.props.editAction.content = layout
      if (this.props.editAction.command === 'move') {
        this.props.onStepwiseEvent('action', this.props.editAction)
      }
    }

    handleDirectionToggle(evt, direction) {
      let index = this.props.editAction.direction.indexOf(direction)
      if (index === -1) {
        this.props.editAction.direction.push(direction)
      } else if (this.props.editAction.direction.length > 1) {
        this.props.editAction.direction.splice(index, 1)
      }
    }

    handleSizeToggle(evt, size) {
      if (!this.props.editAction.size) this.props.editAction.size = ['full']
      let index = this.props.editAction.size.indexOf(size)
      if (index === -1) {
        this.props.editAction.size.push(size)
      } else if (this.props.editAction.size.length > 1) {
        this.props.editAction.size.splice(index, 1)
      }
    }

    handleAmountToggle(evt, amount) {
      if (!this.props.editAction.amount) this.props.editAction.amount = ['full']
      let index = this.props.editAction.amount.indexOf(amount)
      if (index === -1) {
        // choosing 'custom' or 'content' turns off all others
        if (amount === 'custom' || amount === 'match-content') {
          this.props.editAction.amount = []
        }
        // any other selection turns off 'size' and 'content'
        index = this.props.editAction.amount.indexOf('custom')
        if (index !== -1) {
          this.props.editAction.amount.splice(index, 1)
        }
        index = this.props.editAction.amount.indexOf('match-content')
        if (index !== -1) {
          this.props.editAction.amount.splice(index, 1)
        }
        this.props.editAction.amount.push(amount)
      } else if (this.props.editAction.amount.length > 1) {
        this.props.editAction.amount.splice(index, 1)
      }
    }

    handleActionPropertyChange(evt, property, value) {
      let propValue = value ? value : evt.currentTarget.value
      let okToPropagate
      switch (property) {
        case 'command':
          this.props.editAction[property] = propValue
          this.setDefaultsForCommand(evt.currentTarget.value)
          break

        case 'content':
          okToPropagate = true
          switch (this.props.editAction.command) {
            case 'show-image':
            case 'show-video':
              let media = this.props.stepwise.score.getMedia(propValue)
              if (media.type === 'image') {
                this.props.editAction.command = 'show-image'
              } else if (media.type === 'video') {
                this.props.editAction.command = 'show-video'
              }
              break

            case 'play-note':
              okToPropagate = !isNaN(window.noteNameToMidiNoteNum(propValue))
              break

            default:
              break
          }
          this.props.editAction[property] = propValue
          if (okToPropagate)
            this.props.onStepwiseEvent('action', this.props.editAction)
          break

        case 'enter':
        case 'delay':
          this.props.editAction[property] = propValue
          break

        default:
          this.props.editAction[property] = propValue
          this.props.onStepwiseEvent('action', this.props.editAction)
          break
      }
    }

    handleNumericActionPropertyChange(value, property) {
      this.props.editAction[property] = value
      if (property === 'delay') {
        //this.props.onSortNeeded();
      }
    }

    handleDurationSelect(duration) {
      this.props.editAction.duration = duration
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleVelocitySelect(velocity) {
      this.props.editAction.velocity = velocity
      this.props.onStepwiseEvent('action', this.props.editAction)
    }

    handleNoteKeyDown(evt) {
      let noteNum
      let shiftDown = evt.nativeEvent.shiftKey
      var inputField = evt.currentTarget
      switch (evt.key) {
        case 'ArrowDown':
          noteNum = window.noteNameToMidiNoteNum(evt.currentTarget.value)
          if (!shiftDown) {
            noteNum--
          } else {
            noteNum -= 12
          }
          this.handleActionPropertyChange(
            evt,
            'content',
            window.midiNoteNumToNoteName(noteNum)
          )
          setTimeout(() => inputField.focus(), 10)
          break

        case 'ArrowUp':
          noteNum = window.noteNameToMidiNoteNum(evt.currentTarget.value)
          if (!shiftDown) {
            noteNum++
          } else {
            noteNum += 12
          }
          this.handleActionPropertyChange(
            evt,
            'content',
            window.midiNoteNumToNoteName(noteNum)
          )
          setTimeout(() => inputField.focus(), 10)
          break

        default:
          break
      }
    }

    getVisualMedia() {
      var mediaItems = [{ label: 'Choose existing...', value: null }]
      Object.values(this.props.stepwise.score.media).forEach(
        (mediaItem, index) => {
          if (mediaItem.type === 'image' || mediaItem.type === 'video') {
            mediaItems.push({ label: mediaItem.name, value: mediaItem.id })
          }
        }
      )
      return mediaItems
    }

    getAudioMedia() {
      var mediaItems = [{ label: 'Choose existing...', value: null }]
      Object.values(this.props.stepwise.score.media).forEach(
        (mediaItem, index) => {
          if (mediaItem.type === 'audio') {
            mediaItems.push({ label: mediaItem.name, value: mediaItem.id })
          }
        }
      )
      return mediaItems
    }

    getResourceTypeForCommand(command) {
      switch (command) {
        case 'show-image':
          return 'image'

        case 'show-video':
        case 'set-video-volume':
          return 'video'

        case 'play-audio':
        case 'set-audio-volume':
          return 'audio'

        default:
          return 'auto'
      }
    }

    getSequences() {
      var sequences = [{ label: 'Select sequence', value: null }]
      Object.values(this.props.stepwise.score.currentScene.sequences).forEach(
        (sequence, index) => {
          if (sequence !== this.props.sequence) {
            sequences.push({ label: sequence.title, value: sequence.id })
          }
        }
      )
      return sequences
    }

    handleSliderChange(action, property, value) {
      action[property] = Math.round(value * 100) * 0.01
    }

    handleColorChange(action, property, color) {
      action[property] =
        'rgba(' +
        color.rgb.r +
        ',' +
        color.rgb.g +
        ',' +
        color.rgb.b +
        ',' +
        color.rgb.a +
        ')'
    }

    /*getDurationMenu = (action) => {
    console.log('wha');
    return <ButtonGroup className="music-widget note">
      {window.noteDurations.map((duration, index) => {
        let durationProxy = duration * (this.props.stepwise.score.currentScene.pulse.pulsesPerBeat / 4);
        return <Button key={index} intent={action.duration === durationProxy ? Intent.PRIMARY : Intent.NONE} onClick={() => this.handleDurationSelect(durationProxy)}>{window.getNotationForDuration(durationProxy)}</Button>
      })}
    </ButtonGroup>
  }*/

    getEditorContentForAction(action) {
      let avatar
      if (this.props.charactersEnabled) {
        avatar = (
          <CharacterAvatar
            character={action.targetCharacter}
            characterDialog={this.props.characterDialog}
            stepwise={this.props.stepwise}
            sequence={this.props.sequence}
            musicPlayer={this.props.musicPlayer}
            onStepChange={this.props.onStepChange}
            onPanelLayoutNeeded={this.props.onPanelLayoutNeeded}
            onStoryUpdate={this.props.onStoryUpdate}
            changesAffectStep={false}
            addEntranceActions={false}
            clickable={true}
            action={action}
          />
        )
      }

      let commandMenu = (
        <Popover position={Position.BOTTOM}>
          <Button rightIcon="caret-down">
            {this.getLabelForCommand(action.command)}
          </Button>
          {this.commandMenu}
        </Popover>
      )

      let editor
      let durationMenu = (
        <ButtonGroup className="music-widget note">
          {window.noteDurations.map((duration, index) => {
            let durationProxy =
              duration *
              (this.props.stepwise.score.currentScene.pulse.pulsesPerBeat / 4)
            return (
              <Button
                key={index}
                intent={
                  action.duration === durationProxy
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
                onClick={() => this.handleDurationSelect(durationProxy)}
              >
                {window.getNotationForDuration(durationProxy)}
              </Button>
            )
          })}
        </ButtonGroup>
      )
      let dynamicsMenu = (
        <ButtonGroup className="music-widget dynamics">
          {window.noteVelocities.map((velocity, index) => {
            let lo = 0
            if (index > 0) {
              lo = window.noteVelocities[index - 1]
            }
            return (
              <Button
                key={index}
                intent={
                  action.velocity > lo && action.velocity <= velocity
                    ? Intent.PRIMARY
                    : Intent.NONE
                }
                onClick={() => this.handleVelocitySelect(velocity)}
              >
                {window.getNotationForVelocity(velocity)}
              </Button>
            )
          })}
        </ButtonGroup>
      )

      switch (action.command) {
        case 'speak':
        case 'trigger':
          editor = (
            <InputGroup
              id="speak-input"
              value={action.content}
              ref={(component) => {
                let overlays =
                  document.getElementsByClassName('bp3-overlay-open')
                let node = ReactDOM.findDOMNode(component)
                if (
                  node &&
                  this.props.currentColumn === 1 &&
                  overlays.length === 0
                )
                  node.firstChild.focus()
              }}
              placeholder="Enter text..."
              onChange={(evt) =>
                this.handleActionPropertyChange(evt, 'content')
              }
            />
          )
          break

        case 'enter':
          editor = (
            <ButtonGroup id="enter-direction-select" fill={true}>
              <Button
                intent={
                  action.direction.indexOf('top') !== -1 ? Intent.PRIMARY : null
                }
                onClick={(evt) => this.handleDirectionToggle(evt, 'top')}
                icon="arrow-down"
              ></Button>
              <Button
                intent={
                  action.direction.indexOf('right') !== -1
                    ? Intent.PRIMARY
                    : null
                }
                onClick={(evt) => this.handleDirectionToggle(evt, 'right')}
                icon="arrow-left"
              ></Button>
              <Button
                intent={
                  action.direction.indexOf('bottom') !== -1
                    ? Intent.PRIMARY
                    : null
                }
                onClick={(evt) => this.handleDirectionToggle(evt, 'bottom')}
                icon="arrow-up"
              ></Button>
              <Button
                intent={
                  action.direction.indexOf('left') !== -1
                    ? Intent.PRIMARY
                    : null
                }
                onClick={(evt) => this.handleDirectionToggle(evt, 'left')}
                icon="arrow-right"
              ></Button>
            </ButtonGroup>
          )
          break

        case 'move':
          editor = (
            <FormGroup label="Position" labelFor="position-input" inline="true">
              <ControlGroup id="position-input">
                <NumericInput
                  id="destination-left-input"
                  style={{ width: 40 }}
                  value={window.getLayoutComponent(action.content, 'left')}
                  onValueChange={(value) =>
                    this.handleLayoutPropertyChange(value, 'left')
                  }
                />
                <NumericInput
                  id="destination-top-input"
                  style={{ width: 40 }}
                  value={window.getLayoutComponent(action.content, 'top')}
                  onValueChange={(value) =>
                    this.handleLayoutPropertyChange(value, 'top')
                  }
                />
              </ControlGroup>
            </FormGroup>
          )
          break

        case 'sample':
        case 'set-sequence':
        case 'play-sequence':
        case 'stop-sequence':
          editor = (
            <div>
              <HTMLSelect
                options={this.getSequences()}
                value={action.content}
                onChange={(evt) =>
                  this.handleActionPropertyChange(evt, 'content')
                }
              />
            </div>
          )
          break

        case 'show-image':
        case 'show-video':
        case 'play-audio':
          editor = (
            <div>
              <MediaSelector
                stepwise={this.props.stepwise}
                sequence={this.props.sequence}
                user={this.props.user}
                editStep={this.props.editStep}
                editAction={this.props.editAction}
                uploadedMedia={this.props.uploadedMedia}
                uploadedMediaSize={this.props.uploadedMediaSize}
                maxUploadedMedia={this.props.maxUploadedMedia}
                onMediaUploaded={this.props.onMediaUploaded}
                onStepChange={this.props.onStepChange}
                onStoryUpdate={this.props.onStoryUpdate}
                onStepwiseEvent={this.props.onStepwiseEvent}
              />
            </div>
          )
          break

        case 'set-audio-volume':
        case 'set-video-volume':
          editor = (
            <div>
              <FormGroup>
                <Slider
                  min={0}
                  max={1}
                  labelRenderer={false}
                  labelStepSize={1}
                  labelPrecision={1}
                  stepSize={0.01}
                  vertical={false}
                  value={action.volume}
                  onChange={(value) =>
                    this.handleSliderChange(action, 'volume', value)
                  }
                />
              </FormGroup>
            </div>
          )
          break

        case 'set-light':
          editor = (
            <div style={{ height: '32px' }}>
              <div className="form-col min">
                <ControlGroup>
                  <Popover usePortal={false}>
                    <div className="swatch">
                      <div
                        className="color"
                        style={{ backgroundColor: action.color }}
                      />
                    </div>
                    <SketchPicker
                      color={window.getRGBAColor(action.color)}
                      onChangeComplete={(color) =>
                        this.handleColorChange(action, 'color', color)
                      }
                    />
                  </Popover>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Slider
                    min={0}
                    max={1}
                    labelRenderer={false}
                    labelStepSize={1}
                    labelPrecision={1}
                    stepSize={0.01}
                    vertical={false}
                    value={action.intensity}
                    onChange={(value) =>
                      this.handleSliderChange(action, 'intensity', value)
                    }
                  />
                </ControlGroup>
              </div>
            </div>
          )
          break

        case 'play-note':
          editor = (
            <div>
              <div className="form-col min">
                <InputGroup
                  id="note-input"
                  value={action.content}
                  ref={(component) => {
                    let overlays =
                      document.getElementsByClassName('bp3-overlay-open')
                    let node = ReactDOM.findDOMNode(component)
                    if (
                      node &&
                      this.props.currentColumn === 1 &&
                      overlays.length === 0
                    )
                      node.firstChild.focus()
                  }}
                  style={{ width: 60 }}
                  placeholder="Enter MIDI note name..."
                  onKeyDown={(evt) => this.handleNoteKeyDown(evt)}
                  onChange={(evt) =>
                    this.handleActionPropertyChange(evt, 'content')
                  }
                />
              </div>
              <div className="form-col min">
                <Popover content={durationMenu}>
                  <Button className="music-widget note">
                    {window.getNotationForDuration(action.duration)}
                  </Button>
                </Popover>
              </div>
              <div className="form-col min">
                <Popover content={dynamicsMenu}>
                  <Button className="music-widget dynamics">
                    {window.getNotationForVelocity(action.velocity)}
                  </Button>
                </Popover>
              </div>
            </div>
          )
          break

        case 'play-phrase':
          editor = (
            <div>
              <div className="form-col min">
                <HTMLSelect
                  id="chord-select"
                  options={this.chordOptions}
                  value={action.content}
                  onChange={(evt) =>
                    this.handleActionPropertyChange(evt, 'content')
                  }
                />
              </div>
              <div className="form-col min">
                <Popover content={durationMenu}>
                  <Button className="music-widget note">
                    {window.getNotationForDuration(action.duration)}
                  </Button>
                </Popover>
              </div>
              <div className="form-col min">
                <Popover content={dynamicsMenu}>
                  <Button className="music-widget dynamics">
                    {window.getNotationForVelocity(action.velocity)}
                  </Button>
                </Popover>
              </div>
            </div>
          )
          break

        case 'play-beat':
          editor = (
            <div>
              <div className="form-col min">
                <InputGroup
                  id="beat-input"
                  value={action.content}
                  ref={(component) => {
                    let overlays =
                      document.getElementsByClassName('bp3-overlay-open')
                    let node = ReactDOM.findDOMNode(component)
                    if (
                      node &&
                      this.props.currentColumn === 1 &&
                      overlays.length === 0
                    )
                      node.firstChild.focus()
                  }}
                  style={{ width: 220 }}
                  placeholder="Enter beat..."
                  onChange={(evt) =>
                    this.handleActionPropertyChange(evt, 'content')
                  }
                />
              </div>
              <div className="form-col min">
                <Popover content={dynamicsMenu}>
                  <Button className="music-widget dynamics">
                    {window.getNotationForVelocity(action.velocity)}
                  </Button>
                </Popover>
              </div>
            </div>
          )
          break

        default:
          editor = null
          break
      }

      return (
        <div className="action-editor">
          {avatar}
          {commandMenu}
          {editor}
        </div>
      )
    }

    handleRowClick(evt) {
      //let clickedBackground = (evt.target.nodeName === 'TR' || evt.target.nodeName === 'TH' || evt.target.nodeName === 'TD' || (evt.target.nodeName === 'DIV' && evt.target.classList.contains('action-preview')));
      let clickedInput = evt.target.nodeName === 'INPUT'
      let clickedAvatar = evt.target.classList.contains('character-avatar')
      let index = parseFloat(evt.currentTarget.getAttribute('data-index'))
      let action = this.props.editStep.actions[index]
      if (
        action !== this.props.editAction ||
        (!clickedInput && !clickedAvatar)
      ) {
        this.props.onActionChange(action)
      }
    }

    getNewActionData() {
      let character
      if (this.props.editAction) {
        return this.props.editAction.toJSON()
      } else {
        character = Object.values(this.props.stepwise.score.characters)[0]
        return {
          character: character.id,
          command: 'enter',
          delay: 0,
          direction: ['right', 'left', 'top', 'bottom'],
          size: ['full'],
          amount: ['full'],
          content:
            '0 0 ' +
            character.channel.grid.columns +
            ' ' +
            character.channel.grid.rows,
        }
      }
    }

    sequenceHasEnterActionForCharacter(character) {
      for (let i = 0; i < this.props.sequence.steps.length; i++) {
        let step = this.props.sequence.steps[i]
        for (let j = 0; j < step.actions.length; j++) {
          let action = step.actions[j]
          if (
            action.command === 'enter' &&
            action.targetCharacter === character
          ) {
            return true
          }
        }
      }
      return false
    }

    addAction() {
      let action = new Action(
        this.getNewActionData(),
        this.props.stepwise.score
      )
      this.props.editStep.addAction(action)
      this.props.editStep.sortActions()
      this.props.onActionChange(action)
    }

    deleteAction(evt) {
      let data, action
      switch (this.props.editAction.command) {
        case 'speak':
          // clear out the text before deleting
          this.props.editAction.content = ''
          this.props.onStepwiseEvent('action', this.props.editAction)
          break

        case 'show-image':
          data = {
            command: 'clear-image',
            character: this.props.editAction.targetCharacter.id,
          }
          action = new Action(data, this.props.stepwise.score)
          this.props.onStepwiseEvent('action', action)
          break

        case 'show-video':
          data = {
            command: 'clear-video',
            character: this.props.editAction.targetCharacter.id,
          }
          action = new Action(data, this.props.stepwise.score)
          this.props.onStepwiseEvent('action', action)
          break

        default:
          break
      }
      let index = this.props.editStep.actions.indexOf(this.props.editAction)
      let newIndex = -1
      if (this.props.editStep.actions.length > 1) {
        newIndex = Math.max(0, index - 1)
      }
      this.props.onActionChange(this.props.editStep.actions[newIndex])
      this.props.editStep.deleteAction(this.props.editAction)
      evt.stopPropagation()
    }

    selectPreviousStep = () => {
      let index = this.props.sequence.steps.indexOf(this.props.editStep)
      index--
      if (index < 0) {
        index = this.props.sequence.steps.length - 1
      }
      let step = this.props.sequence.steps[index]
      this.props.onStepChange(step)
    }

    selectNextStep = (evt) => {
      if (evt.nativeEvent.shiftKey) {
        this.props.onAddStep()
      }
      let index = this.props.sequence.steps.indexOf(this.props.editStep)
      index++
      if (index >= this.props.sequence.steps.length) {
        index = 0
      }
      let step = this.props.sequence.steps[index]
      this.props.onStepChange(step)
    }

    onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
      if (nextIndex < this.props.editStep.actions.length) {
        let prevIndexAction = this.props.editStep.actions[previousIndex]
        let nextIndexAction = this.props.editStep.actions[nextIndex]
        prevIndexAction.delay = nextIndexAction.delay
        this.props.editStep.actions = reorder(
          this.props.editStep.actions,
          previousIndex,
          nextIndex
        )
      }
    }

    render() {
      if (!this.props.editStep || !this.props.stepwise) {
        return (
          <div className="score-column">
            <div className="sequence-editor">
              <Button
                icon="chevron-left"
                minimal="true"
                onClick={this.props.onGoBack}
              ></Button>
              &nbsp;No step selected
            </div>
          </div>
        )
      } else {
        let lastDelay = -1
        let rows = this.props.editStep.actions.map((action, actionIndex) => {
          let isCurrent = action === this.props.editAction
          let className
          if (isCurrent) className = 'current'
          let delayProxy = action.delay ? action.delay : 0
          let row = (
            <tr
              className={className}
              key={actionIndex}
              data-index={actionIndex}
              onMouseDown={this.handleRowClick}
            >
              {isCurrent ? (
                <th className="delay-index-col">
                  <Popover
                    onClose={() => this.props.editStep.sortActions()}
                    position={Position.RIGHT}
                  >
                    <div>
                      <span className="music-widget note">
                        {window.getNotationForDuration(
                          1 /
                            parseFloat(
                              this.props.stepwise.score.currentScene.pulse
                                .pulsesPerBeat
                            )
                        )}
                      </span>{' '}
                      {delayProxy}
                    </div>
                    <div className="delay-settings">
                      <FormGroup
                        label="Delay (pulses)"
                        labelFor="delay-input"
                        inline="true"
                      >
                        <NumericInput
                          id="delay-input"
                          style={{ width: 40 }}
                          value={
                            this.props.editAction.delay
                              ? this.props.editAction.delay
                              : 0
                          }
                          onValueChange={(value) =>
                            this.handleNumericActionPropertyChange(
                              value,
                              'delay'
                            )
                          }
                        />
                      </FormGroup>
                    </div>
                  </Popover>
                </th>
              ) : null}
              {delayProxy !== lastDelay && !isCurrent ? (
                <th className="delay-index-col">
                  <span className="music-widget note">
                    {window.getNotationForDuration(
                      1 /
                        parseFloat(
                          this.props.stepwise.score.currentScene.pulse
                            .pulsesPerBeat
                        )
                    )}
                  </span>{' '}
                  {delayProxy}
                </th>
              ) : null}
              {delayProxy === lastDelay && !isCurrent ? (
                <th className="delay-index-col"></th>
              ) : null}
              <td>
                {isCurrent && this.props.columnsEnabled === 1
                  ? this.getEditorContentForAction(action)
                  : this.getPreviewContentForAction(action)}
              </td>
            </tr>
          )
          lastDelay = delayProxy
          return row
        })
        rows.push(
          <tr key="add-col">
            <th className="add-col">
              <Button
                minimal="true"
                icon="plus"
                onClick={this.addAction.bind(this)}
              />
            </th>
          </tr>
        )
        let index = 0
        if (this.props.sequence && this.props.editStep) {
          index = this.props.sequence.steps.indexOf(this.props.editStep)
        }
        return (
          <div className="score-column">
            <div className="sequence-editor">
              <Button
                icon="chevron-left"
                minimal="true"
                onClick={this.props.onGoBack}
              ></Button>
              &nbsp;{this.props.sequence.title}, Step {index + 1}
              <ButtonGroup className="pull-right">
                <Button
                  icon={this.props.columnsEnabled > 1 ? 'unlock' : 'lock'}
                  minimal="true"
                  onClick={(evt) => this.props.onColumnsEnabledChange(evt, 2)}
                />
              </ButtonGroup>
            </div>
            {/*<div className="score-column-header">
          {this.props.editStep.actions.length} Actions
        </div>*/}
            <div className="score-column-body no-scroll">
              <table className="step-text-editor">
                <tbody>
                  {/*<Reorder
              reorderId="action-list"
              component="tbody"
              lock="horizontal"
              autoScroll={false}
              holdTime={500}
              onReorder={this.onReorder}
            >*/}
                  {rows}
                  {/*</Reorder>*/}
                </tbody>
              </table>
            </div>
            <div className="step-controls">
              <ButtonGroup fill={true}>
                <Button icon="caret-left" onClick={this.selectPreviousStep}>
                  Prev step
                </Button>
                <Button rightIcon="caret-right" onClick={this.selectNextStep}>
                  Next step
                </Button>
              </ButtonGroup>
            </div>
          </div>
        )
      }
    }
  }
)

export default StepColumn
