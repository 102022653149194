import React from 'react'
import { v4 as uuidv4 } from 'uuid'
var levenshtein = require('fast-levenshtein')

class TunesMapSearch extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchResults = this.handleSearchResults.bind(this)
    this.setupAuthorization()
  }

  setupAuthorization() {
    this.authorization = btoa(
      process.env.REACT_APP_TUNESMAP_USERNAME +
        ':' +
        process.env.REACT_APP_TUNESMAP_PASSWORD
    )
    let headers = new Headers()
    headers.append('Authorization', 'Basic ' + this.authorization)
    headers.append('Content-Type', 'application/json')
    this.headerOptions = {
      headers: headers,
    }
  }

  onTunesMapAuthError(error) {
    navigator.notification.alert(
      'Error loading credentials file. Please contact admin@tunesmap.com for assistance.',
      null,
      'Uh oh!',
      'OK'
    )
    console.log('Error loading TunesMap credentials file.')
    console.log(error)
  }

  doSearch(parameters, page) {
    let url = 'https://new.tunesmap.com/media.json/?'
    if (page === 1) {
      for (let prop in parameters) {
        parameters[prop] = encodeURIComponent(parameters[prop])
      }
    }
    let params = []
    if (parameters.time !== '') params.push('time=' + parameters.time)
    if (parameters.location !== '')
      params.push('location=' + parameters.location)
    if (parameters.artist) params.push('artist=' + parameters.artist)
    if (parameters.genre !== '') params.push('genre=' + parameters.genre)
    if (parameters.mediaType !== '')
      params.push('media_type=' + parameters.mediaType)
    if (parameters.cultureType !== '')
      params.push('culture_type=' + parameters.cultureType)
    params.push('page=' + page)
    url += params.join('&')
    var request = new Request(url, this.headerOptions)
    fetch(request).then((results) => {
      results
        .json()
        .then(this.handleSearchResults)
        .catch((error) => {
          console.error(error)
        })
    })
  }

  handleSearchResults(json) {
    //console.log(json);
    let mediaData = []
    json.forEach((item, i) => {
      let attribution = ''
      let name = item.name
      let temp = item.name.split(', by ')
      if (temp.length === 2) {
        name = temp[0]
        attribution = temp[1]
      }
      let mediaItem
      switch (item.media_type) {
        case 'image':
          mediaItem = {
            id: uuidv4(),
            name: name,
            type: 'image',
            source: item.image_url.replace('mural', 'original'),
            attribution: attribution,
            attributionURL: item.product_url,
            thumbnail: item.image_url.replace('mural', 'small'),
            width: item.original_image_width,
            height: item.original_image_height,
          }
          mediaData.push(mediaItem)
          break

        case 'video':
          mediaItem = {
            id: uuidv4(),
            name: name,
            type: 'video',
            source: item.video_url,
            attribution: attribution,
            attributionURL: item.product_url,
            thumbnail: item.image_url.replace('mural', 'small'),
            width: 640,
            height: 480,
          }
          mediaData.push(mediaItem)
          break

        default:
          break
      }
    })
    this.props.onSearchResults(mediaData)
  }

  normalizeLocation(value, successHandler) {
    if (!value || value.trim() === '') {
      successHandler('')
    } else {
      let url = 'https://new.tunesmap.com/media/locations?location=' + value
      let request = new Request(url, this.headerOptions)
      fetch(request).then((results) => {
        results
          .json()
          .then((results) => {
            successHandler(this.getClosestStringMatch(results, 'value', value))
          })
          .catch((error) => {
            console.error(error)
          })
      })
    }
  }

  normalizeArtistName(value, successHandler) {
    let url = 'https://new.tunesmap.com/artists/names?name=' + value
    let request = new Request(url, this.headerOptions)
    fetch(request).then((results) => {
      results
        .json()
        .then((results) => {
          successHandler(this.getClosestStringMatch(results, 'name', value))
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  getClosestStringMatch(array, property, string) {
    let maxDist = 9999
    let closestMatch = ''
    let distance
    array.forEach((item) => {
      distance = levenshtein.get(string, item[property])
      if (distance < maxDist) {
        maxDist = distance
        closestMatch = item[property]
      }
    })
    return closestMatch
  }

  render() {
    return <div />
  }
}

export default TunesMapSearch
