import React from 'react'
import { HTMLSelect, ControlGroup, Button, Classes } from '@blueprintjs/core'
import MediaPreview from './MediaPreview.js'

class AddMediaControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: this.props.type ? this.props.type : 'image',
      name: '',
      url: '',
      width: null,
      height: null,
    }
    this.typeOptions = [
      { label: 'Image', value: 'image' },
      { label: 'Video', value: 'video' },
      { label: 'Audio', value: 'audio' },
    ]
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.inputName = null
    this.setInputNameRef = (element) => {
      this.inputName = element
    }
    this.focusInputName = () => {
      if (this.inputName) this.inputName.focus()
    }
  }

  componentDidMount() {
    this.setState({
      url: this.props.url ? this.processURL(this.props.url) : '',
    })
    this.focusInputName()
  }

  componentDidUpdate() {
    this.props.onChange(this.props.index, {
      key: this.props.uniqueId,
      type: this.state.type,
      name: this.state.name,
      url: this.state.url,
      width: this.state.width,
      height: this.state.height,
    })
  }

  setType(evt) {
    this.setState({ type: evt.currentTarget.value })
  }

  handleNameChange(evt) {
    this.setState({ name: evt.currentTarget.value })
  }

  processURL(url) {
    if (url.indexOf('https://www.dropbox.com') !== -1) {
      url = url.replace('dl=0', 'raw=1')
    } else if (
      this.getIdFromYouTubeURL(url) ||
      url.indexOf('https://www.twitch.tv') !== -1
    ) {
      this.setState({ type: 'video', width: 1920, height: 1080 })
    }
    return url
  }

  handleURLChange(evt) {
    this.setState({ url: this.processURL(evt.target.value) })
  }

  getIdFromYouTubeURL(url) {
    var id
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] != null) {
      id = url[2].split(/[^0-9a-z_-]/i)
      id = id[0]
      return id
    } else {
      return null
    }
  }

  handleKeyPress(ev) {
    if (ev.key === 'Enter') {
      this.props.onEnter(this.props.index)
    }
  }

  handleDelete() {
    this.props.onDelete(this.props.index)
  }

  handleMetadata(metadata) {
    this.setState({ width: metadata.width, height: metadata.height })
  }

  render() {
    return (
      <ControlGroup fill={true} className="input-media">
        <HTMLSelect
          className="media-type-select"
          options={this.typeOptions}
          value={this.state.type}
          onChange={this.setType.bind(this)}
        />
        <input
          type="text"
          ref={this.setInputNameRef}
          className={Classes.INPUT}
          placeholder="Enter name"
          value={this.state.name}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleNameChange.bind(this)}
        />
        <input
          type="text"
          className={Classes.INPUT}
          placeholder="Enter URL"
          value={this.state.url}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleURLChange.bind(this)}
        />
        <MediaPreview
          className="add-media-preview"
          type={this.state.type}
          source={this.state.url}
          onMetadata={this.handleMetadata.bind(this)}
        />
        <Button
          className="close-btn"
          icon="cross"
          minimal={true}
          onClick={this.handleDelete.bind(this)}
        />
      </ControlGroup>
    )
  }
}

export default AddMediaControl
