import { observable, decorate } from 'mobx'
import {
  primitive,
  list,
  object,
  custom,
  SKIP,
  alias,
  identifier,
  reference,
  serialize,
  mapAsArray,
  serializable,
} from 'serializr'
import { v4 as uuidv4 } from 'uuid'
import * as Tone from 'tone'
import WAAClock from './libs/WAAClock.js'

// root class; global methods (like loading scores)
class Stepwise {
  constructor(config) {
    this.score = null
    this.options = { ...Stepwise.defaults, ...config }
    this.inputEnabled = true
    this.eventManager = new EventManager(this.options.audioContext)
    this.inputManager = new InputManager(
      this.options,
      () => this.nextStep(),
      this
    )
  }

  static get FeatureTypes() {
    return {
      AUDIO: 'audio',
      FRAME: 'frame',
      IMAGE: 'image',
      LOCATION: 'location',
      TEXT: 'text',
      VIDEO: 'video',
    }
  }

  static SerializationReplacer(key, value) {
    let className = null
    if (value) {
      if (typeof value === 'object') {
        className = value.constructor.name
      }
    }
    let illegalClasses = ['EventManager', 'InputManager', 'Channel']
    let illegalKeys = [
      'parentScore',
      'parentScene',
      'parentSequence',
      'parentCharacter',
      'currentScene',
      'defaultSequence',
      'currentSequence',
      'defaultState',
      'channels',
      'targetCharacter',
      'targetFeature',
      'featureIsLocked',
    ]
    if (
      illegalClasses.indexOf(className) !== -1 ||
      illegalKeys.indexOf(key) !== -1
    ) {
      return undefined
    } else {
      return value
    }
  }

  load(data, format) {
    this.score = new Score(this)
    this.score.init(data, format)
  }

  nextStep() {
    if (
      !this.score.preventInputDuringDelays ||
      (this.score.preventInputDuringDelays &&
        !this.eventManager.isWaitingForDelay())
    ) {
      this.score.nextStep()
    }
  }

  serialize() {
    let json = serialize(this.score)
    console.log(json)
    return JSON.stringify(json, null, 2)
    //return JSON.stringify(this.score, Stepwise.SerializationReplacer, 2);
  }

  dispose() {
    this.score.removeReferences()
    this.eventManager.dispose()
    this.inputManager.dispose()
    this.eventManager = null
    this.inputManager = null
  }
}

Stepwise.defaults = {
  clickInput: true,
  dataType: 'string',
  delimiter: ' ',
  keyInput: true,
  keyCodesToIgnore: [9, 16, 17, 18, 20, 27, 224, 91, 93],
  outputToElement: true,
  tapInput: true,
  gamepadInput: true,
}

// handles input
class InputManager {
  constructor(options, nextStep, instance) {
    this.nextStep = nextStep
    this.options = options
    this.instance = instance
    this.init()
  }

  dispose() {
    this.instance = null
    this.nextStep = null
    let body = document.getElementsByTagName('body')[0]
    body.removeEventListener('keydown', this.handleKeydown)
    body.removeEventListener('mousedown', this.handleMouseDown)
    body.removeEventListener('touchstart', this.handleTouchStart)
    body.removeEventListener('gamepadconnected', this.gamepadConnectHandler)
    body.removeEventListener(
      'gamepaddisconnected',
      this.gamepadDisconnectHandler
    )
  }

  handleKeydown(event) {
    if (
      this.options.keyCodesToIgnore.indexOf(event.keyCode) === -1 &&
      this.enabled &&
      !event.metaKey
    ) {
      this.nextStep()
      event.preventDefault()
    }
  }

  handleMouseDown(evt) {
    if (evt.srcElement.closest('.do-not-propagate')) return
    let nodeName
    if (evt.srcElement) {
      nodeName = evt.srcElement.nodeName
    }
    if (this.enabled && nodeName !== 'A') {
      this.nextStep()
    }
  }

  handleTouchStart(evt) {
    let nodeName
    if (evt.srcElement) {
      nodeName = evt.srcElement.nodeName
    }
    if (this.enabled && nodeName !== 'A') {
      this.nextStep()
      evt.preventDefault()
    }
  }

  init() {
    this._haveGamepadEvents = 'ongamepadconnected' in window
    this._controllers = {}
    this._pressedControllerButtons = []
    let inputElement = this.options.element
    let body = document.getElementsByTagName('body')[0]
    if (!inputElement) {
      inputElement = body
    }
    if (this.options.keyInput) {
      body.addEventListener('keydown', (evt) => this.handleKeydown(evt))
    }
    if (this.options.clickInput) {
      inputElement.addEventListener('mousedown', (evt) =>
        this.handleMouseDown(evt)
      )
    }
    if (this.options.tapInput) {
      inputElement.addEventListener('touchstart', (evt) =>
        this.handleTouchStart(evt)
      )
    }
    if (this.options.gamepadInput) {
      var scanGamepads = () => {
        var gamepads = navigator.getGamepads
          ? navigator.getGamepads()
          : navigator.webkitGetGamepads
          ? navigator.webkitGetGamepads()
          : []
        for (var i = 0; i < gamepads.length; i++) {
          if (gamepads[i]) {
            if (gamepads[i].index in this._controllers) {
              this._controllers[gamepads[i].index] = gamepads[i]
            } else {
              this.addGamepad(gamepads[i])
            }
          }
        }
      }
      window.addEventListener('gamepadconnected', (e) =>
        this.gamepadConnectHandler(e)
      )
      window.addEventListener('gamepaddisconnected', (e) =>
        this.gamepadDisconnectHandler(e)
      )
      if (!this._haveGamepadEvents) {
        this._gamepadInterval = setInterval(scanGamepads, 500)
      }
    }
  }

  setEnabled(enabled) {
    this.enabled = enabled
  }

  gamepadConnectHandler(e) {
    // nothing
  }

  gamepadDisconnectHandler(e) {
    this.removeGamepad(e.gamepad)
  }

  addGamepad(gamepad) {
    console.log('add gamepad')
    var scanGamepads = () => {
      var gamepads = navigator.getGamepads
        ? navigator.getGamepads()
        : navigator.webkitGetGamepads
        ? navigator.webkitGetGamepads()
        : []
      for (var i = 0; i < gamepads.length; i++) {
        if (gamepads[i]) {
          if (gamepads[i].index in this._controllers) {
            this._controllers[gamepads[i].index] = gamepads[i]
          } else {
            this.addGamepad(gamepads[i])
          }
        }
      }
    }
    var updateGamepadStatus = () => {
      if (!this._haveGamepadEvents) {
        scanGamepads()
      }
      var i = 0
      var j
      for (j in this._controllers) {
        var controller = this._controllers[j]
        for (i = 0; i < controller.buttons.length; i++) {
          var val = controller.buttons[i]
          var pressed = val === 1.0
          if (typeof val === 'object') {
            pressed = val.pressed
            val = val.value
          }
          if (pressed) {
            if (this._pressedControllerButtons.indexOf(i) === -1) {
              if (this.enabled) {
                this.nextStep()
              }
              this._pressedControllerButtons.push(i)
            }
          } else {
            var index = this._pressedControllerButtons.indexOf(i)
            if (index !== -1) {
              this._pressedControllerButtons.splice(index, 1)
            }
          }
        }
      }
      if (this.enabled) {
        requestAnimationFrame(updateGamepadStatus)
      }
    }
    this._controllers[gamepad.index] = gamepad
    requestAnimationFrame(updateGamepadStatus)
  }

  removeGamepad(gamepad) {
    delete this._controllers[gamepad.index]
  }
}

// handles events
class EventManager {
  constructor(audioContext) {
    this.listeners = []
    this.delayEndTime = 0
    if (audioContext) {
      this.audioContext = audioContext
      this.clock = new WAAClock(this.audioContext)
      this.clock.start()
    }
  }

  addListener(listener) {
    if (this.listeners.indexOf(listener) === -1) {
      this.listeners.push(listener)
    }
  }

  removeListener(listener) {
    var index = this.listeners.indexOf(listener)
    if (index !== -1) {
      this.listeners.splice(index, 1)
    }
  }

  dispose() {
    this.listeners = []
  }

  sendMessage(message, obj) {
    for (let listener of this.listeners) {
      listener(message, obj)
    }
  }

  isWaitingForDelay() {
    return new Date().getTime() < this.delayEndTime
  }

  triggerSequence(sequence) {
    this.listeners.forEach((listener) => listener('sequence', sequence))
  }

  triggerStep(step) {
    this.listeners.forEach((listener) => listener('step', step))
  }

  triggerStates(states) {
    states.forEach((state) => {
      this.triggerState(state)
    })
  }

  triggerState(state) {
    this.listeners.forEach((listener) => listener('state', state))
  }

  triggerActions(actions, millisecondDelay, isSafe = false) {
    actions.forEach((action) =>
      this.triggerAction(action, millisecondDelay, isSafe)
    )
  }

  triggerAction(action, millisecondDelay, isSafe = false) {
    //console.log(millisecondDelay);
    if (!millisecondDelay) {
      action.execute(isSafe)
      this.listeners.forEach((listener) => listener('action', action))
    } else {
      //console.log(this.delayEndTime, new Date().getTime(), new Date().getTime() + millisecondDelay);
      this.delayEndTime = Math.max(
        this.delayEndTime,
        new Date().getTime() + millisecondDelay
      )
      if (!this.clock) {
        //console.log('delayed A');
        setTimeout(() => {
          //console.log('execute');
          action.execute(isSafe)
          this.listeners.forEach((listener) => listener('action', action))
        }, millisecondDelay)
      } else {
        //console.log('delayed B');
        this.clock.setTimeout(() => {
          //console.log('execute');
          action.execute(isSafe)
          this.listeners.forEach((listener) => listener('action', action))
        }, millisecondDelay * 0.001)
      }
    }
  }
}

// an autonomous entity
class Character {
  constructor(data, score) {
    this.visible = true
    this.type = 'person'
    this.color = 'gray'
    this.instrumentId = -1
    this.parentScore = score
    this.features = []
    this.featuresByType = {
      text: [],
      image: [],
      audio: [],
      video: [],
    }
    this.parse(data)
    this.createDefaultFeatures()
    if (!this.channel) this.parentScore.getChannel('main').addCharacter(this)
  }

  parse(data) {
    for (let property in data) {
      switch (property) {
        case 'channel':
          let channel = this.parentScore.getChannel(data[property])
          if (channel) channel.addCharacter(this)
          break

        case 'features':
          for (let featureData of data[property]) {
            this.addFeature(new Feature(featureData, this))
          }
          break

        default:
          this[property] = data[property]
          break
      }
    }
    this.parentScore.characters[this.id] = this
  }

  createDefaultFeatures() {
    this.addFeature(
      new Feature(
        {
          id: this.id + '-frame-default',
          title: this.fullName + ' (Frame)',
          type: Stepwise.FeatureTypes.FRAME,
          isDefault: true,
        },
        this
      )
    )
    this.addFeature(
      new Feature(
        {
          id: this.id + '-video-default',
          title: this.fullName + ' (Video)',
          type: Stepwise.FeatureTypes.VIDEO,
          isDefault: true,
        },
        this
      )
    )
    this.addFeature(
      new Feature(
        {
          id: this.id + '-image-default',
          title: this.fullName + ' (Image)',
          type: Stepwise.FeatureTypes.IMAGE,
          isDefault: true,
        },
        this
      )
    )
    this.addFeature(
      new Feature(
        {
          id: this.id + '-audio-default',
          title: this.fullName + ' (Audio)',
          type: Stepwise.FeatureTypes.AUDIO,
          isDefault: true,
        },
        this
      )
    )
    this.addFeature(
      new Feature(
        {
          id: this.id + '-text-default',
          title: this.fullName + ' (Text)',
          type: Stepwise.FeatureTypes.TEXT,
          isDefault: true,
        },
        this
      )
    )
  }

  removeReferences() {
    this.parentScore = null
  }

  addFeature(feature) {
    this.features.push(feature)
    if (!this.featuresByType[feature.type]) {
      this.featuresByType[feature.type] = []
    }
    this.featuresByType[feature.type].push(feature)
  }

  getFeatureForAction(action) {
    switch (action.command) {
      case 'show':
      case 'show-image':
        return this.getFeatureForType(Stepwise.FeatureTypes.IMAGE)
      case 'show-video':
        return this.getFeatureForType(Stepwise.FeatureTypes.VIDEO)
      case 'play-audio':
        return this.getFeatureForType(Stepwise.FeatureTypes.AUDIO)
      case 'speak':
        return this.getFeatureForType(Stepwise.FeatureTypes.TEXT)
      default:
        break
    }
    return null
  }

  getFeatureForType(type) {
    // return the last feature of a type because the defaults are last to be created
    return this.featuresByType[type][this.featuresByType[type].length - 1]
  }

  setChannel(channel) {
    this.channel = channel
  }

  /*toJSON() {
    var illegalProperties = ['features','featuresByType'];
    var obj = {};
    for (let property in this) {
      if (illegalProperties.indexOf(property) === -1) {
        obj[property] = this[property];
      }
    }
    return obj;
  }*/

  // needs to have ports for dialogue balloons
  // needs to have default states for actions (thought balloon text state for think action, etc) (maybe)
}
decorate(Character, {
  id: [observable, serializable(identifier())],
  fullName: [observable, serializable(primitive())],
  type: [observable, serializable(primitive())],
  color: [observable, serializable(primitive())],
  instrumentId: [observable, serializable(primitive())],
  instrumentName: [observable, serializable(primitive())],
  visible: [observable, serializable(primitive())],
})

class Media {
  constructor(data) {
    this.parse(data)
  }

  parse(data) {
    for (let property in data) {
      this[property] = data[property]
    }
  }
}
decorate(Media, {
  id: [serializable(identifier()), observable],
  name: [serializable(primitive()), observable],
  type: [serializable(primitive()), observable],
  source: [serializable(primitive()), observable],
  attribution: [serializable(primitive()), observable],
  attributionURL: [serializable(primitive()), observable],
  thumbnail: [serializable(primitive()), observable],
  width: [serializable(primitive()), observable],
  height: [serializable(primitive()), observable],
})

// the way an entity manifests in the story
class Feature {
  constructor(data, character) {
    this.parse(data, character)
  }

  parse(data, character) {
    this.id = data.id
    this.type = data.type
    this.isDefault = data.isDefault ? data.isDefault : false
    this.parentCharacter = character
    var stateData
    if (data.defaultState) {
      stateData = data.defaultState
    } else {
      stateData = {
        type: this.type,
        character: character.id,
        transitionCurve: TemporalState.Easing.EASEINOUT,
        transitionDuration: 0.5,
      }
    }
    switch (this.type) {
      case Stepwise.FeatureTypes.FRAME:
        this.defaultState = new FrameState(
          stateData,
          this.parentCharacter.parentScore,
          this.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.TEXT:
        this.defaultState = new TextState(
          stateData,
          this.parentCharacter.parentScore,
          this.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.IMAGE:
        this.defaultState = new ImageState(
          stateData,
          this.parentCharacter.parentScore,
          this.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.AUDIO:
        this.defaultState = new AudioState(
          stateData,
          this.parentCharacter.parentScore,
          this.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.VIDEO:
        this.defaultState = new VideoState(
          stateData,
          this.parentCharacter.parentScore,
          this.parentCharacter
        )
        break
      default:
        break
    }
    this.parentCharacter.parentScore.addFeature(this)
  }

  setupReferences() {
    if (this.defaultState) {
      this.defaultState.setupReferences()
      if (this.type === Stepwise.FeatureTypes.FRAME) {
        this.defaultState.layout =
          '0 0 ' +
          this.parentCharacter.channel.grid.columns +
          ' ' +
          this.parentCharacter.channel.grid.rows
      }
    }
  }

  removeReferences() {
    if (this.defaultState) {
      this.defaultState.removeReferences()
    }
    this.parentCharacter = null
  }
}
decorate(Feature, {
  id: [serializable(identifier()), observable],
  type: [serializable(primitive()), observable],
})

class AbstractState {
  constructor(data, score, character) {
    this.init()
    this.parse(data, score, character)
  }

  init() {
    // do any needed initialization
  }

  parse(data, score, character) {
    for (let property in data) {
      this[property] = data[property]
    }
  }

  toJSON() {
    var obj = {}
    for (let property in this) {
      obj[property] = this[property]
    }
    return obj
  }
}

class TemporalState extends AbstractState {
  static get Easing() {
    return {
      LINEAR: 'linear',
      EASEINOUT: 'easeInOut',
    }
  }

  init() {
    this.targetFeature = null
    this.featureIsLocked = false
    this.transitionCurve = TemporalState.Easing.EASEINOUT
    this.transitionDuration = 0.5
  }

  parse(data, score, character) {
    this.data = data
    for (let property in data) {
      switch (property) {
        case 'character':
          if (!character) {
            this.targetCharacter = score.getCharacter(data.character)
          } else {
            this.targetCharacter = character
          }
          break

        case 'feature':
          this.targetFeature = score.getFeature(data.feature)
          this.featureIsLocked = true
          break

        case 'transitionDuration':
          this.transitionDuration = parseFloat(data[property])
          break

        default:
          this[property] = data[property]
          break
      }
    }
  }

  setupReferences() {
    if (!this.targetFeature) {
      if (!this.targetCharacter) console.log(this, this.data.character)
      this.targetFeature = this.targetCharacter.getFeatureForType(this.type)
    }
  }

  removeReferences() {
    this.targetFeature = null
    this.targetCharacter = null
  }

  toJSON() {
    var obj = {}
    for (let property in this) {
      switch (property) {
        case 'targetCharacter':
          // nothing
          break

        case 'targetFeature':
          if (this.featureIsLocked) {
            obj.feature = this[property].id
          }
          obj.character = this.targetFeature.parentCharacter.id
          break

        default:
          obj[property] = this[property]
          break
      }
    }
    return obj
  }
}
decorate(TemporalState, {
  type: [serializable(primitive()), observable],
  data: [
    serializable(
      custom(
        () => SKIP,
        (v) => SKIP
      )
    ),
    observable,
  ],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  '*': [serializable(primitive()), observable],
})

class FrameState extends TemporalState {
  init() {
    super.init()
    this.margin = '0'
    this.backgroundColor = 'transparent'
    this.opacity = 1
    this.layout = '0 0 1 1'
    this.borderWidth = '5px'
    this.borderColor = 'black'
    this.depth = '0'
  }
}
decorate(FrameState, {
  type: [serializable(primitive()), observable],
  opacity: [serializable(primitive()), observable],
  borderColor: [serializable(primitive()), observable],
  transitionDuration: [serializable(primitive()), observable],
  backgroundColor: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  '*': [serializable(primitive()), observable],
})

class StageState extends TemporalState {
  init() {
    super.init()
    this.margin = '10px'
    this.backgroundColor = 'black'
  }
}

class TextState extends TemporalState {
  static get Modes() {
    return {
      CAPTION: 'caption',
      BALLOON: 'balloon',
    }
  }

  init() {
    super.init()

    // basics
    this.font = 'sans-serif'
    this.fontWeight = 'normal'
    this.fontStyle = 'normal'
    this.fontSize = '3.5vw'
    this.color = 'white'
    this.backgroundColor = 'transparent'
    this.align = 'center'
    this.mode = TextState.Modes.CAPTION

    // layout
    this.textAlign = 'center'
    this.margin = '20px'
    this.padding = '10px'
    this.width = 'auto'
    this.height = 'auto'
    this.overflow = 'hidden'
    this.layout = 'standard'

    // typography
    this.textTransform = 'none'
    this.letterSpacing = '0'
    this.lineHeight = '100%'
    this.whiteSpace = 'normal'

    // effects
    this.textShadow = '1 1 1 black'
    this.textTransform = ''
    this.quotationMarks = false
    this.characterName = false

    // balloon
    /*this.stemAngle = 0;
      this.stemLength = 100;
      this.stemWidth = 20;
      this.stemPosition = {'x': .5, 'y':.5};
      this.balloonType = null;*/
  }
}
decorate(TextState, {
  type: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  font: observable,
  fontWeight: observable,
  fontStyle: observable,
  fontSize: observable,
  color: observable,
  backgroundColor: observable,
  align: observable,
  mode: observable,
  textAlign: observable,
  margin: observable,
  padding: observable,
  width: observable,
  height: observable,
  overflow: observable,
  layout: observable,
  textTransform: observable,
  textShadow: observable,
  letterSpacing: observable,
  lineHeight: observable,
  whiteSpace: observable,
  quotationMarks: observable,
  characterName: observable,
})

class AudioState extends TemporalState {
  init() {
    super.init()
    this.isPlaying = true
    this.pauseOtherAudio = true
    this.volume = 1
    this.loop = true
  }
}
decorate(AudioState, {
  type: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  isPlaying: observable,
  pauseOtherAudio: observable,
  volume: observable,
  loop: observable,
  transitionDuration: observable,
})

/*class InstrumentState extends TemporalState {

}*/

class ImageState extends TemporalState {
  init() {
    super.init()
    this.fit = 'cover'
    this.backgroundPosition = 'center'
    this.transform = 'none'
    this.backgroundRepeat = 'no-repeat'
    this.filter = ''
    this.transitionDuration = 0
    this.showCaption = false
  }
}
decorate(ImageState, {
  type: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  fit: observable,
  scale: observable,
  transform: observable,
  backgroundPosition: observable,
  backgroundRepeat: observable,
  filter: observable,
  transitionDuration: observable,
  showCaption: observable,
})

class VideoState extends TemporalState {
  init() {
    super.init()
    this.fit = 'cover'
    this.transform = 'none'
    this.filter = ''
    this.pauseOtherAudio = true
    this.volume = 1
    this.loop = true
    this.showCaption = false
  }
}
decorate(VideoState, {
  type: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v, context) => {
          if (context.featureIsLocked) {
            return object(Feature)
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  fit: observable,
  transform: observable,
  filter: observable,
  pauseOtherAudio: observable,
  volume: observable,
  loop: observable,
  showCaption: observable,
})

/*class SpriteState extends TemporalState {

}

class AvatarState extends TemporalState {

}

class PropState extends TemporalState {

}

class CameraState extends TemporalState {

}

// a location is not technically a feature; change inheritace?
class LocationState extends TemporalState {

}*/

// specific action taken by a character (that cannot be
// appropriate represented by a state)
class Action {
  constructor(data, score) {
    this.id = uuidv4()
    this.targetCharacter = null
    this.targetFeature = null
    this.featureIsLocked = false
    this.parse(data, score)
  }

  parse(data, score) {
    for (let property in data) {
      switch (property) {
        case 'character':
          this.targetCharacter = score.getCharacter(data.character)
          break
        case 'feature':
          this.targetFeature = score.getFeature(data.feature)
          this.featureIsLocked = true
          break
        default:
          this[property] = data[property]
          break
      }
    }
    if (this.command === 'enter') this.updateFromLegacyFormat()
  }

  updateFromLegacyFormat() {
    if (!this.physics) this.physics = 'push'
    if (!this.size) this.size = ['full']
    switch (this.size) {
      case 'random':
        this.size = [
          'quarter',
          'third',
          'half',
          'two-thirds',
          'three-quarters',
          'full',
        ]
        break
      case 'full':
        this.size = ['full']
        break
      case 'large':
        this.size = ['two-thirds', 'three-quarters']
        break
      case 'small':
        this.size = ['quarter', 'third']
        break
      default:
        break
    }
    if (!this.amount) this.amount = ['full']
    if (!Array.isArray(this.amount)) {
      switch (this.amount) {
        case 'random':
          this.amount = [
            'quarter',
            'third',
            'half',
            'two-thirds',
            'three-quarters',
            'full',
          ]
          break
        case 'one-third':
          this.amount = ['third']
          break
        default:
          this.amount = [this.amount]
          break
      }
    }
    if (this.size.indexOf('custom') !== -1 || this.layoutType === 'explicit') {
      this.size = ['full']
      this.amount = ['custom']
    }
    this.size.forEach((val, i) => {
      switch (val) {
        case 3:
          this.size[i] = 'quarter'
          break
        case 4:
          this.size[i] = 'third'
          break
        case 6:
          this.size[i] = 'half'
          break
        case 8:
          this.size[i] = 'two-thirds'
          break
        case 9:
          this.size[i] = 'three-quarters'
          break
        case 12:
          this.size[i] = 'full'
          break
        default:
          break
      }
    })
    this.amount.forEach((val, i) => {
      switch (val) {
        case 3:
          this.amount[i] = 'quarter'
          break
        case 4:
          this.amount[i] = 'third'
          break
        case 6:
          this.amount[i] = 'half'
          break
        case 8:
          this.amount[i] = 'two-thirds'
          break
        case 9:
          this.amount[i] = 'three-quarters'
          break
        case 12:
          this.amount[i] = 'full'
          break
        default:
          break
      }
    })
  }

  execute(isSafe = false) {
    let sequence
    switch (this.command) {
      case 'next-step':
        if (!isSafe) this.targetCharacter.parentScore.nextStep()
        break

      case 'disable-navigation':
        this.targetCharacter.parentScore.instance.inputManager.setEnabled(false)
        break

      case 'enable-navigation':
        this.targetCharacter.parentScore.instance.inputManager.setEnabled(true)
        break

      case 'sample':
        sequence = this.targetCharacter.parentScore.currentScene.getSequence(
          this.content
        )
        if (sequence) {
          sequence.nextStep(this.mimic ? this.targetCharacter : null)
        }
        break

      case 'play-sequence':
        if (!isSafe) {
          sequence = this.targetCharacter.parentScore.currentScene.getSequence(
            this.content
          )
          if (sequence) sequence.play()
        }
        break

      case 'stop-sequence':
        sequence = this.targetCharacter.parentScore.currentScene.getSequence(
          this.content
        )
        if (sequence) sequence.stop()
        break

      case 'set-sequence':
        sequence = this.targetCharacter.parentScore.currentScene.getSequence(
          this.content
        )
        if (sequence) {
          if (this.autoplay) {
            this.targetCharacter.parentScore.currentScene.playSequence(sequence)
          } else {
            this.targetCharacter.parentScore.currentScene.setSequence(sequence)
          }
        }
        break

      default:
        break
    }
  }

  toJSON() {
    var obj = {}
    for (let property in this) {
      switch (property) {
        case 'targetCharacter':
          if (this[property]) obj.character = this[property].id
          break

        case 'targetFeature':
          if (this.featureIsLocked) {
            if (this[property]) obj.feature = this[property].id
          }
          break

        default:
          obj[property] = this[property]
          break
      }
    }
    return obj
  }

  updateFeature() {
    if (!this.featureIsLocked) {
      this.targetFeature = this.targetCharacter.getFeatureForAction(this)
    }
  }

  reset() {
    let protectedProps = [
      'id',
      'targetCharacter',
      'targetFeature',
      'command',
      'delay',
      'featureIsLocked',
    ]
    Object.keys(this).forEach((key) => {
      if (protectedProps.indexOf(key) === -1) {
        this[key] = undefined
      }
    })
  }
}
decorate(Action, {
  id: [serializable(primitive()), observable],
  targetCharacter: [
    serializable(alias('character', reference(Character))),
    observable,
  ],
  targetFeature: [
    serializable(
      custom(
        (v) => {
          if (v) {
            if (v.featureIsLocked) {
              return object(Feature)
            } else {
              return SKIP
            }
          } else {
            return SKIP
          }
        },
        (v) => v
      )
    ),
    observable,
  ],
  command: [serializable(primitive()), observable],
  content: [serializable(primitive()), observable],
  direction: [serializable(list()), observable],
  size: [serializable(list()), observable],
  amount: [serializable(list()), observable],
  left: [serializable(primitive()), observable],
  top: [serializable(primitive()), observable],
  width: [serializable(primitive()), observable],
  height: [serializable(primitive()), observable],
  duration: [serializable(primitive()), observable],
  delay: [serializable(primitive()), observable],
  loop: [serializable(primitive()), observable],
  append: [serializable(primitive()), observable],
  autoplay: [serializable(primitive()), observable],
  physics: [serializable(primitive()), observable],
  volume: [serializable(primitive()), observable],
  velocity: [serializable(primitive()), observable],
  octave: [serializable(primitive()), observable],
  octaveShift: [serializable(primitive()), observable],
  sequenceLength: [serializable(primitive()), observable],
  startTime: [serializable(primitive()), observable],
  playbackRate: [serializable(primitive()), observable],
  solo: [serializable(primitive()), observable],
  mimic: [serializable(primitive()), observable],
  color: [serializable(primitive()), observable],
  intensity: [serializable(primitive()), observable],
})

class ScoreElement {
  constructor(data, score) {
    this.parentScore = score
    this.init()
    this.parse(data)
  }

  init() {
    // do any needed initialization
  }

  parse(data) {
    // parse the data
  }
}

// a collection of states and actions
class Step extends ScoreElement {
  constructor(data, score, sequence) {
    super(data, score)
    this.parentSequence = sequence
  }

  init() {
    this.features = []
    this.states = []
    this.actions = []
    this.actionsById = {}
  }

  parse(data) {
    // parse feature states
    var state
    data.states.forEach((stateData) => {
      switch (stateData.type) {
        case Stepwise.FeatureTypes.FRAME:
          state = new FrameState(stateData, this.parentScore)
          break

        case Stepwise.FeatureTypes.IMAGE:
          state = new ImageState(stateData, this.parentScore)
          break

        case 'stage':
          state = new StageState(stateData, this.parentScore)
          break

        case Stepwise.FeatureTypes.TEXT:
          state = new TextState(stateData, this.parentScore)
          break

        case Stepwise.FeatureTypes.VIDEO:
          state = new VideoState(stateData, this.parentScore)
          break

        case Stepwise.FeatureTypes.AUDIO:
          state = new AudioState(stateData, this.parentScore)
          break

        default:
          break
      }
      if (state) this.states.push(state)
    })

    // parse actions
    data.actions.forEach((data) => {
      let action = new Action(data, this.parentScore)
      this.addAction(action)
    })

    this.parseStatesIntoActions()
    this.sortActions()
  }

  setupReferences() {
    this.states.forEach((state) => {
      state.setupReferences()
      if (state.targetFeature) {
        this.addFeature(state.targetFeature)
      }
    })
    this.parentScore = null
  }

  removeReferences() {
    this.states.forEach((state) => {
      state.removeReferences()
      state.targetFeature = null
    })
    this.parentSequence = null
  }

  populateStatesForCharacters(characters) {
    characters.forEach((character) => {
      character.features.forEach((feature) => {
        if (!this.containsStateForFeature(feature, true)) {
          //console.log('created state',character.fullName,feature.type);
          let state = this.createStateForFeature(feature, feature.defaultState)
          this.states.push(state)
        } else {
        }
      })
    })
  }

  parseStatesIntoActions() {
    let actionData
    let commands = []
    this.actions.forEach((action) => {
      if (commands.indexOf(action.command) === -1) {
        commands.push(action.command)
      }
    })
    this.states.forEach((state) => {
      switch (state.type) {
        case Stepwise.FeatureTypes.IMAGE:
          if (state.media && commands.indexOf('show-image') === -1) {
            actionData = {
              character: state.targetCharacter.id,
              command: 'show-image',
              content: state.media,
            }
            let action = new Action(actionData, this.parentScore)
            this.addAction(action)
            state.media = null
          }
          break

        case Stepwise.FeatureTypes.VIDEO:
          if (state.media && commands.indexOf('show-video') === -1) {
            actionData = {
              character: state.targetCharacter.id,
              command: 'show-video',
              content: state.media,
            }
            let action = new Action(actionData, this.parentScore)
            this.addAction(action)
            state.media = null
          }
          break

        case Stepwise.FeatureTypes.AUDIO:
          if (state.media && commands.indexOf('play-audio') === -1) {
            actionData = {
              character: state.targetCharacter.id,
              command: 'play-audio',
              content: state.media,
            }
            let action = new Action(actionData, this.parentScore)
            this.addAction(action)
            state.media = null
          }
          break

        default:
          break
      }
    })
  }

  addAction(action) {
    this.actions.push(action)
    this.actionsById[action.id] = action
  }

  deleteAction(action) {
    let index = this.actions.indexOf(action)
    if (index !== -1) {
      this.actions.splice(index, 1)
    }
    delete this.actionsById[action.id]
  }

  sortActions() {
    this.actions.replace(
      this.actions.slice().sort(function (a, b) {
        let delayProxyA = a.delay ? a.delay : 0
        let delayProxyB = b.delay ? b.delay : 0
        return delayProxyA - delayProxyB
      })
    )
  }

  addFeature(feature) {
    if (feature && this.features.indexOf(feature) === -1) {
      this.features.push(feature)
    }
  }

  removeCharacter(character) {
    let arr = this.states
    for (let i = arr.length - 1; i >= 0; i--) {
      if (
        arr[i].targetCharacter === character ||
        arr[i].targetFeature.parentCharacter === character
      ) {
        console.log(
          'deleting ' + arr[i].type + ' state for ' + character.fullName
        )
        arr.splice(i, 1)
      }
    }
    arr = this.actions
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].targetCharacter === character) {
        console.log(
          'deleting ' + arr[i].command + ' action for ' + character.fullName
        )
        arr.splice(i, 1)
      }
    }
    arr = this.features
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].parentCharacter === character) {
        console.log(
          'deleting ' + arr[i].type + ' feature for ' + character.fullName
        )
        arr.splice(i, 1)
      }
    }
  }

  removeMedia(media) {
    let arr = this.states
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].media === media.id) {
        console.log('deleting media for ' + arr[i].targetCharacter.fullName)
        arr[i].media = null
      }
    }
  }

  containsActionForCharacter(command, character) {
    let action
    for (let i = 0; i < this.actions.length; i++) {
      action = this.actions[i]
      if (action.command === command && action.targetCharacter === character) {
        return action
      }
    }
    return false
  }

  containsStateForFeature(feature, matchCharacter = false) {
    for (let state of this.states) {
      if (matchCharacter) {
        if (
          state.type === feature.type &&
          state.targetCharacter === feature.parentCharacter
        ) {
          return true
        }
      } else {
        if (state.type === feature.type) {
          return true
        }
      }
    }
    return false
  }

  createStateForFeature(feature, stateToClone) {
    let state
    let stateData
    if (stateToClone) {
      //stateData = serialize(stateToClone);
      stateData = stateToClone.toJSON()
    } else {
      stateData = {
        type: feature.type,
        character: feature.parentCharacter.id,
        transitionCurve: TemporalState.Easing.EASEINOUT,
        transitionDuration: 0.5,
      }
    }
    switch (feature.type) {
      case Stepwise.FeatureTypes.FRAME:
        state = new FrameState(
          stateData,
          this.parentScore,
          feature.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.TEXT:
        state = new TextState(
          stateData,
          this.parentScore,
          feature.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.IMAGE:
        state = new ImageState(
          stateData,
          this.parentScore,
          feature.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.AUDIO:
        state = new AudioState(
          stateData,
          this.parentScore,
          feature.parentCharacter
        )
        break
      case Stepwise.FeatureTypes.VIDEO:
        state = new VideoState(
          stateData,
          this.parentScore,
          feature.parentCharacter
        )
        break
      default:
        break
    }
    state.setupReferences()
    this.states.push(state)
    return state
  }

  deleteStateForFeature(feature) {
    let state = this.getStateForFeatureType(feature, true)
    let index = this.states.indexOf(state)
    this.states.splice(index, 1)
  }

  getStateForFeatureType(feature, matchCharacter = false) {
    for (let state of this.states) {
      if (matchCharacter) {
        if (
          state.type === feature.type &&
          state.targetCharacter === feature.parentCharacter
        ) {
          return state
        }
      } else {
        if (state.type === feature.type) {
          return state
        }
      }
    }
    return null
  }

  getPrimaryState() {
    let state
    let enterAction
    this.actions.forEach((action) => {
      if (action.command === 'enter') {
        enterAction = action
      }
    })
    if (enterAction) {
      let feature = enterAction.targetCharacter.getFeatureForType('video')
      if (feature) {
        state = this.parentSequence.getCurrentStateForFeatureInStep(
          feature,
          this,
          false
        )
      }
      if (!state || state === feature.defaultState) {
        feature = enterAction.targetCharacter.getFeatureForType('image')
      }
      if (feature) {
        state = this.parentSequence.getCurrentStateForFeatureInStep(
          feature,
          this,
          false
        )
      }
      if (!state || state === feature.defaultState) {
        feature = enterAction.targetCharacter.getFeatureForType('text')
        state = this.parentSequence.getCurrentStateForFeatureInStep(
          feature,
          this,
          false
        )
      }
    }
    return state
  }

  execute(isSafe = false, mimic = null) {
    this.actions.forEach((action) => action.updateFeature())
    this.parentSequence.executeStep(this, isSafe, mimic)
  }

  toJSON() {
    return {
      states: this.states,
      actions: this.actions,
    }
  }
}
decorate(Step, {
  states: [serializable(list(object(TemporalState))), observable],
  actions: [serializable(list(object(Action))), observable],
  actionsById: [
    serializable(
      custom(
        () => SKIP,
        (v) => SKIP
      )
    ),
    observable,
  ],
})

// a collection of steps
class Sequence extends ScoreElement {
  constructor(data, score, scene) {
    super(data, score)
    this.parentScene = scene
  }

  init() {
    this.steps = []
    this.features = []
    this.shuffle = false
    this.repeat = false
    this.ignoreStates = false
    this.count = -1
    this.steps = []
    this.stepDuration = 4
    this.stepIndex = -1
    this.isCompleted = false
    this.isExhausted = false
    this.nextSequence = ''
    this.isPlaying = false
    this.completions = 0
    this.usedIndexes = []
    this.percentCompleted = 0
    this.prompt = ''
  }

  parse(data) {
    this.id = data.id
    this.title = data.title
    if (data.shuffle) this.shuffle = data.shuffle
    if (data.repeat) this.repeat = data.repeat
    if (data.count) this.count = data.count
    if (data.nextSequence) this.nextSequence = data.nextSequence
    if (data.type) this.type = data.type
    if (data.ignoreStates) this.ignoreStates = data.ignoreStates
    if (data.prompt) this.prompt = data.prompt
    if (data.stepDuration) this.stepDuration = data.stepDuration
    var step
    for (let index in data.steps) {
      step = new Step(data.steps[index], this.parentScore, this)
      this.addStep(step)
    }
  }

  setupReferences() {
    this.steps.forEach((step, index) => {
      step.setupReferences()
    })
    Object.values(this.parentScore.characters).forEach((character) => {
      this.addFeatures(character.features)
    })
  }

  populateStatesInFirstStep() {
    if (this.steps.length > 0) {
      this.steps[0].populateStatesForCharacters(
        Object.values(this.parentScore.characters)
      )
    }
  }

  removeReferences() {
    this.steps.forEach((step) => {
      step.removeReferences()
      this.features = []
    })
    this.parentScene = null
  }

  addFeatures(features) {
    for (let feature of features) {
      if (this.features.indexOf(feature) === -1) {
        this.features.push(feature)
      }
    }
  }

  reset(preserveCompletions = false) {
    this.stepIndex = -1
    this.isCompleted = false
    if (!preserveCompletions) this.completions = 0
    this.isExhausted = false
    this.isPlaying = false
    this.percentCompleted = 0
  }

  addStep(step) {
    this.steps.push(step)
  }

  addStepAfterStep(step, newStep) {
    let index = this.steps.indexOf(step)
    if (index !== -1) {
      this.steps.splice(index + 1, 0, newStep)
    }
  }

  play() {
    this.isPlaying = true
    if (this.playEvent) this.playEvent.clear()
    this.playEvent = this.parentScore.instance.eventManager.clock
      .callbackAtTime(() => this.nextStep(), 0)
      .repeat(
        this.stepDuration *
          this.parentScore.currentScene.pulse.millisecondDuration *
          0.001
      )
  }

  stop() {
    this.isPlaying = false
    if (this.playEvent) this.playEvent.clear()
  }

  goToNextSequence() {
    let nextSeq = this.parentScene.getSequence(this.nextSequence)
    if (nextSeq) {
      if (nextSeq.isExhausted) {
        nextSeq.reset()
      }
      this.parentScene.setSequence(nextSeq)
    }
  }

  nextStep(mimic = null) {
    var result = null
    if (this.steps.length > 0) {
      // if the sequence hasn't been exhausted, then
      if (!this.isExhausted) {
        // if the sequence is not shuffled, then
        if (!this.shuffle) {
          // if the sequence has been completed and is set to repeat, then restart it
          if (this.isCompleted && this.repeat) {
            // console.log('sequence was completed; resetting');
            this.reset(true)
          }
          this.stepIndex++
          result = this.steps[this.stepIndex].execute(false, mimic)
          this.percentCompleted = this.stepIndex / parseFloat(this.steps.Count)
          // console.log("step " + this.stepIndex);
          // if this is the last step in the sequence, then
          if (this.stepIndex >= this.steps.length - 1) {
            this.completions++
            // console.log('sequence ' + this.id + ' reached its end');
            // if the sequence is set to repeat, then
            if (this.repeat) {
              // console.log('this is a repeating sequence');
              if (this.count > -1) {
                // console.log('a count has been specified: ' + this.count);
                if (this.completions >= this.count) {
                  // console.log('the count has been exhausted: ' + this.completions + ' completions');
                  this.isExhausted = true
                  if (this.isPlaying) this.stop()
                  if (this.nextSequence !== '') {
                    this.goToNextSequence()
                  }
                } else {
                  // console.log(this.completions + ' out of ' + this.count + ' completions; resetting for another round');
                  this.reset(true)
                }
              } else {
                // console.log('no count specified; resetting for another round');
                this.reset(true)
              }
              // otherwise, if the sequence is not set to repeat, then mark it as completed
            } else {
              // console.log('this is a non-repeating sequence');
              if (this.count > -1) {
                // console.log('a count has been specified: ' + this.count);
                if (this.completions >= this.count) {
                  // console.log('the count has been exhausted: ' + this.completions + ' completions');
                  this.isExhausted = true
                  if (this.isPlaying) this.stop()
                  if (this.nextSequence !== '') {
                    this.goToNextSequence()
                  }
                } else {
                  // console.log('the sequence is completed');
                  this.isCompleted = true
                }
              } else {
                // console.log('no count specified; sequence is completed');
                this.isCompleted = true
                this.isExhausted = true
                if (this.nextSequence !== '') {
                  this.goToNextSequence()
                }
              }
            }
          }
          // shuffled playback
        } else {
          // console.log('this is a shuffled sequence');
          do {
            this.stepIndex = Math.floor(Math.random() * this.steps.length)
          } while (this.usedIndexes.indexOf(this.stepIndex) !== -1)
          this.usedIndexes.push(this.stepIndex)
          if (this.usedIndexes.length >= this.steps.length) {
            // console.log('used up all of the steps; starting over');
            this.usedIndexes = []
            this.completions++
            this.isCompleted = true
          }
          if (this.count !== -1 && this.completions >= this.count) {
            // console.log('the count has been exhausted: ' + this.completions + ' completions');
            this.isExhausted = true
            if (this.nextSequence !== '') {
              this.goToNextSequence()
            }
            if (this.isPlaying) this.stop()
          }
          result = this.steps[this.stepIndex].execute(false, mimic)
        }
      }
    }
    return result
  }

  deleteStep(step) {
    let index = this.steps.indexOf(step)
    if (index !== -1) {
      this.steps.splice(index, 1)
    }
  }

  setCurrentStep(step) {
    let index = this.steps.indexOf(step)
    if (index !== -1) {
      this.stepIndex = index
    }
  }

  getCurrentStateForCharacterInStep(character, featureType, step) {
    let feature = character.getFeatureForType(featureType)
    return this.getCurrentStateForFeatureInStep(feature, step, true, true)
  }

  getCurrentStateForFeatureInStep(
    feature,
    step,
    lookBack = true,
    matchCharacter = false,
    returnDefault = true
  ) {
    let state, priorStep
    var index = this.steps.indexOf(step)
    if (index !== -1) {
      if (step.containsStateForFeature(feature, matchCharacter)) {
        //console.log(feature.type,'got from this step',step.states);
        state = step.getStateForFeatureType(feature, matchCharacter)
      } else if (lookBack) {
        // look for the nearest previous state that does
        for (var i = index - 1; i >= 0; i--) {
          priorStep = this.steps[i]
          if (priorStep.containsStateForFeature(feature, matchCharacter)) {
            //console.log(feature.type,'got from prior step');
            state = priorStep.getStateForFeatureType(feature, matchCharacter)
            break
          }
        }
        if (!state) {
          //console.log(feature.parentCharacter.fullName,feature.type,'got default');
          if (returnDefault) state = feature.defaultState
        }
      } else {
        //console.log(feature.parentCharacter.fullName,feature.type,'got default');
        if (returnDefault) state = feature.defaultState
      }
    } else {
      //console.log(feature.parentCharacter.fullName,feature.type,'got default');
      if (returnDefault) state = feature.defaultState
    }
    return state
  }

  getPriorActionForCharacterFromStep(command, character, step) {
    let action, priorStep
    var index = this.steps.indexOf(step)
    if (index !== -1) {
      for (let i = index; i >= 0; i--) {
        priorStep = this.steps[i]
        action = priorStep.containsActionForCharacter(command, character)
        if (action) {
          break
        }
      }
    }
    return action
  }

  getPriorStateIndexForState(currentState) {
    for (var i = this.stepIndex - 1; i >= 0; i--) {
      var step = this.steps[i]
      for (var j = 0; j < step.states.length; j++) {
        var state = step.states[j]
        if (state && currentState) {
          if (
            (state.type === currentState.type &&
              state.targetCharacter === currentState.targetCharacter) ||
            i === 0
          ) {
            return i
          }
        }
      }
    }
    return -1
  }

  getNextStateIndexForState(currentState) {
    for (var i = this.stepIndex + 1; i < this.steps.length; i++) {
      var step = this.steps[i]
      for (var j = 0; j < step.states.length; j++) {
        var state = step.states[j]
        if (
          state.type === currentState.type &&
          state.targetCharacter === currentState.targetCharacter
        ) {
          return i
        }
      }
    }
    return -1
  }

  containsActionForCharacter(command, character) {
    this.steps.forEach((step) => {
      let action = step.containsActionForCharacter(command, character)
      if (action) {
        return action
      }
    })
    return false
  }

  executeStep(step, isSafe = false, mimic = null) {
    this.parentScore.instance.eventManager.triggerStep(step)
    var priorStep
    var states = []
    var index = this.steps.indexOf(step)
    if (index !== -1) {
      if (!this.ignoreStates) {
        // gather the current state of every feature touched by this sequence
        let state
        for (let feature of this.features) {
          state = null
          // if the step being executed doesn't touch the current feature,
          if (step.containsStateForFeature(feature, true)) {
            state = step.getStateForFeatureType(feature, true)
          } else {
            // look for the nearest previous state that does
            for (var i = index - 1; i >= 0; i--) {
              priorStep = this.steps[i]
              if (priorStep.containsStateForFeature(feature, true)) {
                state = priorStep.getStateForFeatureType(feature, true)
                break
              }
            }
          }
          if (!state) {
            state = feature.defaultState
          }
          states.push(state)
        }
        this.parentScore.instance.eventManager.triggerStates(states)
      }
      step.actions.forEach((action) => {
        let millisecondDelay =
          action.delay * this.parentScene.pulse.millisecondDuration
        let actionProxy = action
        if (mimic) {
          let data = action.toJSON()
          data.character = mimic.id
          actionProxy = new Action(data, this.parentScore)
        }
        this.parentScore.instance.eventManager.triggerAction(
          actionProxy,
          millisecondDelay,
          isSafe
        )
      })
    }
  }

  toJSON() {
    var illegalProperties = [
      'features',
      'count',
      'stepIndex',
      'isCompleted',
      'isExhausted',
      'completions',
      'usedIndexes',
      'percentCompleted',
    ]
    var obj = {}
    for (let property in this) {
      if (illegalProperties.indexOf(property) === -1) {
        obj[property] = this[property]
      }
    }
    return obj
  }
}
decorate(Sequence, {
  id: [serializable(identifier()), observable],
  title: [serializable(primitive()), observable],
  prompt: [serializable(primitive()), observable],
  steps: [serializable(list(object(Step))), observable],
  shuffle: [serializable(primitive()), observable],
  repeat: [serializable(primitive()), observable],
  count: [serializable(primitive()), observable],
  nextSequence: [serializable(primitive()), observable],
  ignoreStates: [serializable(primitive()), observable],
  stepIndex: [observable],
  stepDuration: [serializable(primitive()), observable],
  isPlaying: [serializable(primitive()), observable],
})

class Pulse {
  constructor(data) {
    this.beatsPerMinute = 120
    this.pulsesPerBeat = 4
    this.durationPerBeat = 4
    this.swing = 1
    if (data) {
      for (let property in data) {
        this[property] = data[property]
      }
    }
    this.calculateTempo()
  }

  setBeatsPerMinute(bpm) {
    this.beatsPerMinute = bpm
    this.calculateTempo()
    Tone.Transport.bpm.value = bpm
  }

  setPulsesPerBeat(ppb) {
    this.pulsesPerBeat = ppb
    this.calculateTempo()
  }

  calculateTempo() {
    this.millisecondDuration =
      (60 * 1000) / this.beatsPerMinute / this.pulsesPerBeat
    this.secondDuration = this.millisecondDuration * 0.001
  }
}
decorate(Pulse, {
  beatsPerMinute: [serializable(primitive()), observable],
  pulsesPerBeat: [serializable(primitive()), observable],
  durationPerBeat: [serializable(primitive()), observable],
  swing: [serializable(primitive()), observable],
  millisecondDuration: [observable],
})

// a collection of sequences
class Scene extends ScoreElement {
  init() {
    this.sequences = {}
    this.currentSequence = null
    let data = {
      id: 'history',
      title: 'History',
      repeat: false,
      ignoreStates: false,
      steps: [{ states: [], actions: [] }],
    }
    this.history = new Sequence(data, this.parentScore, this)
  }

  parse(data) {
    this.id = data.id
    this.title = data.title
    data.sequences.forEach((sequenceData) =>
      this.addSequence(new Sequence(sequenceData, this.parentScore, this))
    )
    this.currentSequence = this.defaultSequence =
      this.sequences[data.sequences[0].id]
    this.pulse = new Pulse(data.pulse)
  }

  setupReferences() {
    Object.values(this.sequences).forEach((sequence) => {
      sequence.setupReferences()
    })
    // the default sequence needs to have some default states
    this.defaultSequence.populateStatesInFirstStep()
  }

  removeReferences() {
    Object.values(this.sequences).forEach((sequence) => {
      sequence.removeReferences()
    })
    this.parentScore = null
  }

  addSequence(sequence) {
    this.sequences[sequence.id] = sequence
  }

  setSequence(sequence) {
    this.currentSequence = sequence
    this.parentScore.instance.eventManager.triggerSequence(sequence)
  }

  playSequence(sequence) {
    this.setSequence(sequence)
    sequence.nextStep()
  }

  createSequence() {
    let data = {
      id: uuidv4(),
      title: 'Sequence ' + (Object.values(this.sequences).length + 1),
      repeat: true,
      ignoreStates: true,
      steps: [{ states: [], actions: [] }],
    }
    var sequence = new Sequence(data, this.parentScore, this)
    this.sequences[sequence.id] = sequence
    return sequence
  }

  deleteSequence(sequence) {
    let seqArr = Object.values(this.sequences)
    if (seqArr.length > 1) {
      delete this.sequences[sequence.id]
      this.setSequence(seqArr[0])
    }
  }

  getSequence(id) {
    return this.sequences[id]
  }

  reset() {
    Object.values(this.sequences).forEach((sequence) => {
      sequence.reset()
    })
  }

  stop() {
    Object.values(this.sequences).forEach((sequence) => {
      sequence.stop()
    })
  }

  nextStep() {
    //console.log('scene next step');
    let step = null
    if (this.currentSequence) {
      if (!this.currentSequence.isExhausted) {
        step = this.currentSequence.nextStep()
      }
    }
    return step
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      pulse: this.pulse,
      sequences: Object.values(this.sequences),
    }
  }
}
decorate(Scene, {
  id: [serializable(identifier()), observable],
  title: [serializable(primitive()), observable],
  pulse: [serializable(object(Pulse)), observable],
  sequences: [serializable(mapAsArray(object(Sequence), 'id')), observable],
})

// represents an entire story
class Score {
  constructor(instance) {
    this.title = 'Untitled'
    this.primaryCredit = ''
    this.secondaryCredit = ''
    this.durationDescription = ''
    this.stageWidth = 1920
    this.stageHeight = 1080
    this.enforceStageSize = false
    this.preventInputDuringDelays = true
    this.features = {}
    this.nonDefaultFeatures = {}
    this.channels = {}
    this.characters = {}
    this.scenes = {}
    this.media = {}
    this.currentScene = null
    this.analyticsId = null
    this.instance = instance
  }

  init(data, format) {
    this.addDefaultChannel()
    switch (format.toLowerCase()) {
      case 'json':
        this.parseJSON(data)
        break
      default:
        break
    }
  }

  parseJSON(data) {
    if (data.title) this.title = data.title
    if (data.primaryCredit) this.primaryCredit = data.primaryCredit
    if (data.secondaryCredit) this.secondaryCredit = data.secondaryCredit
    if (data.durationDescription)
      this.durationDescription = data.durationDescription
    if (data.stageWidth) this.stageWidth = data.stageWidth
    if (data.stageHeight) this.stageHeight = data.stageHeight
    if (data.enforceStageSize !== undefined)
      this.enforceStageSize = data.enforceStageSize
    if (data.preventInputDuringDelays !== undefined)
      this.preventInputDuringDelays = data.preventInputDuringDelays
    if (data.analyticsId) this.analyticsId = data.analyticsId
    data.characters.forEach((characterData) => {
      this.addCharacter(new Character(characterData, this))
    })
    data.scenes.forEach((sceneData) =>
      this.addScene(new Scene(sceneData, this))
    )
    this.currentScene = this.scenes[data.scenes[0].id]
    data.media.forEach((mediaData) => this.addMedia(new Media(mediaData)))
    setTimeout(() => {
      this.setupReferences()
      this.instance.eventManager.sendMessage('scoreLoaded', this)
    }, 1)
  }

  setupReferences() {
    Object.values(this.features).forEach((feature) => {
      feature.setupReferences()
    })
    Object.values(this.scenes).forEach((scene) => {
      scene.setupReferences()
    })
  }

  removeReferences() {
    Object.values(this.features).forEach((feature) => {
      feature.removeReferences()
    })
    Object.values(this.scenes).forEach((scene) => {
      scene.removeReferences()
    })
    Object.values(this.characters).forEach((character) => {
      character.removeReferences()
    })
  }

  addDefaultChannel() {
    var channel = new Channel({
      id: 'main',
      title: 'Main',
      grid: { columns: 12, rows: 12 },
      layout: { left: 0, top: 0, width: 12, height: 12 },
    })
    this.addChannel(channel)
  }

  addChannel(channel) {
    this.channels[channel.id] = channel
  }

  getChannel(id) {
    return this.channels[id]
  }

  getChannelForCharacter(id) {
    let channels = Object.values(this.channels)
    for (let channel of channels) {
      if (channel.characters[id]) {
        return channel
      }
    }
    return null
  }

  addCharacter(character) {
    this.characters[character.id] = character
  }

  getCharacter(id) {
    return this.characters[id]
  }

  deleteCharacter(character) {
    Object.values(this.scenes).forEach((scene) => {
      Object.values(scene.sequences).forEach((sequence) => {
        sequence.steps.forEach((step) => {
          step.removeCharacter(character)
        })
      })
    })
    let arr = Object.values(this.features)
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].parentCharacter === character) {
        delete this.features[arr[i].id]
      }
    }
    Object.values(this.channels).forEach((channel) => {
      channel.removeCharacter(character)
    })
    delete this.characters[character.id]
    this.instance.eventManager.sendMessage('characterWasDeleted', character.id)
  }

  addFeature(feature) {
    this.features[feature.id] = feature
    if (!feature.isDefault) {
      this.nonDefaultFeatures[feature.id] = feature
    }
  }

  getFeature(id) {
    return this.features[id]
  }

  addMedia(media) {
    if (Array.isArray(media)) {
      media.forEach((mediaItem) => {
        this.media[mediaItem.id] = mediaItem
      })
    } else {
      this.media[media.id] = media
    }
  }

  getMedia(id) {
    return this.media[id]
  }

  deleteMedia(media) {
    delete this.media[media.id]
    Object.values(this.scenes).forEach((scene) => {
      Object.values(scene.sequences).forEach((sequence) => {
        sequence.steps.forEach((step) => {
          step.removeMedia(media)
        })
      })
    })
  }

  addScene(scene) {
    this.scenes[scene.id] = scene
  }

  setScene(scene) {
    this.currentScene = scene
  }

  reset() {
    Object.values(this.scenes).forEach((scene) => {
      scene.reset()
    })
  }

  stop() {
    Object.values(this.scenes).forEach((scene) => {
      scene.stop()
    })
  }

  nextStep() {
    if (Tone.Transport.state !== 'started') {
      Tone.start()
      Tone.Transport.start()
    }
    if (this.currentScene) {
      return this.currentScene.nextStep()
    }
    return null
  }

  toJSON() {
    let featureData = []
    Object.values(this.features).forEach((feature) => {
      if (!feature.isDefault) {
        featureData.push(feature)
      }
    })
    return {
      characters: Object.values(this.characters),
      features: featureData,
      media: Object.values(this.media),
      scenes: Object.values(this.scenes),
    }
  }
}
decorate(Score, {
  title: [serializable(primitive()), observable],
  primaryCredit: [serializable(primitive()), observable],
  secondaryCredit: [serializable(primitive()), observable],
  durationDescription: [serializable(primitive()), observable],
  stageWidth: [serializable(primitive()), observable],
  stageHeight: [serializable(primitive()), observable],
  enforceStageSize: [serializable(primitive()), observable],
  preventInputDuringDelays: [serializable(primitive()), observable],
  analyticsId: [serializable(primitive()), observable],
  characters: [serializable(mapAsArray(object(Character), 'id')), observable],
  nonDefaultFeatures: [
    serializable(alias('features', mapAsArray(object(Feature), 'id'))),
    observable,
  ],
  media: [serializable(mapAsArray(object(Media), 'id')), observable],
  scenes: [serializable(mapAsArray(object(Scene), 'id')), observable],
})

class Channel {
  constructor(data) {
    this.characters = {}
    this.parse(data)
  }

  parse(data) {
    for (let property in data) {
      this[property] = data[property]
    }
  }

  addCharacter(character) {
    this.characters[character.id] = character
    character.setChannel(this)
  }

  removeCharacter(character) {
    delete this.characters[character.id]
  }

  getVisibleCharacters() {
    var returnedCharacters = []
    Object.values(this.characters).forEach((character) => {
      if (character.visible) {
        returnedCharacters.push(character)
      }
    })
    return returnedCharacters
  }
}

// a location in the story
/*class Location {

}*/

export {
  Stepwise,
  Score,
  Scene,
  Sequence,
  Step,
  Character,
  TemporalState,
  FrameState,
  ImageState,
  TextState,
  VideoState,
  AudioState,
  Media,
  Action,
}

/*
- Automatic routing: image feature, video feature, audio feature,
- 3D features require a camera, one could be created automatically
- Order in list determines order of features being routed
*/
