import React from 'react'
import { observer } from 'mobx-react'
import {
  FormGroup,
  InputGroup,
  Dialog,
  Classes,
  Button,
  Intent,
  NumericInput,
} from '@blueprintjs/core'

const SceneSettingsDialog = observer(
  class SceneSettingsDialog extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
    }

    handlePropertyChange(evt, property, value) {
      this.props.scene[property] = value ? value : evt.currentTarget.value
    }

    handleNumericPropertyChange(property, valueAsNumber) {
      switch (property) {
        case 'beatsPerMinute':
          this.props.scene.pulse.setBeatsPerMinute(valueAsNumber)
          break
        case 'pulsesPerBeat':
          this.props.scene.pulse.setPulsesPerBeat(valueAsNumber)
          break
        default:
          this.props.scene[property] = valueAsNumber
          break
      }
    }

    render() {
      return (
        <Dialog
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          icon="settings"
          title="Scene Settings"
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Title" labelFor="title-input">
              <InputGroup
                id="title-input"
                placeholder="Enter scene title"
                value={this.props.scene.title}
                onChange={(evt) =>
                  this.handlePropertyChange(evt, 'title', evt.target.value)
                }
              />
            </FormGroup>
            <div className="form-col">
              <FormGroup label="Beats per minute" labelFor="bpm-input">
                <NumericInput
                  id="bpm-input"
                  style={{ width: 60 }}
                  value={this.props.scene.pulse.beatsPerMinute}
                  onValueChange={(valueAsNumber) =>
                    this.handleNumericPropertyChange(
                      'beatsPerMinute',
                      valueAsNumber
                    )
                  }
                  placeholder="120"
                  min="1"
                />
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Pulses per beat" labelFor="ppb-input">
                <NumericInput
                  id="ppb-input"
                  style={{ width: 60 }}
                  value={this.props.scene.pulse.pulsesPerBeat}
                  disabled={true}
                  onValueChange={(valueAsNumber) =>
                    this.handleNumericPropertyChange(
                      'pulsesPerBeat',
                      valueAsNumber
                    )
                  }
                  placeholder="4"
                  min="1"
                />
              </FormGroup>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.props.onClose} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }
  }
)

export default SceneSettingsDialog
