import React from 'react'
import {
  FormGroup,
  Popover,
  Button,
  Slider,
  ControlGroup,
  NumericInput,
  InputGroup,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'
import { SketchPicker } from 'react-color'

const FrameStateEditor = observer(
  class FrameStateEditor extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
    }

    handleNumericChange(command, value, property) {
      let temp
      switch (command) {
        case null:
          switch (property) {
            case 'borderWidth':
              this.props.state[property] = value + 'px'
              break

            default:
              this.props.state[property] = value
              this.forceUpdate()
              break
          }
          break

        case 'layout':
          let layout = this.props.state.layout
          temp = layout.split(' ')
          switch (property) {
            case 'left':
              temp[0] = value
              break
            case 'top':
              temp[1] = value
              break
            case 'width':
              temp[2] = value
              break
            case 'height':
              temp[3] = value
              break
            default:
              break
          }
          layout = temp.join(' ')
          this.props.state.layout = layout
          break

        case 'margin':
          let margin = this.props.state.margin
          temp = margin.split(' ')
          if (temp.length === 1) {
            temp.push(temp[0])
            temp.push(temp[0])
            temp.push(temp[0])
          } else if (temp.length === 2) {
            temp.splice(1, null, temp[0])
            temp.push(temp[2])
          }
          switch (property) {
            case 'top':
              temp[0] = parseInt(value)
              break
            case 'right':
              temp[1] = parseInt(value)
              break
            case 'bottom':
              temp[2] = parseInt(value)
              break
            case 'left':
              temp[3] = parseInt(value)
              break
            default:
              break
          }
          margin =
            parseInt(temp[0]) +
            'px ' +
            parseInt(temp[1]) +
            'px ' +
            parseInt(temp[2]) +
            'px ' +
            parseInt(temp[3]) +
            'px'
          this.props.state.margin = margin
          break

        default:
          break
      }
      this.props.onStepwiseEvent('state', this.props.state)
    }

    handleColorChange(property, color) {
      this.props.state[property] =
        'rgba(' +
        color.rgb.r +
        ',' +
        color.rgb.g +
        ',' +
        color.rgb.b +
        ',' +
        color.rgb.a +
        ')'
      this.props.onStepwiseEvent('state', this.props.state)
    }

    handleSliderChange(property, value) {
      this.props.state[property] = value
      this.props.onStepwiseEvent('state', this.props.state)
    }

    handleSwitchChange(evt, property) {
      this.props.state[property] = evt.currentTarget.checked
    }

    handlePropertyChange(evt, property, value) {
      let propValue = value || evt.currentTarget.value
      this.props.state[property] = propValue
      this.props.onStepwiseEvent('state', this.props.state)
    }

    render() {
      return (
        <div className="state-editor-controls">
          <Popover>
            <Button minimal="true">Margin</Button>
            <div className="state-editor-popover">
              <FormGroup helperText="Left, top, right, bottom">
                <ControlGroup id="margin-input">
                  <NumericInput
                    id="margin-left-input"
                    style={{ width: this.fieldWidth }}
                    value={window.getMarginComponent(
                      this.props.state.margin,
                      'left'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('margin', value, 'left')
                    }
                  />
                  <NumericInput
                    id="margin-top-input"
                    style={{ width: this.fieldWidth }}
                    value={window.getMarginComponent(
                      this.props.state.margin,
                      'top'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('margin', value, 'top')
                    }
                  />
                  <NumericInput
                    id="margin-right-input"
                    style={{ width: this.fieldWidth }}
                    value={window.getMarginComponent(
                      this.props.state.margin,
                      'right'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('margin', value, 'right')
                    }
                  />
                  <NumericInput
                    id="margin-bottom-input"
                    style={{ width: this.fieldWidth }}
                    value={window.getMarginComponent(
                      this.props.state.margin,
                      'bottom'
                    )}
                    onValueChange={(value) =>
                      this.handleNumericChange('margin', value, 'bottom')
                    }
                  />
                </ControlGroup>
              </FormGroup>
            </div>
          </Popover>
          <Popover>
            <Button minimal="true">Border</Button>
            <div className="state-editor-popover">
              <FormGroup inline={true}>
                <NumericInput
                  id="border-width-input"
                  style={{ width: this.fieldWidth }}
                  value={parseInt(this.props.state.borderWidth)}
                  min="0"
                  onValueChange={(value) =>
                    this.handleNumericChange(null, value, 'borderWidth')
                  }
                />
              </FormGroup>{' '}
              &nbsp;&nbsp;
              <FormGroup inline={true}>
                <Popover id="border-color-input" usePortal={false}>
                  <div className="swatch">
                    <div
                      className="color"
                      style={{ backgroundColor: this.props.state.borderColor }}
                    />
                  </div>
                  <SketchPicker
                    color={window.getRGBAColor(this.props.state.borderColor)}
                    onChangeComplete={(color) =>
                      this.handleColorChange('borderColor', color)
                    }
                  />
                </Popover>
              </FormGroup>
            </div>
          </Popover>{' '}
          &nbsp;&nbsp;
          <FormGroup inline={true}>
            <Popover id="background-color-input">
              <div className="swatch">
                <div
                  className="color"
                  style={{ backgroundColor: this.props.state.backgroundColor }}
                />
              </div>
              <SketchPicker
                color={window.getRGBAColor(this.props.state.backgroundColor)}
                onChangeComplete={(color) =>
                  this.handleColorChange('backgroundColor', color)
                }
              />
            </Popover>
          </FormGroup>{' '}
          &nbsp;&nbsp;
          <Popover>
            <Button minimal="true" icon="settings" />
            <div className="text-settings">
              <FormGroup
                label="Opacity &nbsp;&nbsp;"
                labelFor="opacity-slider"
                style={{ marginRight: '20px' }}
                inline={true}
              >
                <Slider
                  id="opacity-slider"
                  min={0}
                  max={1}
                  labelStepSize={1}
                  labelPrecision={1}
                  stepSize={0.01}
                  vertical={false}
                  value={this.props.state.opacity}
                  onChange={(value) =>
                    this.handleSliderChange('opacity', value)
                  }
                />
              </FormGroup>
              <FormGroup
                label="Transition duration (secs)"
                labelFor="transition-duration-input"
                inline={true}
              >
                <InputGroup
                  id="transition-duration-input"
                  style={{ width: '50px' }}
                  placeholder="secs"
                  value={this.props.state.transitionDuration}
                  onChange={(evt) =>
                    this.handlePropertyChange(evt, 'transitionDuration')
                  }
                />
              </FormGroup>
            </div>
          </Popover>
          <Popover>
            <Button minimal="true" icon="layers" />
            <div className="state-editor-popover">
              <FormGroup label="Depth" inline={true} labelFor="depth-input">
                <NumericInput
                  id="depth-input"
                  style={{ width: this.fieldWidth }}
                  value={parseInt(this.props.state.depth)}
                  onValueChange={(value) =>
                    this.handleNumericChange(null, value, 'depth')
                  }
                />
              </FormGroup>
            </div>
          </Popover>
        </div>
      )
    }
  }
)

export default FrameStateEditor
