import React from 'react'
import { InputGroup, FormGroup } from '@blueprintjs/core'

class GiphySearchTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: '',
    }
  }

  onKeywordChange(evt) {
    this.setState({ keyword: evt.currentTarget.value })
  }

  getSearchParameters(returnHandler) {
    let parameters = {
      keyword: this.state.keyword,
    }
    returnHandler('giphy', parameters)
  }

  render() {
    return (
      <div>
        <p className="bp3-text-large">
          GIPHY is your top source for the best & newest GIFs & Animated
          Stickers online.
        </p>
        <br />
        <FormGroup>
          <InputGroup
            id="keyword-input"
            value={this.state.keyword}
            placeholder="Search for images..."
            onChange={this.onKeywordChange.bind(this)}
          />
        </FormGroup>
        <p className="bp3-text-small">
          Powered by{' '}
          <a href="https://giphy.com" target="_blank" rel="noopener noreferrer">
            Giphy
          </a>
        </p>
      </div>
    )
  }
}

export default GiphySearchTab
