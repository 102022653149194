import React from 'react'
import { FormGroup, TextArea, HTMLSelect } from '@blueprintjs/core'

class TextComposerTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      textContent: '',
      character: '',
      split: 'none',
      enter: false,
      newStep: false,
    }
    this.splitOptions = [
      { label: 'None', value: 'none' },
      //{label: 'Sentences', value: 'sentence'},
      { label: 'Words', value: 'word' },
      //{label: 'Syllables', value: 'syllable'}
    ]
  }

  getCharacters() {
    let characters = [{ label: 'Select a character', value: '' }]
    Object.values(this.props.stepwise.score.characters).forEach((character) => {
      characters.push({ label: character.fullName, value: character.id })
    })
    return characters
  }

  getSearchParameters(returnHandler) {
    let parameters = {
      content: this.state.textContent,
      split: this.state.split,
      character: this.state.character,
      enter: this.state.enter,
      newState: this.state.newState,
    }
    returnHandler('text', parameters)
  }

  handleTextChange(evt) {
    this.setState({ textContent: evt.currentTarget.value })
  }

  handleSplitChange(evt) {
    this.setState({ split: evt.currentTarget.value })
  }

  handleCharacterChange(evt) {
    this.setState({ character: evt.currentTarget.value })
  }

  render() {
    return (
      <div>
        <p className="bp3-text-large">
          Text entered below can be added as a single action, or split across
          multiple delayed actions for a “typing” effect.
        </p>
        <FormGroup label="" labelFor="text-input">
          <TextArea
            id="text-input"
            placeholder="Enter text to add..."
            growVertically={true}
            fill={true}
            onChange={this.handleTextChange.bind(this)}
            value={this.state.textContent}
          />
        </FormGroup>
        <FormGroup label="Split on" labelFor="split-select" inline={true}>
          <HTMLSelect
            id="split-select"
            options={this.splitOptions}
            value={this.state.split}
            onChange={this.handleSplitChange.bind(this)}
          />
        </FormGroup>
        <FormGroup label="Spoken by" labelFor="character-select" inline={true}>
          <HTMLSelect
            id="split-select"
            options={this.getCharacters()}
            value={this.state.character}
            onChange={this.handleCharacterChange.bind(this)}
          />
        </FormGroup>
      </div>
    )
  }
}

export default TextComposerTab
