import React from 'react'
import {
  Button,
  Dialog,
  Classes,
  Intent,
  FormGroup,
  Tabs,
  Tab,
  PanelStack,
} from '@blueprintjs/core'
import {
  Character,
  Media,
  Step,
  Action,
} from './stepworks/stepwise/stepwise-v2.js'
import AddMediaControl from './AddMediaControl.js'
import MediaPreview from './MediaPreview.js'
import TunesMapSearch from './TunesMapSearch.js'
import TunesMapSearchTab from './TunesMapSearchTab.js'
import UnsplashSearch from './UnsplashSearch.js'
import UnsplashSearchTab from './UnsplashSearchTab.js'
import TextComposerTab from './TextComposerTab.js'
import GiphySearch from './GiphySearch.js'
import SimpleInputPanel from './SimpleInputPanel.js'
import './stepworks/stepworks-studio-utils.js'
import { v4 as uuidv4 } from 'uuid'
import { uniqueNamesGenerator, names } from 'unique-names-generator'

class EZCreateDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: null,
      addMediaKeys: [],
      selectedTabId: 'tunesmap-tab',
      searchResults: null,
      selectedMediaIndices: [],
    }
    this.addMediaData = []
    this.handleAddMediaEnter = this.handleAddMediaEnter.bind(this)
    this.handleAddMediaChange = this.handleAddMediaChange.bind(this)
    this.handleAddMediaDelete = this.handleAddMediaDelete.bind(this)
    this.addMediaToSequence = this.addMediaToSequence.bind(this)
    this.handleMediaSelected = this.handleMediaSelected.bind(this)
    this.handleSimpleSearchSubmit = this.handleSimpleSearchSubmit.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.handleSearchResults = this.handleSearchResults.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
    this.searchRefs = {
      tunesmap: React.createRef(),
      unsplash: React.createRef(),
      giphy: React.createRef(),
    }
    this.searchInterfaceRefs = {
      'tunesmap-tab': React.createRef(),
      'unsplash-tab': React.createRef(),
      'text-tab': React.createRef(),
    }
    this.searchPage = 1
    this.waitingForSearchResults = false
    this.panelStack = [this.getPanelConfig('init')]
  }

  componentDidMount() {}

  onSuccess(result) {
    console.log(result)
  }

  getPanelConfig(panelId) {
    let config = {
      component: SimpleInputPanel,
      props: {
        panelId: panelId,
        onSubmit: this.handleSimpleSearchSubmit,
        onExpand: this.handleExpand,
        tunesmap: this.searchRefs['tunesmap'],
      },
      title: panelId,
    }
    switch (panelId) {
      case 'init':
        if (process.env.REACT_APP_BRANDING === 'tunesmap') {
          config.props.control = [
            'field',
            'field',
            'field',
            'field',
            'field',
            'button',
          ]
          config.props.domains = [
            'text',
            'linkedMedia',
            'tunesmap',
            'unsplash',
            'giphy',
            'webcam',
          ]
          config.props.placeholders = [
            'Enter text to add...',
            'Enter url to a media file...',
            'Search for a musician...',
            'Search for an image...',
            'Search for a gif...',
            'Add your webcam',
          ]
          config.props.dialog = [true, false, true, true, false, false]
        } else {
          config.props.control = ['field', 'field', 'field', 'field', 'button']
          config.props.domains = [
            'text',
            'linkedMedia',
            'unsplash',
            'giphy',
            'webcam',
          ]
          config.props.placeholders = [
            'Enter text to add...',
            'Enter url to a media file...',
            'Search for an image...',
            'Search for a gif...',
            'Add your webcam',
          ]
          config.props.dialog = [true, false, true, false, false]
        }
        break

      default:
        break
    }
    return config
  }

  handleExpand(domain) {
    switch (domain) {
      case 'addMedia':
        this.openDialog('addMedia')
        break

      case 'tunesmap':
      case 'unsplash':
      case 'text':
        this.setState({ selectedTabId: domain + '-tab' })
        this.openDialog('search')
        break

      default:
        break
    }
  }

  openDialog(dialog, data) {
    let state = { view: dialog }
    switch (dialog) {
      case 'addMedia':
        let addMediaKeys = []
        this.addMediaData = []
        if (!data) {
          data = this.getDefaultMediaData()
        }
        addMediaKeys.push(data.key)
        this.addMediaData.push(data)
        this.setState({ addMediaKeys: addMediaKeys })
        break

      default:
        break
    }
    this.setState(state)
  }

  getDefaultMediaData() {
    return {
      key: String.fromCharCode(97 + this.state.addMediaKeys.length),
      type: null,
      name: '',
      url: '',
    }
  }

  closeDialog(dialog) {
    this.setState({ view: null })
    /*switch (dialog) {

      case 'addMedia':
      case 'search':
      this.setState({view: 'main'});
      break;

      case 'selectMedia':
      this.setState({view: null});
      break;
    }*/
  }

  handleAddMediaEnter(index) {
    let addMediaKeys = this.state.addMediaKeys
    let mediaData = this.getDefaultMediaData()
    addMediaKeys.splice(index + 1, 0, mediaData.key)
    this.addMediaData.splice(index + 1, 0, mediaData)
    this.setState({ addMediaKeys: addMediaKeys })
  }

  handleAddMediaChange(index, mediaData) {
    this.addMediaData[index] = mediaData
  }

  handleAddMediaDelete(index) {
    if (this.state.addMediaKeys.length > 1) {
      let addMediaKeys = this.state.addMediaKeys
      addMediaKeys.splice(index, 1)
      this.addMediaData.splice(index, 1)
      this.setState({ addMediaKeys: addMediaKeys })
    }
  }

  addSelectedMedia() {
    let addedMedia = []
    this.state.selectedMediaIndices.forEach((value) => {
      let mediaItem = new Media(this.state.searchResults[value])
      addedMedia.push(mediaItem)
    })
    this.props.stepwise.score.addMedia(addedMedia)
    this.addMediaToSequence(addedMedia)
    this.closeDialog('selectMedia')
  }

  // adds a piece of media to the library, then adds it to the sequence
  addMedia() {
    let addedMedia = []
    let score = this.props.stepwise.score
    this.addMediaData.forEach((mediaData) => {
      let mediaItem
      Object.values(score.media).forEach((mediaItemCandidate) => {
        if (mediaItemCandidate.source === mediaData.url) {
          mediaItem = mediaItemCandidate
        }
      })
      if (!mediaItem) {
        mediaItem = new Media({
          id: uuidv4(),
          name: mediaData.name,
          type: mediaData.type,
          source: mediaData.url,
          width: mediaData.width,
          height: mediaData.height,
        })
      }
      addedMedia.push(mediaItem)
    })
    this.props.stepwise.score.addMedia(addedMedia)
    this.addMediaToSequence(addedMedia)
    this.closeDialog('addMedia')
  }

  getCharacterForMedia(media) {
    var character
    let score = this.props.stepwise.score
    score.currentScene.currentSequence.steps.forEach((step) => {
      step.actions.forEach((action) => {
        if (action.command === 'enter') {
          step.states.forEach((state) => {
            if (
              state.character === action.character &&
              (state.type === 'image' ||
                state.type === 'video' ||
                state.type === 'audio')
            ) {
              if (state.media === media.id) {
                character = action.targetCharacter
              }
            }
          })
        }
      })
    })
    return character
  }

  clearEmptyStep() {
    let score = this.props.stepwise.score
    let sequence = score.currentScene.currentSequence
    if (sequence.steps.length === 1) {
      let step = sequence.steps[0]
      if (step.actions.length === 0) {
        sequence.steps = []
      }
    }
  }

  getDormantCharacter() {
    let dormantCharacter
    let activeCharacters = []
    if (this.webcamCharacter) activeCharacters.push(this.webcamCharacter)
    let score = this.props.stepwise.score
    let sequence = score.currentScene.currentSequence
    if (sequence.steps.length > 0) {
      for (let i = sequence.stepIndex; i >= 0; i--) {
        let step = sequence.steps[i]
        let foundLastFullFrameEntry = false
        step.actions.forEach((action) => {
          if (action.command === 'enter') {
            if (activeCharacters.indexOf(action.targetCharacter) === -1) {
              activeCharacters.push(action.targetCharacter)
            }
            if (
              action.size.indexOf('full') !== -1 &&
              action.size.length === 1 &&
              action.amount.indexOf('full') !== -1 &&
              action.amount.length === 1
            ) {
              foundLastFullFrameEntry = true
            }
          }
        })
        if (foundLastFullFrameEntry) {
          break
        }
      }
    }
    let characters = Object.values(this.props.stepwise.score.characters)
    for (let i = 0; i < characters.length; i++) {
      let character = characters[i]
      if (
        activeCharacters.indexOf(character) === -1 &&
        character.fullName !== 'Narrator'
      ) {
        dormantCharacter = character
        break
      }
    }
    return dormantCharacter
  }

  getFullFrameVideo() {
    let fullFrameVideo
    let score = this.props.stepwise.score
    let sequence = score.currentScene.defaultSequence
    for (let i = sequence.steps.length - 1; i >= 0; i--) {
      let step = sequence.steps[i]
      for (let j = 0; j < step.actions.length; j++) {
        let action = step.actions[j]
        if (action.command === 'enter') {
          if (action.size === 'full' && action.amount === 'full') {
            let feature = action.targetCharacter.getFeatureForType('video')
            let videoState = sequence.getCurrentStateForFeatureInStep(
              feature,
              step,
              false,
              true
            )
            let media = score.getMedia(videoState.media)
            if (media) {
              fullFrameVideo = media
            }
          }
        }
      }
      if (fullFrameVideo) {
        break
      }
    }
    return fullFrameVideo
  }

  // creates a new character for the media item and adds an entry step for it
  addMediaToSequence(media) {
    this.clearEmptyStep()
    let score = this.props.stepwise.score
    let sequence = score.currentScene.defaultSequence
    media.forEach((mediaItem, index) => {
      let character = this.getCharacterForMedia(mediaItem)
      if (!character) {
        character = this.getDormantCharacter()
        if (!character) {
          character = new Character(
            {
              id: uuidv4(),
              fullName: uniqueNamesGenerator({
                dictionaries: [names],
                style: 'capital',
                separator: ' ',
                length: 1,
              }),
              features: [],
            },
            score
          )
        }
        score.addCharacter(character)
      }
      let imageState, videoState, audioState
      if (mediaItem.type === 'image') {
        imageState = {
          type: mediaItem.type,
          character: character.id,
          media: mediaItem.id,
          fit: 'cover',
          backgroundPosition: 'center',
        }
        videoState = {
          type: 'video',
          character: character.id,
          media: null,
        }
        audioState = {
          type: 'audio',
          character: character.id,
          media: null,
        }
      } else if (mediaItem.type === 'video') {
        videoState = {
          type: mediaItem.type,
          character: character.id,
          media: mediaItem.id,
          fit: 'cover',
          backgroundPosition: 'center',
        }
        imageState = {
          type: 'image',
          character: character.id,
          media: null,
        }
        audioState = {
          type: 'audio',
          character: character.id,
          media: null,
        }
      } else if (mediaItem.type === 'audio') {
        audioState = {
          type: mediaItem.type,
          character: character.id,
          media: mediaItem.id,
        }
        videoState = {
          type: mediaItem.type,
          character: character.id,
          media: mediaItem.id,
          fit: 'cover',
          backgroundPosition: 'center',
        }
        imageState = {
          type: 'image',
          character: character.id,
          media: null,
        }
      }
      let stepData = {
        states: [
          imageState,
          videoState,
          audioState,
          {
            type: 'frame',
            character: character.id,
            margin: '0',
            borderWidth: '5px',
            borderColor: 'black',
          },
        ],
        actions: [
          {
            character: character.id,
            command: 'enter',
            direction: ['right', 'left', 'top', 'bottom'],
            size: [
              'quarter',
              'third',
              'half',
              'two-thirds',
              'three-quarters',
              'full',
            ],
            amount: ['match-content'],
            content: '0 0 12 12',
            physics: 'push',
          },
        ],
      }
      /*if (index === 0) {
        stepData.states.push({
          "type": "stage",
          "margin": "10px",
          "backgroundColor": "white"
        })
      }*/
      let step = new Step(stepData, score, sequence)
      if (sequence.steps.length > 0) {
        sequence.addStepAfterStep(sequence.steps[sequence.stepIndex], step)
      } else {
        sequence.addStep(step)
      }
      this.props.onStepChange(step)
    })
    score.setupReferences()
    this.props.onStoryUpdate()
  }

  getWebcamMedia() {
    let webcamMedia
    let media = Object.values(this.props.stepwise.score.media)
    for (let i = 0; i < media.length; i++) {
      if (media.source === 'webcam') {
        webcamMedia = media
        break
      }
    }
    return webcamMedia
  }

  addWebcamStep() {
    this.clearEmptyStep()
    let score = this.props.stepwise.score
    let sequence = score.currentScene.defaultSequence
    this.webcamCharacter = score.getCharacter(this.props.user.nickname)
    this.webcamMedia = score.getMedia()
    if (!this.webcamCharacter) {
      this.webcamCharacter = new Character(
        {
          id: this.props.user.nickname,
          fullName: this.props.user.name,
          features: [],
        },
        score
      )
      score.addCharacter(this.webcamCharacter)
    }
    if (!this.webcamMedia) {
      this.webcamMedia = new Media({
        id: uuidv4(),
        name: 'Webcam',
        type: 'video',
        source: 'webcam',
        width: 1920,
        height: 1080,
      })
      score.addMedia(this.webcamMedia)
    }
    let stepData = {
      states: [
        {
          type: 'video',
          character: this.webcamCharacter.id,
          media: this.webcamMedia.id,
          objectFit: 'cover',
        },
        {
          type: 'frame',
          character: this.webcamCharacter.id,
          margin: '0',
          borderWidth: '5px',
          borderColor: 'black',
        },
      ],
      actions: [
        {
          character: this.webcamCharacter.id,
          command: 'enter',
          direction: ['right', 'left'],
          size: ['full'],
          amount: ['third', 'half', 'two-thirds'],
          content: '0 0 12 12',
          physics: 'push',
        },
      ],
    }
    let step = new Step(stepData, score, sequence)
    if (sequence.steps.length > 0) {
      sequence.addStepAfterStep(sequence.steps[sequence.stepIndex], step)
    } else {
      sequence.addStep(step)
    }
    this.props.onStepChange(step)
    score.setupReferences()
  }

  addTextSteps(parameters) {
    this.clearEmptyStep()
    let score = this.props.stepwise.score
    let sequence = score.currentScene.currentSequence
    let character
    if (parameters.character) {
      character = score.getCharacter(parameters.character)
    } else {
      character = score.getCharacter('narrator')
    }
    let sourceText = []
    switch (parameters.split) {
      case 'word':
        sourceText = parameters.content.split(' ')
        break
      default:
        sourceText.push(parameters.content)
        break
    }
    if (character) {
      let step
      if (parameters.addStep) {
        let stepData = {
          states: [
            {
              type: 'text',
              character: character.id,
              layout: 'standard',
            },
          ],
          actions: [],
        }
        step = new Step(stepData, score, sequence)
        if (sequence.steps.length > 0) {
          sequence.addStepAfterStep(sequence.steps[sequence.stepIndex], step)
        } else {
          sequence.addStep(step)
        }
        this.props.onStepChange(step)
      } else {
        step = sequence.steps[Math.max(0, sequence.stepIndex)]
      }
      if (parameters.enter) {
        let action = new Action(
          {
            character: character.id,
            command: 'enter',
            direction: ['right', 'left', 'top', 'bottom'],
            size: ['full'],
            amount: ['full'],
            content: '0 0 12 12',
            physics: 'push',
          },
          score
        )
        step.actions.push(action)
      }
      sourceText.forEach((token, index) => {
        if (index > 0) token = ' ' + token
        let action = new Action(
          {
            character: character.id,
            command: 'speak',
            content: token,
            append: index > 0,
            delay: index,
          },
          score
        )
        step.actions.push(action)
      })
      score.setupReferences()
    }
    this.closeDialog('search')
  }

  getAddMediaControls() {
    const mediaControls = this.addMediaData.map((mediaData, index) => {
      return (
        <AddMediaControl
          key={mediaData.key}
          uniqueId={mediaData.key}
          url={mediaData.url}
          index={index}
          onEnter={this.handleAddMediaEnter}
          onChange={this.handleAddMediaChange}
          onDelete={this.handleAddMediaDelete}
        />
      )
    })
    return mediaControls
  }

  handleTabChange(newTabId) {
    this.setState({ selectedTabId: newTabId })
  }

  doSearch(domain, parameters) {
    this.waitingForSearchResults = true
    if (!domain) {
      domain = this.state.selectedTabId.split('-')[0]
    }
    this.setSourceCreditForDomain(domain)
    this.lastDomain = domain
    this.lastSearchParameters = parameters
    if (domain === 'webcam') {
      this.addWebcamStep()
      this.props.onStoryUpdate()
    } else if (domain === 'text') {
      this.addTextSteps(parameters)
      this.props.onStoryUpdate()
    } else {
      this.searchRefs[domain].current.doSearch(parameters, this.searchPage)
    }
  }

  setSourceCreditForDomain(domain) {
    switch (domain) {
      case 'tunesmap':
        this.sourceCredit = (
          <p>
            Powered by <a href="https://tunesmap.com">TunesMap</a>
          </p>
        )
        break
      case 'giphy':
        this.sourceCredit = (
          <p>
            Powered by <a href="https://giphy.com">Giphy</a>
          </p>
        )
        break
      case 'unsplash':
        this.sourceCredit = (
          <p>
            Powered by <a href="https://unsplash.com">Unsplash</a>
          </p>
        )
        break
      default:
        this.sourceCredit = null
        break
    }
  }

  handleSearchResults(data) {
    if (data.length === 0) {
      this.maxSearchPage = this.searchPage
    }
    this.waitingForSearchResults = false
    let searchResults = this.state.searchResults
    if (this.searchPage === 1) {
      searchResults = data
    } else {
      searchResults = searchResults.concat(data)
    }
    let selectedMediaIndices
    if (this.searchPage === 1) {
      selectedMediaIndices = []
    } else {
      selectedMediaIndices = this.state.selectedMediaIndices
    }
    this.setState({
      view: 'selectMedia',
      searchResults: searchResults,
      selectedMediaIndices: selectedMediaIndices,
    })
  }

  getSearchResults() {
    if (this.state.searchResults) {
      return this.state.searchResults.map((result, index) => {
        let className = 'media-card'
        if (this.state.selectedMediaIndices.indexOf(index) !== -1) {
          className += ' selected'
        }
        return (
          <div key={index} className={className}>
            <MediaPreview
              index={index}
              className="select-media-preview"
              type={result.type}
              source={result.thumbnail}
              onClick={this.handleMediaSelected}
            />
          </div>
        )
      })
    } else {
      return null
    }
  }

  handleMediaSelected(mediaIndex) {
    let indices = this.state.selectedMediaIndices
    let index = indices.indexOf(mediaIndex)
    if (index === -1) {
      indices.push(mediaIndex)
    } else {
      indices.splice(index, 1)
    }
    this.setState({ selectedMediaIndices: indices })
  }

  handleMediaResultsScroll(evt) {
    let element = evt.currentTarget
    if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
      if (!this.waitingForSearchResults) {
        this.searchPage++
        if (this.searchPage < this.maxSearchPage) {
          this.doSearch(
            this.lastDomain,
            this.lastSearchParameters,
            this.searchPage
          )
        }
      }
    }
  }

  resetSearch() {
    this.searchPage = 1
    this.maxSearchPage = 20
    this.setState({ searchResults: null })
  }

  handleSimpleSearchSubmit(domain, parameters) {
    switch (domain) {
      case 'linkedMedia':
        this.openDialog('addMedia', parameters)
        break

      case 'text':
        this.addTextSteps(parameters)
        break

      default:
        this.resetSearch()
        this.doSearch(domain, parameters)
        break
    }
  }

  handleSearchTabSubmit(evt) {
    evt.preventDefault()
    this.resetSearch()
    let searchInterface =
      this.searchInterfaceRefs[this.state.selectedTabId].current
    searchInterface.getSearchParameters((domain, parameters) => {
      this.doSearch(domain, parameters)
    })
  }

  getSubmitButtonText() {
    switch (this.state.selectedTabId) {
      case 'text-tab':
        return 'Add text'
      default:
        return 'Search'
    }
  }

  render() {
    return (
      <div>
        <PanelStack
          className="simple-input-panel"
          stack={this.panelStack}
          showPanelHeader={false}
        />
        <TunesMapSearch
          ref={this.searchRefs['tunesmap']}
          onSearchResults={this.handleSearchResults}
        />
        <UnsplashSearch
          ref={this.searchRefs['unsplash']}
          onSearchResults={this.handleSearchResults}
        />
        <GiphySearch
          ref={this.searchRefs['giphy']}
          onSearchResults={this.handleSearchResults}
        />
        <Dialog
          isOpen={this.state.view === 'main'}
          onClose={this.props.onClose}
          title="Welcome to Stepworks"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="button-row">
              <Button
                icon="media"
                onClick={() => this.openDialog('addMedia')}
                large={true}
                intent={Intent.PRIMARY}
              >
                Add media
              </Button>
              <Button icon="font" large={true} intent={Intent.PRIMARY}>
                Add text
              </Button>
              <Button
                icon="search"
                onClick={() => this.openDialog('search')}
                large={true}
                intent={Intent.PRIMARY}
              >
                Search
              </Button>
            </div>
            <div className="button-row short">
              <Button icon="list" large={true}>
                Edit sequence
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          className="add-media-dialog"
          isOpen={this.state.view === 'addMedia'}
          title="Add Media"
          onClose={() => this.closeDialog('addMedia')}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Enter one or more media items to add to your story."
              helperText="Press return to add rows"
            >
              {this.getAddMediaControls()}
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.closeDialog('addMedia')}>
                Cancel
              </Button>
              <Button
                onClick={this.addMedia.bind(this)}
                intent={Intent.PRIMARY}
              >
                Done
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.view === 'search'}
          title="Content Options"
          onClose={() => this.closeDialog('search')}
        >
          <form onSubmit={this.handleSearchTabSubmit.bind(this)}>
            <div className={Classes.DIALOG_BODY + ' search-body'}>
              <Tabs
                renderActiveTabPanelOnly={true}
                selectedTabId={this.state.selectedTabId}
                onChange={this.handleTabChange.bind(this)}
              >
                <Tab
                  id="text-tab"
                  title="Text"
                  panel={
                    <TextComposerTab
                      ref={this.searchInterfaceRefs['text-tab']}
                      stepwise={this.props.stepwise}
                      onSearchResults={this.handleSearchResults}
                    />
                  }
                />
                <Tab
                  id="tunesmap-tab"
                  title="TunesMap"
                  panel={
                    <TunesMapSearchTab
                      ref={this.searchInterfaceRefs['tunesmap-tab']}
                      api={this.searchRefs['tunesmap'].current}
                      onSearchResults={this.handleSearchResults}
                    />
                  }
                />
                <Tab
                  id="unsplash-tab"
                  title="Unsplash"
                  panel={
                    <UnsplashSearchTab
                      ref={this.searchInterfaceRefs['unsplash-tab']}
                      onSearchResults={this.handleSearchResults}
                    />
                  }
                />
              </Tabs>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.closeDialog('search')}>
                  Cancel
                </Button>
                <Button intent={Intent.PRIMARY} type="submit">
                  {this.getSubmitButtonText()}
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          className="select-media-dialog"
          isOpen={this.state.view === 'selectMedia'}
          title="Select Media"
          onClose={() => this.closeDialog('selectMedia')}
        >
          <div className={Classes.DIALOG_BODY}>
            {this.sourceCredit}
            <div
              className="media-results-container"
              onScroll={this.handleMediaResultsScroll.bind(this)}
            >
              {this.getSearchResults()}
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.closeDialog('selectMedia')}>
                Cancel
              </Button>
              <Button
                onClick={this.addSelectedMedia.bind(this)}
                intent={Intent.PRIMARY}
              >
                Add media
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default EZCreateDialog
