import React from 'react'
import { createApi } from 'unsplash-js'
import fetch from 'node-fetch'
import { v4 as uuidv4 } from 'uuid'
global.fetch = fetch

class UnsplashSearch extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchResults = this.handleSearchResults.bind(this)
  }

  doSearch(parameters, page) {
    const unsplash = createApi({
      accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
    })
    unsplash.search
      .getPhotos({ query: parameters.keyword, page: page, perPage: 40 })
      .then(this.handleSearchResults)
  }

  handleSearchResults(json) {
    let mediaData = []
    json.response.results.forEach((item, i) => {
      let mediaItem = {
        id: uuidv4(),
        name: item.alt_description,
        type: 'image',
        source: item.urls.regular,
        attribution: item.user.name,
        attributionURL: item.user.portfolio_url,
        thumbnail: item.urls.thumb,
        width: item.width,
        height: item.height,
      }
      mediaData.push(mediaItem)
    })
    this.props.onSearchResults(mediaData)
  }

  render() {
    return <div />
  }
}

export default UnsplashSearch
