import React from 'react'
import ComboThumbnail from './ComboThumbnail.js'
import { observer } from 'mobx-react'
import { Step } from '../stepworks/stepwise/stepwise-v2.js'

const ScoreMediaEditor = observer(
  class ScoreMediaEditor extends React.Component {
    constructor(props) {
      super(props)
      this.actionsByCharacter = []
      this.handleStepChange = this.handleStepChange.bind(this)
      this.addStep = this.addStep.bind(this)
    }

    handleStepChange(step) {
      this.props.onStepChange(step)
      this.forceUpdate()
    }

    sortActions() {
      this.actionsByCharacter = []
      let lastCharacter, currentActions
      this.props.sequence.steps.forEach((step, index) => {
        let sortedStepActions = []
        currentActions = []
        sortedStepActions.push(currentActions)
        step.actions.forEach((action, index) => {
          if (action.targetCharacter !== lastCharacter && !lastCharacter) {
            currentActions = [action]
            sortedStepActions.push(currentActions)
          } else {
            currentActions.push(action)
          }
          lastCharacter = action.targetCharacter
        })
        this.actionsByCharacter.push(sortedStepActions)
      })
    }

    actionsContainMedia(actions) {
      let n = actions.length
      for (var i = 0; i < n; i++) {
        switch (actions[i].command) {
          case 'speak':
            if (actions[i].content.trim() !== '') return true
            break
          case 'show-image':
          case 'show-video':
          case 'play-audio':
            return true
          default:
            break
        }
      }
      return false
    }

    addStep() {
      let stepData = {
        states: [],
        actions: [this.getNewActionData()],
      }
      let step = this.props.sequence.steps[this.props.sequence.stepIndex]
      let newStep = new Step(
        stepData,
        this.props.stepwise.score,
        this.props.sequence
      )
      this.props.sequence.addStepAfterStep(step, newStep)
      this.props.stepwise.score.setupReferences()
      this.props.onStepChange(newStep)
    }

    render() {
      this.sortActions()
      let scoreThumbnails = this.actionsByCharacter.map(
        (actionsByCharacter, index) => {
          let n = actionsByCharacter.length
          for (var i = 0; i < n; i++) {
            if (this.actionsContainMedia(actionsByCharacter[i])) {
              return (
                <ComboThumbnail
                  key={index}
                  index={index}
                  stepwise={this.props.stepwise}
                  sequence={this.props.sequence}
                  onStepChange={this.handleStepChange}
                  actions={actionsByCharacter[i]}
                  compact={false}
                />
              )
            }
          }
          return null
        }
      )
      return (
        <div className="score-media-editor">
          {scoreThumbnails}
          {/*<Button className="combo-thumbnail" minimal="true" icon="plus" onClick={this.props.onAddStep}/>*/}
        </div>
      )
    }
  }
)

export default ScoreMediaEditor
