import React from 'react'
import {
  IPanelProps,
  InputGroup,
  FormGroup,
  ControlGroup,
  Button,
  Classes,
} from '@blueprintjs/core'

class SimpleInputPanel extends React.Component<IPanelProps> {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      domainIndex: 0,
      helperText: 'Use ↑↓ keys to change task',
    }
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.controlRef = React.createRef()
  }

  componentDidMount() {
    let body = document.getElementsByTagName('body')[0]
    body.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    let body = document.getElementsByTagName('body')[0]
    body.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    e = e || window.event
    switch (e.keyCode) {
      case 38: // up arrow
        this.decrementDomainIndex()
        break
      case 40: // down arrow
        this.incrementDomainIndex()
        break
      default:
        break
    }
  }

  decrementDomainIndex() {
    let newIndex = this.state.domainIndex - 1
    if (newIndex < 0) newIndex += this.props.domains.length
    this.setState({ domainIndex: newIndex, inputValue: '' })
  }

  incrementDomainIndex() {
    let newIndex = this.state.domainIndex + 1
    if (newIndex >= this.props.domains.length) newIndex = 0
    this.setState({ domainIndex: newIndex, inputValue: '' })
  }

  handleInputChange(evt) {
    this.setState({ inputValue: evt.target.value })
  }

  handleButtonClick(evt) {
    this.getSearchParameters((domain, parameters) => {
      this.props.onSubmit(domain, parameters)
    })
  }

  handleExpandButtonClick() {
    this.props.onExpand(this.props.domains[this.state.domainIndex])
  }

  getSearchParameters(returnHandler) {
    let domain = this.props.domains[this.state.domainIndex]
    let parameters = {}
    switch (domain) {
      case 'giphy':
        parameters = {
          keyword: this.state.inputValue,
        }
        returnHandler(domain, parameters)
        break

      case 'linkedMedia':
        parameters = {
          key: String.fromCharCode(97),
          type: 'image',
          name: '',
          url: this.state.inputValue,
        }
        returnHandler(domain, parameters)
        break

      case 'tunesmap':
        this.props.tunesmap.current.normalizeArtistName(
          this.state.inputValue,
          (artistName) => {
            parameters = {
              time: '',
              location: '',
              artist: artistName,
              genre: '',
              mediaType: 'image',
              cultureType: '',
            }
            returnHandler(domain, parameters)
          }
        )
        break

      case 'text':
        parameters = {
          content: this.state.inputValue,
          enter: true,
          addStep: true,
        }
        this.setState({ inputValue: '' })
        returnHandler(domain, parameters)
        break

      case 'unsplash':
        parameters = {
          keyword: this.state.inputValue,
        }
        returnHandler(domain, parameters)
        break

      case 'webcam':
        parameters = {
          type: 'webcam',
        }
        returnHandler(domain, parameters)
        break

      default:
        break
    }
  }

  handleSubmit(evt) {
    evt.preventDefault()
    //if (this.props.control[this.state.domainIndex] === 'field') {
    this.getSearchParameters((domain, parameters) => {
      this.props.onSubmit(domain, parameters)
    })
    //}
  }

  render() {
    let logo = null
    let dialogButton = null
    let control = null
    let domain = this.props.domains[this.state.domainIndex]
    let style
    switch (domain) {
      case 'giphy':
        style = { backgroundImage: 'url(images/GIPHY_Icon.png)' }
        break

      case 'text':
        style = { backgroundImage: 'url(images/text.png)' }
        break

      case 'tunesmap':
        style = { backgroundImage: 'url(images/TM_Logo.png)' }
        break

      case 'unsplash':
        style = { backgroundImage: 'url(images/Unsplash_Symbol.png)' }
        break

      case 'webcam':
        style = null
        break

      default:
        style = { backgroundImage: 'url(images/media.png)' }
        break
    }
    if (style) {
      logo = (
        <div className={'simple-input-logo ' + Classes.FIXED} style={style} />
      )
    }
    let controlType = this.props.control[this.state.domainIndex]
    switch (controlType) {
      case 'field':
        control = (
          <InputGroup
            ref={this.controlRef}
            value={this.state.inputValue}
            placeholder={this.props.placeholders[this.state.domainIndex]}
            onChange={this.handleInputChange.bind(this)}
          />
        )
        break

      case 'button':
        control = (
          <Button
            ref={this.controlRef}
            onClick={this.handleButtonClick.bind(this)}
          >
            {this.props.placeholders[this.state.domainIndex]}
          </Button>
        )
        break

      default:
        break
    }
    if (this.props.dialog[this.state.domainIndex]) {
      dialogButton = (
        <Button
          className={Classes.FIXED}
          icon="more"
          onClick={this.handleExpandButtonClick.bind(this)}
        />
      )
    }
    return (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup helperText={this.state.helperText}>
            <ControlGroup fill={true}>
              {logo}
              {control}
              {dialogButton}
            </ControlGroup>
          </FormGroup>
        </form>
      </div>
    )
  }
}

export default SimpleInputPanel
