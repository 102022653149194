import React from 'react'
import {
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  HTMLSelect,
} from '@blueprintjs/core'
import {
  Character,
  Media,
  Step,
  Action,
  FrameState,
  TextState,
  ImageState,
  VideoState,
  AudioState,
} from '../stepworks/stepwise/stepwise-v2.js'
import { v4 as uuidv4 } from 'uuid'
import { uniqueNamesGenerator, names } from 'unique-names-generator'

class MediaDistributor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      characterAssign: 'multiple-characters',
      splitUnit: 'step',
    }
    this.characterAssignOptions = [
      { label: 'multiple characters', value: 'multiple-characters' },
      { label: 'the current character', value: 'same-character' },
    ]
    this.splitUnitOptions = [
      { label: 'multiple steps', value: 'step' },
      { label: 'multiple actions in this step', value: 'action' },
    ]
  }

  addMediaToCurrentAction(mediaItem) {
    this.props.stepwise.score.addMedia(mediaItem)
    this.clearVisualsForCharacter(this.props.editAction.targetCharacter)
    this.props.editAction.command = this.getCommandForMedia(mediaItem)
    this.props.editAction.content = mediaItem.id
    this.props.editAction.updateFeature()
    this.props.onStepwiseEvent('action', this.props.editAction)
  }

  clearVisualsForCharacter(character) {
    let data = {
      command: 'clear-visuals',
      character: character.id,
    }
    let action = new Action(data, this.props.stepwise.score)
    this.props.onStepwiseEvent('action', action)
  }

  addSelectedMediaToLibrary() {
    let addedMedia = []
    this.state.selectedMediaIndices.forEach((value) => {
      let mediaItem = new Media(this.state.searchResults[value])
      addedMedia.push(mediaItem)
    })
    this.props.stepwise.score.addMedia(addedMedia)
  }

  getCharacterForMedia(media) {
    var character
    this.props.sequence.steps.forEach((step) => {
      step.actions.forEach((action) => {
        if (action.command === 'enter') {
          step.states.forEach((state) => {
            if (
              state.character === action.character &&
              (state.type === 'image' ||
                state.type === 'video' ||
                state.type === 'audio')
            ) {
              if (state.media === media.id) {
                character = action.targetCharacter
              }
            }
          })
        }
      })
    })
    if (!character) {
      character = this.getDormantCharacter()
      if (!character) {
        character = this.addCharacter()
      }
    }
    return character
  }

  getDormantCharacter() {
    let dormantCharacter
    let activeCharacters = []
    if (this.webcamCharacter) activeCharacters.push(this.webcamCharacter)
    if (this.props.sequence.steps.length > 0) {
      for (let i = this.props.sequence.stepIndex; i >= 0; i--) {
        let step = this.props.sequence.steps[i]
        let foundLastFullFrameEntry = false
        step.actions.forEach((action) => {
          if (action.command === 'enter') {
            if (activeCharacters.indexOf(action.targetCharacter) === -1) {
              activeCharacters.push(action.targetCharacter)
            }
            if (
              action.size.indexOf('full') !== -1 &&
              action.size.length === 1 &&
              action.amount.indexOf('full') !== -1 &&
              action.amount.length === 1
            ) {
              foundLastFullFrameEntry = true
            }
          }
        })
        if (foundLastFullFrameEntry) {
          break
        }
      }
    }
    let characters = Object.values(this.props.stepwise.score.characters)
    for (let i = 0; i < characters.length; i++) {
      let character = characters[i]
      if (
        activeCharacters.indexOf(character) === -1 &&
        character.fullName !== 'Narrator'
      ) {
        dormantCharacter = character
        break
      }
    }
    return dormantCharacter
  }

  getCommandForMedia(media) {
    switch (media.type) {
      case 'image':
        return 'show-image'
      case 'video':
        return 'show-video'
      case 'audio':
        return 'play-audio'
      default:
        return this.props.editAction.command
    }
  }

  addCharacter() {
    let character = new Character(
      {
        id: uuidv4(),
        fullName: uniqueNamesGenerator({
          dictionaries: [names],
          style: 'capital',
          separator: ' ',
          length: 1,
        }),
        features: [],
      },
      this.props.stepwise.score
    )
    this.props.stepwise.score.addCharacter(character)

    let data = {
      type: 'frame',
      character: character.id,
      backgroundColor: window.getRandomBackgroundColor(),
    }
    let state = new FrameState(data, this.props.stepwise.score, character)
    this.props.sequence.steps[0].states.push(state)

    let captionAlign = window.getRandomCaptionAlign()
    data = {
      type: 'text',
      character: character.id,
      font: 'Newsreader',
      align: captionAlign,
      textAlign: window.getTextAlignFromCaptionAlign(captionAlign),
    }
    state = new TextState(data, this.props.stepwise.score, character)
    this.props.sequence.steps[0].states.push(state)

    data = {
      type: 'image',
      character: character.id,
    }
    state = new ImageState(data, this.props.stepwise.score, character)
    this.props.sequence.steps[0].states.push(state)

    data = {
      type: 'video',
      character: character.id,
    }
    state = new VideoState(data, this.props.stepwise.score, character)
    this.props.sequence.steps[0].states.push(state)

    data = {
      type: 'audio',
      character: character.id,
    }
    state = new AudioState(data, this.props.stepwise.score, character)
    this.props.sequence.steps[0].states.push(state)

    return character
  }

  addCharacterActionForMediaToStep(character, media, step, delay = 0) {
    let action = new Action(
      {
        character: character.id,
        command: this.getCommandForMedia(media),
        content: media.id,
        delay: delay,
      },
      this.props.stepwise.score
    )
    step.actions.push(action)
  }

  addEnterActionForCharacterToStep(character, step, delay = 0) {
    let action = new Action(
      {
        character: character.id,
        command: 'enter',
        direction: ['right', 'bottom'],
        size: [
          'full',
          'third',
          'quarter',
          'half',
          'two-thirds',
          'three-quarters',
        ],
        amount: ['match-content'],
        physics: 'push',
        content: '0 0 12 12',
        delay: delay,
      },
      this.props.stepwise.score
    )
    step.actions.push(action)
  }

  createMedia(mediaData) {
    let addedMedia = []
    mediaData.forEach((data) => {
      let mediaItem = new Media(data)
      addedMedia.push(mediaItem)
    })
    this.props.stepwise.score.addMedia(addedMedia)
    return addedMedia
  }

  distributeMedia(media) {
    if (this.state.characterAssign === 'same-character') {
      if (this.state.splitUnit === 'action') {
        // same character, distribute across actions
        media.forEach((mediaItem, index) => {
          // add to the current action first
          if (index === 0) {
            this.addMediaToCurrentAction(mediaItem)

            // then, create subsequent actions
          } else {
            let delay = this.props.editAction.delay
              ? this.props.editAction.delay
              : 0
            this.addCharacterActionForMediaToStep(
              this.props.editAction.targetCharacter,
              mediaItem,
              this.props.editStep,
              delay + index
            )
          }
        })
      } else {
        // same character, distribute across steps
        media.forEach((mediaItem, index) => {
          // add to the current action first
          if (index === 0) {
            this.addMediaToCurrentAction(mediaItem)

            // then, add to subsequent steps
          } else {
            let proxyIndex = this.props.sequence.stepIndex + index
            let newStep

            // reuse existing step
            if (proxyIndex < this.props.sequence.steps.length) {
              newStep = this.props.sequence.steps[proxyIndex]
              this.addCharacterActionForMediaToStep(
                this.props.editAction.targetCharacter,
                mediaItem,
                newStep
              )

              // create new step
            } else {
              let newStep = new Step(
                { states: [], actions: [] },
                this.props.stepwise.score,
                this.props.sequence
              )
              this.addCharacterActionForMediaToStep(
                this.props.editAction.targetCharacter,
                mediaItem,
                newStep
              )
              this.props.sequence.addStep(newStep)
            }
          }
        })
      }
    } else {
      if (this.state.splitUnit === 'action') {
        // different characters, distribute across actions
        media.forEach((mediaItem, index) => {
          // add to the current action first
          if (index === 0) {
            this.addMediaToCurrentAction(mediaItem)

            // then, create subsequent actions
          } else {
            let delay = this.props.editAction.delay
              ? this.props.editAction.delay
              : 0

            // try to reuse an existing character, otherwise create a new one
            let character = this.getCharacterForMedia(mediaItem)

            // add an enter action for the character, pluse the media action
            this.addEnterActionForCharacterToStep(
              character,
              this.props.editStep,
              delay + index
            )
            this.addCharacterActionForMediaToStep(
              character,
              mediaItem,
              this.props.editStep,
              delay + index
            )
          }
        })

        // init the new characters
        this.props.stepwise.score.setupReferences()
        this.props.onStoryUpdate()
      } else {
        // different characters, distribute across steps
        media.forEach((mediaItem, index) => {
          // add to the current action first
          if (index === 0) {
            this.addMediaToCurrentAction(mediaItem)

            // then, add to subsequent steps
          } else {
            // try to reuse an existing character, otherwise create a new one
            let character = this.getCharacterForMedia(mediaItem)

            // create new step, add enter and media actions to it
            let step = new Step(
              { states: [], actions: [] },
              this.props.stepwise.score,
              this.props.sequence
            )
            this.addEnterActionForCharacterToStep(character, step)
            this.addCharacterActionForMediaToStep(character, mediaItem, step)

            // add the step
            if (this.props.sequence.steps.length > 0) {
              this.props.sequence.addStepAfterStep(
                this.props.sequence.steps[this.props.sequence.stepIndex],
                step
              )
            } else {
              this.props.sequence.addStep(step)
            }
            this.props.onStepChange(step)
          }
        })

        // init the new characters
        this.props.stepwise.score.setupReferences()
        this.props.onStoryUpdate()
      }
    }
  }

  handleCharacterAssignChange = (evt) => {
    this.setState({ characterAssign: evt.currentTarget.value })
  }

  handleSplitUnitChange = (evt) => {
    this.setState({ splitUnit: evt.currentTarget.value })
  }

  render() {
    return (
      <Dialog
        className="distribute-media-dialog"
        isOpen={this.props.isOpen}
        title="Distribute Media"
        onClose={this.props.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            You selected multiple media items. How would you like to add them to
            your story?
          </p>
          <FormGroup label="Assign to" labelFor="split-select" inline={true}>
            <HTMLSelect
              id="split-select"
              options={this.characterAssignOptions}
              value={this.state.characterAssign}
              onChange={this.handleCharacterAssignChange}
            />
          </FormGroup>
          <FormGroup
            label="Split across"
            labelFor="split-unit-select"
            inline={true}
          >
            <HTMLSelect
              id="split-unit-select"
              options={this.splitUnitOptions}
              value={this.state.splitUnit}
              onChange={this.handleSplitUnitChange}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.props.onClose}>Back</Button>
            <Button onClick={this.props.onComplete} intent={Intent.PRIMARY}>
              Add media
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default MediaDistributor
