import React from 'react'
import { Navbar, Alignment, Button } from '@blueprintjs/core'
import { observer } from 'mobx-react'

const PlayBar = observer(
  class PlayBar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        visible: false,
      }
      this.fadeTimeOut = null
      this.mouseOver = false
    }

    fadeIn() {
      if (!this.state.visible) {
        this.setState({ visible: true })
      }
      clearTimeout(this.fadeTimeOut)
      this.fadeTimeOut = setTimeout(this.fadeOut, 3000)
    }

    fadeOut = () => {
      if (!this.mouseOver) this.setState({ visible: false })
    }

    handleMouseEnter = () => {
      this.mouseOver = true
    }

    handleMouseLeave = () => {
      this.mouseOver = false
    }

    nextStep = (evt) => {
      this.props.stepwise.nextStep()
    }

    render() {
      if (this.props.stepwise && this.props.sequence) {
        let className = 'play-bar'
        if (!this.state.visible) {
          className += ' fade-out'
        }
        return (
          <Navbar
            className={className}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading>{this.props.stepwise.score.title}</Navbar.Heading>
              {this.props.sequence.stepIndex + 1} of{' '}
              {this.props.sequence.steps.length}
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
              <Button
                className="do-not-propagate"
                icon="reset"
                minimal="true"
                onMouseDown={this.props.onReset}
              />
              &nbsp;
              <Button
                className="do-not-propagate"
                icon="arrow-right"
                minimal="true"
                onMouseDown={this.nextStep}
              />
            </Navbar.Group>
          </Navbar>
        )
      } else {
        return null
      }
    }
  }
)

export default PlayBar
