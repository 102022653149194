import React from 'react'
import ReactDOM from 'react-dom'
import './stepworks/stepworks-studio-utils.js'
import './index.css'
import '@blueprintjs/table/lib/css/table.css'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<Routes />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
