import React from 'react'
import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Switch,
  Alignment,
  ButtonGroup,
  Popover,
} from '@blueprintjs/core'
import { observer } from 'mobx-react'

const VisualStateEditor = observer(
  class VisualStateEditor extends React.Component {
    constructor(props) {
      super(props)
      this.fieldWidth = 40
      this.repeatOptions = [
        { label: 'Repeat', value: 'repeat' },
        { label: 'Repeat-X', value: 'repeat-x' },
        { label: 'Repeat-Y', value: 'repeat-y' },
        { label: 'No Repeat', value: 'no-repeat' },
        { label: 'Space', value: 'space' },
        { label: 'Round', value: 'round' },
      ]
      this.positionHorzOptions = [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
      ]
      this.positionVertOptions = [
        { label: 'Top', value: 'top' },
        { label: 'Center', value: 'center' },
        { label: 'Bottom', value: 'bottom' },
      ]
    }

    handlePropertyChange(evt, property, value) {
      let propValue = value || evt.currentTarget.value
      switch (property) {
        case 'media':
          if (propValue === '') {
            propValue = null
          }
          this.props.state[property] = propValue
          break

        case 'positionHorz':
          this.props.state.backgroundPosition =
            propValue + ' ' + this.getPositionComponent('vertical')
          break

        case 'positionVert':
          this.props.state.backgroundPosition =
            this.getPositionComponent('horizontal') + ' ' + propValue
          break

        default:
          this.props.state[property] = propValue
          console.log(this.props.state, property, this.props.state[property])
          break
      }
      this.props.onStepwiseEvent('state', this.props.state)
    }

    getVisualMedia() {
      var mediaItems = []
      Object.values(this.props.stepwise.score.media).forEach(
        (mediaItem, index) => {
          if (mediaItem.type === 'image' || mediaItem.type === 'video') {
            mediaItems.push({ label: mediaItem.name, value: mediaItem.id })
          }
        }
      )
      return mediaItems
    }

    getPositionComponent(component) {
      let temp = this.props.state.backgroundPosition.split(' ')
      let horz = temp[0]
      let vert
      if (temp.length > 1) {
        vert = temp[1]
      } else {
        vert = 'center'
      }
      switch (component) {
        case 'horizontal':
          return horz
        case 'vertical':
          return vert
        default:
          return horz
      }
    }

    handleSwitchChange(evt, property) {
      this.props.state[property] = evt.currentTarget.checked
    }

    handleSliderChange(property, value) {
      this.props.state[property] = value
    }

    getLayoutSettings() {
      if (this.props.state.type === 'image') {
        return (
          <React.Fragment>
            <FormGroup inline={true}>
              <ButtonGroup id="fit-select">
                <Button
                  active={this.props.state.fit === 'cover'}
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'fit', 'cover')
                  }
                >
                  Cover
                </Button>
                <Button
                  active={this.props.state.fit === 'contain'}
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'fit', 'contain')
                  }
                >
                  Contain
                </Button>
              </ButtonGroup>
            </FormGroup>{' '}
            &nbsp;&nbsp;
            <FormGroup inline={true}>
              <ButtonGroup>
                <Button
                  active={this.getPositionComponent('horizontal') === 'left'}
                  icon="alignment-left"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionHorz', 'left')
                  }
                />
                <Button
                  active={this.getPositionComponent('horizontal') === 'center'}
                  icon="alignment-vertical-center"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionHorz', 'center')
                  }
                />
                <Button
                  active={this.getPositionComponent('horizontal') === 'right'}
                  icon="alignment-right"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionHorz', 'right')
                  }
                />
              </ButtonGroup>{' '}
              &nbsp;
              <ButtonGroup>
                <Button
                  active={this.getPositionComponent('vertical') === 'top'}
                  icon="alignment-top"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionVert', 'top')
                  }
                />
                <Button
                  active={this.getPositionComponent('vertical') === 'center'}
                  icon="alignment-horizontal-center"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionVert', 'center')
                  }
                />
                <Button
                  active={this.getPositionComponent('vertical') === 'bottom'}
                  icon="alignment-bottom"
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'positionVert', 'bottom')
                  }
                />
              </ButtonGroup>
            </FormGroup>
          </React.Fragment>
        )
      } else if (this.props.state.type === 'video') {
        return (
          <React.Fragment>
            <FormGroup inline={true}>
              <ButtonGroup>
                <Button
                  active={this.props.state.fit === 'cover'}
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'fit', 'cover')
                  }
                >
                  Cover
                </Button>
                <Button
                  active={this.props.state.fit === 'contain'}
                  onClick={(evt) =>
                    this.handlePropertyChange(evt, 'fit', 'contain')
                  }
                >
                  Contain
                </Button>
              </ButtonGroup>
            </FormGroup>{' '}
            &nbsp;&nbsp;
            {/*<Popover>
          <Button minimal="true" icon="settings"/>
          <div className="state-editor-popover">
            <FormGroup label="Volume &nbsp;&nbsp;" inline={true} style={{marginRight:"10px"}}>
              <Slider
                min={0}
                max={1}
                labelStepSize={1}
                labelPrecision={1}
                stepSize={.01}
                vertical={false}
                value={this.props.state.volume}
                onChange={(value) => this.handleSliderChange('volume', value)}
              />
            </FormGroup>
          </div>
        </Popover> &nbsp;&nbsp;
        <FormGroup labelFor="loop-switch" inline={true}>
          <Switch id="loop-switch"
            checked={this.props.state.loop}
            label="Loop"
            onChange={(evt) => this.handleSwitchChange(evt, 'loop')}
          />
        </FormGroup> &nbsp;&nbsp;
        <FormGroup labelFor="pause-audio-switch" inline={true}>
          <Switch id="pause-audio-switch"
            checked={this.props.state.pauseOtherAudio}
            label="Pause other audio"
            onChange={(evt) => this.handleSwitchChange(evt, 'pauseOtherAudio')}
          />
        </FormGroup>*/}
          </React.Fragment>
        )
      } else {
        return null
      }
    }

    getAdvancedSettings() {
      if (this.props.state.type === 'image') {
        return (
          <React.Fragment>
            &nbsp;&nbsp;{' '}
            <Popover>
              <Button minimal={true}>More...</Button>
              <div className="text-settings">
                {/*<div className="form-col">
              <FormGroup label="Width" labelFor="width-input" inline={true}>
                <InputGroup id="width-input" style={{width: '60px'}} value={this.props.state.width}
                  placeholder="Width"
                  onChange={(evt) => this.handlePropertyChange(evt, 'width')}/>
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Height" labelFor="height-input" inline={true}>
                <InputGroup id="height-input" style={{width: '60px'}} value={this.props.state.height}
                  placeholder="Height"
                  onChange={(evt) => this.handlePropertyChange(evt, 'height')}/>
              </FormGroup>
            </div>*/}
                <FormGroup
                  label="Repeat"
                  labelFor="repeat-select"
                  inline={true}
                >
                  <HTMLSelect
                    id="repeat-select"
                    options={this.repeatOptions}
                    value={this.props.state.backgroundRepeat}
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'backgroundRepeat')
                    }
                  />
                </FormGroup>
                <FormGroup
                  label="Transform"
                  labelFor="transform-input"
                  inline={true}
                >
                  <InputGroup
                    id="transform-input"
                    value={this.props.state.transform}
                    placeholder="CSS transform"
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'transform')
                    }
                  />
                </FormGroup>
                <FormGroup label="Filter" labelFor="filter-input" inline={true}>
                  <InputGroup
                    id="filter-input"
                    value={this.props.state.filter}
                    placeholder="CSS filter"
                    onChange={(evt) => this.handlePropertyChange(evt, 'filter')}
                  />
                </FormGroup>
                <FormGroup
                  label="Transition duration (secs)"
                  labelFor="transition-duration-input"
                  inline={true}
                >
                  <InputGroup
                    id="transition-duration-input"
                    style={{ width: '50px' }}
                    placeholder="secs"
                    value={this.props.state.transitionDuration}
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'transitionDuration')
                    }
                  />
                </FormGroup>
                <FormGroup
                  label="Show caption"
                  labelFor="caption-switch"
                  inline={true}
                >
                  <Switch
                    id="caption-switch"
                    checked={this.props.state.showCaption}
                    alignIndicator={Alignment.LEFT}
                    onChange={(evt) =>
                      this.handleSwitchChange(evt, 'showCaption')
                    }
                    inline="true"
                  />
                </FormGroup>
              </div>
            </Popover>
          </React.Fragment>
        )
      } else if (this.props.state.type === 'video') {
        return (
          <React.Fragment>
            &nbsp;&nbsp;{' '}
            <Popover>
              <Button minimal={true}>More...</Button>
              <div className="text-settings">
                {/*<div className="form-col">
              <FormGroup label="Width" labelFor="width-input" inline={true}>
                <InputGroup id="width-input" style={{width: this.fieldWidth}} value={this.props.state.width}
                  placeholder="Width"
                  onChange={(evt) => this.handlePropertyChange(evt, 'width')}/>
              </FormGroup>
            </div>
            <div className="form-col">
              <FormGroup label="Height" labelFor="height-input" inline={true}>
                <InputGroup id="height-input" style={{width: this.fieldWidth}} value={this.props.state.height}
                  placeholder="Height"
                  onChange={(evt) => this.handlePropertyChange(evt, 'height')}/>
              </FormGroup>
            </div>
            <FormGroup label="Aspect ratio" labelFor="aspect-input" inline={true}>
              <InputGroup id="aspect-input" style={{width: '120px'}} value={this.props.state.aspectRatio}
                placeholder="Aspect ratio"
                onChange={(evt) => this.handlePropertyChange(evt, 'aspectRatio')}/>
            </FormGroup>*/}
                <FormGroup
                  label="Transform"
                  labelFor="transform-input"
                  inline={true}
                >
                  <InputGroup
                    id="transform-input"
                    value={this.props.state.transform}
                    placeholder="Transform"
                    onChange={(evt) =>
                      this.handlePropertyChange(evt, 'transform')
                    }
                  />
                </FormGroup>
                <FormGroup label="Filter" labelFor="filter-input" inline={true}>
                  <InputGroup
                    id="filter-input"
                    value={this.props.state.filter}
                    placeholder="CSS filter"
                    onChange={(evt) => this.handlePropertyChange(evt, 'filter')}
                  />
                </FormGroup>
                <FormGroup
                  label="Show caption"
                  labelFor="caption-switch"
                  inline={true}
                >
                  <Switch
                    id="caption-switch"
                    checked={this.props.state.showCaption}
                    alignIndicator={Alignment.LEFT}
                    onChange={(evt) =>
                      this.handleSwitchChange(evt, 'showCaption')
                    }
                    inline="true"
                  />
                </FormGroup>
              </div>
            </Popover>
          </React.Fragment>
        )
      } else {
        return null
      }
    }

    render() {
      return (
        <div className="state-editor-controls">
          {this.getLayoutSettings()}
          {this.getAdvancedSettings()}
        </div>
      )
    }
  }
)

export default VisualStateEditor
