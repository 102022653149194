import React from 'react'
import {
  Button,
  Intent,
  Popover,
  Menu,
  MenuItem,
  Icon,
} from '@blueprintjs/core'
import SearchMediaDialog from './SearchMediaDialog.js'
import LinkMediaDialog from './LinkMediaDialog.js'
import { Stepwise, Media } from '../stepworks/stepwise/stepwise-v2.js'
import { v4 as uuidv4 } from 'uuid'
import firebase from '../firebase.js'

class MediaSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedMedia: [],
      searchMediaDialogVisible: false,
      searchMediaDialogInitialTab: null,
      linkMediaDialogVisible: false,
    }
  }

  canUploadMedia() {
    return (
      this.props.uploadedMediaSize < this.props.maxUploadedMedia ||
      this.props.user.email === 'erikcloyer@gmail.com'
    )
  }

  formatParams(params) {
    return (
      '?' +
      Object.keys(params)
        .map(function (key) {
          return key + '=' + encodeURIComponent(params[key])
        })
        .join('&')
    )
  }

  generateSignature = (callback, params_to_sign) => {
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          callback(xhr.responseText)
        } else {
          console.log('error')
        }
      }
    }
    xhr.open(
      'GET',
      'https://origin.step.works/mediasignature' +
        this.formatParams(params_to_sign),
      true
    )
    xhr.send()
  }

  openUploadWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: 'hsvyywnhi',
        uploadPreset: 'ml_default',
        uploadSignature: this.generateSignature,
        api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
        dropboxAppKey: process.env.REACT_APP_DROPBOX_APP_KEY,
        googleDriveClientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        sources: [
          'local',
          'url',
          'camera',
          'image_search',
          'facebook',
          'dropbox',
        ],
        multiple: false,
        thumbnailTransformation: [{ width: 200, height: 200, crop: 'limit' }],
        defaultSource: 'local',
        cropping: false,
        showAdvancedOptions: false,
        styles: {
          palette: {
            tabIcon: '#FFFFFF',
            inactiveTabIcon: '#8E9FBF',
            action: '#336BFF',
            inProgress: '#00BFFF',
            complete: '#33ff00',
            error: '#EA2727',
            textLight: '#FFFFFF',
            window: '#253039',
            windowBorder: '#253039',
            menuIcons: '#9EADB9',
            link: '#137CBD',
            textDark: '#5C7080',
            sourceBg: '#10161A',
          },
          fonts: {
            default: null,
            'sans-serif': {
              url: null,
              active: true,
            },
          },
        },
      },
      (error, result) => {
        if (result.event === 'success') {
          this.handleUploadSuccess(result)
        } else if (error) {
          this.handleUploadError(error)
        }
      }
    )
  }

  getFeatureTypeForCommand(command) {
    switch (command) {
      case 'show-image':
        return Stepwise.FeatureTypes.IMAGE
      case 'show-video':
        return Stepwise.FeatureTypes.VIDEO
      case 'play-audio':
        return Stepwise.FeatureTypes.AUDIO
      default:
        return ''
    }
  }

  handleUploadError = (error) => {
    console.log(error)
    alert('An error occurred while uploading. Please try again.')
  }

  handleUploadSuccess(result) {
    console.log(result)
    let mediaItem = new Media({
      id: result.info.asset_id,
      name: result.info.original_filename,
      type: this.getFeatureTypeForCommand(this.props.editAction.command),
      source: result.info.secure_url,
      thumbnail: result.info.thumbnail_url,
      width: result.info.width,
      height: result.info.height,
    })
    const db = firebase.firestore()
    let mediaData = {
      assetId: result.info.asset_id,
      user: this.props.user.uid,
      name: 'Untitled',
      source: result.info.secure_url,
      thumbnail: result.info.thumbnail_url ? result.info.thumbnail_url : null,
      bytes: result.info.bytes,
    }
    if (result.info.original_filename)
      mediaData.name = result.info.original_filename
    if (result.info.thumbnail_url)
      mediaData.thumbnail_url = result.info.thumbnail_url
    if (result.info.width) mediaData.width = result.info.width
    if (result.info.height) mediaData.height = result.info.height
    console.log(mediaData)
    db.collection('media')
      .add(mediaData)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id)
      })
      .catch((error) => {
        console.error('Error adding media: ', error)
      })
    this.props.stepwise.score.addMedia(mediaItem)
    this.props.editAction.content = mediaItem.id
    this.props.editAction.updateFeature()
    this.props.onStepwiseEvent('action', this.props.editAction)
    this.props.onMediaUploaded()
    this.forceUpdate()
  }

  getWebcamMedia = () => {
    let webcam
    let mediaArr = Object.values(this.props.stepwise.score.media)
    let n = mediaArr.length
    for (var i = 0; i < n; i++) {
      if (mediaArr[i].source === 'webcam') {
        webcam = mediaArr[i]
      }
    }
    return webcam
  }

  addWebcam = () => {
    let webcam = this.getWebcamMedia()
    if (!webcam) {
      webcam = new Media({
        id: uuidv4(),
        name: 'Webcam',
        type: 'video',
        source: 'webcam',
        width: 1920,
        height: 1080,
      })
      this.props.stepwise.score.addMedia(webcam)
    }
    this.props.editAction.command = 'show-video'
    this.props.editAction.content = webcam.id
    this.props.stepwise.score.setupReferences()
    this.props.onStepChange(this.props.editStep)
  }

  handleMediaContentChange(evt, property) {
    let id = evt.currentTarget.getAttribute('data-media-id')
    let media = this.props.stepwise.score.getMedia(id)
    if (media) {
      this.props.editAction.content = id
      this.forceUpdate()
      this.props.onStepwiseEvent('action', this.props.editAction)
    }
  }

  openSearchMediaDialog = (initialTab) => {
    this.setState({
      searchMediaDialogVisible: true,
      searchMediaDialogInitialTab: initialTab,
    })
  }

  closeSearchMediaDialog = () => {
    this.setState({ searchMediaDialogVisible: false })
  }

  openLinkMediaDialog = () => {
    this.setState({
      linkMediaDialogVisible: true,
    })
  }

  closeLinkMediaDialog = () => {
    this.setState({ linkMediaDialogVisible: false })
  }

  getInitialSearchTabByMediaType(mediaType) {
    switch (mediaType) {
      case 'image':
        return 'unsplash-tab'
      case 'video':
        return 'giphy-tab'
      default:
        return null
    }
  }

  getMediaByType(mediaType) {
    let media = []
    Object.values(this.props.stepwise.score.media).forEach((mediaItem) => {
      if (mediaItem.type === mediaType) {
        media.push(mediaItem)
      }
    })
    return media
  }

  getMediaMenu(mediaArray) {
    return mediaArray.map((mediaItem, index) => {
      return (
        <MenuItem
          key={index}
          text={mediaItem.name}
          data-media-id={mediaItem.id}
          onClick={(evt) => this.handleMediaContentChange(evt)}
        />
      )
    })
  }

  getMediaMenuForCommand(command) {
    let searchAvailable = command === 'show-image' || command === 'show-video'
    let mediaType
    switch (command) {
      case 'show-image':
        mediaType = 'image'
        break
      case 'show-video':
      case 'set-video-volume':
        mediaType = 'video'
        break
      case 'play-audio':
      case 'set-audio-volume':
        mediaType = 'audio'
        break
      default:
        mediaType = 'media'
        break
    }
    let mediaArray = this.getMediaByType(mediaType)
    let canUploadMedia = this.canUploadMedia()
    return (
      <Menu>
        {canUploadMedia ? (
          <MenuItem
            icon="cloud-upload"
            text={'Upload ' + mediaType}
            onClick={this.openUploadWidget}
          />
        ) : null}
        {!canUploadMedia ? (
          <MenuItem
            icon="cloud-upload"
            text={
              'Upload limit exceeded (' + this.props.maxUploadedMedia + 'MB)'
            }
            disabled={true}
          />
        ) : null}
        <MenuItem
          icon="link"
          text={'Link to ' + mediaType}
          onClick={this.openLinkMediaDialog}
        ></MenuItem>
        {searchAvailable ? (
          <MenuItem
            icon="search"
            text={'Search for ' + mediaType}
            onClick={() =>
              this.openSearchMediaDialog(
                this.getInitialSearchTabByMediaType(mediaType)
              )
            }
          ></MenuItem>
        ) : null}
        {mediaArray.length > 0 ? (
          <MenuItem
            icon="media"
            text={'Select ' + mediaType}
            popoverProps={{ openOnTargetFocus: false }}
          >
            {this.getMediaMenu(mediaArray)}
          </MenuItem>
        ) : null}
        {mediaType === 'video' ? (
          <MenuItem
            icon="mobile-video"
            text={'Add webcam'}
            onClick={this.addWebcam}
          ></MenuItem>
        ) : null}
      </Menu>
    )
  }

  render() {
    let media = this.props.stepwise.score.getMedia(
      this.props.editAction.content
    )
    return (
      <div className="media-selector">
        <Popover
          content={this.getMediaMenuForCommand(this.props.editAction.command)}
        >
          <Button icon="media" intent={!media ? Intent.PRIMARY : Intent.NONE} />
        </Popover>
        &nbsp;&nbsp;
        {!media ? <span>No media selected</span> : null}
        {media ? (
          media.type === 'audio' ? (
            <span className="audio-thumb">
              <Icon icon="volume-up" />
              &nbsp;&nbsp;{media.name}
            </span>
          ) : null
        ) : null}
        {media ? (
          media.type === 'image' ? (
            <span>
              <img
                src={media.thumbnail ? media.thumbnail : media.source}
                alt={media.name}
              />
              &nbsp;&nbsp;{media.name}
            </span>
          ) : null
        ) : null}
        {media ? (
          media.type === 'video' ? (
            media.thumbnail ? (
              <span>
                <img src={media.thumbnail} alt={media.name} />
                &nbsp;&nbsp;{media.name}
              </span>
            ) : media.source === 'webcam' ? (
              <span>
                <img src="images/webcam.png" alt={media.name} />
                &nbsp;&nbsp;Webcam
              </span>
            ) : (
              <span>
                <img src="images/video.png" alt={media.name} />
                &nbsp;&nbsp;{media.name}
              </span>
            )
          ) : null
        ) : null}
        <SearchMediaDialog
          stepwise={this.props.stepwise}
          sequence={this.props.sequence}
          editStep={this.props.editStep}
          editAction={this.props.editAction}
          editCharacter={this.props.editCharacter}
          onStepwiseEvent={this.props.onStepwiseEvent}
          isOpen={this.state.searchMediaDialogVisible}
          initialTab={this.state.searchMediaDialogInitialTab}
          onClose={this.closeSearchMediaDialog}
          onStepChange={this.props.onStepChange}
          onStoryUpdate={this.props.onStoryUpdate}
        />
        <LinkMediaDialog
          stepwise={this.props.stepwise}
          sequence={this.props.sequence}
          editStep={this.props.editStep}
          editAction={this.props.editAction}
          editCharacter={this.props.editCharacter}
          onStepwiseEvent={this.props.onStepwiseEvent}
          isOpen={this.state.linkMediaDialogVisible}
          onClose={this.closeLinkMediaDialog}
          onStepChange={this.props.onStepChange}
          onStoryUpdate={this.props.onStoryUpdate}
        />
      </div>
    )
  }
}

export default MediaSelector
